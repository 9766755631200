import { IAddress } from '../react-app-env';

export function fillInAddress(
  autocomplete: google.maps.places.Autocomplete,
): IAddress {
  // Get the place details from the autocomplete object.
  const place = autocomplete.getPlace();

  let address1 = '';
  let address2 = '';
  let city = '';
  let state = '';
  let postcode = null;

  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr
  for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
    const componentType = component.types;

    if (componentType.includes('street_number')) {
      address1 = `${component.long_name} ${address1}`;
    }

    if (componentType.includes('route')) {
      address1 += component.short_name;
    }

    if (componentType.includes('subpremise')) {
      address2 = component.short_name;
    }

    if (componentType.includes('postal_code')) {
      postcode = `${component.long_name}${postcode}`;
    }

    if (componentType.includes('postal_code_suffix')) {
      postcode = `${postcode}-${component.long_name}`;
    }

    if (
      componentType.includes('locality') ||
      componentType.includes('sublocality')
    ) {
      city = component.long_name;
    }

    if (componentType.includes('administrative_area_level_1')) {
      state = component.short_name;
    }
  }

  // After filling the form with address components from the Autocomplete
  // prediction, set cursor focus on the second address line to encourage
  // entry of subpremise information such as apartment, unit, or floor number.
  // address2Field.focus()

  return {
    address1,
    address2,
    city,
    state,
    postalCode: postcode,
    latitude: place?.geometry?.location?.lat(),
    longitude: place?.geometry?.location?.lng(),
  };
}

export function validateAddress(address: Partial<IAddress>) {
  if (!address.city || !address.state) return false;
  return true;
}
