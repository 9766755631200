import { IDropdownOption } from '@fluentui/react';
import { DateTime } from 'luxon';

export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const isNumber = (value: any): value is number => {
  return typeof value === 'number';
};

const isString = (value: any): value is string => {
  return typeof value === 'string';
};

const isArray = <T>(value: any): value is Array<T> => {
  return Array.isArray(value);
};

export const isBooleanObject = (
  value: any,
): value is { [key: string]: boolean; [key: number]: boolean } => {
  let isObject = true;
  if (typeof value === 'object' && value !== null && !isArray(value)) {
    for (const key of Object.keys(value)) {
      if (typeof value[key] !== 'boolean') {
        isObject = false;
      }
    }
  }
  return isObject;
};

export const classNames = (...args: any[]) => {
  const classes: (string | number)[] = [];
  for (const className of args) {
    if (!className) continue;

    if (isNumber(className) || isString(className)) {
      classes.push(className);
    } else if (isArray(className) && className.length > 0) {
      const joinedClassNames = classNames(...className);
      if (joinedClassNames) {
        classes.push(joinedClassNames);
      }
    } else if (isBooleanObject(className)) {
      for (const key of Object.keys(className)) {
        if (className[key]) {
          classes.push(key);
        }
      }
    }
  }

  return classes.join(' ');
};

export function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export async function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/sw.js', {
        scope: '/',
      });
      if (registration.installing) {
        console.log('Service worker installing');
      } else if (registration.waiting) {
        console.log('Service worker installed');
      } else if (registration.active) {
        console.log('Service worker active');
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
}

export function formatDateTimeInput(date: string, format = 'MM/dd/yyyy') {
  return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat(format);
}

export function formatDateForInput(date?: string, format = 'MM/dd/yyyy') {
  if (!date) return '';
  return DateTime.fromFormat(date, format).toFormat('yyyy-MM-dd');
}

export function setRegion(city: string | null, state: string | null) {
  if (city && state) return `${city}, ${state}`;
  else if (state) return state;
  else return '-';
}

export function setFieldIfEmpty(thing: any) {
  if (thing) return thing;
  else return '-';
}

export function validatePassword(p: string) {
  const errors: string[] = [];

  const passwordRegex =
    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z\d]).{8,}$/;

  if (!passwordRegex.test(p)) {
    if (p.length < 8) errors.push('Must be at least 8 characters long');
    if (!/(?=.*[A-Z])/.test(p))
      errors.push('Must have at least 1 uppercase letter');
    if (!/(?=.*[a-z])/.test(p))
      errors.push('Must have at least 1 lowercase letter');
    if (!/(?=.*\d)/.test(p)) errors.push('Must have at least 1 number');
    if (!/(?=.*[^A-Za-z\d])/.test(p))
      errors.push('Must have at least 1 special character');
  }

  return errors;
}

export const formatToUSPhoneNumber = (phoneNumber: string) => {
  // Remove the leading `+` and `1` for E.164 format numbers starting with +1
  const cleaned = phoneNumber.replace(/^\+1/, '');

  // Extract area code, central office code, and station code
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phoneNumber; // Return the original if it doesn't match
};

export const formatDateToBackend = (date: Date): string => {
  const pad = (number: number): string =>
    number < 10 ? '0' + number : number.toString();

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
};

export function formatDateString(input: string | null | undefined) {
  if (!input) return '';

  const date = new Date(input + 'Z');

  if (isNaN(date.getTime())) {
    return '';
  }

  // Get the day, month, and year from the Date object
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();

  // Return the formatted date string
  return `${month}/${day}/${year}`;
}

export function convertUTCToLocal(utcDateString: string) {
  if (!utcDateString) return '';
  const utcDate = new Date(utcDateString + 'Z');
  if (isNaN(utcDate.getTime())) {
    return '';
  }

  const localDateString = utcDate.toLocaleString();

  return localDateString;
}

export const isValidEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export function capitalizeName(name: string): string {
  return name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export const handleMergeOptions = (
  pathData: string,
  option: IDropdownOption<any> | undefined,
) => {
  const optionKey = option?.key;
  const selected = option?.selected;
  if (pathData) {
    if (selected) {
      return [...pathData.split(','), optionKey].join(',');
    } else {
      return pathData
        .split(',')
        .filter((data: string) => data !== optionKey)
        .join(',');
    }
  } else {
    return optionKey;
  }
};

export const isWebKit = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    /webkit/.test(userAgent) && !/chrome|crios|crmo|android/.test(userAgent)
  );
};

export const isAuthRoute = () => {
  const routes = ['/login', '/auth', '/password-reset'];
  const currentPath = window?.location?.pathname;

  return routes.some((route) => currentPath?.startsWith(route));
};

export const isOlderThan7Days = (dateString: string | number | Date) => {
  // Parse the given date string
  const givenDate = new Date(dateString);

  // Get the current date
  const currentDate = new Date();

  // Calculate the date 7 days ago from the current date
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(currentDate.getDate() - 7);

  // Compare the given date with the date 7 days ago
  return givenDate < sevenDaysAgo;
};

export const getDateRangeForPeriod = (period: string) => {
  const now = new Date();
  let startDate, endDate;
  const currentQuarter = Math.floor(now.getMonth() / 3);

  switch (period) {
    case 'week':
      // Start of the week (Sunday)
      startDate = new Date(now);
      startDate.setDate(now.getDate() - now.getDay() + 1);

      // End of the week (Saturday)
      endDate = new Date(now);
      endDate.setDate(now.getDate() + (6 - now.getDay() + 1));
      break;

    case 'month':
      // Start of the month
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);

      // End of the month
      endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      break;

    case 'quarter':
      // Start of the quarter
      startDate = new Date(now.getFullYear(), currentQuarter * 3, 1);

      // End of the quarter
      endDate = new Date(now.getFullYear(), (currentQuarter + 1) * 3, 0);
      break;

    case 'year':
      // Start of the year
      startDate = new Date(now.getFullYear(), 0, 1);

      // End of the year
      endDate = new Date(now.getFullYear(), 11, 31);
      break;

    default:
      throw new Error('Invalid period');
  }

  const formatDate = (date: Date) => {
    const pad = (number: number): string =>
      number < 10 ? '0' + number : number.toString();

    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
  };

  return { startDate: formatDate(startDate), endDate: formatDate(endDate) };
};

export function formatDateRange(startDate: string, endDate: string) {
  // Convert the input strings to Date objects using the proper ISO format
  const start = new Date(`${startDate}T00:00:00Z`); // Use Z to keep it in UTC
  const end = new Date(`${endDate}T00:00:00Z`);

  // Ensure valid date objects are created
  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    return 'Invalid Date Range';
  }

  // Get the month and day parts without localization
  const startMonth = start.toLocaleString('en-US', {
    month: 'long',
    timeZone: 'UTC',
  });
  const endMonth = end.toLocaleString('en-US', {
    month: 'long',
    timeZone: 'UTC',
  });
  const startDay = start.getUTCDate();
  const endDay = end.getUTCDate();
  const year = start.getUTCFullYear();

  // Combine them into the desired format
  return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${year}`;
}

export function convertSecondsToHHMM(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export function formatCurrency(num: number): string {
  return num.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}
export const generateTimeOptions = () => {
  const options = [];
  for (let hour = 6; hour <= 21; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const militaryTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

      let displayHour = hour % 12;
      displayHour = displayHour === 0 ? 12 : displayHour;
      const amPm = hour < 12 ? 'AM' : 'PM';
      const displayTime = `${displayHour}:${minute.toString().padStart(2, '0')} ${amPm}`;

      options.push({ value: militaryTime, label: displayTime });
    }
  }
  return options;
};
