import { useMemo, useState } from 'react';

import { Stack, TextInput } from '../../components/common';
import { APIOrganization } from '../../types/organizations';

type ContingencyAgreementDetailsProps = {
  values: {
    dateSigned?: string;
    paymentTerms?: string;
    lateFee?: string;
    guarantee?: string;
    feePercentage: number | null;
  };
  onChangeHandler: (
    path: keyof Pick<
      APIOrganization,
      'dateSigned' | 'paymentTerms' | 'lateFee' | 'guarantee' | 'feePercentage'
    >,
  ) => (value: any) => void;
  errors: {
    dateSigned?: string;
    paymentTerms?: string;
    lateFee?: string;
    guarantee?: string;
    feePercentage?: string;
  };
  areInputsDisabled?: boolean;
};

export function ContingencyAgreementDetails({
  values,
  onChangeHandler,
  errors,
}: ContingencyAgreementDetailsProps) {
  const [isFocused, setIsFocused] = useState(false);

  const feePercentageValue = useMemo(() => {
    if (isFocused) {
      return values?.feePercentage ?? '';
    } else {
      return values?.feePercentage
        ? `${values?.feePercentage}${values?.feePercentage ? '%' : ''}`
        : '';
    }
  }, [values.feePercentage, isFocused]);

  return (
    <div className='w-full rounded-md border bg-white p-4 shadow-md'>
      <p className='text-xl font-bold'>Contingency Agreement Details</p>

      <Stack spacing={8} className='mt-4'>
        <TextInput
          label='Date Signed'
          type='date'
          value={values?.dateSigned ?? ''}
          handleChange={onChangeHandler('dateSigned')}
          errorMessage={errors?.dateSigned}
        />
        <TextInput
          label='Payment Terms'
          value={values.paymentTerms}
          handleChange={onChangeHandler('paymentTerms')}
          errorMessage={errors.paymentTerms}
        />
        <TextInput
          label='Fee (%)'
          type={isFocused ? 'number' : 'text'}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={feePercentageValue}
          handleChange={onChangeHandler('feePercentage')}
          errorMessage={errors.feePercentage}
        />
        <TextInput
          label='Late Fee'
          value={values.lateFee}
          handleChange={onChangeHandler('lateFee')}
          errorMessage={errors.lateFee}
        />
        <TextInput
          label='Guarantee'
          value={values.guarantee}
          handleChange={onChangeHandler('guarantee')}
          errorMessage={errors.guarantee}
        />
      </Stack>
    </div>
  );
}
