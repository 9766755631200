import { Fragment } from 'react';

import { objectToQueryString, QueryParams } from '../hooks/useQueryParams';

export function formatText(input: string) {
  const texts = input.includes('\r\n')
    ? input.split('\r\n')
    : input.split('\n');
  return texts.map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));
}

export function formatNoteText(input: string) {
  const texts = input.includes('\r\n')
    ? input.split('\r\n')
    : input.split('\n');
  return texts.map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));
}

export function removeDateFromDescription(description: string | null) {
  if (!description) return '';

  const dateRegex =
    /\s*\(\w{3} \w{3}-\d{1,2} '\d{2} \d{1,2}:\d{2}[ap]\/[A-Z]+\)$/;

  return description.replace(dateRegex, '');
}

/**
 * Function to remove the page query from the slideout. For context, we're using "page" for a query parameter in the slideout activities tab. We use "pageNumber" for the main page itself.
 * @param queryParams
 * @param setQueryParams
 * @returns
 */
export const resetPageNumber = (queryParams: QueryParams) => {
  const newParams = { ...queryParams };
  if (newParams.page) {
    delete newParams.page;
  }

  const newQueryParamString = objectToQueryString(newParams);

  return newQueryParamString;
};
