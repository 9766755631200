import RootLayout from '../../../layouts';
import { Users } from './Users';

const UsersPage = () => {
  return (
    <RootLayout>
      <Users />
    </RootLayout>
  );
};

export default UsersPage;
