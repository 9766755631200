import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '../../components/common/Button';
import SearchInput from '../../components/filters/SearchInput';
import StageInput from '../../components/filters/StageInput';
import UsersInput from '../../components/filters/UsersInput';
import { useQueryParams } from '../../hooks/useQueryParams';
import { PageTypes } from '../../types/pages';

function ListDetailsFilters({
  orgCount,
  contactCount,
  reqCount,
}: {
  orgCount: number;
  contactCount: number;
  reqCount: number;
  isBulkActionDisabled: boolean;
  isLoading: boolean;
}) {
  const { type } = useParams();

  const { resetQueryParams, queryParamsSize } = useQueryParams();

  const hasActiveFilters = useMemo(() => {
    return !!queryParamsSize;
  }, [queryParamsSize]);

  return (
    <>
      <div className='w flex w-full flex-col gap-2'>
        <div className='flex w-full items-start justify-between'>
          <div className='flex gap-2'>
            <Tabchange
              type={PageTypes.Contacts}
              label='Contacts'
              count={contactCount}
            />
            <Tabchange
              type={PageTypes.Organizations}
              label='Organizations'
              count={orgCount}
            />
            <Tabchange
              type={PageTypes.Requisitions}
              label='Requisitions'
              count={reqCount}
            />
          </div>
        </div>

        <div className='flex w-full items-start justify-between gap-2'>
          <div className='flex flex-wrap items-center gap-2'>
            <SearchInput />
            <UsersInput />
            {type !== PageTypes.Requisitions && <StageInput />}
          </div>

          <Button
            variant='ghost'
            className={`text-sm ${!hasActiveFilters && 'cursor-default select-none opacity-0'}`}
            onClick={() => {
              resetQueryParams();
            }}
          >
            Remove filters
          </Button>
        </div>
      </div>
    </>
  );
}

export default ListDetailsFilters;

function Tabchange({
  label,
  type,
  count,
}: {
  label: string;
  type: PageTypes;
  count: number;
}) {
  const { id, type: paramType } = useParams();
  const navigate = useNavigate();

  function handleQuickTabChange() {
    navigate(`/lists/${id}/${type}`);
  }

  const checked = type === paramType;

  return (
    <>
      <input
        type='radio'
        id={`${type}-radio`}
        name='quick-tab'
        className='size-0! invisible absolute'
        onChange={handleQuickTabChange}
        checked={checked}
      />
      <label
        htmlFor={`${type}-radio`}
        className={`h-8 w-fit cursor-pointer text-nowrap rounded-md border border-neutral-40 px-2 py-1 text-sm font-medium shadow-sm ${checked ? 'border-secondary-80 bg-secondary-80 text-white' : 'border-neutral-40 bg-white text-neutral-60 hover:border-neutral-60'}`}
        role='radio'
        aria-checked={checked}
        tabIndex={0}
      >
        {`${label} (${count})`}
      </label>
    </>
  );
}
