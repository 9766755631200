import { Button } from '../../components/common/Button';
import Modal from '../../components/common/ModalRadix';
import { Label } from '../../components/ui/label';
import { Textarea } from '../../components/ui/textarea';
import { APIJobContactPipelinePosition } from '../../types/pipeline';

export const ViewAIAnalysisObject = ({
  handleClose,
  pipelineRecord,
}: {
  viewAIAnalysis: object;
  pipelineRecord: APIJobContactPipelinePosition;
  handleClose: () => void;
}) => {
  return (
    <Modal open onOpenChange={handleClose}>
      <Modal.Content title={`AI Analysis`}>
        <div className='space-y-8'>
          <div className='space-y-4'>
            <div>
              <Label className='font-medium'>
                <span className='text-error'>*</span> Do not screen candidates
                based on AI analysis alone. Treat this only as a second opinion.
              </Label>
              <Textarea
                value={
                  pipelineRecord.applicantAIMatchAnalysis == null
                    ? ''
                    : pipelineRecord.applicantAIMatchAnalysis
                }
                className='placeholder:text-neutral'
                readOnly
                style={{ height: 300 }}
              />
            </div>
          </div>
          <div className='flex justify-between'>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ViewAIAnalysisObject;
