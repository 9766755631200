import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ListBulkActions from '../../components/bulk-actions/ListBulkActions';
import { getActivityIcon } from '../../components/common/Activity';
import { BaseTable, Column } from '../../components/common/BaseTable';
import { Button } from '../../components/common/Button';
import { DropdownMenu } from '../../components/common/Dropdown';
import { EmptyUser } from '../../components/common/Icons';
import OrganizationName from '../../components/common/OrganizationName';
import PhoneNumber from '../../components/common/PhoneNumber';
import {
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import { useQueryParams } from '../../hooks/useQueryParams';
import { APIContact } from '../../types/contacts';
import { PageTypes } from '../../types/pages';
import { CheckboxProps } from '../../types/table';
import { legendColors } from '../../utils/constant';
import {
  setFieldIfEmpty,
  setRegion,
  convertUTCToLocal,
} from '../../utils/helpers';

interface ContactsTableProps {
  data: APIContact[];
  handleRowClick: (value: APIContact) => void;
  className?: string;
  checkboxProps?: CheckboxProps;
  hideBulkActions?: boolean;
}

export function ContactsTable({
  data,
  handleRowClick,
  className,
  checkboxProps,
  hideBulkActions = false,
}: ContactsTableProps) {
  const navigate = useNavigate();
  const { queryParamsString } = useQueryParams();
  const queryParams = new URLSearchParams(queryParamsString);

  // Filter data based on engagementColor
  const filteredData = queryParams.get('engagementColor')
    ? data.filter(
        (contact) =>
          contact.engagementColor === queryParams.get('engagementColor'),
      )
    : data;

  const toggleColumnVisibility = (c: Column<APIContact>) => {
    const index = columns.map((column) => column.key).indexOf(c.key);
    setColumns((prevState) => {
      prevState[index].visible = !prevState[index].visible;
      return [...prevState];
    });
  };

  const [columns, setColumns] = useState<Column<APIContact>[]>([
    {
      label: 'Activity',
      key: 'engagementColor',
      visible: true,
      sortEnabled: false,
      className: 'w-[4rem] parent',
      render: (contact) => (
        <center>
          <div
            className={`relative h-5 w-5 rounded-full transition-all`}
            style={{ backgroundColor: contact.engagementColor }}
          >
            <span className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xs font-bold text-white'>
              <>
                {
                  legendColors.find((c) => c.color === contact.engagementColor)
                    ?.label
                }
              </>
            </span>
          </div>
        </center>
      ),
    },
    {
      label: 'Name',
      key: 'firstName',
      visible: true,
      sortEnabled: true,
      className: 'w-[14rem]',
      render: (contact, queryParamsString) => (
        <div
          className='flex items-center gap-2 text-link hover:text-[#167dff] hover:underline'
          onClick={(e) => {
            e.stopPropagation();
            navigate(
              `/contacts/${contact.contactId}${queryParamsString ? `?${queryParamsString}` : ''}`,
            );
          }}
        >
          <EmptyUser size={24} className='flex-shrink-0' />
          <span className='truncate'>
            {contact.firstName} {contact.lastName}
          </span>
        </div>
      ),
    },
    {
      label: 'Title',
      key: 'title',
      visible: true,
      sortEnabled: true,
      className: 'w-[18rem]',
      render: (contact) => setFieldIfEmpty(contact.title),
    },
    {
      label: 'Organization',
      key: 'organizationName',
      visible: true,
      sortEnabled: true,
      className: 'w-[18rem]',
      render: (contact) => (
        <OrganizationName
          hasContract={!!contact.orgHasContract}
          name={contact.organizationName}
        />
      ),
    },
    {
      label: 'City/State',
      key: 'city',
      visible: true,
      sortEnabled: true,
      className: 'w-[12rem]',
      render: (contact) => setRegion(contact.city, contact.state),
    },
    {
      label: 'Date Added',
      key: 'dateEntered',
      visible: true,
      sortEnabled: true,
      className: 'w-[14rem]',
      render: (contact) => setFieldIfEmpty(contact.dateEntered),
    },
    {
      label: 'Last Activity Date',
      key: 'dateLastActivity',
      visible: true,
      sortEnabled: true,
      className: 'w-[14rem]',
      render: (contact) => {
        const lastActivityIcon = getActivityIcon(
          contact?.lastActivityType || '',
          contact?.lastActivityResult || '',
        );
        return (
          <div className='flex items-center'>
            {lastActivityIcon && (
              <div className='material-symbols-outlined mr-2'>
                {lastActivityIcon}
              </div>
            )}
            {setFieldIfEmpty(
              convertUTCToLocal(contact?.dateLastActivity || ''),
            )}
          </div>
        );
      },
    },
    {
      label: 'Phone',
      key: 'phone',
      visible: true,
      sortEnabled: false,
      className: 'w-[12rem]',
      render: (contact) => (
        <PhoneNumber phoneNumber={contact.phone} extension={contact.phoneExt} />
      ),
    },
    {
      label: 'Email',
      key: 'emailAddress',
      visible: true,
      sortEnabled: true,
      className: 'w-[14rem]',
      render: (contact) => setFieldIfEmpty(contact.emailAddress),
    },
  ]);

  return (
    <>
      <div className='z-10 flex w-full'>
        <DropdownMenu>
          <DropdownMenuTrigger className='m-2'>
            <Button variant='ghost'>
              <div className='flex items-center gap-1'>
                <ChevronDown size={16} /> Columns
              </div>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {columns.map((c: Column<APIContact>) => (
              <DropdownMenuCheckboxItem
                checked={c.visible}
                onCheckedChange={() => toggleColumnVisibility(c)}
                key={c.key}
              >
                {c.label}
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <BaseTable
        data={filteredData ? filteredData : data}
        columns={columns}
        idKey='contactId'
        defaultSortKey='dateEntered'
        handleRowClick={handleRowClick}
        className={className}
        checkboxProps={checkboxProps}
      />

      {!hideBulkActions && checkboxProps && (
        <ListBulkActions
          checkboxProps={checkboxProps}
          itemType={PageTypes.Contacts}
        />
      )}
    </>
  );
}

export default ContactsTable;
