import { useDebouncedQueryParam } from '../../hooks/useDebouncedQueryParams';
import { Input } from '../ui/input';

function CityInput() {
  const [city, setCity] = useDebouncedQueryParam({
    key: 'city',
    minLength: 2,
  });

  return (
    <div>
      <Input
        id='city-input'
        value={city}
        placeholder='City'
        className='w-36'
        onChange={(event) => {
          setCity(event.target.value);
        }}
      />
    </div>
  );
}

export default CityInput;
