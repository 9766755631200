import { DragEndEvent } from '@dnd-kit/core';
import { useCallback, useState } from 'react';

import { Toast } from '../components/toast/Toast';
import { APIJobContactPipelinePosition, APIPipeline } from '../types/pipeline';
import { PipelinePosition } from '../types/pipeline';
import { formatDateToBackend } from '../utils/helpers';
import { sortJobContactPipelinePositions } from '../utils/pipeline';
import { useCustomMutation } from './useCustomMutation';

export interface DialogState {
  isOpen: boolean;
  candidateData: APIJobContactPipelinePosition | null;
  newPosition: PipelinePosition | null;
  currentPosition?: PipelinePosition | null;
}

export function usePipelineDragEnd({
  refetch,
  pipelineAPIresponse,
  setPipelineData,
  setIsDragging,
}: {
  refetch: () => void;
  pipelineAPIresponse: APIPipeline | undefined;
  setPipelineData: React.Dispatch<
    React.SetStateAction<APIPipeline | undefined>
  >;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [dialogState, setDialogState] = useState<DialogState>({
    isOpen: false,
    candidateData: null,
    newPosition: null,
  });

  const { mutate: updateCandidatePosition, ...mutationProps } =
    useCustomMutation({
      method: 'patch',
      onSuccess: () => refetch(),
      onSuccessMessage: 'Candidate position updated!',
      onMutate: () => {
        if (!pipelineAPIresponse) return;
        const sortedPositions = sortJobContactPipelinePositions(
          pipelineAPIresponse.jobContactPipelinePositions,
        );
        const previousData = {
          ...pipelineAPIresponse,
          jobContactPipelinePositions: sortedPositions,
        };
        return { previousData };
      },
      onError: (_error, _variables, context) => {
        if (context?.previousData) {
          setPipelineData(context.previousData);
        }
      },
    });

  const updatePipelineDataOptimistically = useCallback(
    (
      candidateData: APIJobContactPipelinePosition,
      newPosition: PipelinePosition,
    ) => {
      const date = formatDateToBackend(new Date());
      setPipelineData((prev) => {
        const updatedPositions = prev!.jobContactPipelinePositions.map(
          (position) =>
            position.jobContactPipelinePositionId ===
            candidateData.jobContactPipelinePositionId
              ? {
                  ...position,
                  pipelinePositionId: newPosition.pipelinePositionId,
                  pipelinePosition: newPosition.pipelinePosition,
                  pipelinePositionOrder: newPosition.pipelinePositionOrder,
                  pipelinePositionUuid: newPosition.pipelinePositionId,
                  updateDate: date,
                }
              : position,
        );
        return {
          ...prev!,
          jobContactPipelinePositions:
            sortJobContactPipelinePositions(updatedPositions),
        };
      });
    },
    [setPipelineData],
  );

  const handleDragEnd = (event: DragEndEvent) => {
    setIsDragging(false);

    const { over, active } = event;
    const jobContactPipelinePosition = active?.data?.current
      ?.jobContactPipelinePosition as APIJobContactPipelinePosition;
    const pipelineColumn = over?.data?.current as PipelinePosition;

    if (!jobContactPipelinePosition || !pipelineColumn) return;

    if (
      Math.abs(
        jobContactPipelinePosition.pipelinePositionOrder -
          pipelineColumn.pipelinePositionOrder,
      ) > 1
    ) {
      Toast.error('Cannot move more than one stage at a time.');
      return;
    }

    if (
      jobContactPipelinePosition.pipelinePositionId ===
      pipelineColumn.pipelinePositionId
    )
      return;

    updatePipelineDataOptimistically(
      jobContactPipelinePosition,
      pipelineColumn,
    );

    setDialogState({
      isOpen: true,
      candidateData: jobContactPipelinePosition,
      newPosition: pipelineColumn,
    });
  };

  const handleDialogSubmit = (additionalData: any) => {
    const { candidateData, newPosition } = dialogState;
    if (!candidateData || !newPosition) return;

    const date = formatDateToBackend(new Date());

    const updatedPosition = {
      ...candidateData,
      pipelinePositionId: newPosition.pipelinePositionId,
      pipelinePosition: newPosition.pipelinePosition,
      pipelinePositionOrder: newPosition.pipelinePositionOrder,
      pipelinePositionUuid: newPosition.pipelinePositionId,
      updateDate: date,
      ...additionalData,
    };

    updateCandidatePosition({
      url: `/jobcontactpipelinepositions/${candidateData.jobContactPipelinePositionId}`,
      body: updatedPosition,
    });

    setDialogState({ isOpen: false, candidateData: null, newPosition: null });
  };

  const handleDialogCancel = () => {
    if (pipelineAPIresponse) {
      const sortedPositions = sortJobContactPipelinePositions(
        pipelineAPIresponse.jobContactPipelinePositions,
      );
      setPipelineData({
        ...pipelineAPIresponse,
        jobContactPipelinePositions: sortedPositions,
      });
    }
    setDialogState({ isOpen: false, candidateData: null, newPosition: null });
  };

  return {
    handleDragEnd,
    dialogState,
    handleDialogSubmit,
    handleDialogCancel,
    ...mutationProps,
  };
}
