import { RouteObject } from 'react-router-dom';

import { Error } from './components/common';
import { NotFoundPage } from './components/common/NotFound';
import { Login } from './components/login/Login';
import { PasswordReset } from './components/login/PasswordReset';
import { PasswordResetToken } from './components/login/PasswordResetToken';
import HotSheetsPage from './pages/admin/hot-sheets';
import ProductivityPage from './pages/admin/productivity';
import Users from './pages/admin/users';
import { AddEditUser } from './pages/admin/users/AddEditUser';
import Auth from './pages/auth';
import ContactsPage from './pages/contacts';
import { AddContactPage } from './pages/contacts/AddContact';
import DashboardPage from './pages/dashboard';
import ListsPage from './pages/lists';
import ListDetailsPage from './pages/lists-details';
import { AddListPage } from './pages/lists/AddList';
import OrganizationsPage from './pages/organizations';
import { AddOrganizationPage } from './pages/organizations/AddOrganization';
import PipelineNew from './pages/pipeline';
import RequisitionsPage from './pages/requisitions';
import { AddRequisitionPage } from './pages/requisitions/AddRequisition';
import './styles/index.css';

interface IRoutes {
  enabled?: boolean;
  navName?: string;
}

type Routes = IRoutes & RouteObject;

// TODO - we can nest our routes https://reactrouter.com/en/main/start/overview#nested-routes
export const routes: Routes[] = [
  {
    path: '/',
    element: <DashboardPage />,
    enabled: true,
  },
  {
    path: '/contacts',
    element: <ContactsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/contacts/:contactId',
    element: <ContactsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/contacts/:contactId/:tabId',
    element: <ContactsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/contacts/add',
    element: <AddContactPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/organizations',
    element: <OrganizationsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/organizations/:organizationId',
    element: <OrganizationsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/organizations/:organizationId/:tabId',
    element: <OrganizationsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/organizations',
    element: <OrganizationsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/organizations/add',
    element: <AddOrganizationPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/requisitions',
    element: <RequisitionsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/requisitions/:requisitionId',
    element: <RequisitionsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/requisitions/:requisitionId/:tabId',
    element: <RequisitionsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/requisitions/add',
    element: <AddRequisitionPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/admin/hot-sheets',
    element: <HotSheetsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/admin/productivity',
    element: <ProductivityPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/admin/users/add',
    element: <AddEditUser />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/admin/users',
    element: <Users />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/admin/users/:id/edit',
    element: <AddEditUser />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/lists',
    element: <ListsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/lists/add',
    element: <AddListPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/lists/edit/:id',
    element: <AddListPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/lists/:id/:type/:entityId/:tabId',
    element: <ListDetailsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/lists/:id/:type/:entityId',
    element: <ListDetailsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/lists/:id/:type',
    element: <ListDetailsPage />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/pipeline/:id',
    element: <PipelineNew />,
    errorElement: <Error />,
    enabled: true,
  },
  {
    path: '/login',
    element: <Login />,
    enabled: true,
  },
  {
    path: '/auth',
    element: <Auth />,
    enabled: true,
  },
  {
    path: '/password-reset/:token',
    element: <PasswordResetToken />,
    enabled: true,
  },
  {
    path: '/password-reset',
    element: <PasswordReset />,
    enabled: true,
  },
  {
    path: '*',
    element: <NotFoundPage />,
    enabled: true,
  },
];
