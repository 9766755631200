import React, {
  PropsWithChildren,
  AriaAttributes,
  useCallback,
  useMemo,
} from 'react';

import { useQueryParams } from '../../hooks/useQueryParams';
import { cn } from '../../lib/utils';
import colors from '../../styles/colors';
import { ArrowDownIcon, ArrowUpIcon, SortIcon } from './Icons';

interface TableBase extends AriaAttributes {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  ref?: React.Ref<any>;
}

interface RowProps extends TableBase {
  tabIndex?: number;
  onClick?: (
    event: React.MouseEvent<HTMLTableRowElement> | React.KeyboardEvent,
  ) => void;
  disabled?: boolean;
  checked?: boolean;
}

export const Row: React.FC<RowProps> = ({
  children,
  onClick,
  className,
  disabled,
  checked,
  ...props
}) => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === ' ' || event.key === 'Enter') {
        onClick?.(event);
      }
    },
    [onClick],
  );

  return (
    <tr
      {...props}
      className={cn(
        `cursor-pointer hover:bg-neutral-20 ${!!checked && 'bg-secondary-10'} ${!!disabled && 'bg-neutral-20'}`,
        className,
      )}
      onKeyDown={handleKeyDown}
      onClick={onClick}
    >
      {children}
    </tr>
  );
};

export interface RowCellProps
  extends React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
  > {}

export const RowCell: React.FC<RowCellProps> = ({
  children,
  className,
  ...rest
}) => (
  <td className={cn('truncate text-nowrap', className)} {...rest}>
    {children}
  </td>
);

interface HeaderProps extends TableBase {
  classNames?: {
    thead?: string;
    tr?: string;
  };
}

export const Header: React.FC<HeaderProps> = ({
  children,
  classNames,
  ...rest
}) => (
  <thead className={cn('bg-table-header-grey', classNames?.thead)} {...rest}>
    <tr className={cn(classNames?.tr)}>{children}</tr>
  </thead>
);

interface HeaderCellProps extends TableBase {
  isSorting?: boolean;
  sortKey?: string;
  // Default sort key is used to determine if the table is sorted by default and what it's sorted by.
  defaultSortKey?: string;
  defaultOrderByDirection?: 'ASC' | 'DESC';
  handleLocalSort?: ({
    key,
    direction,
  }: {
    key: string;
    direction: string;
  }) => void;
  currentLocalSortKey?: string;
  currentLocalSortDirection?: string;
}

export const HeaderCell: React.FC<HeaderCellProps> = ({
  children,
  className,
  style,
  isSorting,
  sortKey = '',
  defaultSortKey = '',
  defaultOrderByDirection = 'ASC',
  currentLocalSortKey,
  currentLocalSortDirection,
  handleLocalSort,
  ...rest
}) => {
  const { queryParams, setQueryParams } = useQueryParams();

  const handleGlobalQuerySort = useCallback(() => {
    const updatedParams = { ...queryParams };

    if (updatedParams.orderBy === sortKey) {
      updatedParams.orderByDirection =
        updatedParams.orderByDirection === 'ASC' ? 'DESC' : 'ASC';
    } else if (!updatedParams.orderBy && defaultSortKey === sortKey) {
      // handles the case where we have a default sort but want to explicitly flip the sort direction
      updatedParams.orderBy = sortKey;
      updatedParams.orderByDirection =
        defaultOrderByDirection === 'ASC' ? 'DESC' : 'ASC';
    } else {
      updatedParams.orderBy = sortKey;
      updatedParams.orderByDirection = 'ASC';
    }

    setQueryParams(updatedParams);
  }, [
    queryParams,
    setQueryParams,
    defaultOrderByDirection,
    defaultSortKey,
    sortKey,
  ]);

  const renderSortIcon = useMemo(() => {
    if (!isSorting || !sortKey) return null;

    if (handleLocalSort) {
      if (currentLocalSortKey === sortKey) {
        return currentLocalSortDirection?.toLowerCase() === 'asc' ? (
          <ArrowUpIcon
            className='ml-0.5'
            color={colors.secondary[80]}
            size={16}
          />
        ) : (
          <ArrowDownIcon
            className='ml-0.5'
            color={colors.secondary[80]}
            size={16}
          />
        );
      }

      return (
        <SortIcon className='ml-0.5' color={colors.secondary[80]} size={20} />
      );
    } else {
      if (
        defaultSortKey === sortKey &&
        !queryParams?.orderBy &&
        !queryParams?.orderByDirection &&
        !queryParams?.query
      ) {
        if (defaultOrderByDirection === 'ASC') {
          return (
            <ArrowUpIcon
              className='ml-0.5'
              color={colors.secondary[80]}
              size={16}
            />
          );
        } else {
          return (
            <ArrowDownIcon
              className='ml-0.5'
              color={colors.secondary[80]}
              size={16}
            />
          );
        }
      }

      if (queryParams?.orderBy === sortKey) {
        return queryParams?.orderByDirection === 'ASC' ? (
          <ArrowUpIcon
            className='ml-0.5'
            color={colors.secondary[80]}
            size={16}
          />
        ) : (
          <ArrowDownIcon
            className='ml-0.5'
            color={colors.secondary[80]}
            size={16}
          />
        );
      }

      return (
        <SortIcon className='ml-0.5' color={colors.secondary[80]} size={20} />
      );
    }
  }, [
    isSorting,
    queryParams,
    sortKey,
    defaultSortKey,
    defaultOrderByDirection,
    currentLocalSortDirection,
    currentLocalSortKey,
    handleLocalSort,
  ]);

  return (
    <th
      onClick={() => {
        if (handleLocalSort && sortKey) {
          handleLocalSort({
            key: sortKey,
            direction: currentLocalSortDirection === 'ASC' ? 'DESC' : 'ASC',
          });
        } else if (sortKey) {
          handleGlobalQuerySort();
        }
      }}
      className={cn(
        'truncate opacity-70',
        className,
        sortKey && 'cursor-pointer opacity-100',
      )}
      style={style}
      {...rest}
    >
      <span className='flex items-center'>
        {children}
        {renderSortIcon}
      </span>
    </th>
  );
};

interface BodyProps extends TableBase {}

export const Body = React.forwardRef<HTMLDivElement, BodyProps>(
  ({ className, children, style, ...rest }, ref) => {
    return (
      <tbody ref={ref} className={className} style={style} {...rest}>
        {children}
      </tbody>
    );
  },
);

interface CaptionProps extends TableBase {
  id: string;
}

export const Caption: React.FC<PropsWithChildren<CaptionProps>> = ({
  children,
  id,
  ...rest
}) => (
  <caption id={id} {...rest}>
    {children}
  </caption>
);

type ClassNamesArgs =
  | string
  | string[]
  | { [className: string]: boolean }[]
  | number
  | number[];

interface TableProps {
  id?: string;
  className?: string;
  classNames?: {
    table?: ClassNamesArgs;
    container?: ClassNamesArgs;
  };
}

export const Table: React.FC<PropsWithChildren<TableProps>> = ({
  id,
  children,
  classNames,
  ...rest
}) => (
  <table id={id} className={cn('h-full w-full', classNames?.table)} {...rest}>
    {children}
  </table>
);
