import { useMemo } from 'react';

import { useQueryParams } from '../../hooks/useQueryParams';
import { Stages } from '../../utils/constant';
import { MultiSelectDropdownFilter } from '../common/MultiSelectDropdownFilter';

function StageInput() {
  const { queryParams, updateQueryParam, removeQueryParam } = useQueryParams();
  const stagesItems = Object.values(Stages).map((s) => ({
    id: s,
    title: s,
  }));

  const handleChange = useMemo(
    () => (value: string | string[]) => {
      updateQueryParam('stage', value, {
        multiple: true,
        resetPageOnChange: true,
      });
    },
    [updateQueryParam],
  );

  const handleClear = useMemo(
    () => () => {
      removeQueryParam('stage');
    },
    [removeQueryParam],
  );

  const isChecked = useMemo(() => !!queryParams.stage, [queryParams.stage]);

  return (
    <MultiSelectDropdownFilter
      classNames={{ container: 'w-40' }}
      isMultiSelect
      items={stagesItems}
      handleChange={handleChange}
      isClearable={!!queryParams.stage}
      onClear={handleClear}
      value={(queryParams.stage || []) as string[]}
      isChecked={isChecked}
      placeholder='Stages'
    />
  );
}

export default StageInput;
