import { useMemo, useState } from 'react';

import { Button } from '../../components/common/Button';
import { LocationIcon } from '../../components/common/Icons';
import Modal from '../../components/common/ModalRadix';
import { Input } from '../../components/ui/input';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import colors from '../../styles/colors';
import { APIContact } from '../../types/contacts';
import {
  APIPipeline,
  APIJobContactPipelinePosition,
} from '../../types/pipeline';
import {
  capitalizeName,
  formatDateToBackend,
  setRegion,
} from '../../utils/helpers';
import { useDebounce } from '../../utils/hooks';

function AddContactToPipelineModal({
  isOpen,
  onClose,
  pipelineData,
  refetchPipelineData,
}: {
  isOpen: boolean;
  onClose: () => void;
  pipelineData: APIPipeline | undefined;
  refetchPipelineData: () => void;
}) {
  const [search, setSearch] = useState('');
  const [query, setQuery] = useState('');
  const [contactsAdded, setContactsAdded] = useState<
    APIJobContactPipelinePosition[]
  >([]);

  function onModalClose() {
    setContactsAdded([]);
    refetchPipelineData();
    onClose();
  }

  const debouncedSearch = useDebounce((value: string) => {
    setQuery(value);
  }, 500);

  const { data, isLoading } = useCustomQuery<APIContact[]>({
    url: '/contacts',
    params: {
      ...(query
        ? { query }
        : { orderBy: 'dateEntered', orderByDirection: 'DESC' }),
    },
    enabled: isOpen,
  });
  const contacts = data?.data;

  return (
    <Modal open={isOpen} onOpenChange={onModalClose}>
      <Modal.Content title='Add contact' className='max-w-xl'>
        <Input
          value={search}
          onChange={(e) => {
            const value = e.target.value;

            setSearch(value);
            debouncedSearch(value);
          }}
          placeholder='Search contacts'
        />
        <div className='grid h-[500px] grid-cols-1 flex-col gap-4 overflow-x-auto pr-2'>
          {isLoading && (
            <div className='size-full animate-pulse rounded-md bg-neutral-40'></div>
          )}
          {!isLoading && (
            <>
              {contacts && (contacts.length ?? 0) > 0 ? (
                <>
                  {contacts.map((contact) => {
                    return (
                      <AddPipelineContactCard
                        key={contact.contactId}
                        contact={contact}
                        contactsAdded={contactsAdded}
                        setContactsAdded={setContactsAdded}
                        pipelineData={pipelineData}
                      />
                    );
                  })}
                </>
              ) : (
                <div className='flex size-full items-center justify-center text-2xl'>
                  No contacts found
                </div>
              )}
            </>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default AddContactToPipelineModal;

function AddPipelineContactCard({
  setContactsAdded,
  contactsAdded,
  contact,
  pipelineData,
}: {
  contact: APIContact;
  pipelineData: APIPipeline | undefined;
  contactsAdded: APIJobContactPipelinePosition[];
  setContactsAdded: React.Dispatch<
    React.SetStateAction<APIJobContactPipelinePosition[]>
  >;
}) {
  const { mutate: removeContactFromPipeline, isPending: isDeletePending } =
    useCustomMutation({
      method: 'delete',
      onSuccess: (response, variables) => {
        const jobContactPipelinePositionId = variables.url.split('/').pop();

        // remove contact from contactsAdded
        setContactsAdded(
          contactsAdded.filter(
            (c) =>
              c.jobContactPipelinePositionId !== jobContactPipelinePositionId,
          ),
        );
      },
      onSuccessMessage: 'Contact removed from pipeline',
    });

  const { mutate: addContactToPipeline, isPending } = useCustomMutation({
    method: 'post',
    onSuccess: (response) => {
      setContactsAdded([...contactsAdded, response.data]);
    },
    onSuccessMessage: 'Contact added to pipeline!',
  });

  function handleContact({ contact }: { contact: APIContact }) {
    const date = formatDateToBackend(new Date());
    const position = pipelineData?.pipelinePositions.find(
      (p) => p.pipelinePosition === 'Inbox',
    );

    addContactToPipeline({
      url: '/jobcontactpipelinepositions',
      body: {
        jobId: pipelineData?.job.jobId,
        contactId: contact.contactId,
        pipelinePositionId: position?.pipelinePositionId,
        pipelinePositionOrder: position?.pipelinePositionOrder,
        pipelinePosition: position?.pipelinePosition,
        outOfProcess: false,
        insertDate: date,
        updateDate: date,
      },
    });
  }

  function handleContactUndo({ contact }: { contact: APIContact }) {
    const currentContact = contactsAdded.find(
      (c) => c.contactId === contact.contactId,
    );
    if (!currentContact) return null;

    removeContactFromPipeline({
      url: `/jobcontactpipelinepositions/${currentContact.jobContactPipelinePositionId}`,
      body: {},
    });
  }

  const contactJustAdded = useMemo(() => {
    return contactsAdded.some((c) => c.contactId === contact.contactId);
  }, [contact.contactId, contactsAdded]);

  const isAlreadyInPipeline = useMemo(() => {
    return pipelineData?.jobContactPipelinePositions.some(
      (p) => p.contactId === contact.contactId,
    );
  }, [contact.contactId, pipelineData]);

  return (
    <div
      key={contact.contactId}
      className='grid h-fit w-full gap-2 rounded-md bg-neutral-40 p-4'
    >
      <div className='flex w-full justify-between'>
        <div className='font-medium'>
          {capitalizeName(`${contact.firstName} ${contact.lastName}`)}
        </div>
        <div className='flex items-center justify-end whitespace-nowrap'>
          <LocationIcon size={16} color={colors.primary[70]} />
          <div>{setRegion(contact.city, contact.state)}</div>
        </div>
      </div>
      <div className=''>{contact.title}</div>
      <div className='-mt-2 flex items-center justify-between'>
        <div>{contact.organizationName}</div>
        {isAlreadyInPipeline && !contactJustAdded && <div>Added</div>}
        {!isAlreadyInPipeline && (
          <>
            {contactJustAdded ? (
              <Button
                isLoading={isDeletePending}
                disabled={isDeletePending}
                onClick={() => handleContactUndo({ contact })}
                variant='ghost'
                className='pr-2.5'
              >
                Undo
              </Button>
            ) : (
              <Button
                isLoading={isPending}
                disabled={isPending}
                onClick={() => handleContact({ contact })}
              >
                Add
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
