import { useMemo } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { CommonSpinner } from '../../components/common';
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
} from '../../components/ui/chart';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { useQueryParams } from '../../hooks/useQueryParams';
import colors from '../../styles/colors';

export type APICommunicationResponse = {
  summary?: {
    userId: string;
    fullName?: string;
    totalCallTime?: number;
    totalOutboundCallTime?: number;
    totalInboundCallTime?: number;
    contactOutboundCallTime?: number;
    orgOutboundCallTime?: number;
    otherOutboundCallTime?: number;
    contactInboundCallTime?: number;
    orgInboundCallTime?: number;
    otherInboundCallTime?: number;
    contactTotalCallCount?: number;
    orgTotalCallCount?: number;
    otherTotalCallCount?: number;
    contactUniqueCallCount?: number;
    orgUniqueCallCount?: number;
    otherUniqueCallCount?: number;
    totalCallCount?: number;
    totalOutboundEmails?: number | null;
    totalInboundEmails?: number | null;
    contactsAdded?: any; // What is this.
  };
  detail?: any;
};

type CommunicationChartData = {
  label: string;
  calls?: number;
  emails?: number;
};

function Communication({ userId }: { userId: string }) {
  const { queryParams } = useQueryParams();

  const { data: response, isLoading } =
    useCustomQuery<APICommunicationResponse>({
      url: `/reports/comms/${userId}`,
      params: {
        interval: queryParams?.timeFrame || 'week',
      },
    });

  const chartConfig: ChartConfig = {
    emails: {
      label: 'Emails',
      color: `${colors.fucsia}`,
    },
    calls: {
      label: 'Calls',
      color: `${colors.secondary.DEFAULT}`,
    },
  };

  const chartData: CommunicationChartData[] = useMemo(() => {
    if (response?.data?.detail) {
      const responseData: CommunicationChartData[] = response.data.detail.map(
        (d: any) => ({
          emails: d.totalInboundEmails + d.totalOutboundEmails,
          calls: d.totalCallCount,
          label: d.label,
        }),
      );

      return responseData;
    }

    return [];
  }, [response]);

  const summary = response?.data?.summary;

  return (
    <div className='flex size-full min-h-[238px] flex-col rounded-lg border border-neutral-40 bg-white p-4'>
      <div className='flex h-full flex-col flex-nowrap items-center justify-between text-secondary-80'>
        {isLoading ? (
          <CommonSpinner />
        ) : (
          <>
            {!chartData?.length ? (
              <div className='flex size-full flex-col gap-4'>
                <p className='text-lg font-bold text-gray-500'>
                  Communications
                </p>
                <div className='flex size-full items-center justify-center text-lg font-medium text-gray-500'>
                  Not available for selected time range
                </div>
              </div>
            ) : (
              <>
                <div className='mr-4 flex size-full items-center truncate'>
                  <span className='text-lg font-bold'>Communication</span>
                </div>
                <div className='flex w-full'>
                  <div className='basis-2/3'>
                    <ChartContainer config={chartConfig} className='size-full'>
                      <AreaChart data={chartData}>
                        <CartesianGrid className='z-0' />
                        <XAxis dataKey='label' className='z-0' />
                        <YAxis className='z-0' />
                        <Area
                          dataKey='calls'
                          type='linear'
                          dot={{
                            fill: colors.secondary.DEFAULT,
                            fillOpacity: 1,
                          }}
                          fill={colors.secondary.DEFAULT}
                          fillOpacity={0.2}
                          stroke={colors.secondary.DEFAULT}
                          className='z-10'
                        />
                        <Area
                          dataKey='emails'
                          type='linear'
                          dot={{
                            fill: colors.fucsia,
                            fillOpacity: 1,
                          }}
                          fill={colors.fucsia}
                          fillOpacity={0.2}
                          stroke={colors.fucsia}
                          className='z-10'
                        />
                        <ChartLegend
                          content={<ChartLegendContent className='flex' />}
                          className='flex justify-start justify-items-start pt-0'
                        />
                        <Tooltip />
                      </AreaChart>
                    </ChartContainer>
                  </div>

                  <div className='flex basis-1/3 flex-col content-center justify-around px-8 pb-4'>
                    <div className='flex flex-col'>
                      <p className='text-xs'>Candidates Contacted</p>
                      <p>{summary?.contactUniqueCallCount}</p>
                    </div>
                    <div className='flex flex-col'>
                      <p className='text-xs'>Orgs Contacted</p>
                      <p>{summary?.orgUniqueCallCount}</p>
                    </div>
                    <div className='flex flex-col'>
                      <p className='text-xs'>Other</p>
                      <p>{summary?.otherUniqueCallCount}</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Communication;
