import { format } from 'date-fns';

import { Button } from '../../components/common/Button';
import { cn } from '../../lib/utils';

type ContentHeaderProps = {
  isLoading: boolean;
  title: string;
  subLabel: string | React.ReactNode;
  buttonNames?: {
    entity: string;
    label: string;
    clickHandler: () => void;
    className?: string;
    hideIcon?: boolean;
    variant?: any;
  }[];
  expiresAtDate?: string | null;
};

export function ListAddForms({
  isLoading,
  title,
  subLabel,
  buttonNames,
  expiresAtDate,
}: ContentHeaderProps) {
  return (
    <header className='flex w-full items-center justify-between gap-4'>
      <div className='flex w-full flex-shrink flex-col truncate'>
        {!isLoading ? (
          <>
            <h1 className='font-medium'>{title}</h1>
            <div className='w-full truncate text-nowrap text-sm'>
              {subLabel}
            </div>
            {expiresAtDate && (
              <div className='text-xs'>
                List Expires: {format(expiresAtDate, 'MMM do, yyyy')}
              </div>
            )}
          </>
        ) : (
          <div className='flex flex-col gap-1'>
            <div className='h-[22px] w-28 animate-pulse rounded-md bg-neutral-40' />
            <div className='h-[18px] w-52 animate-pulse rounded-md bg-neutral-40' />
          </div>
        )}
      </div>

      <div className='flex flex-shrink-0 gap-2'>
        {buttonNames &&
          buttonNames.map((buttonName, index) => (
            <div key={index} className='flex gap-2'>
              <Button
                variant={buttonName?.variant || 'default'}
                onClick={buttonName.clickHandler}
                className={cn(
                  !buttonName.variant &&
                    'h-8 rounded-md border border-neutral-40 bg-white px-2 text-black shadow-sm hover:bg-gray-100',
                  buttonName.className,
                )}
              >
                {!buttonName?.hideIcon && <span>&#43;</span>} {buttonName.label}
              </Button>
            </div>
          ))}
      </div>
    </header>
  );
}
