export function formatContentHeaderSubLabel({
  label,
  rowsCount,
  limit = 1000,
  sponsoredAdLimit,
  sponsoredAdsCount,
}: {
  label: string;
  rowsCount: number;
  limit?: number;
  sponsoredAdLimit?: number;
  sponsoredAdsCount?: number;
}) {
  const isOverLimit = rowsCount > limit;
  const isSingle = rowsCount === 1;

  const limitLabel = isSingle ? label.slice(0, -1) : label;

  return (
    <div>
      <span>{isOverLimit ? `${limit}+` : rowsCount ?? '0'}</span>{' '}
      <span>{limitLabel}</span>
      {sponsoredAdsCount !== undefined && sponsoredAdLimit !== undefined && (
        <>
          <span>{', '}</span>
          <span className='text-sm text-success'>
            <span>{sponsoredAdsCount}</span>
            {' of your '}
            <span>{sponsoredAdLimit}</span>
            {' sponsored ads used'}
          </span>
        </>
      )}
    </div>
  );
}
