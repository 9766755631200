import { useReducer, useState } from 'react';

import { useCustomMutation } from '../../hooks/useCustomMutation';
import { IAction, TextInput, commonReducer } from '../common';
import { Button } from '../common/Button';
import FileUpload from '../common/FileUpload';
import Modal from '../common/ModalRadix';

interface AddAttachmentsModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  tableId: string;
  tableName: string;
  refetchAttachments: () => void;
  attachmentType?: string;
  onSubmit?: (file: File | null, textAreaValue: string) => void;
}

interface APIAttachment {
  textAreaValue: string;
}

const initialState: Partial<APIAttachment> = {
  textAreaValue: '',
};

export const validateContact = (state: Partial<APIAttachment>) => {
  const errors: Partial<Record<keyof APIAttachment, string>> = {};
  if (!state.textAreaValue) errors.textAreaValue = 'Description is required';
  return errors;
};

function AddAttachmentsModal({
  isOpen,
  onDismiss,
  tableId,
  tableName,
  refetchAttachments,
  attachmentType,
  onSubmit,
}: AddAttachmentsModalProps) {
  const [file, setFile] = useState<File | null>(null);

  const [state, dispatch]: [APIAttachment, React.Dispatch<IAction>] =
    useReducer(commonReducer, initialState);

  const [{ errors }, errorDispatch]: [any, React.Dispatch<IAction>] =
    useReducer(commonReducer, { errors: {} });

  const { mutate: saveAttachment, isPending } = useCustomMutation({
    onSuccess: async () => {
      await refetchAttachments();
      onDismiss();
    },
    onSuccessMessage: 'Attachment added successfully!',
  });

  async function saveAttachments() {
    if (!file) {
      return;
    }

    const formData = new FormData();

    formData.append('multipartFile', file, file.name);

    saveAttachment({
      url: `/attachments?tableId=${tableId}&&tableName=${tableName}&&description=${state.textAreaValue}&&makePrimaryAttachment=${attachmentType === 'resume' ? true : false}`,
      body: formData,
    });
  }

  const handleChange = (path: keyof APIAttachment) => (value: any) => {
    if (errors[path]) {
      errorDispatch({ type: 'remove', path: `errors.${path}` });
    }

    dispatch({ path, value });
  };

  return (
    <Modal
      open={isOpen}
      onOpenChange={() => {
        onDismiss();
      }}
    >
      <Modal.Content
        title={attachmentType === 'resume' ? 'Add Resume' : `Add Attachments`}
      >
        <div>
          <div className='my-6 flex w-full flex-col'>
            <TextInput
              required
              textArea
              className='mt-1 flex min-h-[80px] rounded-md border border-slate-200 bg-white text-sm ring-offset-white placeholder:text-slate-500 disabled:opacity-50'
              label='Description'
              placeholder='Write description...'
              handleChange={handleChange('textAreaValue')}
              errorMessage={errors.textAreaValue}
            />
          </div>
          <FileUpload
            acceptDocumentOnly={attachmentType === 'resume'}
            handleFile={(file) => setFile(file)}
          />
          <div className='mt-8 flex justify-between gap-4'>
            <Modal.Close>Cancel</Modal.Close>
            <Button
              disabled={!file || isPending}
              isLoading={isPending}
              onClick={() => {
                const errors = validateContact(state);
                if (Object.values(errors).length) {
                  errorDispatch({ path: 'errors', value: errors });
                  return;
                }
                if (onSubmit) {
                  onSubmit(file, state.textAreaValue);
                } else {
                  saveAttachments();
                }
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default AddAttachmentsModal;
