import { useReducer } from 'react';

import { useGlobalState } from '../../context/GlobalContext';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { ActivityTypes } from '../../pages/organizations/inputs';
import { ErrorMessage, IAction, commonReducer } from '../common';
import { Button } from '../common/Button';
import Modal from '../common/ModalRadix';
import Select from '../common/SelectNew';
import { Toast } from '../toast/Toast';
import { Label } from '../ui/label';
import { Textarea } from '../ui/textarea';

interface AddActivitiesModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  tableId: string | undefined;
  tableName: string;
  activityUrl?: string;
  refetchAttachments: () => void;
  attachmentType?: string;
  onSubmit?: (file: File | null, textAreaValue: string) => void;
}

interface APIAttachment {
  activityType: string;
  additionalNotes: string;
}

const initialState: Partial<APIAttachment> = {
  activityType: '',
  additionalNotes: '',
};

export const validateContact = (state: Partial<APIAttachment>) => {
  const errors: Partial<Record<keyof APIAttachment, string>> = {};
  if (!state.activityType) errors.activityType = 'ActivityType is required';
  if (!state.additionalNotes)
    errors.additionalNotes = 'Additional Notes is required';

  return errors;
};

function AddActivitiesModal({
  isOpen,
  onDismiss,
  tableId,
  tableName,
  activityUrl,
  refetchAttachments,
}: AddActivitiesModalProps) {
  const { me: user } = useGlobalState();

  const [state, dispatch]: [APIAttachment, React.Dispatch<IAction>] =
    useReducer(commonReducer, initialState);

  const [{ errors }, errorDispatch]: [any, React.Dispatch<IAction>] =
    useReducer(commonReducer, { errors: {} });

  const { mutate: submitAttachment, isPending } = useCustomMutation({
    onSettled: async (data, error) => {
      if (error) {
        Toast.error(error.message);
      } else {
        await refetchAttachments();
        dispatch({ type: 'reset', value: initialState });
        onDismiss();
      }
    },
    onSuccessMessage: 'Activity added successfully!',
  });

  async function saveActivities() {
    const selectedActivity = ActivityTypes?.find(
      (activity) => activity?.displayText === state?.activityType,
    );
    const data = {
      userName: user?.userName,
      userId: user?.userId,
      [tableName]: tableId,
      notes: state?.additionalNotes,
      activityType: selectedActivity?.activityType,
      result: selectedActivity?.result,
    };

    submitAttachment({
      url: activityUrl ?? '',
      body: data,
    });
  }

  const handleChange = (path: keyof APIAttachment) => (value: any) => {
    if (errors[path]) {
      errorDispatch({ type: 'remove', path: `errors.${path}` });
    }

    dispatch({ path, value });
  };

  return (
    <Modal
      open={isOpen}
      onOpenChange={() => {
        onDismiss();
        dispatch({ type: 'reset', value: initialState });
      }}
    >
      <Modal.Content title='Add Activity'>
        <div>
          <div>
            <div className='flex items-baseline gap-8 pb-4'>
              <Select
                required
                classNames={{ content: 'max-h-[200px]' }}
                label='Activity Type'
                value={state.activityType}
                onChange={(value) => handleChange('activityType')(value)}
                options={ActivityTypes.map((activity) => ({
                  value: activity?.displayText,
                  label: activity?.displayText,
                }))}
                errorMessage={errors.activityType}
              />
            </div>
            <div className='flex flex-grow flex-col gap-2'>
              <Label>
                Additional Notes <span className='text-error'>*</span>
              </Label>
              <Textarea
                placeholder='Add additional notes...'
                value={state?.additionalNotes}
                onChange={(e) =>
                  handleChange('additionalNotes')(e.target.value)
                }
                className='placeholder:text-neutral'
              />
              {errors.additionalNotes && (
                <ErrorMessage message={errors.additionalNotes} />
              )}
            </div>
          </div>

          <div className='mt-8 flex justify-between gap-4'>
            <Modal.Close>Cancel</Modal.Close>
            <Button
              isLoading={isPending}
              onClick={() => {
                const errors = validateContact(state);
                if (Object.values(errors).length) {
                  errorDispatch({ path: 'errors', value: errors });
                  return;
                }
                saveActivities();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default AddActivitiesModal;
