import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EmptyState } from '../../components/common';
import { Button } from '../../components/common/Button';
import {
  ContentHeader,
  ContentContainer,
  ContentInnerContainer,
} from '../../components/common/Content';
import { WarningIcon } from '../../components/common/Icons';
import PageContent from '../../components/common/PageContent';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { usePageTitle } from '../../hooks/usePageTitle';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useQueryParamsChange } from '../../hooks/useQueryParamsChange';
import { useTableSelection } from '../../hooks/useTableSelection';
import { APIContact } from '../../types/contacts';
import { PageTypes } from '../../types/pages';
import { generateBaseTableApiParams } from '../../utils/api';
import { formatContentHeaderSubLabel } from '../../utils/contentHelpers';
import { removeTabsParamsAndReturnQueryString } from '../../utils/tabs';
import { AddContactFromResume } from './AddContactFromResume';
import { ContactView } from './ContactView';
import ContactsFilters from './ContactsFilters';
import ContactsTable from './ContactsTable';

export function Contacts() {
  const { queryParams } = useQueryParams();

  const { contactId, tabId } = useParams();

  const navigate = useNavigate();

  const [showAddResumeContactModal, setShowAddResumeContactModal] =
    useState(false);

  const {
    data: response,
    refetch,
    isError,
    isLoading,
    isFetching,
    error,
  } = useCustomQuery<APIContact[]>({
    url: '/contacts',
    params: {
      ...generateBaseTableApiParams(queryParams, 'dateEntered'),
      ...(queryParams.engagementColor && {
        engagementColor: queryParams.engagementColor,
      }),
    },
  });

  const { data: contacts = [], numberofrecords, pages } = response ?? {};
  const displayedContacts = useMemo(() => {
    if (!queryParams.engagementColor) {
      return contacts;
    }
    return contacts.filter(
      (contact) => contact.engagementColor === queryParams.engagementColor,
    );
  }, [contacts, queryParams.engagementColor]);

  const contactsForSelection = useMemo(() => {
    return contacts.map((contact) => ({
      id: contact.contactId,
      isInList: false,
      disabled: false,
      engagementColor: contact.engagementColor,
    }));
  }, [contacts]);

  const totalFilteredCount = numberofrecords;
  const { clearSelectedItems, handleSelectionChange, ...checkboxProps } =
    useTableSelection(contactsForSelection, totalFilteredCount);

  const handleClearSelection = useCallback(() => {
    clearSelectedItems();
  }, [clearSelectedItems]);
  useQueryParamsChange(queryParams, handleClearSelection);

  const pageTitleContact = useMemo(() => {
    return contacts?.find((c) => c.contactId === contactId);
  }, [contacts, contactId]);

  usePageTitle(
    pageTitleContact
      ? `${pageTitleContact.firstName} ${pageTitleContact.lastName} • Contacts`
      : 'Contacts',
  );

  useEffect(() => {
    refetch();
  }, [queryParams.engagementColor, refetch]);

  const contentHeaderSubLabel = useMemo(() => {
    return formatContentHeaderSubLabel({
      label: 'contacts',
      rowsCount: numberofrecords ?? 0,
    });
  }, [numberofrecords]);

  const handleRowClick = (contact: APIContact, event: any) => {
    if (contact?.contactId) {
      handleSelectionChange(contact.contactId, event.shiftKey);
    }
  };

  if (error) {
    return (
      <EmptyState
        error={isError}
        icon={WarningIcon}
        title={error.message ?? 'Error'}
        link={
          <button onClick={() => window.location.reload()} className='blue'>
            Refresh Page
          </button>
        }
      />
    );
  }

  return (
    <>
      <ContentContainer>
        <ContentHeader
          title='Contacts'
          subLabel={contentHeaderSubLabel}
          link={{ to: '/contacts/add', label: 'Add Contact' }}
          ContentHeaderButton={() => (
            <Button
              onClick={() => setShowAddResumeContactModal(true)}
              className='primary p-10 font-medium'
            >
              Add from Resume
            </Button>
          )}
        />
        <ContentInnerContainer>
          <PageContent
            contentType={PageTypes.Contacts}
            data={contacts}
            pages={pages}
            isLoading={isLoading}
            TableComponent={ContactsTable}
            handleRowClick={handleRowClick}
            FiltersComponent={ContactsFilters}
            isFetchingNewTableData={isFetching}
            checkboxProps={{
              clearSelectedItems,
              handleSelectionChange,
              ...checkboxProps,
              currentFilters: generateBaseTableApiParams(
                queryParams,
                'dateEntered',
              ),
            }}
          />
        </ContentInnerContainer>
      </ContentContainer>

      <ContactView
        isOpen={!!contactId}
        contacts={displayedContacts}
        contactId={contactId}
        onClose={() => {
          const queryParamsString =
            removeTabsParamsAndReturnQueryString(queryParams);

          navigate(
            `/contacts${queryParamsString ? `?${queryParamsString}` : ''}`,
          );
        }}
        onContactSave={refetch}
        tabId={tabId}
        panelBaseRoute={`/contacts`}
      />

      <AddContactFromResume
        isOpen={showAddResumeContactModal}
        onDismiss={() => setShowAddResumeContactModal(false)}
      />
    </>
  );
}
