import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { z } from 'zod';

import { Button } from '../../components/common/Button';
import { CKEditor } from '../../components/common/CKEditor';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../components/ui/alert-dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form';
import { Input } from '../../components/ui/input';
import { Separator } from '../../components/ui/seperator';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomMutation } from '../../hooks/useCustomMutation';

type EmailContactFormTypes = z.infer<typeof formSchema>;

interface EmailContactsModalProps {
  open: boolean;
  setIsBulkActionModalOpen: any;
  itemsCount: number | null;
  listId?: string;
}

interface EmailContactForm {
  form: EmailContactFormTypes;
}

interface TemplateVariableItem {
  label: string;
  text: string;
  bg: string;
}

const Container = styled.div.attrs({})`
  min-width: 800px;
`;

const templateVariables = [
  {
    label: '{{contact.firstName}}',
    text: '#533BE4',
    bg: '#F0EEFF',
  },
  {
    label: '{{contact.lastName}}',
    text: '#533BE4',
    bg: '#F0EEFF',
  },
  // TODO: Introduce more when parser becomes more robust.
  // {
  //   label: '{{contact.email}}',
  //   text: '#533BE4',
  //   bg: '#F0EEFF',
  // },
  // {
  //   label: '{{contact.jobtitle}}',
  //   text: '#533BE4',
  //   bg: '#F0EEFF',
  // },
  // {
  //   label: '{{contact.organization}}',
  //   text: '#533BE4',
  //   bg: '#F0EEFF',
  // },
  // {
  //   label: '{{user.firstName}}',
  //   text: '#309288',
  //   bg: '#E7F8F3',
  // },
  // {
  //   label: '{{user.lastName}}',
  //   text: '#309288',
  //   bg: '#E7F8F3',
  // },
  // {
  //   label: '{{user.phone}}',
  //   text: '#309288',
  //   bg: '#E7F8F3',
  // },
  // {
  //   label: '{{ user.email}}',
  //   text: '#309288',
  //   bg: '#E7F8F3',
  // },
] as TemplateVariableItem[];

const EmailContactsModal = ({
  open,
  setIsBulkActionModalOpen,
  itemsCount,
  listId,
}: EmailContactsModalProps) => {
  const { me } = useGlobalState();

  const { mutate: handleSubmit } = useCustomMutation({
    method: 'post',
    onSuccess: () => {
      setIsBulkActionModalOpen(false);
    },
    onSuccessMessage: 'Emails dispatch successfully',
  });

  const defaultValues = {
    from: me?.emailAddress || '',
    to: '{{contact.email}}',
    subject: '',
    message: '',
    emailSignature: 'yes',
  };

  const form = useForm<EmailContactFormTypes>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const onSubmit = (values: EmailContactFormTypes) => {
    if (!listId) return;

    // ! We're currently assuming that all users in Prod have Outlook connection.
    // ! We will eventually use deafultEmailService user setting provided by the API.
    handleSubmit({
      url: '/comms/batchemail',
      body: {
        listId,
        externalService: 'Outlook',
        subject: values.subject,
        template: values.message,
      },
    });
  };

  return (
    <Container>
      <AlertDialog
        open={open}
        onOpenChange={() => setIsBulkActionModalOpen(null)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Send Email to {itemsCount} Contacts
            </AlertDialogTitle>
            <AlertDialogDescription>
              <EmailContactForm form={form} onSubmit={onSubmit} />
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className='flex flex-row justify-between'>
            <div className='flex w-full flex-row justify-between'>
              <div>
                <Button
                  onClick={() => setIsBulkActionModalOpen(null)}
                  variant='secondary'
                >
                  Cancel
                </Button>
              </div>
              <div className='flex flex-row gap-2'>
                <Button type='submit' form='contactEmailForm'>
                  Submit
                </Button>
              </div>
            </div>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Container>
  );
};

const EmailContactForm = ({
  form,
  onSubmit,
}: {
  form: UseFormReturn<EmailContactFormTypes>;
  onSubmit: (values: EmailContactFormTypes) => void;
}) => (
  <>
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} id='contactEmailForm'>
        <div className='flex flex-col gap-4'>
          <FormField
            control={form.control}
            name='from'
            render={({ field }) => (
              <FormItem>
                <FormLabel className='text-base'>
                  From <span className='text-error'>*</span>
                </FormLabel>
                <FormControl>
                  <Input {...field} disabled />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='to'
            render={({ field }) => (
              <FormItem>
                <FormLabel className='text-base'>
                  To <span className='text-error'>*</span>
                </FormLabel>
                <FormControl>
                  <Input {...field} disabled />
                </FormControl>
              </FormItem>
            )}
          />

          <Separator />

          <FormField
            control={form.control}
            name='subject'
            render={({ field }) => (
              <FormItem>
                <FormLabel className='text-base'>
                  Subject <span className='text-error'>*</span>
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage className='text-error' />
              </FormItem>
            )}
          />

          <div className='grid grid-cols-12'>
            <div className='col-span-8'>
              <FormField
                control={form.control}
                name='message'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className='text-base'>
                      Message <span className='text-error'>*</span>
                    </FormLabel>
                    <FormControl>
                      <CKEditor
                        onChange={field.onChange}
                        value={field.value ?? ''}
                      />
                    </FormControl>
                    <FormMessage className='text-error' />
                  </FormItem>
                )}
              />
            </div>
            <div className='col-span-4 flex w-full flex-col gap-1 px-1 '>
              <FormLabel className='pb-1 text-base'>Variables</FormLabel>
              {templateVariables.map(
                (item: TemplateVariableItem, i: number) => (
                  <div key={i}>
                    <span style={{ background: item.bg, color: item.text }}>
                      {item.label}
                    </span>
                  </div>
                ),
              )}
            </div>
          </div>

          <Separator />
        </div>
      </form>
    </Form>
  </>
);

const formSchema = z.object({
  from: z.string().min(1, 'From Email required'),
  to: z.string().min(1, 'To email required'),
  subject: z.string().min(1, 'Subject is required'),
  message: z.string().min(1, 'Message Body is required'),
});

export default EmailContactsModal;
