import { useState, useEffect, useRef } from 'react';

interface UseReadMoreOptions {
  maxLines?: number;
}

export const useReadMore = (
  content: string,
  { maxLines = 6 }: UseReadMoreOptions = {},
) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = contentRef.current;
    if (element) {
      const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
      const maxHeight = lineHeight * maxLines;
      setIsTruncated(element.scrollHeight > maxHeight);
    }
  }, [content, maxLines]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return {
    contentRef,
    isExpanded,
    isTruncated,
    toggleExpand,
  };
};
