import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EmptyState } from '../../components/common';
import {
  ContentHeader,
  ContentContainer,
  ContentInnerContainer,
} from '../../components/common/Content';
import { WarningIcon } from '../../components/common/Icons';
import PageContent from '../../components/common/PageContent';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { usePageTitle } from '../../hooks/usePageTitle';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useQueryParamsChange } from '../../hooks/useQueryParamsChange';
import { useTableSelection } from '../../hooks/useTableSelection';
import { APIOrganization } from '../../types/organizations';
import { PageTypes } from '../../types/pages';
import { generateBaseTableApiParams } from '../../utils/api';
import { formatContentHeaderSubLabel } from '../../utils/contentHelpers';
import { removeTabsParamsAndReturnQueryString } from '../../utils/tabs';
import OrganizationsFilters from './OrganizationFilters';
import { OrganizationView } from './OrganizationView';
import OrganizationsTable from './OrganizationsTable';

export function Organizations() {
  const { queryParams } = useQueryParams();
  const { organizationId, tabId } = useParams();
  const navigate = useNavigate();

  const {
    data: response,
    isError,
    error,
    isLoading,
    isFetching,
  } = useCustomQuery<APIOrganization[]>({
    url: '/organizations',
    params: generateBaseTableApiParams(queryParams, 'dateEntered'),
  });

  const { data: organizations = [], numberofrecords, pages } = response ?? {};

  const orgsForSelection = useMemo(() => {
    return organizations.map((org) => ({
      id: org.organizationId,
      isInList: false,
      disabled: false,
    }));
  }, [organizations]);
  const totalFilteredCount = numberofrecords;
  const { clearSelectedItems, handleSelectionChange, ...checkboxProps } =
    useTableSelection(orgsForSelection, totalFilteredCount);

  const handleClearSelection = useCallback(() => {
    clearSelectedItems();
  }, [clearSelectedItems]);
  useQueryParamsChange(queryParams, handleClearSelection);

  const pageTitleContact = useMemo(() => {
    return organizations?.find((o) => o.organizationId === organizationId);
  }, [organizations, organizationId]);

  usePageTitle(
    pageTitleContact
      ? `${pageTitleContact?.organizationName} • Organizations`
      : 'Organizations',
  );

  const contentHeaderSubLabel = useMemo(() => {
    return formatContentHeaderSubLabel({
      label: 'organizations',
      rowsCount: numberofrecords ?? 0,
    });
  }, [numberofrecords]);

  // TODO: Change this, select item.
  const handleRowClick = (org: APIOrganization, event: any) => {
    if (org?.organizationId) {
      handleSelectionChange(org.organizationId, event.shiftKey);
    }
  };

  if (error) {
    return (
      <EmptyState
        error={isError}
        icon={WarningIcon}
        title={error.message ?? 'Error'}
        link={
          <button onClick={() => window.location.reload()} className='blue'>
            Refresh Page
          </button>
        }
      />
    );
  }

  return (
    <>
      <ContentContainer>
        <ContentHeader
          title='Organizations'
          subLabel={contentHeaderSubLabel}
          link={{ to: '/organizations/add', label: 'Add Organization' }}
        />
        <ContentInnerContainer>
          <PageContent
            contentType={PageTypes.Organizations}
            data={organizations}
            pages={pages}
            isLoading={isLoading}
            TableComponent={OrganizationsTable}
            handleRowClick={handleRowClick}
            FiltersComponent={OrganizationsFilters}
            isFetchingNewTableData={isFetching}
            checkboxProps={{
              clearSelectedItems,
              handleSelectionChange,
              currentFilters: generateBaseTableApiParams(
                queryParams,
                'dateEntered',
              ),
              ...checkboxProps,
            }}
          />
        </ContentInnerContainer>
      </ContentContainer>

      <OrganizationView
        isOpen={!!organizationId}
        organizationId={organizationId}
        organizations={organizations}
        onClose={() => {
          const queryParamsString =
            removeTabsParamsAndReturnQueryString(queryParams);

          navigate(
            `/organizations${queryParamsString ? `?${queryParamsString}` : ''}`,
          );
        }}
        tabId={tabId}
        panelBaseRoute={`/organizations`}
        invalidatePath={`/organizations`}
      />
    </>
  );
}
