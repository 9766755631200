import { useEffect, useRef } from 'react';

import { QueryParams } from './useQueryParams';

export function useQueryParamsChange(
  queryParams: QueryParams,
  onNonPageParamsChange: () => void,
) {
  const prevParamsRef = useRef<QueryParams | null>(null);

  useEffect(() => {
    // skip the first render
    if (prevParamsRef.current === null) {
      prevParamsRef.current = queryParams;
      return;
    }

    if (areQueryParamsDifferentExceptPage(prevParamsRef.current, queryParams)) {
      onNonPageParamsChange();
    }

    prevParamsRef.current = queryParams;
  }, [queryParams, onNonPageParamsChange]);
}

function areQueryParamsDifferentExceptPage(
  prevParams: QueryParams,
  currentParams: QueryParams,
): boolean {
  const prevParamsWithoutPage = { ...prevParams };
  const currentParamsWithoutPage = { ...currentParams };

  delete prevParamsWithoutPage.pageNumber;
  delete currentParamsWithoutPage.pageNumber;

  if (
    Object.keys(prevParamsWithoutPage).length !==
    Object.keys(currentParamsWithoutPage).length
  ) {
    return true;
  }

  for (const key in prevParamsWithoutPage) {
    const prevValue = prevParamsWithoutPage[key];
    const currentValue = currentParamsWithoutPage[key];

    if (Array.isArray(prevValue) && Array.isArray(currentValue)) {
      if (prevValue.length !== currentValue.length) {
        return true;
      }
      if (!prevValue.every((val) => currentValue.includes(val))) {
        return true;
      }
    } else if (prevValue !== currentValue) {
      return true;
    }
  }

  return false;
}
