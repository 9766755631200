import axios from 'axios';
import { useCombobox } from 'downshift';
import { MapPin, XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDebounceValue, useSessionStorage } from 'usehooks-ts';
import { v4 as uuidv4 } from 'uuid';

import { useGlobalState } from '../../context/GlobalContext';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { cn } from '../../lib/utils';
import { APIAddress } from '../../types/common';
import { MapboxFeature, MapboxSearchboxV1Response } from '../../types/mapbox';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { ErrorMessage, Required } from './Error';

const addressFields: (keyof APIAddress)[] = [
  'address1',
  'city',
  'state',
  'postalCode',
  'latitude',
  'longitude',
];

type AddressInputProps = {
  handleChange: (address: APIAddress) => void;
  handleClear?: () => void;
  disabled?: boolean;
  required?: boolean;
  selectedAddress?: Partial<APIAddress>;
  errorMessage?: string;
  checkRegion?: boolean;
  className?: string;
  classNames?: {
    container?: string;
    input?: string;
  };
  hideLabel?: boolean;
  hideIcon?: boolean;
  placeholder?: string;
};

function formatAddress({
  address,
  city,
  state,
  postalCode,
}: {
  address?: string;
  city?: string;
  state?: string;
  postalCode?: string | null;
}) {
  const fullAddress = [];

  if (address) fullAddress.push(address);
  if (city) fullAddress.push(city);
  if (state) fullAddress.push(state);
  if (postalCode) fullAddress.push(postalCode);

  return fullAddress.join(' ');
}

// TODO: Need to add portal to the dropdown piece of this component
const AddressInput = ({
  handleChange,
  handleClear,
  disabled = false,
  required = false,
  selectedAddress,
  errorMessage,
  checkRegion = false,
  className = '',
  classNames,
  hideLabel = false,
  hideIcon = false,
  placeholder = 'Enter address',
}: AddressInputProps) => {
  const sessionToken = useSessionToken();
  const [searchValue, setSearchValue] = useDebounceValue('', 500);
  const [inputValue, setInputValue] = useState('');
  const [regionValidationError, setRegionValidationError] = useState<
    string | null
  >('');
  const { me } = useGlobalState();

  useEffect(() => {
    if (selectedAddress) {
      setInputValue(
        formatAddress({
          address: selectedAddress.address1,
          city: selectedAddress.city,
          state: selectedAddress.state,
          postalCode: selectedAddress.postalCode,
        }),
      );
    } else {
      setInputValue('');
    }
  }, [selectedAddress]);

  const {
    data: suggestResponse,
    isLoading,
    status,
  } = useCustomQuery<MapboxSearchboxV1Response>({
    ignoreBaseUrl: true,
    withCredentials: false,
    url: 'https://api.mapbox.com/search/searchbox/v1/suggest',
    params: {
      q: searchValue ?? '',
      access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      session_token: sessionToken ?? uuidv4(),
      types: 'address, postcode, place',
      limit: 5,
      country: 'US',
      language: 'en',
    },
    enabled: !!searchValue,
  });

  const addresses = suggestResponse?.data.suggestions ?? [];

  const {
    getMenuProps,
    isOpen,
    getInputProps,
    getLabelProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox<any>({
    items: addresses,
    itemToString: (addressToFormat: APIAddress | null) => {
      if (!addressToFormat) return '';

      const formattedAddress = formatAddress({
        address: addressToFormat?.address1,
        city: addressToFormat?.city,
        state: addressToFormat?.state,
        postalCode: addressToFormat?.postalCode,
      });

      return formattedAddress;
    },
    inputValue,
    onInputValueChange: ({ inputValue }) => {
      setInputValue(inputValue || '');
      setSearchValue(inputValue || '');
    },
    selectedItem: selectedAddress,
    onSelectedItemChange: ({
      selectedItem,
    }: {
      selectedItem: MapboxFeature;
    }) => {
      handleAddressChange(selectedItem);
    },
  });

  const handleBlur = () => {
    setRegionValidationError(null);
  };

  const handleAddressChange = async (address: MapboxFeature) => {
    setRegionValidationError(null);

    try {
      const response = await axios.get(
        `https://api.mapbox.com/search/searchbox/v1/retrieve/${address.mapbox_id}`,
        {
          params: {
            access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
            session_token: sessionToken ?? uuidv4(),
          },
        },
      );

      const { coordinates, context, feature_type } =
        response.data.features[0].properties;
      if (checkRegion) {
        if (!me?.admin && me?.assignedStates) {
          const assignedStates = me?.assignedStates ?? [];

          const isAddressInAssignedState = assignedStates.includes(
            context.region.region_code,
          );

          if (!isAddressInAssignedState) {
            setRegionValidationError(
              'You can only add addresses in your assigned states.',
            );

            return;
          }
        }
      }

      let address1 = '';
      let city = '';
      let state = '';
      let postalCode = null;
      let latitude = null;
      let longitude = null;

      switch (feature_type) {
        case 'postcode': {
          city = context.place.name;
          state = context.region.region_code;
          postalCode = context.postcode.name;
          latitude = coordinates.latitude;
          longitude = coordinates.longitude;
          break;
        }

        case 'place': {
          city = context.place.name;
          state = context.region.region_code;
          latitude = coordinates.latitude;
          longitude = coordinates.longitude;
          break;
        }

        case 'address': {
          address1 = context.address.name;
          city = context.place.name;
          state = context.region.region_code;
          postalCode = context.postcode.name;
          latitude = coordinates.latitude;
          longitude = coordinates.longitude;
          break;
        }

        default:
          break;
      }

      const addressState = {
        address1,
        city,
        state,
        postalCode,
        latitude,
        longitude,
      };

      handleChange(addressState);
    } catch (error) {
      setRegionValidationError('Something went wrong, please try again!');
      console.error(error);
    }
  };

  const clearAddress = () => {
    setInputValue('');
    setSearchValue('');

    if (handleClear) {
      handleClear();
    }
  };

  const hasAddressSelected =
    selectedAddress && addressFields.some((field) => selectedAddress[field]);

  return (
    <div className={cn('relative w-full', className, classNames?.container)}>
      {!hideLabel && (
        <div className='mb-1 flex items-center justify-between'>
          <div>
            <Label className='text-base font-semibold' {...getLabelProps()}>
              Address
            </Label>
            {required && <Required />}
          </div>
        </div>
      )}
      <div className='flex flex-col'>
        <div className='relative flex items-center'>
          <span className='sr-only'>Search Contacts</span>
          {!hideIcon && (
            <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
              <MapPin className='h-4 w-4 text-neutral-60' />
            </span>
          )}
          <Input
            placeholder={placeholder}
            className={cn(
              `w-full truncate ${!hideIcon && 'pl-8'} ${hasAddressSelected && 'pr-6'}`,
              classNames?.input,
            )}
            disabled={disabled}
            {...getInputProps({
              onBlur: handleBlur,
            })}
          />
          {hasAddressSelected && !disabled && (
            <button
              onClick={clearAddress}
              className='absolute right-2 ml-2 mt-0.5 flex'
            >
              <XIcon size={14} />
            </button>
          )}
        </div>
        {(regionValidationError || errorMessage) && (
          <ErrorMessage message={regionValidationError || errorMessage} />
        )}
      </div>
      <ul
        {...getMenuProps()}
        className={`absolute z-30 mt-1 max-h-80 w-72 overflow-auto rounded-md border border-neutral-40 bg-white p-0 shadow-lg ${!isOpen && 'hidden'}`}
      >
        {isOpen && (
          <>
            {addresses?.length ? (
              <>
                {addresses?.map((address, index) => {
                  return (
                    <li
                      className={cn(
                        highlightedIndex === index && 'bg-neutral-40',
                        selectedItem === address && 'font-bold',
                        'flex flex-col border-neutral-40 p-1 [&:not(:last-child)]:border-b ',
                      )}
                      key={address.mapbox_id}
                      {...getItemProps({
                        item: address,
                        index,
                      })}
                    >
                      <div className='flex items-center gap-1'>
                        <MapPin className='h-3 w-3 text-neutral-60' />
                        <span className='text-sm'>{address.name}</span>
                      </div>
                      <span className='pl-4 text-sm text-gray-700'>
                        {address.place_formatted}
                      </span>
                    </li>
                  );
                })}
              </>
            ) : status === 'pending' && !isLoading ? (
              <div className='p-2 text-sm '>
                Start typing to find locations...
              </div>
            ) : isLoading ? (
              <div className='flex items-center gap-2 pl-2'>
                <div className='p-2 text-sm '>Processing...</div>
              </div>
            ) : (
              <div className='p-2 text-sm '>No results found</div>
            )}
          </>
        )}
      </ul>
    </div>
  );
};

export default AddressInput;

function useSessionToken() {
  const [sessionToken, setSessionToken] = useSessionStorage('sessionToken', '');
  useEffect(() => {
    if (!sessionToken) {
      setSessionToken(uuidv4());
    }
  }, [sessionToken, setSessionToken]);

  return sessionToken;
}
