import { IDropdownOption } from '@fluentui/react';
import { useQueryClient } from '@tanstack/react-query';
import { format, fromZonedTime } from 'date-fns-tz';
import { useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Dropdown,
  IAction,
  TextInput,
  UsersDropdown,
  commonReducer,
} from '../../components/common';
import { Button } from '../../components/common/Button';
import DatePicker from '../../components/common/DatePicker';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { usePageTitle } from '../../hooks/usePageTitle';
import RootLayout from '../../layouts';
import { cn } from '../../lib/utils';
import { APIList, ListType } from '../../types/lists';

const Container = styled.div.attrs({
  className: 'px-8 sm:px-16',
})`
  max-width: 600px;
`;

const initialState: Partial<APIList> = {
  code: '',
  template: '',
  userName: '',
  userId: '',
  description: '',
  shared: true,
  expiresAt: undefined,
};

const listTypeOptions = Object.values(ListType).map((value) => ({
  key: value,
  text: value,
}));

const sixMonths = new Date();
sixMonths.setMonth(sixMonths.getMonth() + 6);

export const validateList = (state: Partial<APIList>) => {
  const errors: Partial<Record<keyof APIList, string>> = {};
  if (!state.description) errors.description = 'List name is required';
  if (!state.template) errors.template = 'List type is required';
  if (!state.memo) errors.memo = 'Description is required';
  if (!state.expiresAt) errors.expiresAt = 'Expiry Date is required';
  return errors;
};

export function AddListPage() {
  return (
    <RootLayout allowOverflow>
      <AddList />
    </RootLayout>
  );
}

export function AddList({
  onSave,
  onCancel,
  isModal = false,
}: {
  onSave?: () => void;
  onCancel?: () => void;
  isModal?: boolean;
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { me } = useGlobalState();
  const queryClient = useQueryClient();
  const isEditPage = Boolean(id);
  usePageTitle(`${isEditPage ? 'Edit' : 'Add'} Lists`);
  const [state, dispatch]: [APIList, React.Dispatch<IAction>] = useReducer(
    commonReducer,
    initialState,
  );
  const [{ errors }, errorDispatch]: [any, React.Dispatch<IAction>] =
    useReducer(commonReducer, { errors: {} });

  const { mutate: submitForm, isPending } = useCustomMutation({
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['/lists'],
        type: 'all',
      });

      if (isModal && !!onSave) {
        onSave();
        return;
      }
      navigate('/lists');
      return;
    },
    onSuccessMessage: isEditPage ? 'List updated' : 'List created',
    method: isEditPage ? 'patch' : 'post',
  });

  const { data, isLoading, isError } = useCustomQuery({
    url: `/lists/${id}`,
    enabled: isEditPage && Boolean(id),
    options: { staleTime: 0 },
  });
  const lists = data?.data;

  const handleSubmit = async () => {
    const errors = validateList(state);

    if (Object.values(errors).length) {
      errorDispatch({ path: 'errors', value: errors });
      return;
    }

    const expiresAtDate = fromZonedTime(
      new Date(state?.expiresAt ?? '').toISOString(),
      'America/Chicago',
    );
    const formattedDate = format(
      expiresAtDate,
      "yyyy-MM-dd'T'HH:mm:ssXXX",
    ).replace('T', ' ');

    await submitForm({
      url: isEditPage ? `/lists/${id}` : '/lists',
      body: {
        ...state,
        expiresAt: formattedDate,
      },
    });
  };

  const handleChange = (path: keyof APIList) => (value: any) => {
    if (errors[path]) {
      errorDispatch({ type: 'remove', path: `errors.${path}` });
    }

    dispatch({ path, value });
  };

  const handleDropdownChange =
    (path: string) =>
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption<any> | undefined,
    ) => {
      if (errors.userAssigned) {
        errorDispatch({ type: 'remove', path: 'errors.userAssigned' });
      }

      dispatch({ path, value: option?.key });
    };

  useEffect(() => {
    if (!state.userId && me) {
      dispatch({
        type: 'set',
        value: { userId: me.userId, recruiterEmail: me.emailAddress },
      });
    }
  }, [state.userId, me]);

  useEffect(() => {
    if (lists && !isLoading && !isError) {
      dispatch({
        type: 'set',
        value: {
          description: lists?.description,
          userId: lists?.userId,
          listId: lists?.listId,
          template: lists?.template,
          shared: lists?.shared,
          memo: lists?.memo,
          expiresAt: lists?.expiresAt ?? null,
        },
      });
    }
  }, [lists, isError, isLoading]);

  const expiresValue = state?.expiresAt ? new Date(state.expiresAt) : undefined;

  return (
    <>
      <div className='flex size-full flex-col items-center justify-start p-4'>
        <Container
          className={cn(
            !isModal
              ? 'size-full h-fit overflow-auto rounded-md border border-neutral-40 bg-white p-4 shadow-md'
              : 'size-full h-fit !p-4',
          )}
        >
          <h1 className='my-8 text-2xl font-bold'>
            {isEditPage ? 'Edit Lists' : 'Add New Lists'}
          </h1>
          <div className='flex flex-col gap-4'>
            <TextInput
              required
              label='List Name'
              handleChange={handleChange('description')}
              value={state.description || ''}
              errorMessage={errors.description}
            />
            <Dropdown
              required
              color='black'
              label='List Type'
              selectedKey={state.template}
              options={listTypeOptions}
              onChange={handleDropdownChange('template')}
              errorMessage={errors.template}
            />
            <UsersDropdown
              selectedKey={state.userId}
              defaultSelectedKey={state.userId}
              onChange={handleDropdownChange('userId')}
              errorMessage={errors.userAssigned}
              activeUserOnly
            />
            <TextInput
              required
              label='Description'
              value={state.memo || ''}
              handleChange={handleChange('memo')}
              errorMessage={errors.memo}
            />
            <Dropdown
              required
              color='black'
              label='Share'
              selectedKey={state.shared ? 1 : 0}
              options={[
                { key: 1, text: 'Share with all users' },
                { key: 0, text: 'Private' },
              ]}
              onChange={handleDropdownChange('shared')}
            />

            <DatePicker
              label='Select Expiry Date'
              value={expiresValue}
              onChange={handleChange('expiresAt')}
              classNames={{ trigger: 'w-full' }}
              onClear={() => handleChange('expiresAt')('')}
              errorMessage={errors.expiresAt}
              disabledDates={{
                before: new Date(),
                after: sixMonths,
              }}
            />
          </div>
          <div className='my-8 flex justify-between'>
            <Button
              onClick={() => {
                if (isModal && !!onCancel) {
                  onCancel();
                  return;
                }
                navigate(-1);
              }}
              variant='secondary'
            >
              Back
            </Button>
            <Button
              onClick={handleSubmit}
              isLoading={isPending}
              disabled={isPending}
            >
              Save
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}
