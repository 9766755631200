import { getActivityIcon } from '../../components/common/Activity';
import { BaseTable, Column } from '../../components/common/BaseTable';
import { Button } from '../../components/common/Button';
import PhoneNumber from '../../components/common/PhoneNumber';
import { useGlobalState } from '../../context/GlobalContext';
import { ListItem } from '../../types/lists';
import { CheckboxProps } from '../../types/table';
import { setFieldIfEmpty, convertUTCToLocal } from '../../utils/helpers';
import ListDetailStagesDropdown from './ListDetailStagesDropdown';

// import { StageDropdown } from './StageDropdown';

interface ListContactTableProps {
  data: ListItem[];
  handleRowClick: (value: ListItem) => void;
  className?: string;
  updateListItem: (value: string | null, listItem: ListItem) => void;
  removeListItem: (
    event: React.MouseEvent<HTMLButtonElement>,
    listItemId: string | null,
  ) => void;
  handleEntityClick: (id: string) => void;
  isDeleteItemPending: boolean;
  checkboxProps: CheckboxProps;
}

export function ListContactsTable({
  data,
  handleRowClick,
  className,
  updateListItem,
  removeListItem,
  isDeleteItemPending,
  handleEntityClick,
  checkboxProps,
}: ListContactTableProps) {
  const { users } = useGlobalState();

  const columns: Column<ListItem>[] = [
    {
      label: 'Stage',
      key: 'stage',
      sortEnabled: true,
      className: 'w-[12rem]',
      render: (item) => (
        <ListDetailStagesDropdown
          item={item}
          handleDropdownChange={updateListItem}
        />
      ),
    },
    {
      label: 'Name',
      key: 'name',
      sortEnabled: true,
      className: 'w-[12rem]',
      render: (item) => {
        return (
          <span
            onClick={(e) => {
              e.stopPropagation();

              if (item.tableId) {
                handleEntityClick(item.tableId);
              }
            }}
            className='text-link hover:text-[#167dff] hover:underline'
          >
            {setFieldIfEmpty(item.name)}
          </span>
        );
      },
    },
    {
      label: 'Title',
      key: 'contactTitle',
      sortEnabled: true,
      className: 'w-[18rem]',
      render: (item) => setFieldIfEmpty(item.contactTitle),
    },
    {
      label: 'Phone',
      key: 'phone',
      className: 'w-[12rem]',
      sortEnabled: false,
      render: (item) => <PhoneNumber phoneNumber={item.phone} />,
    },
    {
      label: 'Organization',
      key: 'organizationName',
      sortEnabled: true,
      className: 'w-[18rem]',
      render: (item) => setFieldIfEmpty(item.organizationName),
    },
    {
      label: 'Recent Activity',
      key: 'lastActivityDate',
      sortEnabled: true,
      className: 'w-[14rem]',
      render: (item) => (
        <div className='flex w-full min-w-0 items-center'>
          <div className='material-symbols-outlined mr-2'>
            {getActivityIcon(item.lastActivityType || '', '')}
          </div>
          <span className='truncate'>
            {item.lastActivityDate
              ? convertUTCToLocal(item.lastActivityDate)
              : setFieldIfEmpty(item.lastActivityDate)}
          </span>
        </div>
      ),
    },
    {
      label: 'User',
      key: 'userId',
      sortEnabled: false,
      className: 'w-[10rem]',
      render: (item) =>
        users?.find((u) => u.userId === item.userId)?.fullName || '-',
    },
    {
      label: 'Action',
      key: 'action',
      sortEnabled: false,
      className: 'w-[10rem]',
      render: (item) => (
        <Button
          disabled={isDeleteItemPending}
          variant='ghost'
          onClick={(e) => removeListItem(e, item.listItemId)}
          className='z-10 mx-auto flex h-6 items-center gap-2 self-start px-2 py-1 font-bold text-primary underline'
        >
          Remove
        </Button>
      ),
    },
  ];

  return (
    <BaseTable
      data={data}
      columns={columns}
      idKey='listItemId'
      defaultSortKey='name'
      handleRowClick={handleRowClick}
      className={className}
      checkboxProps={checkboxProps}
    />
  );
}

export default ListContactsTable;
