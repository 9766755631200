import * as React from 'react';

import { cn } from '../../lib/utils';
import colors from '../../styles/colors';
import { Input, InputProps } from '../ui/input';
import { DollarSignIcon } from './Icons';

interface CurrencyInputProps extends InputProps {}

const formatCurrencyInput = (value: string): string => {
  if (!value) return '';

  const withoutCommas = value.replace(/,/g, '');
  const withoutLeadingZeros = withoutCommas.replace(/^0+(?=\d)/, '');
  const [wholePart, decimalPart] = withoutLeadingZeros.split('.');
  const wholeWithCommas = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const result =
    decimalPart !== undefined
      ? `${wholeWithCommas}.${decimalPart}`
      : wholeWithCommas;

  return result || '0';
};

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  className,
  onChange,
  ...rest
}) => {
  return (
    <div className='relative'>
      <span className='absolute inset-y-0 left-0 flex items-center text-sm text-gray-500 dark:text-gray-400'>
        <DollarSignIcon color={colors.neutral[40]} />
      </span>
      <Input
        className={cn(
          'text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
          className,
        )}
        onChange={(e) => {
          if (e?.target?.value) {
            const formattedString = formatCurrencyInput(e?.target?.value);
            e.target.value = formattedString;
          }

          if (onChange) {
            onChange(e);
          }
        }}
        {...rest}
      />
    </div>
  );
};

CurrencyInput.displayName = 'CurrencyInput';

export default CurrencyInput;
