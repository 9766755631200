import { useState } from 'react';

import {
  ContentHeader,
  ContentContainer,
} from '../../../components/common/Content';
import DatePickerRange from '../../../components/common/DatePickerRange';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { APIRequisition } from '../../../types/requisitions';
import { formatDateRange, getDateRangeForPeriod } from '../../../utils/helpers';
import RegionAdsBarChart from './AdsByRegionsAndStates';
import CashInToBillingRatio from './CashInToBillingRatio';
import F2FIntvwPlacementRatio from './F2FIntvwPlacementRatio';
import PipeLineOverview from './PipeLineOverview';
import ProactiveCallEffort from './ProactiveCallEffort';
import SponsoredAdsChart from './SponsoredAdsChart';
import UserHeatMap from './UserHeatMap';

export type ProductivityPageState = {
  selectedRow?: APIRequisition;
  isFiltersOpen: boolean;
  search: string;
};

export function Productivity() {
  const { queryParams, setQueryParams } = useQueryParams();

  const [timeFrame, setTimeFrame] = useState<string>(
    (queryParams?.timeFrame as string) || 'today',
  );

  usePageTitle('Productivity');

  const timeFrames = [
    {
      id: 'today',
      label: 'Today',
    },
    {
      id: 'week',
      label: 'Current Week',
    },
    {
      id: 'month',
      label: 'Current Month',
    },
    {
      id: 'quarter',
      label: 'Current Quarter',
    },
    {
      id: 'year',
      label: 'YTD',
    },
  ];

  return (
    <>
      <ContentContainer className='m-auto flex max-w-[2100px] overflow-auto'>
        <div className='flex shrink-0 items-center justify-between overflow-hidden'>
          <ContentHeader
            title='Company Productivity Dashboard'
            subLabel={
              <div>
                <span className='font-bold'>
                  {queryParams?.startDate && queryParams?.endDate ? (
                    formatDateRange(
                      queryParams?.startDate as string,
                      queryParams?.endDate as string,
                    )
                  ) : (
                    <div className='mb-5' />
                  )}
                </span>
              </div>
            }
          />
          <div className='flex flex-wrap items-center justify-start gap-2 overflow-y-hidden sm:justify-end sm:gap-4'>
            <DatePickerRange
              startParam='startDate'
              endParam='endDate'
              tooltipText={'Pick a range.'}
              singleDay
              formatDate={false}
              onChange={() => setTimeFrame('custom')}
              onClear={() => {
                setTimeFrame('today');
              }}
            />
            {timeFrames?.map((item, key) => {
              const isChecked = timeFrame === item?.id;
              return (
                <label
                  onClick={() => {
                    setTimeFrame(item?.id);
                    const newParams = { ...queryParams };
                    if (item?.id === 'today') {
                      delete newParams.startDate;
                      delete newParams.endDate;
                      delete newParams.timeFrame;
                    } else {
                      const { startDate, endDate } = getDateRangeForPeriod(
                        item?.id,
                      );

                      newParams.startDate = startDate;
                      newParams.endDate = endDate;
                      newParams.timeFrame = item?.id;
                    }
                    setQueryParams(newParams);
                  }}
                  key={item?.id}
                  htmlFor='my-jobs-checkbox'
                  className={`h-8 cursor-pointer rounded-md border border-neutral-40 px-2 py-1 text-sm font-medium shadow-sm ${isChecked ? 'bg-secondary-80  text-white' : 'bg-white text-neutral-60'}`}
                  role='checkbox'
                  aria-checked={isChecked}
                  tabIndex={key + 1}
                >
                  {item?.label}
                </label>
              );
            })}
          </div>
        </div>

        <div className='grid grid-cols-12 gap-6 overflow-x-auto'>
          <div className='col-span-6 2xl:col-span-3'>
            <SponsoredAdsChart isDisabled={timeFrame !== 'today'} />
          </div>
          <div className='col-span-6 2xl:col-span-3'>
            <RegionAdsBarChart isDisabled={timeFrame !== 'today'} />
          </div>
          <div className='col-span-6 2xl:col-span-3'>
            <F2FIntvwPlacementRatio />
          </div>
          <div className='col-span-6 2xl:col-span-3'>
            <CashInToBillingRatio billing={189984.89} cashIn={141060.58} />
          </div>
          <div className='col-span-12'>
            <PipeLineOverview />
          </div>
          <div className='col-span-12 size-full'>
            <ProactiveCallEffort />
          </div>
          <div className='col-span-12'>
            <UserHeatMap
              isDisabled={timeFrame !== 'today' && timeFrame !== 'week'}
              timeFrame={timeFrame}
            />
          </div>
        </div>
      </ContentContainer>
    </>
  );
}
