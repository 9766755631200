import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EditorConfig } from '@ckeditor/ckeditor5-core';
import { CKEditor as Editor } from '@ckeditor/ckeditor5-react';
import styled from 'styled-components';

import { ErrorMessage } from './Error';

const EditorWrapper = styled.div`
  .ck-content {
    /* padding: 0 0 0 2rem; */
    min-height: 270px;
    h2 {
      font-size: 22px;
    }
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
    ul,
    ol {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
  }

  .ck-sticky-panel__content {
    position: static !important;
  }

  .ck-editor__top {
    position: static !important;
  }
`;

const editorConfiguration: EditorConfig = {
  toolbar: {
    items: ['heading', 'bold', 'italic', 'bulletedList', 'numberedList'],
    shouldNotGroupWhenFull: true,
  },
};

const sanitizeContent = (dirtyContent: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(dirtyContent, 'text/html');

  // Remove all image tags
  const images = doc.getElementsByTagName('img');
  if (images && images.length > 0) {
    while (images[0]) {
      images[0]?.parentNode?.removeChild(images[0]);
    }
  }

  return doc.body.innerHTML;
};

export function CKEditor({
  onChange,
  disabled,
  value,
  errorMessage,
}: {
  onChange: (value: string) => void;
  disabled?: boolean;
  value?: string;
  errorMessage?: string;
}) {
  return (
    <EditorWrapper>
      <Editor
        editor={ClassicEditor}
        config={editorConfiguration}
        data={value ?? ''}
        disabled={disabled}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(sanitizeContent(data));
        }}
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </EditorWrapper>
  );
}
