import { useState } from 'react';

import BulkActions, {
  BulkAction,
} from '../../components/bulk-actions/BulkActions';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import useInvalidateQuery from '../../hooks/useInvalidateQuery';
import { SelectionState } from '../../hooks/useTableSelection';
import EmailContactsModal from '../contacts/EmailContactsModal';
import BulkUpdateJobModal from './BulkUpdateJobModal';

function ListDetailsBulkActions({
  type,
  selectionState,
  listSize,
  listId,
  clearSelectedItems,
}: {
  type?: string;
  selectionState?: SelectionState;
  listSize: number;
  listId: string;
  clearSelectedItems: () => void;
}) {
  const [isBulkActionModalOpen, setIsBulkActionModalOpen] = useState<
    'enable' | 'disable' | 'email' | null
  >(null);

  const invalidate = useInvalidateQuery();

  const itemsCount =
    selectionState?.mode === 'individual'
      ? selectionState?.newlySelected.size
      : listSize - (selectionState?.blacklistedIds.size as number);

  const handleConfirmAction = async () => {
    let listItemIds: string[] = [];
    let url: string = '';

    if (
      selectionState?.mode === 'individual' &&
      selectionState.newlySelected.size
    ) {
      url = `/lists/${listId}/jobs/change`;
      listItemIds = [...selectionState.newlySelected.values()];
    } else if (selectionState?.mode === 'allFiltered') {
      const removalIds = selectionState?.blacklistedIds?.values() || [];
      url = `/lists/${listId}/jobs/batchchange`;
      listItemIds = [...removalIds];
    }

    await changeSelected({
      url,
      body: {
        listItemIds,
        change: {
          indeedSponsored: isBulkActionModalOpen === 'enable' ? 2 : 0,
        },
      },
    });
  };

  const { mutate: changeSelected } = useCustomMutation({
    method: 'patch',
    onSuccess: () => {
      clearSelectedItems();
      invalidate([`/lists/${listId}`], { refetchType: 'active' });
    },
    onSuccessMessage: `${itemsCount} items successfully updated`,
  });

  const bulkActions = [
    ...(type === 'requisitions'
      ? [
          {
            label: 'Sponsor on Indeed',
            type: 'primary',
            onClickHandler: async () => {
              setIsBulkActionModalOpen('enable');
            },
          },
          {
            label: 'Remove from Indeed',
            type: 'primary',
            onClickHandler: async () => {
              setIsBulkActionModalOpen('disable');
            },
          },
        ]
      : []),
    // TODO: When routes are updated, we can reintroduce this as a bulk action feature.
    // ...(type === 'contacts'
    //   ? [
    //       {
    //         label: 'Send email',
    //         type: 'primary',
    //         onClickHandler: async () => {
    //           setIsBulkActionModalOpen('email');
    //         },
    //       },
    //     ]
    //   : []),
  ] as BulkAction[];

  return (
    <>
      <BulkActions
        bulkActions={bulkActions}
        selectedItemsCount={itemsCount as number}
      />

      {(isBulkActionModalOpen === 'enable' ||
        isBulkActionModalOpen === 'disable') && (
        <BulkUpdateJobModal
          isBulkActionModalOpen={!!isBulkActionModalOpen}
          setIsBulkActionModalOpen={setIsBulkActionModalOpen}
          handleConfirmAction={handleConfirmAction}
        />
      )}
      {isBulkActionModalOpen === 'email' && (
        <EmailContactsModal
          open={!!isBulkActionModalOpen}
          setIsBulkActionModalOpen={setIsBulkActionModalOpen}
          itemsCount={itemsCount}
        />
      )}
    </>
  );
}

export default ListDetailsBulkActions;
