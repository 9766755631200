import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { EmptyState } from '../../components/common';
import { Button } from '../../components/common/Button';
import {
  ContentHeader,
  ContentContainer,
  ContentInnerContainer,
} from '../../components/common/Content';
import { WarningIcon } from '../../components/common/Icons';
import PageContent from '../../components/common/PageContent';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { usePageTitle } from '../../hooks/usePageTitle';
import { useQueryParams } from '../../hooks/useQueryParams';
import { APIList } from '../../types/lists';
import { PageTypes } from '../../types/pages';
import { formatContentHeaderSubLabel } from '../../utils/contentHelpers';
import { ListFilters } from './ListFilters';
import ListTable from './ListTable';

export function Lists() {
  const { queryParams } = useQueryParams();

  const {
    data: response,
    isError,
    error,
    isLoading,
    isFetching,
  } = useCustomQuery<APIList[]>({
    url: '/lists',
    params: queryParams,
  });

  const { data: lists = [], numberofrecords, pages } = response ?? {};

  const navigate = useNavigate();

  usePageTitle('Lists');

  const contentHeaderSubLabel = useMemo(() => {
    return formatContentHeaderSubLabel({
      label: 'Lists',
      rowsCount: numberofrecords ?? 0,
    });
  }, [numberofrecords]);

  const handleRowClick = useCallback(
    (list: APIList) => {
      const { contactCount, organizationCount, jobCount } = list;

      const counts = [
        { type: 'contacts', count: contactCount || 0 },
        { type: 'organizations', count: organizationCount || 0 },
        { type: 'requisitions', count: jobCount || 0 },
      ];

      const largest = counts.reduce((prev, current) =>
        prev.count > current.count ? prev : current,
      );

      return navigate(`/lists/${list.listId}/${largest.type}`);
    },
    [navigate],
  );

  if (error) {
    return (
      <EmptyState
        error={isError}
        icon={WarningIcon}
        title={error.message ?? 'Error'}
        link={
          <Button onClick={() => window.location.reload()} className='blue'>
            Refresh Page
          </Button>
        }
      />
    );
  }

  return (
    <>
      <ContentContainer>
        <ContentHeader
          title='Lists'
          subLabel={contentHeaderSubLabel}
          link={{ to: '/lists/add', label: 'Create New List' }}
        ></ContentHeader>
        <ContentInnerContainer>
          <PageContent
            contentType={PageTypes.Lists}
            data={lists}
            pages={pages}
            isLoading={isLoading}
            handleRowClick={handleRowClick}
            FiltersComponent={ListFilters}
            TableComponent={ListTable}
            isFetchingNewTableData={isFetching}
          />
        </ContentInnerContainer>
      </ContentContainer>
    </>
  );
}
