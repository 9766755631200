import { useState } from 'react';

import { CommonSpinner } from '../../components/common';
import { Button } from '../../components/common/Button';
import FileUpload from '../../components/common/FileUpload';
import Modal from '../../components/common/ModalRadix';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { APIContact } from '../../types/contacts';
import { AddContact } from './AddContact';

interface AddContactFromResumeProps {
  isOpen: boolean;
  onDismiss: () => void;
}

const uploadSteps: { id: string; title: string }[] = [
  {
    id: 'upload',
    title: 'Upload Resume',
  },
  {
    id: 'addContact',
    title: 'Add Contact',
  },
  {
    id: 'contactAdded',
    title: 'Success',
  },
];

const AddContactFromResume = ({
  isOpen,
  onDismiss,
}: AddContactFromResumeProps) => {
  const [resume, setResume] = useState<File | undefined>(undefined);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentContact, setCurrentContact] = useState<APIContact>();

  const handleFile = (file: File) => {
    setResume(file);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    if (resume) {
      formData.append('multipartFile', resume);
    }

    await submitFile({
      url: '/contacts/resume',
      body: formData,
    });
  };

  const { mutate: submitFile, isPending } = useCustomMutation({
    onSuccess: (res: any) => {
      const generatedContact: APIContact = res?.data;
      if (generatedContact) {
        setCurrentContact({
          ...generatedContact,
        });
        setCurrentStep(1);
      }
    },
    onSuccessMessage: 'Resume successfully submitted!',
  });

  const handleReset = () => {
    setResume(undefined);
    setCurrentContact(undefined);
    setCurrentStep(0);
  };

  const handleClose = () => {
    handleReset();
    onDismiss();
  };

  const handleContactAdded = () => {
    handleReset();
    setCurrentStep(2);
  };

  return (
    <Modal open={isOpen} onOpenChange={() => handleClose()}>
      <Modal.Content title={uploadSteps[currentStep].title}>
        {!isPending && currentStep === 0 && (
          <>
            <FileUpload handleFile={(file: any) => handleFile(file)} />

            <div className='flex-column flex justify-between'>
              <Button onClick={() => handleClose()} variant='secondary'>
                Cancel
              </Button>
              <Button onClick={handleSubmit} disabled={!resume}>
                Submit
              </Button>
            </div>
          </>
        )}
        {isPending && currentStep === 0 && (
          <div className='flex flex-col gap-4'>
            <div className='flex size-full items-center justify-center'>
              <CommonSpinner size='sm' />
            </div>
            <h2 className='text-center text-xl'>
              Analyzing Resume. Hang tight, this might take a sec.
            </h2>
          </div>
        )}
        {currentStep === 1 && (
          <>
            <AddContact
              isUsedAsComponent
              contact={currentContact}
              onSubmit={() => handleContactAdded()}
              onCancel={() => handleClose()}
            />
          </>
        )}
        {currentStep === 2 && (
          <div className='flex flex-col justify-between'>
            <div className='flex flex-col justify-center gap-4'>
              <h2 className='text-center text-xl'>Contact Added Succesfully</h2>
              <p className='text-center'>Add another?</p>
            </div>
            <div className='flex flex-row justify-between'>
              <Button onClick={() => handleClose()} variant='secondary'>
                Done
              </Button>
              <Button onClick={handleReset}>Add Another</Button>
            </div>
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};

AddContactFromResume.displayName = 'AddContactFromResume';

export { AddContactFromResume };
