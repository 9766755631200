import RootLayout from '../../layouts';
import { Requisitions } from './Requisitions';

const RequisitionsPage = () => {
  return (
    <RootLayout>
      <Requisitions />
    </RootLayout>
  );
};

export default RequisitionsPage;
