import React, { createContext, useContext, useState, ReactNode } from 'react';

import { CallInfo } from '../types/phone';

interface PhoneContextType {
  callPhone: CallInfo | null;
  setCallPhone: (info: CallInfo | null) => void;
}

const PhoneContext = createContext<PhoneContextType | undefined>(undefined);

export const PhoneProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [callPhone, setCallPhone] = useState<CallInfo | null>(null);

  return (
    <PhoneContext.Provider value={{ callPhone, setCallPhone }}>
      {children}
    </PhoneContext.Provider>
  );
};

export const usePhone = () => {
  const context = useContext(PhoneContext);
  if (!context) {
    throw new Error('usePhone must be used within a PhoneProvider');
  }
  return context;
};
