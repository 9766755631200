import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const formatDate = (isoDate: string | null) => {
  if (!isoDate) return '-';
  try {
    const zonedTime = toZonedTime(
      isoDate,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
    return format(zonedTime, 'MM/dd/yyyy h:mm a');
  } catch (error) {
    return '-';
  }
};
