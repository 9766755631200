import { Check, ChevronDownIcon, XIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';

import { useCustomQuery } from '../../hooks/useCustomQuery';
import { cn } from '../../lib/utils';
import { APIOrganization } from '../../types/organizations';
import { useDebounce } from '../../utils/hooks';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { ErrorMessage } from './Error';
import OrganizationName from './OrganizationName';

export type OrganizationState = {
  id: string | null;
  label: string | null;
  contract?: boolean | null;
};

function OrganizationSelect({
  value,
  onChange,
  disabled = false,
  required = false,
  onClear,
  errorMessage = '',
}: {
  value: OrganizationState | undefined;
  onChange: (value: OrganizationState) => void;
  disabled?: boolean;
  onClear?: () => void;
  required?: boolean;
  errorMessage?: string;
}) {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const debounceSearch = useDebounce((value: string) => {
    setSearchValue(value);
  }, 500);

  const { data: response, isLoading } = useCustomQuery<APIOrganization[]>({
    url: `/organizations`,
    params: searchValue ? { organizationName: searchValue } : {},
  });

  const organizations = response?.data || [];

  useEffect(() => {
    let timeoutId: any;
    if (!open) {
      timeoutId = setTimeout(() => {
        setSearchValue('');
      }, 300);
    }
    return () => clearTimeout(timeoutId);
  }, [open]);

  return (
    <div className='my-2 flex w-full flex-col space-y-1.5'>
      <label htmlFor='organization'>
        Organization
        {required && <span className='text-error'>*</span>}
      </label>
      <Popover
        open={open}
        onOpenChange={(open) => {
          if (open && disabled) return;

          setOpen(open);
        }}
      >
        <PopoverTrigger disabled={disabled} asChild>
          <div
            className={cn(
              `flex h-8 w-full max-w-full cursor-default items-center justify-between rounded-[6px] border border-slate-300 bg-white px-2 py-1 text-left shadow-sm placeholder:text-neutral-600 hover:border-neutral ${
                disabled &&
                ' bg-neutral-2 text-neutral-48 hover:border-slate-300'
              } sm:text-sm`,
            )}
          >
            <>
              {value?.label ? (
                <OrganizationName
                  hasContract={!!value?.contract}
                  name={value?.label}
                  size={16}
                />
              ) : (
                'Select organization...'
              )}
            </>
            <div className='flex flex-shrink-0 cursor-pointer items-center justify-center'>
              {value && onClear && !disabled && (
                <span
                  onClick={(e) => {
                    e.preventDefault();

                    onClear();
                    setOpen(false);
                  }}
                  className='mx-2 mt-[2px]'
                >
                  <XIcon size={16} className='text-neutral-60' />
                </span>
              )}

              {!disabled && (
                <>
                  <span className='text-neutral-60'>|</span>
                  <ChevronDownIcon
                    size={16}
                    className='ml-1 mt-[2px] text-neutral-60'
                  />
                </>
              )}
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent className='popover-content-width-full p-0'>
          <Command shouldFilter={false}>
            <CommandInput
              placeholder='Search organization...'
              onValueChange={debounceSearch}
            />
            <CommandList>
              <CommandEmpty>
                {isLoading ? 'Loading...' : 'No organization found.'}
              </CommandEmpty>
              <CommandGroup>
                {organizations.map((org) => {
                  const isActive = value?.id === org.organizationId;

                  return (
                    <CommandItem
                      key={org.organizationId}
                      value={org.organizationId}
                      onSelect={() => {
                        onChange({
                          id: org.organizationId,
                          label: org.organizationName,
                          contract: !!org.contract,
                        });
                        setOpen(false);
                      }}
                      className={`hover:bg-gray-100 ${isActive && 'bg-gray-300'} flex items-center justify-between`}
                    >
                      <OrganizationName
                        hasContract={!!org.contract}
                        name={org.organizationName}
                        size={16}
                      />
                      <Check
                        className={cn(
                          'mr-2 h-4 w-4',
                          value?.id === org.organizationId
                            ? 'opacity-100'
                            : 'opacity-0',
                        )}
                      />
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
}

export default OrganizationSelect;
