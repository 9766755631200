function ProactiveCallEffortRatio() {
  return (
    <div className=' flex size-full flex-col gap-4 overflow-hidden rounded-lg border border-neutral-40 bg-white p-4'>
      <div className='flex size-full flex-col gap-4'>
        <p className='text-lg font-bold text-gray-500'>
          Proactive call effort ratio
        </p>
        <div className='flex size-full items-center justify-center text-lg font-medium text-gray-500'>
          Not available for selected time range
        </div>
      </div>
    </div>
  );
}

export default ProactiveCallEffortRatio;
