import { useMemo, useState } from 'react';

import { useGlobalState } from '../../context/GlobalContext';
import { useToast } from '../../hooks/use-toast';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import useInvalidateQuery from '../../hooks/useInvalidateQuery';
import { useTableSelection } from '../../hooks/useTableSelection';
import { APIList, ListType } from '../../types/lists';
import { PageTypes } from '../../types/pages';
import { CheckboxProps } from '../../types/table';
import { Stages } from '../../utils/constant';
import { useDebounce } from '../../utils/hooks';
import { CommonSpinner, EmptyState } from '../common';
import { BaseTable, Column } from '../common/BaseTable';
import { Button } from '../common/Button';
import { SearchIcon } from '../common/Icons';
import Modal from '../common/ModalRadix';
import { Pagination } from '../common/Pagination';
import Select from '../common/SelectNew';
import TableCellTooltip from '../common/TableCellTooltip';
import { Input } from '../ui/input';

export const getTableKey = (itemType: PageTypes): string => {
  if (itemType === PageTypes.Requisitions) {
    return 'jobs';
  }
  return itemType.toLowerCase();
};

interface AddToListModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedItems: Set<string> | undefined;
  getSelectionPayload?: CheckboxProps['getSelectionPayload'];
  getSelectedCount: CheckboxProps['getSelectedCount'];
  itemType: PageTypes;
  currentFilters?: CheckboxProps['currentFilters'];
  clearSelectedItems: CheckboxProps['clearSelectedItems'];
}

function AddToListModal({
  isOpen,
  onClose,
  selectedItems,
  itemType,
  getSelectionPayload,
  getSelectedCount,
  currentFilters,
  clearSelectedItems,
}: AddToListModalProps) {
  const { findUserById, users } = useGlobalState();

  const { toast } = useToast();

  const [search, setSearch] = useState('');
  const [listFilterType, setListFilterType] = useState('');
  const [userAssignedId, setUserAssignedId] = useState('');
  const [localPage, setLocalPage] = useState(1);

  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const setDebouncedSearch = useDebounce((value: string) => {
    setDebouncedSearchValue(value);
    setLocalPage(1);
  }, 500);

  const invalidate = useInvalidateQuery();

  const { data: response, isLoading } = useCustomQuery<APIList[]>({
    url: '/lists',
    params: {
      ...(!!debouncedSearchValue && { query: debouncedSearchValue }),
      ...(!!listFilterType && { template: listFilterType }),
      ...(!!userAssignedId && { userId: userAssignedId }),
      pageNumber: localPage,
    },
  });

  const { data: lists = [], pages } = response ?? {};

  const listsForSelection = useMemo(
    () =>
      lists.map((list) => ({
        id: list.listId,
        isInList: false,
        disabled: false,
      })),
    [lists],
  );
  const {
    selectionState: selectedListItemState,
    handleSelectionChange,
    ...checkboxProps
  } = useTableSelection(listsForSelection);

  const [isSaving, setIsSaving] = useState(false);
  const { mutateAsync: addItemsToList } = useCustomMutation({
    method: 'post',
  });

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setSearch(value);
    setDebouncedSearch(value);
  }

  function handleClear() {
    setSearch('');
    setDebouncedSearch('');
    setListFilterType('');
    setUserAssignedId('');
  }

  async function handleSaveItems() {
    setIsSaving(true);

    try {
      if (!selectedItems?.size && !getSelectionPayload) {
        return;
      }

      const selectedLists = Array.from(selectedListItemState.newlySelected);
      const selectionPayload = getSelectionPayload?.();

      const savePromises = selectedLists.map(async (listId) => {
        const tableKey = getTableKey(itemType);

        if (selectionPayload?.mode === 'allFiltered') {
          const filters = { ...currentFilters };

          // escape hatch for state filter - the backend expects an array no matter what but if we only have one state in our query params, we pass it as a string
          if (filters?.state) {
            if (!Array.isArray(filters.state)) {
              filters.state = [filters.state];
            }
          }

          const filterPayload = {
            [tableKey]: filters,
          };

          return addItemsToList({
            url: `/lists/${listId}/batchitems`,
            body: {
              ...filterPayload,
              removalIds: selectionPayload.blacklistedIds,
            },
          });
        } else {
          const formattedItems = Array.from(selectedItems ?? []).map(
            (itemId) => ({
              tableId: itemId,
              tableName: tableKey,
              stage: Stages.toInterview,
            }),
          );
          return addItemsToList({
            url: `/lists/${listId}/items`,
            body: formattedItems,
          });
        }
      });

      await Promise.all(savePromises);

      invalidate([`/lists`], { refetchType: 'none' });
      selectedLists.forEach((listId) => {
        invalidate([`/lists/${listId}`], { refetchType: 'active' });
        invalidate([`/lists/${listId}`], { refetchType: 'none' });
      });

      const itemCount =
        selectionPayload?.mode === 'allFiltered'
          ? `all filtered items (${getSelectedCount()})`
          : `${selectedItems?.size ?? 0} items`;

      toast({
        description: `${itemCount} added to ${selectedListItemState.newlySelected.size} list${
          selectedListItemState.newlySelected.size > 1 ? 's' : ''
        }`,
        duration: 5000,
      });

      clearSelectedItems?.();
      onClose();
    } catch (error) {
      console.error('Error saving items to lists:', error);
      toast({
        title: 'Error!',
        description: 'Failed to add items to one or more lists',
        variant: 'destructive',
        duration: 5000,
      });
    } finally {
      setIsSaving(false);
    }
  }

  const listTypeOptions = Object.values(ListType).map((value) => ({
    value: value,
    label: value,
  }));

  const userAssignedOptions = users.map((user) => ({
    value: user.userId!,
    label: user.fullName!,
  }));

  const columns: Column<APIList>[] = [
    {
      label: 'Name',
      key: 'firstName',
      sortEnabled: false,
      className: 'w-[14rem]',
      render: (list) => list.description,
    },
    {
      label: 'List type',
      key: 'template',
      sortEnabled: false,
      className: 'w-[10rem]',
      render: (list) => list.template,
    },
    {
      label: 'Description',
      key: 'description',
      sortEnabled: false,
      className: 'w-[14rem]',
      render: (list) => <TableCellTooltip content={list.memo} />,
    },
    {
      label: 'Date Created',
      key: 'date-created',
      sortEnabled: false,
      className: 'w-[10rem]',
      render: (list) => list.insertDate,
    },
    {
      label: 'User',
      key: 'user',
      sortEnabled: false,
      className: 'w-[14rem]',
      render: (list) => {
        const user = findUserById(list.userId);
        return user?.fullName;
      },
    },
  ];

  return (
    <Modal open={isOpen} onOpenChange={onClose}>
      <Modal.Content
        title='Add to list(s)'
        className='h-[90vh] w-[80vw] max-w-[3000px]'
      >
        <div className='flex gap-4'>
          <Input
            placeholder='Search lists'
            className='text-secondary-80'
            value={search}
            onChange={handleSearchChange}
          />
          <Select
            placeholder='User assigned'
            options={userAssignedOptions}
            value={userAssignedId}
            onChange={(value) => {
              setUserAssignedId(value);
              setLocalPage(1);
            }}
            showClearOption
          />
          <Select
            placeholder='List type'
            options={listTypeOptions}
            value={listFilterType}
            onChange={(value) => {
              setListFilterType(value);
              setLocalPage(1);
            }}
            showClearOption
          />
        </div>
        {isLoading && (
          <div className='flex size-full items-center justify-center'>
            <CommonSpinner size='sm' />
          </div>
        )}
        {!isLoading && (
          <>
            {lists && lists.length > 0 ? (
              <>
                <div className='relative size-full overflow-hidden rounded border'>
                  <div className='size-full overflow-auto'>
                    <BaseTable
                      data={lists}
                      columns={columns}
                      idKey='listId'
                      defaultSortKey='dateEntered'
                      handleRowClick={(row, event) => {
                        if (row.listId) {
                          handleSelectionChange(row.listId, event.shiftKey);
                        }
                      }}
                      checkboxProps={{
                        selectionState: selectedListItemState,
                        handleSelectionChange,
                        ...checkboxProps,
                      }}
                    />
                  </div>
                </div>
                <div className='flex items-center justify-between'>
                  {!!pages && pages > 1 ? (
                    <Pagination
                      pages={pages}
                      localPage={localPage}
                      handleLocalPageChange={(page) => {
                        setLocalPage(page);
                      }}
                    />
                  ) : (
                    <div className='h-4 w-4' />
                  )}
                  <div className='flex items-center gap-4'>
                    <Button variant='secondary' onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSaveItems}
                      isLoading={isSaving}
                      disabled={
                        !selectedListItemState.newlySelected.size || isSaving
                      }
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <EmptyState
                icon={SearchIcon}
                iconSize={24}
                title='No results found'
                link={
                  <div className='flex'>
                    <span style={{ maxWidth: 300, textAlign: 'center' }}>
                      No results matched your search criteria. Try searching for
                      something else.
                    </span>
                  </div>
                }
                handleClear={handleClear}
              />
            )}
          </>
        )}
      </Modal.Content>
    </Modal>
  );
}

export default AddToListModal;
