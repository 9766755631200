import { IDropdownOption } from '@fluentui/react';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import {
  DetailsBox,
  IAction,
  Stack,
  commonReducer,
} from '../../components/common';
import { CKEditor } from '../../components/common/CKEditor';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { IAddress } from '../../react-app-env';
import { APIRequisition } from '../../types/requisitions';
import { formatDateToBackend, handleMergeOptions } from '../../utils/helpers';
import {
  ExternalFeeds,
  JobDetails,
  NCHCR,
  ReqContact,
  WebPostings,
  validateRequisition,
} from './AddRequisition';

export function RequisitionDetails({
  requisition: r,
  onRequisitionSave,
}: {
  requisition: APIRequisition;
  onRequisitionSave: () => void;
}) {
  const [state, dispatch]: [
    APIRequisition & { customPostalCode?: string },
    React.Dispatch<IAction>,
  ] = useReducer(commonReducer, {});

  const [sponsoredAdOverLimitError, setSponsoredAdOverLimitError] =
    useState(false);

  const [{ errors, ...options }, optionsReducer] = useReducer(commonReducer, {
    errors: {},
  });

  const { mutate: saveReq, isPending } = useCustomMutation({
    onSuccess: () => {
      onRequisitionSave?.();
    },
    method: 'put',
    onError: (error) => {
      if (
        (error as unknown as { response: { status: number } })?.response
          ?.status === 422
      ) {
        setSponsoredAdOverLimitError(true);
      }
    },
  });

  const toggleEdit = useCallback(
    (path: string) => () => {
      optionsReducer({ path, value: !options[path] });
    },
    [options],
  );

  const handleChange = useCallback(
    (path: keyof APIRequisition) => (value: any) => {
      dispatch({ path, value });
    },
    [],
  );

  const updateReq = useCallback(
    (path: string, validation: string) => async () => {
      const errors = validateRequisition({ ...state }, validation);

      if (Object.entries(errors).length) {
        return optionsReducer({ path: 'errors', value: errors });
      }

      const jobPostVariants =
        state.jobPostVariants?.map((variant) => ({
          activeVariant: false,
          city: variant.city,
          deleted: false,
          insertDate: formatDateToBackend(new Date()),
          jobTitle: variant.useJobTitle ? state.jobTitle : variant.jobTitle,
          latitude: variant.latitude,
          longitude: variant.longitude,
          postalCode: variant.postalCode || variant.customPostalCode || '',
          refreshCount: 0,
          state: variant.state,
          jobId: state.jobId,
        })) ?? [];

      const updatedState = {
        ...r,
        ...state,
        jobDescription: state?.jobDescription,
        postalCode: state.postalCode ?? state.customPostalCode,
        // remove customPostalCode as it was only needed for handling cases where a city, state was selected and not an address
        customPostalCode: undefined,
        jobPostVariants,
      };

      await saveReq({
        url: `/jobs/${state.jobId}`,
        body: updatedState,
      });

      toggleEdit(path)();
    },
    [state, toggleEdit, saveReq, r],
  );

  const handleDropdownChange = useCallback(
    (path: string) =>
      (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption<any> | undefined,
      ) => {
        if (errors[path]) {
          optionsReducer({ type: 'remove', path: `errors[${path}]` });
        }
        if (path === 'userId') {
          const opt = option as any;
          dispatch({
            type: 'set',
            value: {
              userId: opt?.key,
              distributionList: opt?.email,
            },
          });
        } else dispatch({ path, value: option?.key });
      },
    [errors],
  );

  const handleMultiDropdownChange =
    (path: string) =>
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption<any> | undefined,
    ) => {
      const pathData = state[path as keyof APIRequisition] as string;

      const data = handleMergeOptions(pathData, option);
      dispatch({ path, value: data });
    };

  const handleAddressChange = useCallback(
    (address: IAddress) => {
      if (errors.city) optionsReducer({ type: 'remove', path: 'errors.city' });
      dispatch({ type: 'set', value: address });
    },
    [errors.city],
  );

  const handleSelectChange = useCallback(
    (path: string) => (newValue: any) => {
      if (errors[path])
        optionsReducer({ path: `errors[${path}]`, type: 'remove' });
      dispatch({
        type: 'set',
        value: newValue?.update ?? '',
      });
    },
    [errors],
  );

  const editRequisition = useMemo(
    () => options.requisition,
    [options.requisition],
  );
  const editWeb = useMemo(() => options.web, [options.web]);
  const editNCHCR = useMemo(() => options.NCHCR, [options.NCHCR]);
  const editContact = useMemo(() => options.contact, [options.contact]);
  const editExternal = useMemo(() => options.external, [options.external]);
  // const editContingency = useMemo(() => options.other, [options.other])
  const editDescription = useMemo(
    () => options.description,
    [options.description],
  );

  useEffect(() => {
    if (r) {
      dispatch({ type: 'reset', value: r });
      optionsReducer({ type: 'reset', value: { errors: {} } });
    }
  }, [r]);

  return (
    <Stack spacing={24} className=''>
      <DetailsBox
        title='Job Details'
        edit={editRequisition}
        onSubmit={updateReq('requisition', 'details')}
        toggleEdit={toggleEdit('requisition')}
        isPending={isPending}
      >
        <JobDetails
          disabled={!editRequisition}
          state={state}
          errors={errors}
          handleChange={handleChange}
          handleDropdownChange={handleDropdownChange}
          handleOrgChange={handleSelectChange('organizationId')}
          handleAddressChange={handleAddressChange}
          handleMultiDropdownChange={handleMultiDropdownChange}
          dispatch={dispatch}
        />
      </DetailsBox>

      <DetailsBox
        title='Web Posting'
        edit={editWeb}
        toggleEdit={toggleEdit('web')}
        onSubmit={updateReq('web', 'web')}
        isPending={isPending}
      >
        <WebPostings
          state={state}
          errors={errors}
          disabled={!editWeb}
          handleChange={handleChange}
          handleDropdownChange={handleDropdownChange}
          handleMultiDropdownChange={handleMultiDropdownChange}
        />
      </DetailsBox>

      <DetailsBox
        title='NCHCR'
        edit={editNCHCR}
        toggleEdit={toggleEdit('NCHCR')}
        onSubmit={updateReq('NCHCR', 'nchcr')}
        isPending={isPending}
      >
        <NCHCR
          state={state}
          errors={errors}
          disabled={!editNCHCR}
          handleDropdownChange={handleDropdownChange}
          handleChange={handleChange}
        />
      </DetailsBox>

      <DetailsBox
        title='Hiring Contact'
        edit={editContact}
        toggleEdit={toggleEdit('contact')}
        onSubmit={updateReq('contact', 'contact')}
        isPending={isPending}
      >
        <ReqContact
          state={state}
          errors={errors}
          disabled={!editContact}
          handleChange={handleChange}
        />
      </DetailsBox>

      <DetailsBox
        title='External Feeds'
        edit={editExternal}
        toggleEdit={toggleEdit('external')}
        onSubmit={updateReq('external', 'external')}
        isPending={isPending}
      >
        <ExternalFeeds
          sponsoredAdOverLimitError={sponsoredAdOverLimitError}
          state={{ ...state }}
          errors={errors}
          dispatch={dispatch}
          disabled={!editExternal}
          handleDropdownChange={handleDropdownChange}
          handleHCENChange={(data) =>
            handleSelectChange('hcenSpecialty')(
              data ?? { update: { hcenSpecialty: '' } },
            )
          }
        />
      </DetailsBox>

      <DetailsBox
        title='Job Description'
        edit={editDescription}
        toggleEdit={toggleEdit('description')}
        onSubmit={updateReq('description', 'other')}
        isPending={isPending}
      >
        <div className='mt-2'>
          <CKEditor
            errorMessage={errors?.jobDescription}
            disabled={!editDescription}
            value={state.jobDescription ?? ''}
            onChange={handleChange('jobDescription')}
          />
        </div>
      </DetailsBox>
    </Stack>
  );
}
