import { useEffect, useState } from 'react';

import { useGlobalState } from '../../context/GlobalContext';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { NoteRecord } from '../../types/notes';
import { Button } from '../common/Button';
import Modal from '../common/ModalRadix';

interface AddNoteModalProps {
  isOpen: boolean;
  onDismiss: any;
  entityId: string;
  entityName: string;
  editingNote: NoteRecord | null;
  setEditingNote: React.Dispatch<React.SetStateAction<NoteRecord | null>>;
  refetchNotesData: any;
}

function AddNoteModal({
  isOpen,
  onDismiss,
  entityId,
  entityName,
  editingNote,
  setEditingNote,
  refetchNotesData,
}: AddNoteModalProps) {
  const isEditing = !!editingNote;
  const [textAreaValue, setTextAreaValue] = useState(editingNote?.note ?? '');
  const { me: user } = useGlobalState();

  const { mutate: saveNote, isPending } = useCustomMutation({
    method: isEditing ? 'patch' : 'post',
    onSuccess: () => {
      refetchNotesData();
      if (isEditing) {
        setEditingNote(null);
      }
      setTextAreaValue('');
      onDismiss();
    },
    onSuccessMessage: isEditing ? 'Note saved!' : 'Note added successfully!',
  });

  async function handleSaveNote() {
    const body = isEditing
      ? { ...editingNote, note: textAreaValue }
      : {
          note: textAreaValue,
          tableName: entityName,
          tableId: entityId,
          userName: user?.userName,
          userId: user?.userId,
        };

    saveNote({
      url: isEditing ? `/notes/${editingNote?.noteId}` : '/notes',
      body,
    });
  }

  useEffect(() => {
    setTextAreaValue(editingNote?.note ?? '');
  }, [editingNote?.note]);

  const isSavingEnabled = !!textAreaValue;

  return (
    <Modal open={isOpen} onOpenChange={onDismiss}>
      <Modal.Content title={`${editingNote ? 'Edit' : 'Add'} note`}>
        <div>
          <textarea
            placeholder='Note...'
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
            className='flex min-h-[80px] w-full resize-none rounded-md border border-slate-200 bg-white px-3 py-2 text-sm ring-offset-white placeholder:text-slate-500 focus-visible:outline-none disabled:opacity-50'
          />
          <div className='mt-8 flex gap-4'>
            <Modal.Close>Cancel</Modal.Close>
            <Button
              disabled={!isSavingEnabled || isPending}
              isLoading={isPending}
              onClick={handleSaveNote}
              className={`primary font-bold`}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default AddNoteModal;
