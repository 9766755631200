import { useState } from 'react';

import { CommonSpinner } from '.';
import { useGlobalState } from '../../context/GlobalContext';
import { ActivityType } from '../../data/activityTitleMap';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { useQueryParams } from '../../hooks/useQueryParams';
import Select from '../common/SelectNew';
import AddActivitiesModal from '../modals/AddActivitiesModal';
import { Activity } from './Activity';
import { AddIcon } from './Icons';
import { Pagination } from './Pagination';

type BaseActivity = {
  userName: string | null;
  userId: string | null;
  Activity_Id: number;
  notes: string;
  activityType: ActivityType;
  result: string | null;
  activityDate: string;
  insertDate: string;
  selectDate: string | null;
  updateDate: string | null;
  deleted: boolean;
};

type ContactActivity = BaseActivity & {
  contactActivityId: string;
  contactId: string;
  Candidate_Id: number;
  jobId: string | null;
};

type OrganizationActivity = BaseActivity & {
  organizationActivityId: string;
  organizationId: string;
  Company_Id: number;
};

type JobActivity = BaseActivity & {
  jobActivityId: string;
  Job_Id: number;
  jobId: string;
  contactId: string | null;
};

export interface Params {
  [key: string]: string | undefined;
}

type APIActivity = ContactActivity | OrganizationActivity | JobActivity;

const activityFilterOptions = [
  { value: 'comms', label: 'Comms' },
  { value: 'contract', label: 'Contracts' },
  { value: 'meetings', label: 'Meetings' },
  { value: 'recordChanges', label: 'Record Changes' },
  { value: 'pipeline', label: 'Pipeline' },
];

export function ActivityView({
  url,
  params,
  paginationKey = 'page',
}: {
  url?: string;
  params?: Params;
  paginationKey?: string;
}) {
  const [isAddActivityModalOpen, setIsAddActivityModalOpen] =
    useState<boolean>(false);
  const [activityFilter, setActivityFilter] = useState('');

  const { users } = useGlobalState();
  const { queryParams } = useQueryParams();

  const {
    data,
    isLoading,
    refetch: refetchActivityData,
  } = useCustomQuery<APIActivity[]>({
    url: url as string,
    params: {
      ...params,
      ...(queryParams[paginationKey]
        ? { pageNumber: queryParams[paginationKey] }
        : {}),
      ...(activityFilter ? { activityTypeGroup: activityFilter } : {}),
    } as Params,
  });

  const { data: activities, pages } = data ?? {};
  const [activityKey, activityValue] = params
    ? Object.entries(params)[0]
    : ['', ''];

  return (
    <>
      {isLoading && (
        <div className='flex size-full items-center justify-center'>
          <CommonSpinner size='md' />
        </div>
      )}

      {!isLoading && (
        <div className='flex size-full flex-col gap-4 overflow-hidden'>
          <div className='flex w-full items-center justify-between border-b pb-4'>
            <Select
              placeholder='Select an activity'
              options={activityFilterOptions}
              value={activityFilter}
              onChange={(value) => {
                setActivityFilter(value);
              }}
              showClearOption
            />
            {activityKey === 'organizationId' && (
              <button
                onClick={() => setIsAddActivityModalOpen(true)}
                className='flex items-center gap-2 rounded border px-2 py-1 hover:bg-gray-100'
              >
                <AddIcon size={16} />
                <span>Add</span>
              </button>
            )}
          </div>
          {activities?.length ? (
            <div className='flex w-full flex-col gap-4 overflow-auto'>
              <div className='self-start'>Past Activity</div>
              {!isLoading &&
                !!activities?.length &&
                activities.map((a: APIActivity) => {
                  const assignee =
                    users?.find((u) => u.userId === a.userId)?.fullName ??
                    a.userName ??
                    '-';

                  const id =
                    'contactActivityId' in a
                      ? a.contactActivityId
                      : 'organizationActivityId' in a
                        ? a.organizationActivityId
                        : 'jobActivityId' in a
                          ? a.jobActivityId
                          : '';

                  return (
                    <Activity
                      key={id}
                      activity={{
                        assignee,
                        type: a.activityType,
                        result: a.result || '',
                        description: a.notes,
                        date: a.activityDate,
                        text: a.activityType,
                      }}
                    />
                  );
                })}
            </div>
          ) : (
            <span className='text-xl'>No activities found</span>
          )}
          {!!pages && pages > 1 && (
            <Pagination pages={pages} paginationKey={paginationKey} />
          )}
        </div>
      )}
      <AddActivitiesModal
        isOpen={isAddActivityModalOpen}
        onDismiss={() => setIsAddActivityModalOpen(false)}
        activityUrl={url}
        tableId={activityValue}
        tableName={activityKey}
        refetchAttachments={refetchActivityData}
      />
    </>
  );
}
