import { E164Number } from 'libphonenumber-js/types';

export type APIList = {
  userName: string | null;
  userId: string | null;
  listId: string;
  code: string | null;
  description: string | null;
  memo: string | null;
  ownerUserName: string | null;
  ownerUserId: string | null;
  category: string | null;
  activityType: string | null;
  template: string | null;
  shared: true | null;
  dateEntered: string | null;
  contactCount: number | null;
  jobCount: number | null;
  organizationCount: number | null;
  insertDate: string | null;
  selectDate: string | null;
  updateDate: string | null;
  deleted: boolean | null;
  listItems: Array<ListItem>;
  name: string | null;
  tableUuid: string | null;
  tableName: string | null;
  insertEndPoint: string | null;
  expiresAt: string | null;
  engagementColor?: string;
  engagementCount?: string;
};

export type ListItem = {
  listItemId: string | null;
  code: string | null;
  listId: string | null;
  tableId: string | null;
  comments: string | null;
  description: string | null;
  rankId: number | null;
  tableName: string | null;
  dateEntered: string | null;
  insertDate: string | null;
  selectDate: string | null;
  updateDate: string | null;
  deleted: true | null;
  stage: string | null;
  status: string | null;
  jobTitle: string | null;
  profession: string | null;
  salaryBottom: string | null;
  salaryTop: string | null;
  name: string | null;
  contactTitle: string | null;
  phone: E164Number | null;
  emailAddress: string | null;
  organizationName: string | null;
  lastActivityType: string | null;
  lastActivityDate: string | null;
  lastActivityUserId: string | null;
  tableUuid: string | null;
  insertEndPoint: string | null;
  companyIdPCR: number | null;
  candidateIdPCR: number | null;
  jobIdPCR: number | null;
  userId: string | null;
  city: string | null;
  state: string | null;
  numberOfCandidatesInInbox: number | null;
  totalCandidates: number | null;
  indeedSponsored: number | null | undefined;
  orgHasContract?: boolean | null;
};

export enum ListType {
  Candidates = 'Candidates',
  Contacts = 'Contacts',
  General = 'General',
  Marketing = 'Marketing',
  Organizations = 'Organizations',
  Recruiting = 'Recruiting',
  Templates = 'Templates',
}
