import {
  Map,
  AdvancedMarker,
  useAdvancedMarkerRef,
  InfoWindow,
  useMap,
  useMapsLibrary,
  MapControl,
  ControlPosition,
} from '@vis.gl/react-google-maps';
import {
  BriefcaseMedicalIcon,
  BuildingIcon,
  CircleAlertIcon,
  CircleUserRoundIcon,
} from 'lucide-react';
import { useEffect, useRef, useState } from 'react';

import { useCustomQuery } from '../../hooks/useCustomQuery';
import { capitalizeName } from '../../utils/helpers';
import { TooltipContent, TooltipTrigger, Tooltip } from '../ui/tooltips';

type Location = {
  insertEndPoint: string;
  latitude: number | null;
  longitude: number | null;
  name: string;
  tableName: 'contacts' | 'jobs' | 'organizations';
  tableUuid: string;
};

const GoogleMapsView = ({
  url,
  primaryPinKey,
}: {
  url: string;
  primaryPinKey: 'jobs' | 'contacts' | 'organizations';
}) => {
  const [clickedMapIcon, setClickedMapIcon] = useState<Location | null>(null);

  const directionsRendererRef = useRef<any>(null);
  const coreLibRef = useRef<any>(null);
  const routesLibRef = useRef<any>(null);
  const mapRef = useRef<any>(null);

  useEffect(() => {
    return () => {
      if (directionsRendererRef.current) {
        directionsRendererRef.current.setMap(null);
      }
    };
  }, []);

  const handleMapClick = () => {
    if (clickedMapIcon) {
      setClickedMapIcon(null);

      if (directionsRendererRef.current) {
        directionsRendererRef.current.setMap(null);

        if (mapRef.current && routesLibRef.current) {
          const DirectionsRenderer = routesLibRef.current.DirectionsRenderer;

          directionsRendererRef.current = new DirectionsRenderer({
            map: mapRef.current,
          });
        }
      }
    }
  };

  const { data: res } = useCustomQuery<Location[]>({
    url,
    params: {},
    enabled: !!url,
  });

  const locationData = res?.data;

  return (
    <Map
      defaultCenter={{ lat: 30.2915147, lng: -97.7274074 }}
      defaultZoom={10}
      gestureHandling={'greedy'}
      reuseMaps
      mapId={process.env.REACT_APP_GOOGLE_MAPS_MAPID}
      onClick={handleMapClick}
    >
      <TrafficLayerComponent coreLibRef={coreLibRef} mapRef={mapRef} />
      <CandidatesLegend
        locationData={locationData}
        setClickedMapIcon={setClickedMapIcon}
      />
      <MapContent
        locationData={locationData}
        directionsRendererRef={directionsRendererRef}
        mapRef={mapRef}
        routesLibRef={routesLibRef}
        coreLibRef={coreLibRef}
        setClickedMapIcon={setClickedMapIcon}
        clickedMapIcon={clickedMapIcon}
        primaryPinKey={primaryPinKey}
      />
    </Map>
  );
};

function CandidatesLegend({
  locationData,
  setClickedMapIcon,
}: {
  locationData: Location[] | undefined;
  setClickedMapIcon: React.Dispatch<React.SetStateAction<Location | null>>;
}) {
  const candidates =
    locationData?.filter(
      (location: any) => location.tableName === 'contacts',
    ) ?? [];

  if (candidates.length === 0) return null;

  return (
    <MapControl position={ControlPosition.RIGHT_CENTER}>
      <div className='mr-4 max-h-[400px] w-[200px] overflow-auto rounded-lg bg-white shadow-2xl'>
        <div className='sticky top-0 flex items-center justify-between border-b border-[#263238] bg-white  px-2 pt-1 shadow-sm'>
          <div className='text-left text-lg font-bold '>Contacts</div>
        </div>
        <div className='flex flex-col items-start px-2 py-1'>
          {candidates &&
            candidates.map((candidate) => {
              const isDisabled =
                candidate.latitude === null || candidate.longitude === null;

              return (
                <button
                  key={candidate.tableUuid}
                  onClick={() => {
                    setClickedMapIcon(candidate);
                  }}
                  className='flex w-full items-center gap-2 truncate text-nowrap p-1 text-left text-sm transition-all duration-300 hover:bg-gray-200 disabled:cursor-not-allowed disabled:bg-white disabled:text-gray-400'
                  disabled={isDisabled}
                >
                  {isDisabled && (
                    <Tooltip>
                      <TooltipTrigger>
                        <CircleAlertIcon size={14} />
                      </TooltipTrigger>
                      <TooltipContent className='border-2 border-[#263238] bg-white shadow-md'>
                        <div className='text-black'>Unknown location</div>
                      </TooltipContent>
                    </Tooltip>
                  )}
                  <span>{capitalizeName(candidate.name)}</span>
                </button>
              );
            })}
        </div>
      </div>
    </MapControl>
  );
}

const TrafficLayerComponent = ({ coreLibRef, mapRef }: any) => {
  useEffect(() => {
    if (google && google.maps && mapRef.current) {
      const newTrafficLayer = new google.maps.TrafficLayer();
      newTrafficLayer.setMap(mapRef.current);
    }
  }, [coreLibRef, mapRef]);

  return null;
};

function MapContent({
  locationData,
  primaryPinKey,
  directionsRendererRef,
  setClickedMapIcon,
  clickedMapIcon,
  mapRef,
  routesLibRef,
  coreLibRef,
}: {
  primaryPinKey: 'jobs' | 'contacts' | 'organizations';
  locationData: Location[] | undefined;
  directionsRendererRef: any;
  setClickedMapIcon: React.Dispatch<React.SetStateAction<Location | null>>;
  clickedMapIcon: any;
  mapRef: any;
  routesLibRef: any;
  coreLibRef: any;
}) {
  const map = useMap();
  const routesLib = useMapsLibrary('routes');
  const coreLib = useMapsLibrary('core');

  useEffect(() => {
    if (routesLib && map && !directionsRendererRef.current) {
      const DirectionsRenderer = routesLib.DirectionsRenderer;
      directionsRendererRef.current = new DirectionsRenderer({ map });
    }

    if (map && !mapRef.current) {
      mapRef.current = map;
    }

    if (routesLib && !routesLibRef.current) {
      routesLibRef.current = routesLib;
    }

    if (coreLib && !coreLibRef.current) {
      coreLibRef.current = coreLib;
    }
  }, [
    routesLib,
    coreLibRef,
    map,
    directionsRendererRef,
    routesLibRef,
    mapRef,
    coreLib,
  ]);

  const nonContactLocations =
    locationData?.filter(
      (location: any) => location.tableName !== 'contacts',
    ) ?? [];

  return (
    <>
      {locationData &&
        locationData.map((location: any) => {
          if (location.latitude === null || location.longitude === null)
            return null;

          return (
            <MapMarker
              key={location.tableUuid}
              primaryPinKey={primaryPinKey}
              location={location}
              setClickedMapIcon={setClickedMapIcon}
              clickedMapIcon={clickedMapIcon}
              nonContactLocations={nonContactLocations}
              directionsRenderer={directionsRendererRef.current}
            />
          );
        })}
    </>
  );
}

function MapMarker({
  location,
  primaryPinKey,
  setClickedMapIcon,
  clickedMapIcon,
  nonContactLocations,
  directionsRenderer,
}: {
  location: Location;
  primaryPinKey: 'jobs' | 'contacts' | 'organizations';
  setClickedMapIcon: React.Dispatch<React.SetStateAction<Location | null>>;
  clickedMapIcon: Location | null;
  nonContactLocations: Location[];
  directionsRenderer: any;
}) {
  const map = useMap();
  const routesLib = useMapsLibrary('routes');
  const coreLib = useMapsLibrary('core');

  const [markerRef, marker] = useAdvancedMarkerRef();

  const [duration, setDuration] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [lastDirectionClicked, setLastDirectionClicked] = useState<
    string | null
  >(null);

  const isClicked = clickedMapIcon?.tableUuid === location.tableUuid;
  const isPrimaryPin = location.tableName === primaryPinKey;
  const isContactPin = location.tableName === 'contacts';

  useEffect(() => {
    if (!map || !location.latitude || !location.longitude) return;

    if (isPrimaryPin) {
      map.panTo({ lat: location.latitude, lng: location.longitude });
    }
  }, [map, isPrimaryPin, location]);

  useEffect(() => {
    if (!map || !location.latitude || !location.longitude) return;

    if (isClicked) {
      map.panTo({ lat: location.latitude, lng: location.longitude });
    }
  }, [isClicked, location, map]);

  const getDirections = ({ destination }: { destination: Location }) => {
    if (!routesLib || !coreLib || !map) return;

    if (!duration) {
      setIsLoading(true);
    }

    const DirectionsService = routesLib.DirectionsService;
    const LatLng = coreLib.LatLng;
    const TravelMode = routesLib.TravelMode;

    const directionsService = new DirectionsService();

    if (
      !destination.latitude ||
      !destination.longitude ||
      !location.latitude ||
      !location.longitude
    )
      return;

    directionsService.route(
      {
        origin: new LatLng(location.latitude, location.longitude),
        destination: new LatLng(destination.latitude, destination.longitude),
        travelMode: TravelMode.DRIVING,
      },
      (result, status) => {
        setIsLoading(false);

        if (status === 'OK') {
          directionsRenderer.setDirections(result);

          const durationInMinutes =
            result?.routes?.[0]?.legs?.[0]?.duration?.text;
          setDuration(durationInMinutes);
        } else {
          console.error('Directions request failed due to ' + status);
        }
      },
    );
  };

  if (!location.latitude || !location.longitude) return null;

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        position={{ lat: location.latitude, lng: location.longitude }}
        onClick={() => {
          setClickedMapIcon(location);
        }}
        zIndex={isClicked ? 2000 : isPrimaryPin ? 1000 : 1}
      >
        <div
          className='relative z-10 flex items-center gap-4 rounded-full border-2 border-[#263238] bg-white p-1 text-[#263238] transition-transform duration-200 hover:scale-110'
          style={{ boxShadow: '6px 6px 3px rgba(0, 0, 0, 0.2)' }}
        >
          {location.tableName === 'jobs' && (
            <BriefcaseMedicalIcon
              stroke='black'
              size={isPrimaryPin ? 36 : 24}
            />
          )}
          {location.tableName === 'contacts' && (
            <CircleUserRoundIcon stroke='black' size={isPrimaryPin ? 36 : 24} />
          )}
          {location.tableName === 'organizations' && (
            <BuildingIcon stroke='black' size={isPrimaryPin ? 36 : 24} />
          )}
          <div className=' absolute bottom-[-24px] left-[50%] z-20 h-auto -translate-x-[50%] rounded-lg bg-white shadow-lg'>
            <div className='flex size-full h-7 items-center justify-center text-nowrap  rounded-lg border-2 border-[#263238] px-1'>
              {capitalizeName(location.name)}
            </div>
          </div>
        </div>
      </AdvancedMarker>
      {isClicked && (
        <InfoWindow
          anchor={marker}
          className='min-w-40'
          // @ts-expect-error prop works but ts doesn't recognize it
          headerDisabled
        >
          <div className='flex flex-col gap-2'>
            <div className='flex flex-col gap-2'>
              <div className='font-bold'>{capitalizeName(location.name)}</div>
              {isContactPin &&
                nonContactLocations &&
                nonContactLocations.map((nonContactLocation) => {
                  const isLastDirectionClicked =
                    lastDirectionClicked === nonContactLocation.tableUuid;
                  return (
                    <button
                      key={nonContactLocation.tableUuid}
                      onClick={() => {
                        setLastDirectionClicked(nonContactLocation.tableUuid);
                        getDirections({ destination: nonContactLocation });
                      }}
                      className={`flex items-center gap-1 hover:bg-gray-200 ${isLastDirectionClicked && 'font-bold'}`}
                    >
                      <span>{nonContactLocation.name}</span>
                      <span className='material-symbols-outlined'>
                        directions
                      </span>
                    </button>
                  );
                })}
            </div>
            {isLoading && <div>Loading...</div>}
            {duration && (
              <div>
                <span>Travel time: </span>
                <span className='font-semibold'>{duration}</span>
              </div>
            )}
          </div>
        </InfoWindow>
      )}
    </>
  );
}

export default GoogleMapsView;
