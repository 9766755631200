import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ChevronLeftIcon } from '../../components/common/Icons';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { usePageTitle } from '../../hooks/usePageTitle';
import { usePipelineDragEnd } from '../../hooks/usePipelineDragEnd';
import { useTableSelection } from '../../hooks/useTableSelection';
import { APIContact } from '../../types/contacts';
import {
  APIPipeline,
  APIJobContactPipelinePosition,
} from '../../types/pipeline';
import { capitalizeName } from '../../utils/helpers';
import { formatDateString } from '../../utils/helpers';
import { sortJobContactPipelinePositions } from '../../utils/pipeline';
import AddContactToPipelineModal from './AddContactToPipelineModal';
import { CandidateMovedModal } from './CandidateMovedModal';
import ContactCard from './ContactCard';
import OutOfProcess from './OutOfProcess';
import { OutOfProcessModal } from './OutOfProcessModal';
import PipelineColumn from './PipelineColumn';

const pipelineTabs = [
  {
    id: 'pipeline',
    label: 'Pipeline',
  },
  {
    id: 'outOfProcess',
    label: 'Out of Process',
  },
];

export function PipelineContainer() {
  const [selectedTab, setSelectedTab] = useState('pipeline');
  const { id } = useParams();
  const navigate = useNavigate();
  const { users } = useGlobalState();
  const { data, refetch } = useCustomQuery<APIPipeline>({
    url: `/jobs/${id}/pipeline`,
    params: { limit: 100 },
  });
  const pipelineAPIresponse = data?.data;

  usePageTitle(
    `Pipeline • ${pipelineAPIresponse ? `${pipelineAPIresponse.job?.jobTitle} • ` : ''}mlee Pulse`,
  );
  const contacts = pipelineAPIresponse?.contacts ?? [];
  const totalContacts = contacts.length;

  const contactsForSelection = contacts.map((contact) => ({
    id: contact.contactId,
    isInList: false,
    disabled: false,
  }));

  const { ...checkboxProps } = useTableSelection(
    contactsForSelection,
    totalContacts,
  );

  const { job } = pipelineAPIresponse ?? {};

  const user =
    users?.find((u) => u.userId === job?.userId)?.fullName ?? job?.userName;

  return (
    <>
      <div className='grid h-full w-auto grid-rows-layout gap-4'>
        <div className='w-fit rounded-lg border border-neutral-40 bg-light-blue p-4'>
          <div className='flex flex-col items-start gap-4'>
            <button
              onClick={() => navigate(`/requisitions/${job?.jobId}`)}
              className='flex items-center gap-2 font-bold'
            >
              <ChevronLeftIcon size={16} />
              <span>{job?.jobTitle}</span>
            </button>
            <div className='flex items-center gap-8'>
              <span className='flex flex-col text-sm'>
                <span>{user}</span>
              </span>
            </div>
            <div className='flex items-center gap-8'>
              <span className='flex flex-col text-sm'>
                <b>Organization</b>
                <span>{job?.organizationName ?? '-'}</span>
              </span>
              <span className='flex flex-col text-sm'>
                <b>Location</b>
                <span>{`${job?.city}/${job?.state}`}</span>
              </span>
              <span className='flex flex-col text-sm'>
                <b>Status</b>
                <span>{job?.status ?? '-'}</span>
              </span>
              <span className='flex flex-col text-sm'>
                <b>Profession</b>
                <span>{job?.profession ?? '-'}</span>
              </span>
              <span className='flex flex-col text-sm'>
                <b>User</b>
                <span>{user ? capitalizeName(user) : '-'}</span>
              </span>
              <span className='flex flex-col text-sm'>
                <b>Date posted</b>
                <span>{formatDateString(job?.datePosted)}</span>
              </span>
            </div>
          </div>
        </div>

        <div className='flex h-full w-auto flex-col overflow-hidden rounded-lg'>
          <div className='flex gap-2 pl-2'>
            {pipelineTabs.map((tab) => {
              const isActive = selectedTab === tab.id;

              return (
                <button
                  key={tab.id}
                  onClick={() => setSelectedTab(tab.id)}
                  className={`border border-b-0 border-neutral-40 ${isActive ? 'bg-white shadow-md' : 'bg-neutral-20'} px-2 py-1 font-bold text-neutral-60`}
                >
                  {tab.label}
                </button>
              );
            })}
          </div>
          <div className='h-full w-auto overflow-hidden rounded-lg border border-neutral-40 bg-white p-4 shadow-lg'>
            <div className='grid h-full w-full grid-flow-col overflow-auto rounded-lg border'>
              {selectedTab === 'pipeline' && (
                <Pipeline
                  pipelineAPIresponse={pipelineAPIresponse}
                  refetch={refetch}
                  checkboxProps={checkboxProps}
                />
              )}
              {selectedTab === 'outOfProcess' && (
                <OutOfProcess
                  pipelineAPIresponse={pipelineAPIresponse}
                  refetch={refetch}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Pipeline({
  pipelineAPIresponse,
  refetch,
  checkboxProps,
}: {
  pipelineAPIresponse: APIPipeline | undefined;
  refetch: () => void;
  checkboxProps: any;
}) {
  const [activeContact, setActiveContact] = useState<{
    contact: APIContact;
    jobContactPipelinePosition: APIJobContactPipelinePosition;
  } | null>(null);
  const [dropdownId, setDropdownId] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingEnabled, setIsDraggingEnabled] = useState<boolean>(true);
  const [outOfProcessId, setOutOfProcessId] = useState<string | null>(null);
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const [pipelineData, setPipelineData] = useState<APIPipeline | undefined>(
    undefined,
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  const {
    handleDragEnd,
    dialogState,
    handleDialogCancel,
    handleDialogSubmit,
    ...mutationProps
  } = usePipelineDragEnd({
    refetch,
    pipelineAPIresponse,
    setPipelineData,
    setIsDragging,
  });

  useEffect(() => {
    if (pipelineAPIresponse) {
      const sortedPositions = sortJobContactPipelinePositions(
        pipelineAPIresponse.jobContactPipelinePositions,
      );
      setPipelineData({
        ...pipelineAPIresponse,
        jobContactPipelinePositions: sortedPositions,
      });
    }
  }, [pipelineAPIresponse]);

  const handleDragStart = (event: DragEndEvent) => {
    const { active } = event;

    const contact = active.data.current?.contact as APIContact;
    const jobContactPipelinePosition = active.data.current
      ?.jobContactPipelinePosition as APIJobContactPipelinePosition;

    if (!contact) return null;

    setActiveContact({ contact, jobContactPipelinePosition });
    setIsDragging(true);
  };

  const { pipelinePositions } = pipelineData ?? {};

  return (
    <>
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToFirstScrollableAncestor]}
        sensors={sensors}
        autoScroll={true}
      >
        {!!pipelinePositions &&
          pipelinePositions.map((p) => {
            return (
              <PipelineColumn
                key={p.pipelinePositionId}
                pipelineAPIresponse={pipelineData}
                pipelinePosition={p}
                dropdownId={dropdownId}
                setDropdownId={setDropdownId}
                setOutOfProcessId={setOutOfProcessId}
                isDragging={isDragging}
                isDraggingEnabled={isDraggingEnabled}
                setIsDraggingEnabled={setIsDraggingEnabled}
                setIsAddContactModalOpen={() => setIsAddContactModalOpen(true)}
              />
            );
          })}

        <DragOverlay
          modifiers={[restrictToFirstScrollableAncestor]}
          dropAnimation={{
            duration: 200,
          }}
        >
          {isDragging && activeContact ? (
            <>
              <ContactCard
                contact={activeContact.contact}
                pipelineRecord={activeContact.jobContactPipelinePosition}
                setIsDraggingEnabled={setIsDraggingEnabled}
                dropdownId={dropdownId}
                setDropdownId={setDropdownId}
                checkboxProps={checkboxProps}
              />
            </>
          ) : null}
        </DragOverlay>
      </DndContext>

      <OutOfProcessModal
        outOfProcessId={outOfProcessId}
        pipelineData={pipelineData}
        setDropdownId={setDropdownId}
        setOutOfProcessId={setOutOfProcessId}
        setPipelineData={setPipelineData}
        setIsDraggingEnabled={setIsDraggingEnabled}
        refetchPipelineData={refetch}
      />

      <AddContactToPipelineModal
        isOpen={isAddContactModalOpen}
        onClose={() => setIsAddContactModalOpen(false)}
        pipelineData={pipelineData}
        refetchPipelineData={refetch}
      />
      {dialogState.isOpen && (
        <CandidateMovedModal
          dialogState={dialogState}
          handleDialogSubmit={handleDialogSubmit}
          handleDialogCancel={handleDialogCancel}
          pipelineData={pipelineData}
          isApiPending={mutationProps.isPending}
        />
      )}
    </>
  );
}
