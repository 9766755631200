import { ResponsivePie } from '@nivo/pie';

import { useCustomQuery } from '../../../hooks/useCustomQuery';
import colors from '../../../styles/colors';

type APISponsoredAds = {
  sponsoredJobs: number;
  totalJobs: number;
};

function SponsoredAdsChart({ isDisabled }: { isDisabled: boolean }) {
  const { data: response, isLoading } = useCustomQuery<APISponsoredAds>({
    url: '/admin/reports/sponsoredads',
  });

  const sponsoredAdsData = response?.data;

  const sponsoredPercentage = sponsoredAdsData
    ? (sponsoredAdsData.sponsoredJobs / sponsoredAdsData.totalJobs) * 100
    : 0;

  const data = [
    {
      id: 'Sponsored',
      value: sponsoredAdsData?.sponsoredJobs ?? 0,
    },
    {
      id: 'Unsponsored',
      value: sponsoredAdsData
        ? sponsoredAdsData.totalJobs - sponsoredAdsData.sponsoredJobs
        : 0,
    },
  ];

  return (
    <div className='flex h-[16rem] w-full flex-col gap-4 overflow-hidden rounded-lg border border-neutral-40 bg-white p-4'>
      {isLoading && (
        <div className='flex size-full animate-pulse flex-col gap-4'>
          <div className='h-7 w-2/3 rounded-md bg-slate-300'></div>
          <div className='size-full rounded-md bg-slate-300'></div>
        </div>
      )}
      {!isLoading && (
        <>
          {isDisabled ? (
            <div className='flex size-full flex-col gap-4'>
              <p className='text-lg font-bold text-gray-500'>
                Sponsored to Total Ads (%)
              </p>
              <div className='flex size-full items-center justify-center text-lg font-medium text-gray-500'>
                Not available for selected time range
              </div>
            </div>
          ) : (
            <div className='flex size-full flex-col'>
              <p className='text-lg font-bold'>
                Sponsored to Total Requisitions (%)
              </p>
              <div className='flex size-full items-center justify-around gap-6'>
                <div className='relative flex size-full items-center justify-center'>
                  <div className='size-full'>
                    <ResponsivePie
                      data={data}
                      margin={{ top: 10, right: 10, bottom: 32, left: 10 }}
                      innerRadius={0.85}
                      padAngle={0.7}
                      startAngle={0}
                      cornerRadius={0}
                      activeOuterRadiusOffset={10}
                      colors={[colors.darkPurple, colors.lightPurple]}
                      enableArcLinkLabels={false}
                      enableArcLabels={false}
                      isInteractive={true}
                      legends={[
                        {
                          anchor: 'bottom',
                          direction: 'row',
                          justify: false,
                          translateX: 0,
                          translateY: 30,
                          itemsSpacing: 16,
                          itemWidth: 80,
                          itemHeight: 15,
                          itemTextColor: '#000',
                          itemDirection: 'left-to-right',
                          itemOpacity: 1,
                          symbolSize: 12,
                          symbolShape: 'circle',
                        },
                      ]}
                    />
                  </div>
                  <span className='absolute left-1/2 top-1/2 -mt-2 flex h-fit w-fit -translate-x-1/2 -translate-y-1/2 transform items-center justify-center text-4xl'>
                    {!Number.isNaN(sponsoredPercentage) &&
                      `${sponsoredPercentage.toFixed(0)}%`}
                  </span>
                </div>
                <div className='flex flex-col gap-4'>
                  <div className='font-medium text-secondary-80'>
                    <div className='text-sm'>Sponsored reqs</div>
                    <div>{sponsoredAdsData?.sponsoredJobs ?? 0}</div>
                  </div>
                  <div className='font-medium text-secondary-80'>
                    <div className='text-sm'>Total reqs</div>
                    <div>{sponsoredAdsData?.totalJobs ?? 0}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default SponsoredAdsChart;
