function F2FIntvwPlacementRatio() {
  return (
    <div className='flex size-full flex-col gap-4 rounded-lg border border-neutral-40 bg-white p-4'>
      <div className='flex items-center gap-2'>
        <div className={`truncate text-lg font-bold text-gray-500`}>
          F2F Interview to Placement Ratio
        </div>
      </div>
      <div className='flex size-full items-center justify-center text-lg font-medium text-gray-500'>
        Coming soon
      </div>
    </div>
  );
}

export default F2FIntvwPlacementRatio;
