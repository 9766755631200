import { LoaderCircleIcon, XIcon } from 'lucide-react';
import { useMemo, useState } from 'react';

import { CommonSpinner } from '../../components/common';
import { PhoneIcon } from '../../components/common/Icons';
import OrganizationName from '../../components/common/OrganizationName';
import PhoneNumber from '../../components/common/PhoneNumber';
import { Sheet, SheetClose, SheetContent } from '../../components/ui/sheet';
import { useRingCentralContext } from '../../context/RingCentralContext';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { APIOrganization } from '../../types/organizations';
import { setFieldIfEmpty } from '../../utils/helpers';
import { generatePhoneLink } from '../../utils/phone';
import { OrganizationTabs } from './OrganizationTabs';

export function OrganizationView({
  organizationId,
  organizations,
  isOpen,
  onClose,
  tabId,
  panelBaseRoute,
  invalidatePath,
}: {
  organizationId?: string;
  isOpen: boolean;
  onClose: () => void;
  organizations?: APIOrganization[];
  tabId?: string;
  panelBaseRoute?: string;
  // TODO: Need to rethink invalidatePath. Right now it does not accept multiple paths.
  invalidatePath?: string;
}) {
  const [isPhoneLoading, setIsPhoneLoading] = useState(false);

  const { fetchMatchedContacts } = useRingCentralContext();

  const preLoadedOrganization = organizations?.find(
    (o) => o.organizationId === organizationId,
  );

  const { data, isLoading: isOrganizationLoading } =
    useCustomQuery<APIOrganization>({
      url: `/organizations/${organizationId}`,
      enabled: isOpen && !!organizationId && !preLoadedOrganization,
    });

  const organization = preLoadedOrganization ?? data?.data;

  const region = useMemo(
    () =>
      organization?.city
        ? `${organization.city}, ${organization.state}`
        : organization?.state,
    [organization],
  );

  const phoneLink = useMemo(() => {
    return generatePhoneLink(organization?.phone ?? '');
  }, [organization]);

  const handlePhoneClick = async (phone: string) => {
    setIsPhoneLoading(true);
    const iframe: any = document.querySelector('#rc-widget-adapter-frame');

    await fetchMatchedContacts([phone]);

    if (typeof (window as any).RCAdapter !== 'undefined') {
      (window as any).RCAdapter.setMinimized(false);
    }

    if (iframe?.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: 'rc-adapter-new-call',
          phoneNumber: phone,
          toCall: true,
        },
        '*',
      );

      setIsPhoneLoading(false);
    } else {
      console.error('RingCentral widget iframe not found or not accessible');
    }
  };

  return (
    <Sheet
      open={isOpen}
      onOpenChange={() => {
        onClose();
      }}
      modal={false}
    >
      <SheetContent
        className='w-full overflow-auto bg-white md:max-w-[1200px]'
        hideCloseButton
        showCustomOverlay
      >
        {isOrganizationLoading && (
          <div className='flex size-full items-center justify-center overflow-hidden'>
            <CommonSpinner size='md' />
          </div>
        )}
        {!isOrganizationLoading && !!organization && (
          <div
            className=' grid size-full gap-4 overflow-hidden'
            style={{ gridTemplateRows: 'auto 1fr' }}
          >
            <div className='flex flex-col gap-4 rounded-t-lg border border-neutral-40 bg-light-blue p-4'>
              <div className='flex justify-between'>
                <div className='text-xl font-bold'>
                  <OrganizationName
                    hasContract={!!organization.contract}
                    name={organization.organizationName}
                    hideTooltip
                  />
                </div>
                <SheetClose>
                  <XIcon />
                </SheetClose>
              </div>
              <div className='grid grid-cols-3 gap-2'>
                <span className='flex flex-col text-sm'>
                  <b>Phone</b>
                  <span className='overflow-hidden truncate'>
                    <PhoneNumber phoneNumber={organization.phone} />
                  </span>
                </span>
                <span className='flex flex-col text-sm'>
                  <b>City/State</b>
                  <span className='overflow-hidden truncate'>
                    {setFieldIfEmpty(region)}
                  </span>
                </span>
                <span className='flex flex-col text-sm'>
                  <b>Client</b>
                  <span className='overflow-hidden truncate'>
                    {organization.dateSigned ? 'Yes' : 'No'}
                  </span>
                </span>
              </div>
              <div className='grid grid-cols-3'>
                <span className='flex flex-col text-sm'>
                  <b>Industry/ Specialty</b>
                  <span className='overflow-hidden truncate'>
                    {setFieldIfEmpty(organization.specialty)}
                  </span>
                </span>
                <span className='flex flex-col text-sm'>
                  <b>Address</b>
                  <span className='overflow-hidden truncate'>
                    {setFieldIfEmpty(organization.address1)}
                  </span>
                </span>
              </div>
              <div className='flex justify-center gap-6'>
                {organization?.phone && (
                  <button
                    className='flex flex-col items-center text-sm text-primary-70'
                    onClick={() => {
                      handlePhoneClick(phoneLink);
                    }}
                  >
                    <div className='rounded-full border border-primary-70 p-2'>
                      {isPhoneLoading ? (
                        <LoaderCircleIcon size={20} className='animate-spin' />
                      ) : (
                        <PhoneIcon size={20} />
                      )}
                    </div>
                    <span>Phone</span>
                  </button>
                )}
              </div>
            </div>

            <div className='size-full overflow-hidden'>
              <OrganizationTabs
                organization={organization}
                tabId={tabId}
                panelBaseRoute={panelBaseRoute}
                invalidatePath={invalidatePath}
              />
            </div>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
}
