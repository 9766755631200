import React from 'react';

import { BaseTable, Column } from '../../components/common/BaseTable';
import { Button } from '../../components/common/Button';
import OrganizationName from '../../components/common/OrganizationName';
import PhoneNumber from '../../components/common/PhoneNumber';
import { useGlobalState } from '../../context/GlobalContext';
import { activityTitleMap, ActivityType } from '../../data/activityTitleMap';
import { ListItem } from '../../types/lists';
import { CheckboxProps } from '../../types/table';
import { Stages } from '../../utils/constant';
import { setFieldIfEmpty, convertUTCToLocal } from '../../utils/helpers';
import ListDetailStagesDropdown from './ListDetailStagesDropdown';

interface ListOrganizationsTableProps {
  data: ListItem[];
  handleRowClick: (value: ListItem) => void;
  className?: string;
  updateListItem: (stage: string | null, listItem: ListItem) => void;
  removeListItem: (
    event: React.MouseEvent<HTMLButtonElement>,
    listItemId: string | null,
  ) => void;
  handleEntityClick: (id: string) => void;

  isDeleteItemPending: boolean;
  checkboxProps: CheckboxProps;
}

export function getStageKey(value: string): string | undefined {
  for (const [key, stageValue] of Object.entries(Stages)) {
    if (stageValue === value) {
      return key;
    }
  }
}

export function ListOrganizationsTable({
  data,
  handleRowClick,
  className,
  updateListItem,
  removeListItem,
  isDeleteItemPending,
  handleEntityClick,
  checkboxProps,
}: ListOrganizationsTableProps) {
  const { users } = useGlobalState();

  const columns: Column<ListItem>[] = [
    {
      label: 'Organization',
      key: 'organizationName',
      sortEnabled: false,
      className: 'w-[30rem]',
      render: (item) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();

              if (item.tableId) {
                handleEntityClick(item.tableId);
              }
            }}
            className='w-fit text-link hover:text-[#167dff] hover:underline'
          >
            <OrganizationName
              hasContract={!!item.orgHasContract}
              name={item.organizationName}
            />
          </div>
        );
      },
    },
    {
      label: 'Phone',
      key: 'phone',
      sortEnabled: false,
      className: 'w-[12rem]',
      render: (item) => <PhoneNumber phoneNumber={item.phone} />,
    },
    {
      label: 'City/State',
      key: 'city',
      sortEnabled: true,
      className: 'w-[12rem]',
      render: (item) => {
        const region = item.city ? `${item.city}, ${item.state}` : item.state;
        return setFieldIfEmpty(region);
      },
    },
    {
      label: 'Recent Activity',
      key: 'lastActivityDate',
      sortEnabled: true,
      className: 'w-[14rem]',
      render: (item) => {
        const icon = item.lastActivityType
          ? activityTitleMap[
              item?.lastActivityType?.toLowerCase() as ActivityType
            ]?.icon
          : 'unknown_med';

        return (
          <div className='flex gap-1'>
            <div className='material-symbols-outlined'>
              {icon ?? 'unknown_med'}
            </div>
            {item.lastActivityDate
              ? convertUTCToLocal(item.lastActivityDate)
              : setFieldIfEmpty(item.lastActivityDate)}
          </div>
        );
      },
    },
    {
      label: 'User',
      key: 'userId',
      sortEnabled: false,
      className: 'w-[9rem]',
      render: (item) =>
        users?.find((u) => u.userId === item.userId)?.fullName || '-',
    },
    {
      label: 'Stage',
      key: 'stage',
      sortEnabled: true,
      className: 'w-[12rem]',
      render: (item) => {
        return (
          <ListDetailStagesDropdown
            item={item}
            handleDropdownChange={updateListItem}
          />
        );
      },
    },
    {
      label: 'Action',
      key: 'action',
      sortEnabled: false,
      className: 'w-[8rem]',
      render: (item) => (
        <Button
          disabled={isDeleteItemPending}
          onClick={(e) => removeListItem(e, item.listItemId)}
          className='mx-auto flex h-6 items-center gap-2 self-start px-2 py-1 font-bold text-primary underline'
          variant='ghost'
        >
          Remove
        </Button>
      ),
    },
  ];

  return (
    <BaseTable
      data={data}
      columns={columns}
      idKey='listItemId'
      defaultSortKey='organizationName'
      handleRowClick={handleRowClick}
      className={className}
      checkboxProps={checkboxProps}
    />
  );
}

export default ListOrganizationsTable;
