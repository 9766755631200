import { useNavigate } from 'react-router-dom';

import { CommonSpinner } from '../../components/common';
import { DollarSignIcon, IndeedIcon } from '../../components/common/Icons';
import {
  Body,
  Header,
  HeaderCell,
  Row,
  RowCell,
  Table,
} from '../../components/common/Table';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { APIContact } from '../../types/contacts';
import { APIContactPipeline } from '../../types/pipeline';

export function Pipelines({ contact }: { contact: APIContact }) {
  const navigate = useNavigate();
  const { data, isLoading } = useCustomQuery<APIContactPipeline[]>({
    url: `/contacts/${contact.contactId}/jobs`,
  });

  const handleRowClick = (jobId: string) => {
    navigate(`/pipeline/${jobId}`);
  };

  const contacts = data?.data;

  return (
    <>
      {isLoading && (
        <div className='flex size-full items-center justify-center gap-4'>
          <CommonSpinner size='md' />{' '}
        </div>
      )}

      {!isLoading && contacts?.length ? (
        <div className='flex'>
          <Table
            id='activities-table'
            classNames={{
              table: 'size-full table table border border-neutral-40',
            }}
          >
            <Header>
              <HeaderCell>Status</HeaderCell>
              <HeaderCell>Job title</HeaderCell>
              <HeaderCell>Organization</HeaderCell>
              <HeaderCell>Progress</HeaderCell>
            </Header>
            <Body>
              {contacts.map((c, key) => (
                <Row
                  className='cursor-pointer'
                  key={key}
                  onClick={() => handleRowClick(c.job.jobId)}
                >
                  <RowCell scope='row' style={{ borderLeftWidth: 0 }}>
                    <div className='flex items-center'>
                      <span>{c?.job?.status ?? '-'}</span>
                      {c?.job?.status === 'Available' && (
                        <>
                          {(c?.job?.indeedSponsored === 1 ||
                            c?.job?.indeedSponsored === 2) && (
                            <span className='ml-2'>
                              <IndeedIcon />
                            </span>
                          )}
                          {c?.job?.indeedSponsored === 2 && (
                            <span className='mt-0.5'>
                              <DollarSignIcon />
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </RowCell>
                  <RowCell scope='row' style={{ borderLeftWidth: 0 }}>
                    {c?.job?.jobTitle ? <div>{c?.job?.jobTitle}</div> : '-'}
                  </RowCell>
                  <RowCell scope='row' style={{ borderLeftWidth: 0 }}>
                    {c?.job?.organizationName ?? '-'}
                  </RowCell>

                  <RowCell scope='row' style={{ borderLeftWidth: 0 }}>
                    <div className='mx-1 my-0.5 w-[75%] max-w-md'>
                      <h2 className='mb-2'>
                        {c?.jobcontactpipelineposition?.outOfProcess
                          ? `OOP (${c?.jobcontactpipelineposition?.outOfProcessReason ?? '-'})`
                          : c?.pipelineposition?.pipelinePosition ?? '-'}
                      </h2>
                      {/* <div className='flex items-center relative'>
                        <div className='flex-1 h-2 rounded-full overflow-hidden'>
                          <div
                            className='h-full bg-dark-blue rounded-full transition-width duration-300'
                            style={{ width: `${90}%` }}
                          ></div>
                        </div>
                        <span
                          className={`absolute ml-[5px] text-xs`}
                          style={{ left: `${90}%` }}
                        >
                          {90}%
                        </span>
                      </div> */}
                    </div>
                  </RowCell>
                </Row>
              ))}
            </Body>
          </Table>
        </div>
      ) : (
        <div className='flex w-full justify-center'>
          <span className='text-center text-xl'>No Pipelines</span>
        </div>
      )}
    </>
  );
}
