import { QueryParams, useQueryParams } from '../hooks/useQueryParams';
import { TabsParams } from '../utils/tabs';

type TabParamKey = keyof typeof TabsParams;

export function useTabQueryParams() {
  const { queryParams, updateQueryParam, removeQueryParam, setQueryParams } =
    useQueryParams();

  const clearTabParams = () => {
    const updatedParams = new URLSearchParams(
      queryParams as Record<string, string>,
    );
    (Object.values(TabsParams) as string[]).forEach((param) => {
      updatedParams.delete(param);
    });

    setQueryParams(updatedParams);
  };

  const updateTabQueryParam = (
    key: TabParamKey,
    value: string | string[],
    options = {},
  ) => {
    updateQueryParam(TabsParams[key], value, options);
  };

  const removeTabQueryParam = (key: TabParamKey) => {
    removeQueryParam(TabsParams[key]);
  };

  const getTabQueryParams = (): QueryParams => {
    const tabParamValues = Object.values(TabsParams) as string[];
    return Object.fromEntries(
      Object.entries(queryParams).filter(([key]) =>
        tabParamValues.includes(key),
      ),
    );
  };

  return {
    queryParams,
    updateTabQueryParam,
    removeTabQueryParam,
    clearTabParams,
    getTabQueryParams,
  };
}
