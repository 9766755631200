import { useMemo } from 'react';

import {
  Select as SelectRoot,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '../../components/ui/select';
import { cn } from '../../lib/utils';
import { Label } from '../ui/label';
import { ErrorMessage } from './Error';

type SelectProps = {
  options: { value: string; label: string }[];
  value: string;
  onChange: (value: string) => void;
  label?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: string;
  showClearOption?: boolean;
  defaultValue?: string;
  classNames?: {
    container?: string;
    label?: string;
    trigger?: string;
    value?: string;
    content?: string;
    item?: string;
  };
  hideLabel?: boolean;
};

const Select = ({
  label,
  value,
  onChange,
  options,
  placeholder,
  classNames,
  required = false,
  disabled = false,
  errorMessage = '',
  showClearOption = false,
  defaultValue,
  hideLabel = false,
}: SelectProps) => {
  const selectedOption = useMemo(() => {
    return options.find((o) => {
      if (value) {
        return o.value === value;
      } else {
        return o.value === defaultValue;
      }
    });
  }, [value, options, defaultValue]);

  function handleOptionSelect(selectValue: string) {
    let selectedValue = selectValue;

    if (selectValue === '_clear_select_value') {
      selectedValue = '';
    } else if (selectValue === defaultValue) {
      selectedValue = '';
    }

    onChange(selectedValue);
  }

  return (
    <div
      className={cn(
        'flex w-full max-w-64 flex-col',
        classNames?.container ?? '',
      )}
    >
      {label && !hideLabel && (
        <Label className={cn('mb-1', classNames?.label)}>
          <span>{label}</span>{' '}
          {required && <span className=' text-error'>*</span>}
        </Label>
      )}
      <SelectRoot
        disabled={disabled}
        value={value || defaultValue}
        onValueChange={handleOptionSelect}
      >
        <SelectTrigger
          className={cn(
            'h-8 w-full text-secondary-80 shadow-sm hover:border-neutral-60 focus:ring-2 focus:ring-offset-2 focus:ring-offset-white',
            disabled ? 'hover:border-neutral-40' : '',
            classNames?.trigger ?? '',
          )}
        >
          <div
            className={cn(
              `truncate ${disabled && 'text-black'} ${!value && 'font-medium text-neutral-60'}`,
              classNames?.value,
            )}
          >
            {selectedOption?.label ||
              value ||
              placeholder ||
              'Select an option'}
          </div>
        </SelectTrigger>
        <SelectContent className={classNames?.content ?? ''}>
          {options.map((option) => (
            <SelectItem
              className={classNames?.item ?? 'text-secondary-80'}
              key={option.value}
              value={option.value}
              defaultChecked={option.value === defaultValue}
            >
              {option.label}
            </SelectItem>
          ))}
          {showClearOption && (
            <SelectItem
              value='_clear_select_value'
              className='mt-2 border-t text-neutral-60'
            >
              Clear selection
            </SelectItem>
          )}
        </SelectContent>
      </SelectRoot>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default Select;
