import { useCallback, useMemo } from 'react';

import { Draggable, Droppable } from '../../components/common/Drag';
import { AddIcon } from '../../components/common/Icons';
import { Checkbox } from '../../components/ui/checkbox';
import { useTableSelection } from '../../hooks/useTableSelection';
import { APIContact } from '../../types/contacts';
import { APIPipeline, PipelinePosition } from '../../types/pipeline';
import ContactCard from './ContactCard';

// These should not be hardcoded anymore - need to be dynamic
const pipelineHeaderColorMap = {
  inbox: '#317DFF',
  screening: '#31A63E',
  responded: '#6611F5',
  presented: '#F7903B',
  'phone/virtual interview': '#3DAFFE',
  'in-person interview': '#9754EB',
  'offer pending': '#3391E2',
  placed: '#5EC37F',
};

export default function PipelineColumn({
  pipelinePosition,
  pipelineAPIresponse,
  dropdownId,
  setDropdownId,
  isDraggingEnabled,
  setOutOfProcessId,
  setIsAddContactModalOpen,
  setIsDraggingEnabled,
}: {
  pipelinePosition: PipelinePosition;
  pipelineAPIresponse: APIPipeline | undefined;
  dropdownId: string | null;
  setDropdownId: React.Dispatch<React.SetStateAction<string | null>>;
  setIsDraggingEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isDragging: boolean;
  isDraggingEnabled: boolean;
  setOutOfProcessId: React.Dispatch<React.SetStateAction<string | null>>;
  setIsAddContactModalOpen: () => void;
}) {
  const { contacts, jobContactPipelinePositions } = pipelineAPIresponse ?? {};
  const {
    pipelinePosition: columnTitle,
    pipelinePositionId: pipelineColumnId,
  } = pipelinePosition;

  const contactsMap = useMemo(() => {
    const map = new Map<string, APIContact>();
    if (contacts) {
      contacts.forEach((contact) => {
        map.set(contact.contactId, contact);
      });
    }

    return map;
  }, [contacts]);
  // Get the total number of contacts
  const totalContacts = contacts?.length ?? 0;

  // Get all contacts for the current column
  const columncontacts = useMemo(() => {
    return jobContactPipelinePositions
      ?.filter((position) => position.pipelinePositionId === pipelineColumnId)
      .map((position) => {
        return {
          contact: contactsMap.get(position.contactId),
          jobContactPipelinePosition: position,
        };
      });
  }, [contactsMap, jobContactPipelinePositions, pipelineColumnId]);
  // Create a list of contacts for the selection
  const contactsForSelection = contacts?.map((contact) => ({
    id: contact.contactId,
    isInList: false,
    disabled: false,
  }));
  // Get the checkbox props
  const { clearSelectedItems, handleSelectionChange, ...restCheckboxProps } =
    useTableSelection(contactsForSelection ?? [], totalContacts);
  // Create the checkbox props
  const checkboxProps = useMemo(
    () => ({
      ...restCheckboxProps,
      handleSelectionChange: (id: string) => handleSelectionChange(id, false),
      clearSelectedItems,
    }),
    [restCheckboxProps, handleSelectionChange, clearSelectedItems],
  );

  const columnHeaderColor =
    pipelineHeaderColorMap[
      columnTitle.toLowerCase() as keyof typeof pipelineHeaderColorMap
    ];
  const isInbox = columnTitle.toLowerCase() === 'inbox';

  const getContactsForColumn = useCallback(
    (pipelineColumnId: string) => {
      return (jobContactPipelinePositions ?? [])
        .filter((position) => position.pipelinePositionId === pipelineColumnId)
        .filter((position) => !position.outOfProcess)
        .map((position) => {
          return {
            contact: contactsMap.get(position.contactId),
            jobContactPipelinePosition: position,
          };
        });
    },
    [contactsMap, jobContactPipelinePositions],
  );

  const contactCards = useMemo(() => {
    return getContactsForColumn(pipelineColumnId).map((pipelineRecord) => {
      const { contact, jobContactPipelinePosition } = pipelineRecord;
      if (!contact) return null;
      return (
        <Draggable
          id={contact.contactId}
          key={contact.contactId}
          data={pipelineRecord}
          disabled={!isDraggingEnabled}
          className='w-full'
        >
          <ContactCard
            contact={contact}
            pipelineRecord={jobContactPipelinePosition}
            dropdownId={dropdownId}
            setDropdownId={setDropdownId}
            setIsDraggingEnabled={setIsDraggingEnabled}
            hideContact={false}
            setOutOfProcessId={setOutOfProcessId}
            checkboxProps={checkboxProps}
          />
        </Draggable>
      );
    });
  }, [
    dropdownId,
    getContactsForColumn,
    isDraggingEnabled,
    pipelineColumnId,
    setDropdownId,
    setIsDraggingEnabled,
    setOutOfProcessId,
    checkboxProps,
  ]);

  return (
    <Droppable
      id={pipelineColumnId}
      key={pipelineColumnId}
      data={pipelinePosition}
      className={`flex size-full`}
    >
      {({ isOver }: { isOver: boolean }) => {
        return (
          <div className={`min-h-auto relative flex w-44 flex-grow flex-col`}>
            <div
              className={`sticky top-0 z-10 flex w-full justify-center px-3 py-1.5 font-bold text-white`}
              style={{
                backgroundColor: columnHeaderColor,
                borderRight: columnHeaderColor,
              }}
            >
              <Checkbox
                // This is the checkbox for the column-wide selection
                id={columncontacts?.[0]?.contact?.contactId ?? ''}
                checked={checkboxProps.isItemSelected(
                  columncontacts?.[0]?.contact?.contactId ?? '',
                )}
                disabled={checkboxProps.isItemDisabled(
                  columncontacts?.[0]?.contact?.contactId ?? '',
                )}
                onClick={() => {
                  if (columncontacts) {
                    columncontacts?.forEach((columnContact) => {
                      if (columnContact.contact) {
                        checkboxProps.handleSelectionChange(
                          columnContact.contact.contactId,
                        );
                      }
                    });
                  }
                }}
              />
              <div
                className={`flex w-full items-center justify-between truncate text-sm font-bold`}
              >
                <div className='flex-1' />
                <span className='flex-1'>{columnTitle}</span>
                <button
                  className='flex w-full flex-1 justify-end'
                  onClick={setIsAddContactModalOpen}
                >
                  <AddIcon
                    color='white'
                    className={`${isInbox ? '' : 'hidden'}`}
                  />
                </button>
              </div>
            </div>
            <div
              className={`flex w-full flex-grow flex-col items-center gap-y-4 border-r p-2 ${isOver ? 'bg-neutral-40' : 'bg-gray-50'}`}
            >
              {contactCards}
            </div>
          </div>
        );
      }}
    </Droppable>
  );
}
