import RootLayout from '../../layouts';
import { Dashboard } from './Dashboard';

const DashboardPage = () => {
  return (
    <RootLayout>
      <Dashboard />
    </RootLayout>
  );
};

export default DashboardPage;
