import { IDropdownOption } from '@fluentui/react';

export const HCENSpecialties = [
  'Addiction/Chemical Dependency',
  'Andrologist',
  'Anesthesia Technician',
  'Audiologist',
  'BioMechanical Lab Tech',
  'Blood Bank Technician',
  'Bone Densitometry',
  'Cardiac Intervention Tech',
  'Cardiac Tech',
  'Cath Lab Technician',
  'Cell Therapy Technologist',
  'Chemist',
  'Chiropractor',
  'Coroner',
  'Counselor',
  'CST / FA - Certified First Assist',
  'CST / ST - Surgical Technologist',
  'CT Scan Technician',
  'CV Intervention Technician',
  'Cytogenetic Technologist',
  'Cytologist',
  'Dialysis Technician',
  'Dietitian',
  'Dosimetrist',
  'Echocardiographer',
  'EEG Technician',
  'EKG Technologist',
  'Electrophysiology Technician / EP',
  'Embryologist',
  'Emergency Medical Tech / EMT',
  'Emergency Room Tech',
  'Endoscopy Technologist',
  'Histotechnician',
  'Hyperbaric Technologist',
  'Imaging Assistant',
  'Interventional Radiology',
  'Laboratory Manager',
  'Lithotripsy Technologist',
  'Mammographer',
  'Management / Administration',
  'Marriage / Family Therapist',
  'Massage Therapist',
  'Medical Assistant',
  'Medical Laboratory Technician',
  'Medical Laboratory Technologists / MLT',
  'Medical Physicist',
  'Medical Technician',
  'Medical Technologist',
  'Microbiologist',
  'Molecular Technologist',
  'Monitor Technician',
  'MRI Technologist',
  'Nuclear Med Tech',
  'Nursing',
  'Nursing Assistant / CNA',
  'Nutritionist',
  'OB Ultrasound Technician',
  'Occupational Therapist OT',
  'Occupational Therapy Assistant COTA / OTA',
  'Ophthalmic Technician',
  'Ophthalmology Assistant',
  'Orthopedic Technician',
  'PACS Administrator',
  'ParaMedic',
  'Pathologists',
  'Pathology Assistant',
  'Patient Care Assistant / PCA',
  'PET Technologist',
  'Phlebotomist',
  'Physical Therapist / PT',
  'Physical Therapy Assistant / PTA',
  'Polysomnographer',
  'Psychologist',
  'Psychosocial',
  'Radiation Technician',
  'Radiology / Xray Technician',
  'Recreational Therapist / RCIS',
  'Rehab Manager / Director',
  'Rehab Technician',
  'Respiratory Therapist RRT/CRT',
  'Scientific / Research',
  'Scientist',
  'Sitter',
  'Sleep Technician',
  'Social Worker',
  'Sonographer',
  'Special Procedures Technician',
  'Speech Language Pathologist / SLP',
  'Sterile Processing Tech',
  'Ultrasound Technician',
  'Vascular Intervention Technician',
  'Vascular Ultrasound Technician',
];

export const jobTypes: IDropdownOption<any>[] = [
  { key: 'FullTimeRegular', text: 'FTP Full-Time Regular' },
  { key: 'FullTimeTemporary', text: 'FTT Full-Time Temporary' },
  { key: 'PartTimeRegular', text: 'PTP Part-Time Regular' },
  { key: 'PartTimeTemporary', text: 'PTT Part-Time Temporary' },
  { key: 'ContractFullTime', text: 'CFT Contract Full-Time' },
  { key: 'ContractPartTime', text: 'CPT Contract Part-Time' },
  { key: 'TempToPermanent', text: 'TTP Temp-to-Permanent' },
  { key: 'Replacement', text: 'RPL Replacement' },
  { key: 'FullTimeCoOp', text: 'COF Full-Time CO-OP' },
  { key: 'PartTimeCoOp', text: 'COP Part-Time CO-OP' },
  { key: 'Internship', text: 'INT Internship/JLD' },
  { key: 'Exempt', text: 'EXM Exempt' },
  { key: 'NonExempt', text: 'NXM Non-Exempt' },
];

export const status: IDropdownOption[] = [
  { key: 'Available', text: 'Available' },
  { key: 'Filled', text: 'Filled' },
  { key: 'InternalOnly', text: 'Internal Only' },
  { key: 'Pending', text: 'Pending' },
  { key: 'Hold', text: 'Hold' },
  { key: 'Expired', text: 'Expired' },
  { key: 'Closed', text: 'Closed' },
  { key: 'OfferAccepted', text: 'Offer Accepted' },
  { key: 'NotAvailable', text: 'Not Available' },
  { key: 'JobTemplate', text: 'Job Template' },
];

export const visaTypes = [
  'U.S. Citizen',
  'H1-B',
  'J-1',
  'O-1',
  'Green Card',
  'TN-1',
];

export const zipRecruiterTypes = [
  'Accounting/Finance',
  'Admin/Secretarial',
  'Advertising',
  'Architect/Design',
  'Art/Media/Writers',
  'Automotive',
  'Banking',
  'Biotech/Pharmaceutical',
  'Computer/Software',
  'Construction/Skilled Trade',
  'Customer Service',
  'Domestic Help/Care',
  'Education',
  'Engineering',
  'Environmental Science',
  'Events',
  'Everything Else',
  'Facilities/Maintenance',
  'General Labor/Warehouse',
  'Gov/Military',
  'Healthcare',
  'Hospitality/Restaurant',
  'HR & Recruiting',
  'Information Technology',
  'Insurance',
  'Internet',
  'Law Enforcement/Security',
  'Legal',
  'Management &amp; Exec',
  'Manufacturing/Operations',
  'Marketing/PR',
  'Nonprofit &amp; Fund',
  'Oil/Energy/Power',
  'Quality Assurance',
  'Real Estate',
  'Research &amp; Dev',
  'Retail',
  'Sales &amp; Biz Dev',
  'Salon/Beauty/Fitness',
  'Social Services',
  'Supply Chain/Logistics',
  'Telecommunications',
  'Travel',
  'Trucking/Transport',
  'TV/Film/Musicians',
  'Vet &amp; Animal Care',
  'Work from Home',
];

export const profession = [
  'Engineering',
  'Executive',
  'IT / Information Technology',
  'Nursing',
  'Occupational Therapy',
  'Pharmacy',
  'Physical Therapy',
  'Physician / Surgeon',
  'Speech Therapy',
  'Lab',
  'Sales',
  'Radiology',
  'Allied Health',
  'Cardiopulmonary',
  'Respiratory',
  'Finance',
];
