import { ResponsiveHeatMap } from '@nivo/heatmap';
import { useMemo } from 'react';

import { useGlobalState } from '../../../context/GlobalContext';
import { useCustomQuery } from '../../../hooks/useCustomQuery';
import { useQueryParams } from '../../../hooks/useQueryParams';

interface APIUserHeatMap {
  userId: string;
  data: { label: string; value: number }[];
}

function UserHeatMap({
  isDisabled,
  timeFrame,
}: {
  isDisabled: boolean;
  timeFrame: string;
}) {
  const { queryParams } = useQueryParams();
  const { users } = useGlobalState();

  const { data: response, isLoading } = useCustomQuery<APIUserHeatMap[]>({
    url: '/admin/reports/userheatmap',
    params: {
      startDate: queryParams?.startDate,
      endDate: queryParams?.endDate,
      interval: queryParams?.timeFrame || 'day',
    },
    enabled: !queryParams?.timeFrame || queryParams?.timeFrame === 'week',
  });

  const userHeatMapData = response?.data;

  const heatMapData = useMemo(() => {
    return (
      userHeatMapData?.map((data) => {
        const user =
          users?.find((u) => u.userId === data?.userId)?.fullName || '';
        return {
          id: user,
          data: Object.entries(data?.data)?.map((item) => {
            const { label, value } = item[1];
            return { x: label, y: value };
          }),
        };
      }) || []
    );
  }, [userHeatMapData, users]);

  return (
    <div className='flex h-[800px] w-full flex-col gap-3 rounded-lg border border-neutral-40 bg-white p-4'>
      <div className='grid grid-cols-[2fr,1fr] justify-between'>
        <div className='flex items-center gap-2'>
          <div
            className={`material-symbols-outlined font-bold ${isDisabled && 'text-gray-500'}`}
          >
            person
          </div>
          <span
            className={`text-lg font-bold  ${isDisabled && 'text-gray-500'}`}
          >
            User Session
          </span>
        </div>
      </div>

      {isLoading ? (
        <div className='size-full animate-pulse rounded-md bg-gray-400' />
      ) : isDisabled ? (
        <div className='flex size-full items-center justify-center text-lg font-medium text-gray-500'>
          Not available for selected time range
        </div>
      ) : (
        <div className='relative h-[700px] w-auto overflow-hidden'>
          <ResponsiveHeatMap
            data={heatMapData}
            margin={{ top: 40, right: 30, bottom: 20, left: 100 }}
            axisTop={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 5,
            }}
            colors={{
              type: 'sequential',
              scheme: 'purples',
              minValue: 0,
              maxValue: timeFrame === 'today' ? 40 : 480,
            }}
            emptyColor='#eeeeee'
            enableLabels={false}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.8]] }}
          />
        </div>
      )}
    </div>
  );
}

export default UserHeatMap;
