import { E164Number } from 'libphonenumber-js/types';
import ReactPhoneNumberInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';

import { useGlobalState } from '../../context/GlobalContext';
import { maskPhoneNumber } from '../../utils/phone';
import { inputClass } from '../ui/input';
import { ErrorMessage } from './Error';

interface IPhone {
  onChange: (value: E164Number | undefined) => void;
  value: E164Number | undefined;
  disabled?: boolean;
  errorMessage?: string;
  required?: boolean;
  placeholder?: string;
  label?: string;
  id: string;
  className?: string;
  hideLabel?: boolean;
}

function PhoneInput({
  required = false,
  onChange,
  value,
  disabled,
  errorMessage,
  placeholder = '',
  label,
  id,
  className = '',
  hideLabel,
}: IPhone) {
  const { isAdmin } = useGlobalState();
  return (
    <div className={className}>
      <div className='flex flex-col gap-1'>
        {!hideLabel && (
          <div>
            <label htmlFor={id} className='relative mb-1 inline'>
              <span>{label || 'Phone Number'}</span>
              {required && (
                <span className='absolute -right-2 font-semibold text-error'>
                  *
                </span>
              )}
            </label>
          </div>
        )}
        {disabled ? (
          <input
            disabled
            type='text'
            className={inputClass}
            value={maskPhoneNumber(value, isAdmin)}
          />
        ) : (
          <ReactPhoneNumberInput
            placeholder={placeholder}
            disabled={disabled}
            useNationalFormatForDefaultCountryValue
            defaultCountry='US'
            onChange={onChange}
            value={value}
            className={inputClass}
            id={id}
          />
        )}
      </div>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
}

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
