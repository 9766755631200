import { useDroppable, useDraggable } from '@dnd-kit/core';
import { CSSProperties, ReactNode } from 'react';

import { cn } from '../../lib/utils';

export function Droppable({
  id,
  children,
  data,
  className = '',
}: {
  id: string;
  children: ({ isOver }: { isOver: boolean }) => ReactNode;
  data?: any;
  style?: CSSProperties;
  className?: string;
}) {
  const { isOver, setNodeRef } = useDroppable({
    id,
    data: data,
  });

  return (
    <div ref={setNodeRef} className={cn(className)}>
      {children({ isOver })}
    </div>
  );
}

export function Draggable({
  id,
  children,
  data,
  disabled = false,
  className,
}: {
  id: string;
  children: JSX.Element;
  data?: any;
  disabled?: boolean;
  className?: string;
}) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    data,
    disabled,
  });

  const s = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        touchAction: 'none',
      }
    : {};

  return (
    <div
      ref={setNodeRef}
      style={{
        ...s,
      }}
      className={className}
      {...listeners}
      {...attributes}
    >
      {children}
    </div>
  );
}
