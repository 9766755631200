import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useReducer, useState } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';

import {
  IAction,
  TextInput as TextInputDeprecated,
  commonReducer,
} from '../../components/common';
import AddressInput from '../../components/common/AddressInput';
import { Button } from '../../components/common/Button';
import OrganizationSelect from '../../components/common/OrganizationSelect';
import PhoneInput from '../../components/common/PhoneInput';
import TextInput from '../../components/common/TextInput';
import AddAttachmentsModal from '../../components/modals/AddAttachmentsModal';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../components/ui/alert-dialog';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import RootLayout from '../../layouts';
import { cn } from '../../lib/utils';
import { IAddress } from '../../react-app-env';
import { APIContact } from '../../types/contacts';
import { APIOrganization } from '../../types/organizations';
import { isValidEmail } from '../../utils/helpers';
import { StructuredTitleDropdown } from './StructuredTitleDropdown';

export interface APIC extends IAddress {
  firstName: string;
  lastName: string;
  title?: string;
  phone: string;
  emailAddress?: string;
  resume?: any;
  userId?: string;
}

const initialState: Partial<APIContact> = {
  firstName: '',
  lastName: '',
  title: '',
  phone: undefined,
  emailAddress: '',
  address1: '',
  city: '',
  state: '',
  postalCode: '',
  userId: '',
  phoneExt: '',
  orgHasContract: null,
};

export const validateContact = (state: Partial<APIContact>) => {
  const errors: Partial<Record<keyof APIContact, string>> = {};
  if (!state.firstName) errors.firstName = 'First name is required';

  if (!state.lastName) errors.lastName = 'Last name is required';

  if (!state.phone && !state.emailAddress) {
    errors.emailAddress = 'Email OR phone number is required';
    errors.phone = 'Email OR phone number is required';
  }

  if (state.emailAddress && !isValidEmail(state.emailAddress))
    errors.emailAddress = 'Email address not valid';

  if (state.phone && !isPossiblePhoneNumber(state.phone)) {
    errors.phone = 'Phone number not valid';
  }

  return errors;
};

export function AddContactPage() {
  return (
    <RootLayout allowOverflow>
      <AddContact />
    </RootLayout>
  );
}

interface AddContactTypes {
  contact?: APIContact;
  organization?: APIOrganization;
  isUsedAsComponent?: boolean;
  onCancel?: () => void;
  onSubmit?: (contact: APIContact) => void;
  // Currently using this for adding a contact from the hiring contract input
  organizationOverride?: {
    organizationId: string | null;
    organizationName: string | null;
    organizationHasContract: boolean;
    organizationInputDisabled: boolean;
  };
}

export function AddContact({
  contact,
  organization,
  isUsedAsComponent,
  onCancel,
  onSubmit,
  organizationOverride,
}: AddContactTypes) {
  const [file, setFile] = useState<File | null>(null);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [showDuplicateContactAlert, setShowDuplicateContactAlert] =
    useState(false);
  const [duplicateContact, setDuplicateContact] = useState<APIContact | null>(
    null,
  );
  const [isAddResumeModalOpen, setIsAddResumeModalOpen] = useState(false);

  const navigate = useNavigate();
  const { me } = useGlobalState();

  const queryClient = useQueryClient();

  const [state, dispatch]: [APIContact, React.Dispatch<IAction>] = useReducer(
    commonReducer,
    initialState,
  );
  const [createdContact, setCreatedContact] = useState<APIContact>();
  const [{ errors }, errorDispatch]: [any, React.Dispatch<IAction>] =
    useReducer(commonReducer, { errors: {} });

  const { mutate: submitAttachment, isPending: isPendingAttachmentUpload } =
    useCustomMutation({
      onSettled: () => {
        onSubmit
          ? onSubmit(createdContact as APIContact)
          : navigate('/contacts');
      },
    });

  const { mutate: submitForm, isPending } = useCustomMutation({
    onError: (error: any) => {
      if (error?.response?.status === 422) {
        const message = error?.response?.data?.message;
        const parsedMessage: APIContact | null = message
          ? JSON.parse(message)
          : null;

        setDuplicateContact(parsedMessage);
        setShowDuplicateContactAlert(true);
      }
    },
    onSuccess: (data) => {
      setCreatedContact(data?.data);

      if (!file) {
        queryClient.refetchQueries({
          queryKey: ['/contacts'],
          type: 'all',
        });

        onSubmit ? onSubmit(data?.data) : navigate('/contacts');
        return;
      }

      const tableId = data?.data?.contactId;
      if (!tableId) {
        return;
      }
      const formData = new FormData();
      formData.append('multipartFile', file, file.name);

      submitAttachment({
        url: `/attachments?tableId=${tableId}&&tableName=contacts&&description=${textAreaValue}&&makePrimaryAttachment=true`,
        body: formData,
      });
    },
    onSuccessMessage: 'Contact saved successfully!',
  });

  const handleSubmit = async () => {
    const errors = validateContact(state);

    if (Object.values(errors).length) {
      errorDispatch({ path: 'errors', value: errors });
      return;
    }

    await submitForm({
      url: '/contacts',
      body: {
        ...state,
        titleId: state.titleId === 'Other' ? null : state.titleId,
      },
    });
  };

  const handleChange = (path: keyof APIContact) => (value: any) => {
    if (errors[path]) {
      if (path === 'emailAddress' || path === 'phone') {
        errorDispatch({ type: 'remove', path: 'errors.emailAddress' });
        errorDispatch({ type: 'remove', path: 'errors.phone' });
      } else {
        errorDispatch({ type: 'remove', path: `errors.${path}` });
      }
    }

    dispatch({ path, value });
  };

  const handleAddressChange = (address: IAddress) => {
    if (errors.address1) {
      errorDispatch({ type: 'remove', path: 'errors.address1' });
    }

    dispatch({ type: 'set', value: address });
  };

  useEffect(() => {
    if (!state.userId && me) {
      dispatch({
        type: 'set',
        value: { userId: me.userId, recruiterEmail: me.emailAddress },
      });
    }
  }, [state.userId, me]);

  const handleSelectChange = (path: string) => (newValue: any) => {
    if (errors[path])
      errorDispatch({ path: `errors[${path}]`, type: 'remove' });

    dispatch({
      type: 'set',
      value: newValue?.update ?? {},
    });
  };

  useEffect(() => {
    if (contact || organization) {
      dispatch({
        type: 'reset',
        value: {
          firstName: contact?.firstName,
          lastName: contact?.lastName,
          emailAddress: contact?.emailAddress,
          phone: contact?.phone,
          phoneExt: contact?.phoneExt,
          workPhone: contact?.workPhone,
          workExt: contact?.workExt,
          title: contact?.title,
          titleId: contact?.titleId ?? '',
          organizationId:
            organizationOverride?.organizationId ||
            contact?.organizationId ||
            organization?.organizationId,
          organizationName:
            organizationOverride?.organizationName ||
            contact?.organizationName ||
            organization?.organizationName,
          address1: contact?.address1,
          city: contact?.city,
          state: contact?.state,
          postalCode: contact?.postalCode,
          orgHasContract: contact?.orgHasContract,
        },
      });
    }
  }, [
    contact,
    organization,
    organizationOverride?.organizationId,
    organizationOverride?.organizationName,
  ]);

  return (
    <>
      <div
        className={`flex size-full flex-col items-center justify-start p-4 ${isUsedAsComponent && 'mx-1 pt-0'}`}
      >
        <div
          className={cn(
            'max-w-[600px] px-8 sm:px-16',
            `size-full h-fit overflow-auto bg-white ${isUsedAsComponent ? 'px-0 sm:px-0' : 'rounded-md border border-neutral-40 p-4 shadow-md'}`,
          )}
        >
          {!isUsedAsComponent && (
            <h1 className='my-8 text-2xl font-bold'>Add Contact</h1>
          )}
          <div className='flex flex-col gap-4'>
            <div className='mx-1 flex gap-4'>
              <TextInputDeprecated
                label='First Name'
                required
                value={state?.firstName || ''}
                handleChange={handleChange('firstName')}
                errorMessage={errors.firstName}
                fullWidth
              />
              <TextInputDeprecated
                label='Last Name'
                required
                value={state?.lastName || ''}
                handleChange={handleChange('lastName')}
                errorMessage={errors.lastName}
                fullWidth
              />
            </div>
            <div className='mx-1 flex gap-4'>
              <PhoneInput
                required={!state.emailAddress}
                onChange={handleChange('phone')}
                value={state.phone ?? undefined}
                errorMessage={errors.phone}
                id='add-contact-phone'
                className='flex-1'
              />
              <TextInput
                prefix='#'
                id='contact-phone-ext'
                label='Extension'
                value={state?.phoneExt || ''}
                onChange={(e) => {
                  const value = e.target.value;

                  if (/^\d{0,6}$/.test(value)) {
                    handleChange('phoneExt')(value);
                  }
                }}
                errorMessage={errors.phoneExt}
                className='flex-1'
              />
            </div>
            <div className='mx-1 flex gap-4'>
              <PhoneInput
                onChange={handleChange('workPhone')}
                value={state.workPhone ?? undefined}
                errorMessage={errors.workPhone}
                id='add-work-phone'
                className='flex-1'
                label='Work Phone'
              />
              <TextInput
                prefix='#'
                id='contact-work-ext'
                label='Extension'
                value={state?.workExt || ''}
                onChange={(e) => {
                  const value = e.target.value;

                  if (/^\d{0,6}$/.test(value)) {
                    handleChange('workExt')(value);
                  }
                }}
                errorMessage={errors.workExt}
                className='flex-1'
              />
            </div>

            <TextInputDeprecated
              required={!state.phone}
              label='Email address'
              value={state?.emailAddress || ''}
              handleChange={handleChange('emailAddress')}
              errorMessage={errors.emailAddress}
            />
            <StructuredTitleDropdown
              label='Job Title'
              required
              errorMessage={errors?.title}
              value={state?.titleId || ''}
              handleChange={(title, titleId) => {
                handleChange('titleId')(titleId);
                handleChange('title')(titleId === 'Other' ? null : title);
              }}
            />

            {state?.titleId === 'Other' && (
              <TextInputDeprecated
                label=''
                value={state?.title || ''}
                handleChange={handleChange('title')}
                errorMessage={
                  errors.title &&
                  'If "Other" is selected above, a job title must be entered here'
                }
                placeholder='Enter other job title...'
              />
            )}

            <OrganizationSelect
              value={
                organizationOverride
                  ? {
                      label: organizationOverride?.organizationName,
                      id: organizationOverride?.organizationId,
                      contract: organizationOverride?.organizationHasContract,
                    }
                  : state.organizationId
                    ? {
                        label: state.organizationName,
                        id: state.organizationId,
                        contract: !!state.orgHasContract,
                      }
                    : undefined
              }
              onChange={(value) => {
                handleSelectChange('organizationId')({
                  update: {
                    organizationName: value.label,
                    organizationId: value.id,
                    orgHasContract: value.contract,
                  },
                });
              }}
              onClear={() =>
                dispatch({ type: 'remove', path: 'organizationId' })
              }
              disabled={!!organizationOverride}
            />
            <AddressInput
              handleChange={handleAddressChange}
              selectedAddress={{
                address1: state.address1 ?? '',
                city: state.city ?? '',
                state: state.state ?? '',
                postalCode: state.postalCode ?? '',
              }}
              errorMessage={errors.address1}
            />
            {!(contact && contact?.contactId) && (
              <div className='flex flex-col gap-2'>
                <label htmlFor={'attchresume'}>{'Attach Resume'}</label>
                {!file ? (
                  <Button onClick={() => setIsAddResumeModalOpen(true)}>
                    Add
                  </Button>
                ) : (
                  <>
                    {file && (
                      <div className='rounded-md border border-gray-200 bg-white p-4'>
                        <p>
                          <strong>Name:</strong> {file?.name}
                        </p>
                        <p>
                          <strong>Size:</strong> {file?.size} bytes
                        </p>
                        <p>
                          <strong>Type:</strong> {file?.type}
                        </p>
                        <p>
                          <strong>Description:</strong> {textAreaValue}
                        </p>
                        {file && (
                          <div
                            onClick={() => {
                              setFile(null);
                            }}
                            className={`mt-2 cursor-pointer text-right font-bold text-primary-70`}
                          >
                            Clear
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div className='mt-16 flex justify-between'>
            <Button
              onClick={() => (onCancel ? onCancel() : navigate(-1))}
              variant='secondary'
            >
              Back
            </Button>
            <Button
              onClick={handleSubmit}
              isLoading={isPending || isPendingAttachmentUpload}
              disabled={isPending || isPendingAttachmentUpload}
            >
              Save
            </Button>
          </div>
        </div>
      </div>

      <AlertDialog
        open={showDuplicateContactAlert}
        onOpenChange={() => setShowDuplicateContactAlert(false)}
      >
        <AlertDialogContent className='bg-white'>
          <AlertDialogHeader>
            <AlertDialogTitle>Duplicate Contact</AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogDescription>
            <div className='flex flex-col gap-4'>
              <p>Contact with the same email or phone number already exists.</p>
              <div className='flex flex-col gap-2 rounded-md bg-neutral-40 p-2'>
                <p className='font-bold'>
                  {duplicateContact?.firstName} {duplicateContact?.lastName}
                </p>
                <p>{duplicateContact?.title}</p>
              </div>
            </div>
          </AlertDialogDescription>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                setDuplicateContact(null);
                setShowDuplicateContactAlert(false);
              }}
            >
              Close
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                setShowDuplicateContactAlert(false);
                window.open(
                  `/contacts/${duplicateContact?.contactId}`,
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
            >
              View contact
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AddAttachmentsModal
        attachmentType='resume'
        isOpen={isAddResumeModalOpen}
        onDismiss={() => setIsAddResumeModalOpen(false)}
        tableId={''}
        tableName={''}
        onSubmit={(file, textAreaValue) => {
          setFile(file);
          setTextAreaValue(textAreaValue);
          setIsAddResumeModalOpen(false);
        }}
        refetchAttachments={() => {}}
      />
    </>
  );
}
