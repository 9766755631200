import { E164Number } from 'libphonenumber-js/types';
import React from 'react';

import TextInput from '../../components/common/TextInput';
import { APIContact } from '../../types/contacts';
import { Button } from '../common/Button';
import PhoneInput from '../common/PhoneInput';

interface AddCallContactProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  state: {
    firstName: string | null;
    lastName: string | null;
    phone?: E164Number | null;
  };
  errors: {
    firstName?: string;
    lastName?: string;
    phone?: string;
  };
  handleChange: (path: keyof APIContact) => (value: any) => void;
}

const AddCallContact: React.FC<AddCallContactProps> = ({
  isOpen,
  onClose,
  onSave,
  state,
  errors,
  handleChange,
}) => {
  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
      <div className='flex w-full max-w-sm flex-col rounded-lg bg-white p-6 shadow-lg'>
        <div className='flex items-center justify-between'>
          <h3 className='text-xl font-semibold text-gray-800'>Add Contact</h3>
          <button
            onClick={onClose}
            className='text-gray-500 hover:text-gray-800'
          >
            <span className='material-symbols-outlined'>close</span>
          </button>
        </div>
        <div className='mt-4 flex flex-col gap-4'>
          <div className='mx-1 flex gap-4'>
            <TextInput
              id='first-name'
              label='First Name'
              value={state.firstName || ''}
              onChange={(e) => {
                const value = e.target.value;
                handleChange('firstName')(value);
              }}
              errorMessage={errors.firstName}
              className='flex-1'
            />
            <TextInput
              id='last-name'
              label='Last Name'
              value={state.lastName || ''}
              onChange={(e) => {
                const value = e.target.value;
                handleChange('lastName')(value);
              }}
              errorMessage={errors.lastName}
              className='flex-1'
            />
          </div>
          <div className='mx-1 flex gap-4'>
            <PhoneInput
              required
              onChange={handleChange('phone')}
              value={state.phone ?? undefined}
              errorMessage={errors.phone}
              id='add-contact-phone'
              className='flex-1'
            />
          </div>
        </div>
        <div className='mt-16 flex justify-between'>
          <Button onClick={onClose} variant='secondary'>
            Back
          </Button>
          <Button onClick={onSave}>Save</Button>
        </div>
      </div>
    </div>
  );
};

export default AddCallContact;
