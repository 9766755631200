import { cn } from '../../lib/utils';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltips';
import { ContractIcon, ContractDeleteIcon } from './Icons';

const OrganizationName = ({
  hasContract = false,
  size = 20,
  name = '',
  hideTooltip = false,
  onClick,
  className,
}: {
  hasContract: boolean;
  name: string | null;
  size?: number;
  // TODO figure out bug on OrganizationView component. The tooltip auto opens when the slideout opens. I think this has something to do with the sheet being rendered in a portal.
  hideTooltip?: boolean;
  onClick?: () => void;
  className?: string;
}) => {
  if (!name) return null;

  return (
    <div
      className={cn('flex w-full min-w-0 flex-1 items-center', className)}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick();
        }
      }}
    >
      <Tooltip {...(hideTooltip ? { open: false } : {})}>
        <TooltipTrigger>
          <div className='mr-1 rounded'>
            {hasContract ? (
              <ContractIcon size={size} />
            ) : (
              <ContractDeleteIcon size={size} />
            )}
          </div>
        </TooltipTrigger>
        <TooltipContent
          className='pointer-events-none whitespace-nowrap rounded px-1 py-0.5  text-xs font-bold'
          style={{
            backgroundColor: hasContract ? '#E7F8F3' : '#FCEAEA',
            color: hasContract ? '#309288' : '#920E0E',
          }}
        >
          {hasContract ? 'Contracted' : 'Not Contracted'}
        </TooltipContent>
      </Tooltip>
      <div className='overflow-hidden truncate'>{name}</div>
    </div>
  );
};

export default OrganizationName;
