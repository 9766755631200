import { E164Number } from 'libphonenumber-js/types';

import { useGlobalState } from '../../context/GlobalContext';
import { maskPhoneNumber } from '../../utils/phone';

const PhoneNumber = ({
  phoneNumber,
  extension,
}: {
  phoneNumber: E164Number | null;
  extension?: string | null;
  className?: string;
}) => {
  const { isAdmin } = useGlobalState();

  if (!phoneNumber) return <span>-</span>;

  return (
    <div className={`flex w-full min-w-0 gap-2`}>
      <span className='truncate'>{maskPhoneNumber(phoneNumber, isAdmin)}</span>
      {!!extension && <span>{`x${extension}`}</span>}
    </div>
  );
};

export default PhoneNumber;
