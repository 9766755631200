import RootLayout from '../../layouts';
import { Lists } from './Lists';

const ListsPage = () => {
  return (
    <RootLayout>
      <Lists />
    </RootLayout>
  );
};

export default ListsPage;
