import { ChevronDown } from 'lucide-react';
import React, { useEffect, useState } from 'react';

//import { useNavigate } from 'react-router-dom';
import { BaseTable, Column } from '../../components/common/BaseTable';
import { Button } from '../../components/common/Button';
import TableCellTooltip from '../../components/common/TableCellTooltip';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import { useGlobalState } from '../../context/GlobalContext';
import { useQueryParams } from '../../hooks/useQueryParams';
import { APIList } from '../../types/lists';
import { CheckboxProps } from '../../types/table';
import { legendColors } from '../../utils/constant';
import { setFieldIfEmpty, convertUTCToLocal } from '../../utils/helpers';

interface ListTableProps {
  data: APIList[];
  handleRowClick: (value: APIList) => void;
  className?: string;
  checkboxProps?: CheckboxProps;
}

function ListTable({
  data,
  handleRowClick,
  className,
  checkboxProps,
}: ListTableProps) {
  const { findUserById } = useGlobalState();
  //const navigate = useNavigate();
  const { queryParamsString } = useQueryParams();
  const queryParams = new URLSearchParams(queryParamsString);

  // Filter data based on engagementColor
  const filteredData = queryParams.get('engagementColor')
    ? data.filter(
        (contact) =>
          contact.engagementColor === queryParams.get('engagementColor'),
      )
    : data;

  /* - Commenting this out because it's not used currently - 2024-12-12
  const editPageHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
      event.stopPropagation();
      navigate(`/lists/edit/${id}`);
    },
    [navigate],
  );*/

  const toggleColumnVisibility = (c: Column<APIList>) => {
    const index = columns.map((column) => column.key).indexOf(c.key);
    setColumns((prevState) => {
      prevState[index].visible = !prevState[index].visible;
      return [...prevState];
    });
  };

  // Set initial visibility state for columns
  useEffect(() => {
    setColumns((prevState) =>
      prevState.map((column) => ({
        ...column,
        visible: true, // Set all columns to visible by default
      })),
    );
  }, []);

  const [columns, setColumns] = useState<Column<APIList>[]>([
    {
      label: 'Activity',
      key: 'engagementColor',
      sortEnabled: false,
      className: 'w-[4.5rem]',
      render: (list) => (
        <center>
          <div
            className={`relative h-5 w-5 rounded-full transition-all`}
            style={{ backgroundColor: list.engagementColor }}
          >
            <span className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xs font-bold text-white'>
              <>
                {
                  legendColors.find((c) => c.color === list.engagementColor)
                    ?.label
                }
              </>
            </span>
          </div>
        </center>
      ),
    },
    {
      label: 'List',
      key: 'description',
      sortEnabled: false,
      className: 'w-[14rem]',
      render: (list) => <TableCellTooltip content={list.description} />,
    },
    {
      label: 'List type',
      key: 'template',
      sortEnabled: true,
      className: 'w-[6rem]',
      render: (list) => setFieldIfEmpty(list.template),
    },
    {
      label: 'Description',
      key: 'memo',
      sortEnabled: false,
      className: 'w-[16rem]',
      render: (list) => {
        return <TableCellTooltip content={list.memo} />;
      },
    },
    {
      label: 'Contacts',
      key: 'contactCount',
      sortEnabled: true,
      className: 'w-[9rem]',
      render: (list) => setFieldIfEmpty(list.contactCount),
    },
    {
      label: 'Organizations',
      key: 'organizationCount',
      sortEnabled: true,
      className: 'w-[9rem]',
      render: (list) => setFieldIfEmpty(list.organizationCount),
    },
    {
      label: 'Requisitions',
      key: 'jobCount',
      sortEnabled: true,
      className: 'w-[9rem]',
      render: (list) => setFieldIfEmpty(list.jobCount),
    },
    {
      label: 'Recent activity',
      key: 'updateDate',
      sortEnabled: true,
      className: 'w-[12rem]',
      render: (list) => {
        return list.updateDate
          ? convertUTCToLocal(list.updateDate)
          : setFieldIfEmpty(list.updateDate);
      },
    },
    {
      label: 'Date Created',
      key: 'insertDate',
      sortEnabled: true,
      className: 'w-[14rem]',
      render: (list) => {
        return list.insertDate
          ? convertUTCToLocal(list.insertDate)
          : setFieldIfEmpty(list.insertDate);
      },
    },
    {
      label: 'User Assigned',
      key: 'user',
      sortEnabled: false,
      className: 'w-[9rem]',
      render: (list) => {
        const user = findUserById(list.userId);
        return setFieldIfEmpty(user?.fullName);
      },
    },
    {
      label: 'Expires At',
      key: 'expiresAt',
      sortEnabled: true,
      className: 'w-[14rem]',
      render: (list) => {
        return list.expiresAt
          ? convertUTCToLocal(list.expiresAt)
          : setFieldIfEmpty(list.expiresAt);
      },
    },
    /* Commenting this out because it's not used currently - 2024-12-12
    {
      label: '',
      key: 'action',
      sortEnabled: false,
      className: 'w-[8rem]',
      render: (item) => (
        <Button
          variant='ghost'
          onClick={(e) => editPageHandler(e, item?.listId)}
          className='z-10 mx-auto flex h-6 items-center gap-2 self-start px-2 py-1 font-bold text-primary underline'
        >
          Edit
        </Button>
      ),
    },*/
  ]);

  return (
    <>
      <div className='z-10 flex w-full'>
        <DropdownMenu>
          <DropdownMenuTrigger className='m-2'>
            <Button variant='ghost'>
              <div className='flex items-center gap-1'>
                <ChevronDown size={16} /> Columns
              </div>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {columns.map((c: Column<APIList>) => (
              <DropdownMenuCheckboxItem
                checked={c.visible}
                onCheckedChange={() => toggleColumnVisibility(c)}
                key={c.key}
              >
                {c.label}
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <BaseTable
        data={filteredData ? filteredData : data}
        columns={columns}
        idKey='listId'
        defaultSortKey='insertDate'
        handleRowClick={handleRowClick}
        className={className}
        checkboxProps={checkboxProps}
      />
    </>
  );
}

export default ListTable;
