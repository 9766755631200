import { QueryKey, useQueryClient, QueryFilters } from '@tanstack/react-query';

interface InvalidateQueryOptions extends QueryFilters {
  refetchType?: 'active' | 'inactive' | 'all' | 'none';
  throwOnError?: boolean;
  cancelRefetch?: boolean;
}

const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  const invalidateQuery = async (
    queryKey: QueryKey,
    options?: InvalidateQueryOptions,
  ) => {
    await queryClient.invalidateQueries(
      {
        queryKey,
        refetchType: options?.refetchType ?? 'active',
      },
      {
        throwOnError: options?.throwOnError,
        cancelRefetch: options?.cancelRefetch,
        ...options,
      },
    );
  };

  return invalidateQuery;
};

export default useInvalidateQuery;
