export const activityTitleMap = {
  import: {
    text: 'Added',
    icon: 'add',
  },
  add: {
    text: 'Added',
    icon: 'add',
  },
  update: {
    text: 'Updated',
    icon: 'change_history',
  },
  addedu: {
    text: 'Education history',
    icon: 'school',
  },
  addhist: {
    text: 'Job history',
    icon: 'work_history',
  },
  addname: {
    text: 'Contact added',
    icon: 'person_add',
  },
  call: {
    text: 'Call',
    icon: 'call',
  },
  email: {
    text: 'Email sent',
    icon: 'mail',
  },
  emailrecd: {
    text: 'Email received',
    icon: 'mark_email_unread',
  },
  emailto: {
    text: 'Email sent',
    icon: 'forward_to_inbox',
  },
  inbound: {
    text: 'Inbound call',
    icon: 'phone_callback',
  },
  inquiry: {
    text: 'Inquiry',
    icon: 'live_help',
  },
  linkname: {
    text: 'Contact linked',
    icon: 'link',
  },
  mergedupe: {
    text: 'Merged with duplicate',
    icon: 'merge',
  },
  outbound: {
    text: 'Outbound call',
    icon: 'call',
  },
  pipeline: {
    text: 'Pipeline',
    icon: 'valve',
  },
  pla_follow: {
    text: 'Follow',
    icon: 'follow',
  },
  savenam: {
    text: 'Contact saved',
    icon: 'save',
  },
  system: {
    text: 'System',
    icon: 'settings_applications',
  },
  meetingset: {
    text: 'Meeting Set',
    icon: 'event',
  },
  meetingconducted: {
    text: 'Meeting Conducted',
    icon: 'event_available',
  },
  contractsent: {
    text: 'Sent Contract',
    icon: 'contract',
  },
  contractexecuted: {
    text: 'Contract Executed',
    icon: 'contract_edit',
  },
  contractfollowup: {
    text: 'Contract Follow Up',
    icon: 'contract',
  },
  contractpending: {
    text: 'Contract Pending',
    icon: 'contract',
  },
  contractrejected: {
    text: 'Contract Rejected',
    icon: 'contract_delete',
  },
  checkin: {
    text: 'Check-in',
    icon: 'fact_check',
  },
  checkinprospect: {
    text: 'Check-in: Prospect',
    icon: 'fact_check',
  },
  checkinclient: {
    text: 'Check-in: Client',
    icon: 'fact_check',
  },
  joborder: {
    text: 'Job Order Received',
    icon: 'receipt',
  },
  refresh: {
    text: 'Refresh',
    icon: 'refresh',
  },
  other: {
    text: 'Other',
    icon: 'info',
  },
  esmsinbound: {
    text: 'Text received',
    icon: 'chat',
  },
  esmsoutbound: {
    text: 'Text sent',
    icon: 'comment',
  },
  smsinbound: {
    text: 'Text received',
    icon: 'chat',
  },
  smsoutbound: {
    text: 'Text sent',
    icon: 'comment',
  },
  // CHANGECO
  // BULKEMAIL
  // PIPELINEP
};

export const listOfStates = {
  Northeast: [
    'CT',
    'DC',
    'DE',
    'ME',
    'MA',
    'MD',
    'NH',
    'NJ',
    'NY',
    'PA',
    'RI',
    'VT',
  ],
  Midwest: [
    'IA',
    'IL',
    'IN',
    'KS',
    'MI',
    'MN',
    'MO',
    'NE',
    'ND',
    'OH',
    'SD',
    'WI',
  ],
  South: [
    'AL',
    'AR',
    'FL',
    'GA',
    'KY',
    'LA',
    'MS',
    'NC',
    'OK',
    'SC',
    'TN',
    'TX',
    'VA',
    'WV',
  ],
  West: [
    'AK',
    'AZ',
    'CA',
    'CO',
    'HI',
    'ID',
    'MT',
    'NV',
    'NM',
    'OR',
    'UT',
    'WA',
    'WY',
  ],
};

export const statesAbrv = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export type ActivityType = keyof typeof activityTitleMap;
