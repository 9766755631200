import {
  CallClient,
  Call,
  CallAgent,
  DtmfTone,
  IncomingCall,
} from '@azure/communication-calling';
import {
  AzureCommunicationTokenCredential,
  PhoneNumberIdentifier,
} from '@azure/communication-common';
import axios from 'axios';
import Cookies from 'js-cookie';
import { E164Number } from 'libphonenumber-js';
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';

import { useGlobalState } from '../../context/GlobalContext';
import { usePhone } from '../../context/PhoneContext';
import { useToast } from '../../hooks/use-toast';
import { apiBase, useCustomMutation } from '../../hooks/useCustomMutation';
import { Recording, RecordingRequest, CallInfo } from '../../types/phone';
import { Pad } from './Pad';

export const Caller = forwardRef(
  (
    {
      callInfo,
      cleanCallInfo,
    }: {
      callInfo: CallInfo | null;
      cleanCallInfo: () => void;
    },
    ref,
  ) => {
    const [callStatus, setCallStatus] = useState<string>('Dial the number');
    const [number, setNumber] = useState<string>('');
    const [buttonReceiveCall, setButtonReceiveCall] = useState<boolean>(false);
    const [call, setCall] = useState<Call | null>(null);
    const [recordingData, setRecordingData] = useState<Recording | null>(null);
    const [callAgent, setCallAgent] = useState<CallAgent | null>(null);
    const { toast } = useToast();
    const [incoming, setIncoming] = useState<IncomingCall>();
    const rejectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [isCalling, setIsCalling] = useState<boolean>(false);
    const [isMinimized, setIsMinimized] = useState<boolean>(true);
    const [isDialPadView, setIsDialPadView] = useState<boolean>(true);
    const [isQuestionContactView, setIsQuestionContactView] =
      useState<boolean>(false);
    const [isAddContactView, setIsAddContactView] = useState<boolean>(false);
    const [errorPhoneNumber, setErrorPhoneNumber] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState(false);
    const [callName, setCallName] = useState<string>('');
    const callNameRef = useRef(callName);
    const { me } = useGlobalState();
    const { setCallPhone } = usePhone();

    const ACS_PHONE_NUMBER = callInfo?.userPhone;
    const ACS_BASEURL = process.env.REACT_APP_ACS_RECORDINGAPI;
    const ringingAudio = useMemo(() => new Audio('/audio/ringing.mp3'), []);

    let acsAccessToken = Cookies.get('acsAccessToken');
    let expDate: Date | null = null;

    const { mutate: addContact } = useCustomMutation({
      method: 'post',
      onSuccess: (response: any) => {
        const contact = response.data;
        callingContact(contact);
      },
      onError: (error: any) => {
        console.log(error?.response?.data?.message);
      },
      onSuccessMessage: 'Contact added',
      showErrorToast: false,
    });

    const changeStateAddContact = async (
      firstName: string | null,
      lastName: string | null,
      phone: E164Number | null,
    ) => {
      await addContact({
        url: '/contacts',
        body: {
          address1: '',
          city: '',
          emailAddress: '',
          firstName: firstName,
          lastName: lastName,
          orgHasContract: null,
          phone: phone,
          phoneExt: '',
          postalCode: '',
          recruiterEmail: me?.emailAddress,
          state: '',
          title: '',
          userId: me?.userId,
          phoneStatus: status,
        },
      });
    };

    const { mutate: updateUser } = useCustomMutation({
      method: 'patch',
      onError: (error: any) => {
        console.log(error?.response?.data?.message);
      },
      onSuccessMessage: 'User call updated.',
      showErrorToast: false,
    });

    const changeStateUser = async (status: string) => {
      await updateUser({
        url: '/users',
        body: {
          phoneStatus: status,
        },
      });
    };

    const validateAcsAccessToken = async () => {
      try {
        if (acsAccessToken) {
          const tokenPayload = acsAccessToken.split('.')[1];
          const decodedPayload = atob(tokenPayload);
          const tokenObj: { exp: number } = JSON.parse(decodedPayload);
          expDate = new Date(tokenObj.exp * 1000);
          const currentTime = new Date().getTime();

          if (expDate && currentTime > expDate.getTime()) {
            const response = await axios.get(
              `${apiBase}${'/login/refreshacs'}`,
              {
                withCredentials: true,
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              },
            );
            if (response) {
              acsAccessToken = Cookies.get('acsAccessToken');
            } else {
              acsAccessToken = undefined;
            }
          }
        }
      } catch (e) {
        console.error('Error parsing token:', e);
      }

      if (acsAccessToken == undefined) {
        toast({
          title: 'Info',
          description: 'Contact your administrator.',
          variant: 'default',
          duration: 7000,
        });
        setIsCalling(false);
        setIsMinimized(true);
        setIsDialPadView(true);
        setNumber('');
        setCallName('');
        setCallStatus('Dial the number');
        return;
      }
    };

    const createCallAgentClient = async () => {
      if (!callAgent) {
        validateAcsAccessToken();

        if (acsAccessToken) {
          const tokenCredential = new AzureCommunicationTokenCredential(
            acsAccessToken == undefined ? '' : acsAccessToken,
          );
          const newCallClient = new CallClient();
          const callAgent =
            await newCallClient.createCallAgent(tokenCredential);

          callAgent.on('callsUpdated', (event) => {
            event.added.forEach((addedCall) => {
              addedCall.on('stateChanged', async () => {
                if (addedCall.state !== 'EarlyMedia') {
                  setCallStatus(addedCall.state);
                }

                if (addedCall.state === 'Ringing') {
                  changeStateUser('Busy');
                  const result = await addedCall.info.getServerCallId();
                  const mri = (addedCall as any).callInfo._tsCall.callerMri;
                  if (callInfo) {
                    const recordRequests = {
                      serverCallId: result,
                      callConnectionId: addedCall.id,
                      recordingContent: 'audio',
                      recordingChannel: 'unmixed',
                      recordingFormat: 'wav',
                      callerId: mri,
                      fromUserId: callInfo.userId,
                      fromContactId: '',
                      fromPhone: ACS_PHONE_NUMBER ?? '',
                      fromName: callInfo.userName,
                      toUserId: callInfo.contactUserId,
                      toPhone: callInfo.contactPhone,
                      toName: callInfo.contactFirstName,
                      toContactId: callInfo.contactId,
                      toOrganizationId: callInfo.contactOrganizationId,
                      direction: 'outbound',
                      external: true,
                    };
                    try {
                      const res = await startRecordingCall(recordRequests);
                      setRecordingData(res.recordingId);
                    } catch (error) {
                      console.log('Error recording call:', error);
                    }
                  }
                }

                if (addedCall.state === 'Connected') {
                  changeStateUser('Call');
                  setCallStatus(addedCall.state);
                }

                if (addedCall.state === 'Disconnected') {
                  handleHangUp();
                  changeStateUser('Available');
                }
              });
            });
          });

          setCallAgent(callAgent);

          return callAgent;
        }
      }

      return callAgent;
    };

    const callContact = async () => {
      try {
        const callAgentClient = await createCallAgentClient();

        if (callAgentClient && ACS_PHONE_NUMBER) {
          if (callInfo) {
            if (call) {
              // Add participant to current call
              handleAddCall(callInfo);
            } else {
              setCallName(
                callInfo.contactFirstName + ' ' + callInfo.contactLastName,
              );
              setIsMinimized(false);
              setIsDialPadView(false);
              setIsCalling(true);
              setCall(null);

              const newCall = callAgentClient.startCall(
                [
                  {
                    phoneNumber: callInfo.contactPhone,
                  },
                ],
                { alternateCallerId: { phoneNumber: ACS_PHONE_NUMBER } },
              );
              setCall(newCall);
            }
          }
        }
      } catch (error) {
        console.log('Error creating CallAgent: ', error);
      }
    };

    const createIncomingCallConfiguration = async () => {
      try {
        const callAgentClient = await createCallAgentClient();

        if (callAgentClient) {
          callAgentClient.on('incomingCall', async (event) => {
            // There is a call already
            if (callAgentClient.calls.length > 0) {
              changeStateUser('Call');
              return;
            } else {
              // plays ringing audio
              setIsRingingAudio(true);

              // find contact information
              const phoneNumber =
                (
                  event.incomingCall.callerInfo
                    .identifier as PhoneNumberIdentifier
                ).phoneNumber || '';

              const contact = await getUserByPhoneNumber(phoneNumber);

              setCallName(contact ? contact.name : 'Unknown number');
              setIsCalling(true);
              setIsMinimized(false);
              setIsDialPadView(false);
              setIncoming(event.incomingCall);
              setButtonReceiveCall(true);
              setCallStatus('Incoming call...');

              rejectTimeoutRef.current = setTimeout(async () => {
                try {
                  await event.incomingCall.reject();
                  setCallStatus('Call rejected due to timeout');
                  setCallName('');
                } catch (error) {
                  console.error('Error rejecting call after timeout:', error);
                }
              }, 20000);

              event.incomingCall.on('callEnded', () => {
                if (rejectTimeoutRef.current) {
                  clearTimeout(rejectTimeoutRef.current);
                }
                setIsRingingAudio(false);
                setCallName('');
                setIsCalling(false);
                setIsMinimized(true);
                setIsDialPadView(true);
                setIncoming(undefined);
                setButtonReceiveCall(false);
                setCallStatus('Disconnecting...');
                changeStateUser('Available');
              });
            }
          });
        } else {
          return;
        }
      } catch (error) {
        console.log('Error creating CallAgent: ', error);
      }
    };

    const handleAddCall = async (callInfo: CallInfo) => {
      try {
        if (call && ACS_PHONE_NUMBER) {
          const remoteParticipant = call.addParticipant(
            { phoneNumber: callInfo.contactPhone },
            { alternateCallerId: { phoneNumber: ACS_PHONE_NUMBER } },
          );

          remoteParticipant.on('stateChanged', async () => {
            if (remoteParticipant.state === 'Connecting') {
              setCallStatus('Ringing New Participant');
            } else {
              if (remoteParticipant.state === 'Connected') {
                setCallStatus('New Participant Connected');
                setCallName('Group Call');
              }

              if (remoteParticipant.state === 'Disconnected') {
                setCallStatus('Remote Participant Disconnected');
              }

              try {
                setTimeout(async () => setCallStatus('Connected'), 4000);
              } catch (error) {
                console.log('Error on setCallStatus');
              }
            }
          });
        }
      } catch (error) {
        console.error('Error handling remote participant:', error);
      }
    };

    const getUserByPhoneNumber = async (phoneNumber: string) => {
      let contact;
      try {
        const res = await axios.post(`${apiBase}/phonelookup`, [phoneNumber], {
          withCredentials: true,
        });

        contact = res.data.find(
          (contact: any) => contact.phoneNumber === phoneNumber,
        );
      } catch (error) {
        console.log('Number Lookup Error', error);
      }
      return contact;
    };

    const callingContact = (contact: any) => {
      const info = {
        activeDialPad: true,
        contactId: contact.contactId || '',
        contactUserId: contact.userId || '',
        contactFirstName: contact.firstName || '',
        contactLastName: contact.lastName || '',
        contactOrganizationId: contact.organizationId || '',
        contactPhone: contact.phone,
        contactExtension: contact.phoneExt || '',
        userId: me?.userId || '',
        userName: me?.userName || '',
        fullName: me?.fullName || '',
        emailAddress: me?.emailAddress || '',
        userPhone: me?.phone || me?.directDial1 || '',
      };
      setNumber('');
      setCallPhone(info);
    };

    const handleQuestion = async (value: string) => {
      const number: E164Number = isCalling
        ? (value as E164Number)
        : value.startsWith('+')
          ? (value as E164Number)
          : (`+${value}` as E164Number);
      const e164Regex = /^\+(\d{1,3})(\d{4,14})$/;
      if (e164Regex.test(number)) {
        const contact = await getContactByPhoneNumber(
          encodeURIComponent(number),
        );
        if (contact != undefined) {
          callingContact(contact);
        } else {
          setIsQuestionContactView(true);
        }
      } else {
        setErrorPhoneNumber(true);
      }
    };

    const getContactByPhoneNumber = async (phone: string) => {
      let contact;
      try {
        const res = await axios.get(
          `${apiBase}/contacts?phone=${phone}&orderBy=dateEntered&orderByDirection=DESC`,
          {
            withCredentials: true,
          },
        );
        contact = res.data ? res.data[0] : [];
      } catch (error) {
        console.log('Number Search Error', error);
      }
      return contact;
    };

    const setRecordingOutgoingCallConfiguration = async (
      result: string,
      addedCall: Call,
    ) => {
      if (callInfo) {
        const mri = (addedCall as any).callInfo._tsCall.callerMri;
        const recordRequests = {
          serverCallId: result,
          callConnectionId: addedCall.id,
          recordingContent: 'audio',
          recordingChannel: 'unmixed',
          recordingFormat: 'wav',
          callerId: mri,
          fromUserId: callInfo.userId,
          fromContactId: '',
          fromPhone: ACS_PHONE_NUMBER ?? '',
          fromName: callInfo.userName,
          toUserId: callInfo.contactUserId,
          toPhone: callInfo.contactPhone,
          toName: callInfo.contactFirstName,
          toContactId: callInfo.contactId,
          toOrganizationId: callInfo.contactOrganizationId,
          direction: 'outbound',
          external: true,
        };
        try {
          const res = await startRecordingCall(recordRequests);
          setRecordingData(res.recordingId);
        } catch (error) {
          console.log('Error recording call:', error);
        }
      }
    };

    const setRecordingIncomingCallConfiguration = async (
      result: string,
      addedCall: Call,
    ) => {
      const callerPhoneNumber =
        (addedCall.callerInfo.identifier as PhoneNumberIdentifier)
          .phoneNumber || '';

      const contact = await getUserByPhoneNumber(callerPhoneNumber);
      const mri = (addedCall as any).tsCall.currentUserSkypeIdentity.id;

      const recordRequests = {
        serverCallId: result,
        callConnectionId: addedCall ? addedCall.id : '',
        recordingContent: 'audio',
        recordingChannel: 'unmixed',
        recordingFormat: 'wav',
        callerId: mri ? '8:' + mri : '',
        fromUserId: '',
        fromContactId: contact ? contact.id : '',
        fromPhone: callerPhoneNumber,
        fromName: contact ? contact.name : 'Unknown',
        toUserId: me?.userId ?? '',
        toPhone: me?.directDial1 ?? '',
        toName: me?.userName ?? '',
        direction: 'inbound',
        toContactId: '',
        toOrganizationId: '',
        external: true,
      };
      try {
        const res = await startRecordingCall(recordRequests);
        setRecordingData(res.recordingId);
      } catch (error) {
        console.log('Error recording call:', error);
      }
    };

    const handleAnswerCall = async () => {
      if (incoming) {
        try {
          setIsRingingAudio(false);
          const serverCallId = await incoming.info.getServerCallId();
          const call = await incoming.accept();
          if (rejectTimeoutRef.current) {
            clearTimeout(rejectTimeoutRef.current);
          }
          changeStateUser('Call');
          setRecordingIncomingCallConfiguration(serverCallId, call);
          setCall(call);
          setButtonReceiveCall(false);
          setCallStatus('Call Acepted');
          setIncoming(undefined);
        } catch (error) {
          console.error('Error accepting incoming call:', error);
        }
      }
    };

    const sendDtmfTones = useCallback(
      async (tones: DtmfTone[]) => {
        for (const tone of tones) {
          if (call != null) {
            try {
              setTimeout(async () => await call.sendDtmf(tone), 500);
            } catch (error) {
              console.log('Error to send tone');
            }
          }
        }
      },
      [call],
    );

    const setIsRingingAudio = async (state: boolean) => {
      try {
        if (state) {
          ringingAudio.loop = true;
          await ringingAudio.play();
        } else {
          ringingAudio.pause();
          ringingAudio.currentTime = 0;
        }
      } catch (ex) {
        return;
      }
    };

    const convertStringToDtmfTones = useCallback(
      (DtmfTones: string): DtmfTone[] => {
        const tones: DtmfTone[] = [];

        for (const char of DtmfTones) {
          switch (char) {
            case '0':
              tones.push('Num0');
              break;
            case '1':
              tones.push('Num1');
              break;
            case '2':
              tones.push('Num2');
              break;
            case '3':
              tones.push('Num3');
              break;
            case '4':
              tones.push('Num4');
              break;
            case '5':
              tones.push('Num5');
              break;
            case '6':
              tones.push('Num6');
              break;
            case '7':
              tones.push('Num7');
              break;
            case '8':
              tones.push('Num8');
              break;
            case '9':
              tones.push('Num9');
              break;
            case '#':
              tones.push('Pound');
              break;
            case '*':
              tones.push('Star');
              break;
            default:
              break;
          }
        }
        return tones;
      },
      [],
    );

    const handlePaste = useCallback(
      async (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === 'v') {
          if (
            !isMinimized &&
            isDialPadView &&
            !isQuestionContactView &&
            !isAddContactView &&
            isFocused
          ) {
            event.preventDefault();
            try {
              const clipboardText = await navigator.clipboard.readText();
              const formattedNumber: E164Number = isCalling
                ? (clipboardText as E164Number)
                : clipboardText.startsWith('+')
                  ? (clipboardText as E164Number)
                  : (`+${clipboardText}` as E164Number);

              const e164Regex = /^\+(\d{1,3})(\d{4,14})$/;
              if (e164Regex.test(formattedNumber)) {
                setNumber(formattedNumber);
              } else {
                setErrorPhoneNumber(true);
              }
            } catch (error) {
              console.error('Error al leer desde el portapapeles:', error);
            }
          }
        }
      },
      [
        isCalling,
        isMinimized,
        isDialPadView,
        isQuestionContactView,
        isAddContactView,
        isFocused,
        setNumber,
        setErrorPhoneNumber,
      ],
    );

    useEffect(() => {
      window.addEventListener('keydown', handlePaste);
      return () => {
        window.removeEventListener('keydown', handlePaste);
      };
    }, [handlePaste]);

    const handleDialpadKeyPress = useCallback(
      (buttonValue: string) => {
        if (number.length < 15) {
          if (buttonValue.match(/^[0-9*#+]$/)) {
            const dtmfTones = convertStringToDtmfTones(buttonValue);
            sendDtmfTones(dtmfTones);
            setNumber((prev) => prev + buttonValue);
            setErrorPhoneNumber(false);
          }
        }
      },
      [number, convertStringToDtmfTones, sendDtmfTones],
    );

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (
          !isMinimized &&
          isDialPadView &&
          !isQuestionContactView &&
          !isAddContactView
        ) {
          const key = event.key;
          const isValidDialKey = key.match(/^[0-9*#]$/);
          const isFunctionKey =
            /^F\d+$/.test(key) ||
            [
              'ArrowUp',
              'ArrowDown',
              'ArrowLeft',
              'ArrowRight',
              'PageUp',
              'PageDown',
              'Home',
              'End',
            ].includes(key);

          if (isValidDialKey && !isFunctionKey) {
            handleDialpadKeyPress(key);
          } else if (key === 'Backspace') {
            setNumber((prev) => prev.slice(0, -1));
          }
          setErrorPhoneNumber(false);
        }
      },
      [
        isMinimized,
        isDialPadView,
        isQuestionContactView,
        isAddContactView,
        handleDialpadKeyPress,
        setNumber,
      ],
    );

    useEffect(() => {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [handleKeyDown]);

    const startRecordingCall = async (recordRequest: RecordingRequest) => {
      try {
        const response = await fetch(`${ACS_BASEURL}/api/recording/start`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(recordRequest),
        });

        if (!response.ok) {
          console.log('Not starting recording');
        }

        const data = await response.json();
        return data;
      } catch (error) {
        console.log('POST request failed:', error);
      }
    };

    const stopRecordingCall = async (recordRequest: Recording) => {
      try {
        const response = await fetch(`${ACS_BASEURL}/api/recording/stop`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(recordRequest),
        });

        if (response.ok) {
          const data = await response.json();
          return data;
        } else {
          console.log('Record not finished');
        }
      } catch (error) {
        console.log('POST request failed:', error);
      }
    };

    const clearInfo = () => {
      cleanCallInfo();
    };

    const handleHangUp = async () => {
      try {
        let result;
        if (incoming) {
          result = await incoming.reject();
          setIsRingingAudio(false);
          setIncoming(undefined);
          return result;
        }
        if (call) {
          result = await call.hangUp({ forEveryone: true });
          setCall(null);
        }
      } catch (error) {
        return;
      }

      setButtonReceiveCall(false);
      setIsCalling(false);
      setIsMinimized(true);
      setIsDialPadView(true);
      setNumber('');
      setCallName('');
      setCallStatus('Dial the number');
      clearInfo();

      if (recordingData?.recordingId != null) {
        stopRecordingCall(recordingData)
          .then(() => {
            console.log('Stop recording call:');
          })
          .catch((error) => {
            console.log('Error recording call:', error);
          });
      }
    };

    const callsUpdatedSuscription = (event: { added: any[] }) => {
      event.added.forEach((addedCall) => {
        addedCall.on('stateChanged', async () => {
          if (addedCall.state !== 'EarlyMedia') {
            setCallStatus(addedCall.state);
          }

          if (addedCall.state === 'Ringing') {
            const result = await addedCall.info.getServerCallId();

            setRecordingOutgoingCallConfiguration(result, addedCall);
          }

          if (addedCall.state === 'Connected') {
            setCallStatus(addedCall.state);
            changeStateUser('Call');
          }

          if (addedCall.state === 'Disconnected') {
            handleHangUp();
            changeStateUser('Available');
          }
        });
      });
    };

    useImperativeHandle(ref, () => ({
      callContact,
      createIncomingCallConfiguration: createIncomingCallConfiguration,
    }));

    useEffect(() => {
      if (callInfo && callAgent) {
        callAgent.on('callsUpdated', callsUpdatedSuscription);
      }

      return () => {
        if (callAgent) {
          callAgent.off('callsUpdated', callsUpdatedSuscription);
        }
      };
    });

    useEffect(() => {
      callNameRef.current = callName;
    }, [callName]);

    return (
      <Pad
        callName={callName}
        callStatus={callStatus}
        number={number}
        setNumber={setNumber}
        isMinimized={isMinimized}
        setIsMinimized={setIsMinimized}
        isDialPadView={isDialPadView}
        setIsDialPadView={setIsDialPadView}
        handleHangUp={handleHangUp}
        handleDialpadKeyPress={handleDialpadKeyPress}
        buttonReceiveCall={buttonReceiveCall}
        handleAnswerCall={handleAnswerCall}
        isCalling={isCalling}
        errorPhoneNumber={errorPhoneNumber}
        saveContact={changeStateAddContact}
        isQuestionContactView={isQuestionContactView}
        setIsQuestionContactView={setIsQuestionContactView}
        isAddContactView={isAddContactView}
        setIsAddContactView={setIsAddContactView}
        handleQuestion={handleQuestion}
        isFocused={isFocused}
        setIsFocused={setIsFocused}
      />
    );
  },
);
