import { AlertCircleIcon } from 'lucide-react';
import { useRouteError } from 'react-router-dom';

import colors from '../../styles/colors';
import { NotFound } from './NotFound';

export function ErrorMessage({ message }: { message?: string }) {
  if (!message) return null;

  return (
    <div className='slide-in mt-1 flex flex-row items-center gap-1'>
      <AlertCircleIcon
        size={14}
        className='mt-0.5 shrink-0 self-start text-error'
      />
      <p
        style={{ color: colors.error.DEFAULT }}
        className='break-words text-sm'
      >
        {message}
      </p>
    </div>
  );
}

export function Required() {
  return <span style={{ color: colors.error.DEFAULT, paddingLeft: 4 }}>*</span>;
}

export function Error() {
  const error: any = useRouteError();
  console.error(error);
  return (
    <NotFound
      code='500'
      message='Something went wrong'
      description='Refresh your page and try again'
    />
  );
}
