import { E164Number } from 'libphonenumber-js/types';
import { ActivityIcon } from 'lucide-react';

import DatePickerRange from '../../components/common/DatePickerRange';
import PhoneInput from '../../components/common/PhoneInput';
import ActiveScaleInput from '../../components/filters/ActiveScaleInput';
import OrganizationInput from '../../components/filters/OrganizationInput';
import RadiusSearchInput from '../../components/filters/RadiusSearchInput';
import SearchInput from '../../components/filters/SearchInput';
import StateInput from '../../components/filters/StatesInput';
import UsersInput from '../../components/filters/UsersInput';
import { useDebouncedQueryParam } from '../../hooks/useDebouncedQueryParams';
import { useQueryParams } from '../../hooks/useQueryParams';
import { StructuredTitleDropdown } from './StructuredTitleDropdown';

function ContactsFilters() {
  const { queryParams, updateQueryParam, removeQueryParam } = useQueryParams();

  const [phone, setPhone] = useDebouncedQueryParam({
    key: 'phone',
    minLength: 6,
  });

  const showRelevantSortButton = !!(
    queryParams.query &&
    queryParams.orderBy &&
    queryParams.orderByDirection
  );

  function PlusIcon() {
    return (
      <span className='material-symbols-outlined' style={{ fontSize: '20px' }}>
        add
      </span>
    );
  }

  return (
    <>
      <SearchInput
        containerClassName='w-96'
        showRelevantSortButton={showRelevantSortButton}
        placeholder='Search names, titles, full email addresses, cities'
      />
      <PhoneInput
        value={phone as E164Number}
        onChange={(value: E164Number | undefined) => setPhone(value ?? '')}
        placeholder='Phone Number'
        id='search-phone'
        hideLabel
      />
      <StructuredTitleDropdown
        label=''
        placeholder='Job Title'
        classNames={{
          container: 'w-80',
          trigger:
            'h-8 text-sm text-secondary-80 border-neutral-40 cursor-pointer',
        }}
        isClearable={!!queryParams?.titleId}
        onClear={() => removeQueryParam('titleId')}
        value={(queryParams?.titleId || '') as string}
        isChecked={!!queryParams?.titleId}
        handleChange={(_title, titleId) =>
          updateQueryParam('titleId', titleId, {
            multiple: false,
            resetPageOnChange: true,
          })
        }
      />
      <OrganizationInput />
      <UsersInput placeholder='Added by' />
      <StateInput />
      <RadiusSearchInput />
      <DatePickerRange
        Label={PlusIcon}
        tooltipText='Date added range'
        startParam='insertDateStart'
        endParam='insertDateEnd'
      />
      <DatePickerRange
        Label={ActivityIcon}
        tooltipText='Last activity range'
        startParam='dateLastActivityStart'
        endParam='dateLastActivityEnd'
      />
      <ActiveScaleInput />
    </>
  );
}

export default ContactsFilters;
