import { ReactNode } from 'react';

import PageHeader from '../components/common/PageHeader';
import { GlobalStateProvider } from '../context/GlobalContext';

const RootLayout = ({
  children,
  allowOverflow = false,
}: {
  children: ReactNode;
  allowOverflow?: boolean;
}) => {
  return (
    <GlobalStateProvider>
      <div className={`flex h-screen w-full flex-col`}>
        <PageHeader />
        <main
          className={`m-auto h-full w-full max-w-[3000px] flex-grow ${allowOverflow ? 'overflow-auto' : 'overflow-hidden'}`}
        >
          {children}
        </main>
      </div>
    </GlobalStateProvider>
  );
};

export default RootLayout;
