import NumberFilterInput from '../../components/common/NumberFilterInput';
import { useDebouncedQueryParam } from '../../hooks/useDebouncedQueryParams';

function MinBedsInput() {
  const [minBeds, setMinBeds] = useDebouncedQueryParam({
    key: 'minBeds',
  });

  const handleMinBedsChange = (value: number | null) => {
    setMinBeds(value ? value.toString() : '');
  };

  return (
    <NumberFilterInput
      value={minBeds ? Number(minBeds) : null}
      onChange={handleMinBedsChange}
      renderValue={(value: number | null) => {
        if (value) {
          return `Min beds (${value})`;
        }
        return 'Min beds';
      }}
      classNames={{ closeIcon: 'text-white' }}
    />
  );
}
export default MinBedsInput;
