import { CaretDownIcon, CheckIcon } from '@radix-ui/react-icons';
import { XIcon } from 'lucide-react';
import { useState } from 'react';

import { ErrorMessage } from '../../components/common';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../../components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/ui/popover';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { cn } from '../../lib/utils';

export type StructuredTitle = {
  titleId: string;
  parentTitleId: string;
  nchcrId: string;
  mainSpecialty: string;
  title: string;
  parentNchcrId: string;
};

export function StructuredTitleDropdown({
  errorMessage,
  handleChange,
  disabled = false,
  value,
  label,
  classNames,
  required,
  placeholder,
  isChecked,
  isClearable,
  onClear,
}: {
  errorMessage?: string;
  disabled?: boolean;
  handleChange: (title: string, titleId: string) => void;
  value: string;
  label?: string;
  placeholder?: string;
  isChecked?: boolean;
  isClearable?: boolean;
  onClear?: () => void;
  classNames?: {
    container?: string;
    label?: string;
    trigger?: string;
    value?: string;
    content?: string;
    item?: string;
  };
  required?: boolean;
}) {
  const [open, setOpen] = useState(false);

  const { data } = useCustomQuery<StructuredTitle[]>({
    url: '/titles',
    options: { staleTime: 0 },
  });

  const titles = data?.data;

  const selectedTitle = (
    value && titles ? titles?.find((title) => title?.titleId === value) : {}
  ) as StructuredTitle;

  return (
    <>
      <div
        className={cn(
          'flex w-full max-w-full flex-col gap-2',
          classNames?.container ?? '',
        )}
      >
        {label && (
          <div className='relative w-fit'>
            <div className={cn('font-bold', classNames?.label)}>{label}</div>
            {required && (
              <div className='absolute -right-2 top-0 text-error'>*</div>
            )}
          </div>
        )}
        <div>
          <Popover
            open={open}
            onOpenChange={(isOpen) => {
              if (!disabled) {
                setOpen(isOpen);
              }
            }}
          >
            <PopoverTrigger disabled={disabled} asChild>
              <div
                aria-expanded={open}
                aria-disabled={disabled}
                className={cn(
                  `${open && 'border-sky-500 ring-sky-500'} flex h-8 w-full max-w-full items-center justify-between rounded-[6px] border border-slate-300 bg-white px-2 py-1 text-left font-medium shadow-sm placeholder:text-neutral-600 hover:border-neutral-60 sm:text-sm ${disabled && 'bg-neutral-2 text-neutral-48 '} ${classNames?.trigger} ${isChecked && 'bg-secondary-80  text-white'}`,
                )}
              >
                <div className='truncate'>
                  {value === 'Other' ? (
                    <span>{value}</span>
                  ) : value && titles ? (
                    selectedTitle?.parentTitleId ? (
                      `${selectedTitle?.mainSpecialty}: ${selectedTitle?.title}`
                    ) : (
                      selectedTitle?.title
                    )
                  ) : (
                    <span className='text-neutral-60'>
                      {placeholder || 'Select title...'}
                    </span>
                  )}
                </div>

                <span className='flex items-center'>
                  {isClearable && (
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        onClear && onClear();
                      }}
                      className='ml-2 flex'
                    >
                      <XIcon size={14} className='mt-0.5' />
                    </span>
                  )}
                  <CaretDownIcon
                    className={`h-6 w-6 shrink-0 opacity-40 ${isClearable ? 'ml-0' : 'ml-2'}`}
                  />
                </span>
              </div>
            </PopoverTrigger>
            <PopoverContent
              align='start'
              className='popover-content-width-full w-full bg-white p-0'
            >
              <Command
                filter={(value, search) => {
                  const valueLower = value.toLowerCase();
                  const searchLower = search.toLowerCase();
                  if (valueLower === 'other') return 1;
                  if (valueLower.includes(searchLower)) return 1;
                  return 0;
                }}
              >
                <CommandInput placeholder='Choose a title...' className='h-9' />
                <CommandList>
                  <CommandEmpty>No options</CommandEmpty>
                  <CommandGroup>
                    {titles?.map((data) => {
                      const isActive = value === data?.titleId;
                      return (
                        <CommandItem
                          key={data?.titleId}
                          value={
                            data?.parentTitleId
                              ? `${data?.mainSpecialty}: ${data?.title},${data?.titleId}`
                              : data?.title
                          }
                          onSelect={(currentValue: string) => {
                            const titleId = currentValue?.split(',')[1];
                            const title = titles?.find(
                              (title) => title?.titleId === titleId,
                            );
                            if (title) {
                              handleChange(
                                title?.parentTitleId
                                  ? `${title?.mainSpecialty}: ${title?.title}`
                                  : title?.title,
                                titleId,
                              );
                              setOpen(false);
                            }
                          }}
                          className={`hover:bg-gray-100 ${isActive && 'bg-gray-300'}`}
                        >
                          {data?.parentTitleId
                            ? `${data?.mainSpecialty}: ${data?.title}`
                            : data?.title}{' '}
                          <CheckIcon
                            className={cn(
                              'ml-auto h-4 w-4',
                              isActive ? 'opacity-100' : 'opacity-0',
                            )}
                          />
                        </CommandItem>
                      );
                    })}
                    <CommandItem
                      key={'Other'}
                      value={'Other'}
                      onSelect={(value: string) => {
                        handleChange('Other', value);
                        setOpen(false);
                      }}
                      className={`hover:bg-gray-100 ${value === 'Other' && 'bg-gray-300'}`}
                    >
                      Other
                      <CheckIcon
                        className={cn(
                          'ml-auto h-4 w-4',
                          value === 'Other' ? 'opacity-100' : 'opacity-0',
                        )}
                      />
                    </CommandItem>
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          {errorMessage && <ErrorMessage message={errorMessage} />}
        </div>
      </div>
    </>
  );
}
