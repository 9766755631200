import { TooltipContent, TooltipTrigger } from '@radix-ui/react-tooltip';
import { ArrowUpRightIcon, ChevronDown } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ListBulkActions from '../../components/bulk-actions/ListBulkActions';
import { getActivityIcon } from '../../components/common/Activity';
import { BaseTable, Column } from '../../components/common/BaseTable';
import { Button } from '../../components/common/Button';
import {
  DollarSignIcon,
  ErrorIcon,
  IndeedIcon,
} from '../../components/common/Icons';
import OrganizationName from '../../components/common/OrganizationName';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import { Tooltip } from '../../components/ui/tooltips';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { useQueryParams } from '../../hooks/useQueryParams';
import colors from '../../styles/colors';
import { PageTypes } from '../../types/pages';
import { APIRequisition } from '../../types/requisitions';
import { CheckboxProps } from '../../types/table';
import { legendColors } from '../../utils/constant';
import {
  USDollar,
  setRegion,
  setFieldIfEmpty,
  formatDateString,
  convertUTCToLocal,
} from '../../utils/helpers';
import { status } from './inputs';

interface RequisitionsTableProps {
  data: APIRequisition[];
  handleRowClick: (value: APIRequisition) => void;
  className?: string;
  checkboxProps?: CheckboxProps;
  hideCopyButton?: boolean;
  hideBulkActions?: boolean;
}

const RequisitionsTable: React.FC<RequisitionsTableProps> = ({
  data: requisitions,
  handleRowClick,
  className = '',
  checkboxProps,
  hideCopyButton = false,
  hideBulkActions = false,
}) => {
  const { users } = useGlobalState();
  const navigate = useNavigate();
  const { queryParamsString } = useQueryParams();
  const queryParams = new URLSearchParams(queryParamsString);

  // Filter data based on engagementColor
  const filteredData = queryParams.get('engagementColor')
    ? requisitions.filter(
        (contact) =>
          contact.engagementColor === queryParams.get('engagementColor'),
      )
    : requisitions;

  const [copyJobId] = useState('');

  // Check if date posted > 8 days for available reqs.
  const getDateDifference = (requisitionDate?: string | null) => {
    if (!requisitionDate) return false;
    const date = new Date().getTime();
    const reqDate = new Date(requisitionDate).getTime();

    const timeDifference = date - reqDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference > 8;
  };

  useCustomQuery<APIRequisition>({
    url: `/jobs/${copyJobId}`,
    enabled: !!copyJobId,
  });

  const toggleColumnVisibility = (c: Column<APIRequisition>) => {
    const index = columns.map((column) => column.key).indexOf(c.key);
    setColumns((prevState) => {
      prevState[index].visible = !prevState[index].visible;
      return [...prevState];
    });
  };

  const [columns, setColumns] = useState<Column<APIRequisition>[]>([
    ...(hideCopyButton
      ? []
      : [
          {
            label: 'Activity',
            key: 'engagementColor',
            sortEnabled: false,
            className: 'w-[4rem]',
            visible: true,
            render: (requisition: APIRequisition) => (
              <center>
                <div
                  className={`relative h-5 w-5 rounded-full transition-all`}
                  style={{ backgroundColor: requisition.engagementColor }}
                >
                  <span className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xs font-bold text-white'>
                    <>
                      {
                        legendColors.find(
                          (c) => c.color === requisition.engagementColor,
                        )?.label
                      }
                    </>
                  </span>
                </div>
              </center>
            ),
          },
        ]),
    {
      label: 'Date Posted',
      key: 'datePosted',
      sortEnabled: true,
      className: 'w-[9rem]',
      visible: true,
      render: (requisition) => {
        return (
          <div className='flex h-full items-center justify-between'>
            {formatDateString(requisition.datePosted)}
          </div>
        );
      },
    },
    {
      label: 'Status',
      key: 'status',
      sortEnabled: true,
      className: 'w-[8rem]',
      visible: true,
      render: (requisition) => {
        let warning;

        if (requisition.indeedSponsored === 2) {
          if ((requisition.numberOfCandidatesInInbox as number) >= 10) {
            warning = 'Removed from Indeed: 10 or more candidates in the inbox';
          } else if (getDateDifference(requisition.datePosted)) {
            warning = 'Removed from Indeed: date posted is older than 8 days';
          }
        }

        const getStatus = () =>
          status.find((s) => s.key === requisition.status)?.text;

        return (
          <div className='flex items-center'>
            <span>{getStatus()}</span>
            {requisition.status === 'Available' && (
              <>
                {(requisition.indeedSponsored === 1 ||
                  requisition.indeedSponsored === 2) && (
                  <span className='ml-2'>
                    <IndeedIcon />
                  </span>
                )}
                {requisition.indeedSponsored === 2 && !warning && (
                  <span className='mt-0.5'>
                    <DollarSignIcon />
                  </span>
                )}
                {warning && (
                  <Tooltip>
                    <TooltipTrigger>
                      <div className='ml-1 flex items-center justify-center rounded'>
                        <ErrorIcon size={16} color='#920E0E' />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent
                      className='z-666 pointer-events-none whitespace-nowrap rounded px-1 py-0.5  text-xs font-bold drop-shadow-lg'
                      style={{
                        backgroundColor: '#FCEAEA',
                        color: '#920E0E',
                      }}
                    >
                      {warning}
                    </TooltipContent>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        );
      },
    },
    {
      label: 'Pipeline',
      key: 'numberOfCandidatesInInbox',
      sortEnabled: true,
      className: 'w-[10.5rem]',
      visible: true,
      render: (requisition) => (
        <div
          className='group relative flex items-center overflow-visible pr-4 text-link hover:text-[#167dff] hover:underline'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            window.open(`/pipeline/${requisition.jobId}`);
          }}
        >
          <span
            className={`mr-2 flex h-5 w-5 items-center justify-center rounded-full ${requisition.numberOfCandidatesInInbox ? 'bg-error text-sm text-white' : 'bg-transparent text-black'} font-medium`}
          >
            {`${requisition.numberOfCandidatesInInbox}`}
          </span>
          <span className='text-nowrap '>{`Inbox, ${requisition?.totalCandidates} Total`}</span>
          <span className='absolute -right-2 hidden items-center group-hover:inline'>
            <ArrowUpRightIcon
              className='hidden group-hover:block'
              color={colors.primary.DEFAULT}
            />
          </span>
        </div>
      ),
    },
    {
      label: 'Job Title',
      key: 'jobTitle',
      sortEnabled: true,
      className: 'w-[22rem]',
      visible: true,
      render: (requisition, queryParamsString) => (
        <div
          className='text-link hover:text-[#167dff] hover:underline'
          onClick={(e) => {
            e.stopPropagation();
            navigate(
              `/requisitions/${requisition.jobId}?${queryParamsString ?? ''}`,
            );
          }}
        >
          {requisition.jobTitle}
        </div>
      ),
    },
    {
      label: 'Organization',
      key: 'organizationName',
      sortEnabled: true,
      className: 'w-[18rem]',
      visible: true,
      render: (requisition) => (
        <OrganizationName
          hasContract={!!requisition.orgHasContract}
          name={requisition.organizationName}
        />
      ),
    },
    {
      label: 'City/State',
      key: 'city',
      sortEnabled: true,
      className: 'w-[12rem]',
      visible: true,
      render: (requisition) => setRegion(requisition?.city, requisition?.state),
    },
    {
      label: 'User Assigned',
      key: 'userName',
      sortEnabled: false,
      className: 'w-[9rem]',
      visible: true,
      render: (requisition) => {
        const user =
          users?.find((u) => u.userId === requisition.userId)?.fullName ??
          requisition.userName;
        return setFieldIfEmpty(user);
      },
    },
    {
      label: 'Profession',
      key: 'profession',
      sortEnabled: false,
      className: 'w-[12rem]',
      visible: true,
      render: (requisition) => setFieldIfEmpty(requisition.profession),
    },
    {
      label: 'Salary',
      key: 'salaryBottom',
      sortEnabled: true,
      className: 'w-[12rem]',
      visible: true,
      render: (requisition) => {
        const sTop = USDollar.format(Number(requisition.salaryTop));
        const sBottom = USDollar.format(Number(requisition.salaryBottom));
        return `${sBottom} - ${sTop}`;
      },
    },
    {
      label: 'Last Activity Date',
      key: 'dateLastActivity',
      sortEnabled: true,
      className: 'w-[14rem]',
      visible: true,
      render: (requisition) => (
        <div className='flex items-center'>
          <div className='material-symbols-outlined mr-2'>
            {getActivityIcon(
              requisition?.lastActivityType || '',
              requisition?.lastActivityResult || '',
            )}
          </div>
          {setFieldIfEmpty(
            convertUTCToLocal(requisition?.dateLastActivity || ''),
          )}
        </div>
      ),
    },
  ]);

  return (
    <>
      <div className='z-10 flex w-full'>
        <DropdownMenu>
          <DropdownMenuTrigger className='m-2'>
            <Button variant='ghost'>
              <div className='flex items-center gap-1'>
                <ChevronDown size={16} /> Columns
              </div>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {columns.map((c: Column<APIRequisition>) => (
              <DropdownMenuCheckboxItem
                checked={c.visible}
                onCheckedChange={() => toggleColumnVisibility(c)}
                key={c.key}
              >
                {c.label}
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <BaseTable
        data={filteredData ? filteredData : requisitions}
        columns={columns}
        idKey='jobId'
        defaultSortKey='datePosted'
        handleRowClick={handleRowClick}
        className={className}
        checkboxProps={checkboxProps}
      />

      {!hideBulkActions && checkboxProps && (
        <ListBulkActions
          checkboxProps={checkboxProps}
          itemType={PageTypes.Requisitions}
        />
      )}
    </>
  );
};

export default RequisitionsTable;
