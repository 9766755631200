import React, { useCallback, useState } from 'react';

import { useGlobalState } from '../../context/GlobalContext';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { APIList } from '../../types/lists';
import { setFieldIfEmpty } from '../../utils/helpers';
import { useDebounce } from '../../utils/hooks';
import { CommonSpinner, Dropdown, EmptyState } from '../common';
import { Button } from '../common/Button';
import { SearchIcon } from '../common/Icons';
import Modal from '../common/ModalRadix';
import { Body, Header, HeaderCell, Row, RowCell, Table } from '../common/Table';
import { Input } from '../ui/input';

interface AddToListModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  refetchNotesData: () => void;
  listAPI: string;
}

interface SearchFilters {
  query?: string;
}

function AddToListModal({
  isOpen,
  onDismiss,
  refetchNotesData,
  listAPI,
}: AddToListModalProps) {
  const [searchFilters, setSearchFilters] = useState<SearchFilters>(
    {} as SearchFilters,
  );
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [listType, setListType] = useState<string | number | undefined>('all');
  const [search, setSearch] = useState('');

  const { data: response, isLoading } = useCustomQuery({
    url: '/lists',
    params: {
      ...searchFilters,
      template: listType && listType !== 'all' ? listType : null,
    },
  });

  const { data: lists } = response ?? {};

  const { mutate: saveNote, isPending } = useCustomMutation({
    method: 'post',
    onSuccess: () => {
      refetchNotesData();
      onDismiss();
      setSearch('');
      setSearchFilters({});
      setSelectedRows([]);
      setListType('all');
    },
    onSuccessMessage: 'List added successfully!',
  });
  const { users } = useGlobalState();

  async function handleSaveNote() {
    await saveNote({
      url: listAPI,
      body: selectedRows,
    });
  }

  const debounceSearch = useDebounce((value: string) => {
    const newParams = { ...searchFilters };

    if (!value) {
      delete newParams.query;
      setSearchFilters(newParams);
    } else if (value.length > 2) {
      newParams.query = value;
      setSearchFilters(newParams);
    }
  }, 500);

  const handleAllCheck = (e: any) => {
    const setSelectedRowsSafe = setSelectedRows || (() => {});
    if (e.target.checked) {
      setSelectedRowsSafe(lists?.map((list: any) => list?.listId));
    } else {
      setSelectedRowsSafe([]);
    }
  };

  const isRowSelected = useCallback(
    (key: string) => selectedRows?.includes(key),
    [selectedRows],
  );

  const handleSelectRow = useCallback(
    (key: string) => {
      if (setSelectedRows) {
        setSelectedRows((prevSelectedRows) => {
          if (prevSelectedRows.includes(key)) {
            return prevSelectedRows.filter((rowKey) => rowKey !== key);
          } else {
            return [...prevSelectedRows, key];
          }
        });
      }
    },
    [setSelectedRows],
  );

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;

    setSearch(value);
    debounceSearch(value);
  }

  return (
    <Modal
      open={isOpen}
      onOpenChange={() => {
        onDismiss();
        setSearch('');
        setSearchFilters({});
        setSelectedRows([]);
        setListType('all');
      }}
    >
      <Modal.Content title='Add to List' className='max-w-[900px] gap-6'>
        <div className='grid grid-cols-[1fr,270px] gap-5'>
          <div className='flex w-full flex-col items-start'>
            <label className='py-[5px]'>Search</label>
            <Input
              placeholder='Search'
              onChange={handleSearchChange}
              value={search}
            />
          </div>

          <Dropdown
            color='black'
            label='List Type'
            selectedKey={listType}
            options={[
              { key: 'all', text: 'All types' },
              { key: 'Recruiting', text: 'Recruiting' },
              { key: 'Marketing', text: 'Marketing' },
              { key: 'Candidates', text: 'Candidates' },
              { key: 'Contacts', text: 'Contacts' },
              { key: 'Organizations', text: 'Organizations' },
              { key: 'General', text: 'General' },
            ]}
            onChange={(_e, option) => setListType(option?.key)}
          />
        </div>

        {isLoading ? (
          <div className='flex size-full items-center justify-center'>
            <CommonSpinner size='sm' />
          </div>
        ) : lists?.length ? (
          <>
            <div className='max-h-96 overflow-auto'>
              <Table
                classNames={{ table: 'relative w-full', container: 'w-full' }}
              >
                <Header
                  classNames={{
                    thead:
                      'bg-neutral-2 sticky -top-[1px] shadow-sm z-10 border',
                  }}
                >
                  <HeaderCell className='opacity-100'>
                    <input type='checkbox' onChange={handleAllCheck} />
                  </HeaderCell>
                  <HeaderCell className='opacity-100'>List</HeaderCell>
                  <HeaderCell className='opacity-100'>Description</HeaderCell>
                  <HeaderCell className='opacity-100'>Date Created</HeaderCell>
                  <HeaderCell className='opacity-100'>User assigned</HeaderCell>
                </Header>
                <Body>
                  {lists?.map((list: APIList, key: number) => {
                    const user =
                      users?.find((u) => u.userId === list.userId)?.fullName ??
                      list.userName;
                    return (
                      <Row className='cursor-pointer' key={key}>
                        <RowCell>
                          <input
                            className='cursor-pointer'
                            type='checkbox'
                            checked={isRowSelected(list?.listId ?? '')}
                            onChange={() => handleSelectRow(list?.listId ?? '')}
                          />
                        </RowCell>

                        <RowCell>{setFieldIfEmpty(list.description)}</RowCell>
                        <RowCell>{setFieldIfEmpty(list.template)}</RowCell>

                        <RowCell>{setFieldIfEmpty(list.insertDate)}</RowCell>
                        <RowCell>{setFieldIfEmpty(user)}</RowCell>
                      </Row>
                    );
                  })}
                </Body>
              </Table>
            </div>
            <div className='mt-2 flex justify-between gap-4'>
              <Modal.Close>Cancel</Modal.Close>
              <Button
                isLoading={isPending}
                onClick={handleSaveNote}
                className={`primary font-bold`}
              >
                Add
              </Button>
            </div>
          </>
        ) : (
          <EmptyState
            icon={SearchIcon}
            iconSize={24}
            title='No results found'
            link={
              <div className='flex'>
                <span style={{ maxWidth: 300, textAlign: 'center' }}>
                  No results matched your search criteria. Try searching for
                  something else.
                </span>
              </div>
            }
          />
        )}
      </Modal.Content>
    </Modal>
  );
}

export default AddToListModal;
