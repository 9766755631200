import { XIcon } from 'lucide-react';
import React, { useState, useCallback } from 'react';

import { cn } from '../../lib/utils';

interface NumberFilterInputProps {
  value: number | null;
  onChange: (value: number | null) => void;
  classNames?: {
    input?: string;
    inactiveWithValue?: string;
    closeIcon?: string;
  };
  renderValue?: (value: number | null) => string;
  disabled?: boolean;
}

const NumberFilterInput: React.FC<NumberFilterInputProps> = ({
  value,
  onChange,
  classNames,
  renderValue,
  disabled,
}) => {
  const [showInput, setShowInput] = useState(false);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value === '' ? null : Number(e.target.value);
      onChange(newValue);
    },
    [onChange],
  );

  const handleClear = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onChange(null);
    },
    [onChange],
  );

  if (showInput) {
    return (
      <input
        type='number'
        disabled={disabled}
        value={value === null ? '' : value}
        onChange={handleInputChange}
        onBlur={() => setShowInput(false)}
        className={cn(
          `h-8 w-36 rounded-md border border-neutral-40 px-2 py-1 text-sm font-normal shadow-sm focus:border-neutral-60 focus:outline-none`,
          classNames?.input,
        )}
        min={0}
        autoFocus
      />
    );
  }

  return (
    <div
      onClick={() => {
        if (!disabled) {
          setShowInput(true);
        }
      }}
      className={cn(
        `flex h-8 w-36 cursor-text items-center justify-between rounded-md border px-2 py-1 text-sm font-medium shadow-sm transition-colors
        ${
          value !== null && value > 0
            ? `border-secondary-80 bg-secondary-80 text-white ${classNames?.inactiveWithValue}`
            : 'border-neutral-40 bg-white text-neutral-60 hover:border-neutral-60'
        }  ${disabled && 'cursor-not-allowed hover:border-neutral-40'}`,
        classNames?.input,
      )}
      role='button'
      tabIndex={0}
    >
      <span>{renderValue?.(value)}</span>
      {value !== null && value > 0 && (
        <button
          onClick={handleClear}
          className={cn('ml-2 mt-0.5 flex text-black', classNames?.closeIcon)}
        >
          <XIcon size={14} />
        </button>
      )}
    </div>
  );
};

export default NumberFilterInput;
