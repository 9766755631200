import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';

export const apiBase =
  (process.env.REACT_APP_BASE_URL ?? 'http://localhost') + '/v1';

type Response<Data = any> = {
  data: Data;
  page: number;
  offset: number;
  numberofrecords: number;
  pages: number;
};

export async function getFetcher({
  url,
  params,
  ignoreBaseUrl = false,
  withCredentials = true,
}: {
  url: string;
  params: object;
  ignoreBaseUrl?: boolean;
  withCredentials?: boolean;
}) {
  const fetchUrl = ignoreBaseUrl ? url : `${apiBase}${url}`;

  try {
    const response = await axios.get(fetchUrl, {
      params,
      paramsSerializer: { indexes: null },
      withCredentials,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const { pagenumber, offset, numberofrecords, numberofpages } =
      response.headers ?? {};

    return {
      data: response.data,
      page: pagenumber && Number(pagenumber),
      offset: offset && Number(offset),
      numberofrecords: numberofrecords && Number(numberofrecords),
      pages: numberofpages && Number(numberofpages),
    };
  } catch (error: any) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export function useCustomQuery<Data = any>({
  url,
  params = {},
  ignoreBaseUrl = false,
  enabled = true,
  withCredentials = true,
  options,
}: {
  url: string;
  params?: object;
  ignoreBaseUrl?: boolean;
  withCredentials?: boolean;
  enabled?: boolean;
  options?: Omit<
    UseQueryOptions<Response<Data>, Error, Response<Data>, QueryKey>,
    'queryKey' | 'queryFn'
  >;
}) {
  const response = useQuery<Response<Data>, Error, Response<Data>, QueryKey>({
    queryKey: [url, params],
    queryFn: () => getFetcher({ url, params, ignoreBaseUrl, withCredentials }),
    enabled,
    ...options,
  });

  return response;
}
