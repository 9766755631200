import {
  useMutation,
  MutationFunction,
  DefaultError,
} from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

import { useToast } from './use-toast';

export const apiBase =
  (process.env.REACT_APP_BASE_URL ?? 'http://localhost') + '/v1';

type MutationVariables<T> = {
  url: string;
  body?: T;
};

export const createMutationFn =
  <TData = any, TVariables = any>(method: string = 'post') =>
  async ({
    url,
    body,
  }: MutationVariables<TVariables>): Promise<AxiosResponse<TData>> => {
    switch (method) {
      case 'post':
        return await axios.post<TData>(`${apiBase}${url}`, body, {
          withCredentials: true,
        });
      case 'patch':
        return await axios.patch<TData>(`${apiBase}${url}`, body, {
          withCredentials: true,
        });
      case 'put':
        return await axios.put<TData>(`${apiBase}${url}`, body, {
          withCredentials: true,
        });
      case 'delete':
        return await axios.delete<TData>(`${apiBase}${url}`, {
          withCredentials: true,
        });
      default:
        return await axios.post<TData>(`${apiBase}${url}`, body, {
          withCredentials: true,
        });
    }
  };

export function useCustomMutation<TData = any, TVariables = any>({
  method,
  onSuccess,
  onMutate,
  onError,
  onSettled,
  showErrorToast = true,
  onSuccessMessage,
}: {
  method?: 'post' | 'patch' | 'put' | 'delete';
  onSuccess?: (
    response: AxiosResponse<TData, any>,
    variables: MutationVariables<TVariables>,
    context: any,
  ) => void;
  onMutate?: (variables: MutationVariables<TVariables>) => void;
  onError?: (
    error: DefaultError,
    variables: MutationVariables<TVariables>,
    context: any,
  ) => void;
  showErrorToast?: boolean;
  onSettled?: (
    data: AxiosResponse<TData, any> | undefined,
    error: DefaultError | null,
    variables: MutationVariables<TVariables>,
    context: any,
  ) => void;
  onSuccessMessage?: string;
}) {
  const { toast } = useToast();

  const navigate = useNavigate();
  const mutationFn: MutationFunction<
    AxiosResponse<TData>,
    MutationVariables<TVariables>
  > = createMutationFn<TData, TVariables>(method);

  const mutation = useMutation<
    AxiosResponse<TData>,
    DefaultError,
    MutationVariables<TVariables>
  >({
    mutationFn,
    ...(onMutate ? { onMutate } : {}),
    ...(onSettled ? { onSettled } : {}),
    onSuccess(response, variables, context) {
      toast({
        description: onSuccessMessage ?? 'Data saved successfully',
      });
      onSuccess?.(response, variables, context);
    },
    onError(error, variables, context) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          if (window.location.pathname === '/login') {
            toast({
              title: 'Error!',
              description: 'Invalid email or password',
              variant: 'destructive',
            });
          } else {
            navigate(`/login?redirectUrl=${window.location.pathname}`);
          }
        } else {
          showErrorToast &&
            toast({
              title: 'Error!',
              description: 'Error saving data. Please try again',
              variant: 'destructive',
            });
          if (onError) onError(error, variables, context);
        }
      }
    },
  });

  return mutation;
}
