import { Upload } from 'lucide-react';
import { useRef, useState } from 'react';

import { theme } from '../../utils/theme';

interface FileUploadProps {
  handleFile: (file: File | null, byteArray: any) => void;
  acceptDocumentOnly?: boolean;
  boxModalClassName?: string;
}

interface FileDetails {
  fileName: string;
  fileSize: string;
  mimeType: string;
}

function FileUpload({
  handleFile,
  acceptDocumentOnly = false,
  boxModalClassName = '',
}: FileUploadProps) {
  const [fileDetails, setFileDetails] = useState<FileDetails | null>(null);
  const [docTypeError, setDocTypeError] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (files: any) => {
    const file = files[0];

    const acceptedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    if (acceptDocumentOnly && !acceptedTypes.includes(file.type)) {
      setDocTypeError('Please attach the file in a valid format.');
      return;
    } else {
      setDocTypeError('');
    }

    const reader = new FileReader();
    reader.onload = (event: any) => {
      const arrayBuffer = event.target.result;
      const byteArray = new Uint8Array(arrayBuffer);
      handleFile(file, byteArray);
      setFileDetails({
        fileName: file.name,
        fileSize: file.size,
        mimeType: file.type,
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const handleDrop = (event: {
    preventDefault: () => void;
    stopPropagation: () => void;
    dataTransfer: { files: any };
  }) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;
    handleFileUpload(files);
  };

  const handleDragOver = (event: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div
      className={`mx-auto mb-10 mt-2 max-w-md rounded-lg border p-4 shadow-lg ${boxModalClassName}`}
      style={docTypeError ? { borderColor: theme.danger } : {}}
    >
      <div
        className='cursor-pointer rounded-md border-2 border-dashed p-4'
        onDrop={handleDrop}
        style={docTypeError ? { borderColor: theme.danger } : {}}
        onDragOver={handleDragOver}
        onClick={() =>
          document && document?.getElementById('fileInput')?.click()
        }
      >
        <div className='mb-4 flex justify-center'>
          <Upload color={theme.blue} size={48} />
        </div>
        <input
          id='fileInput'
          type='file'
          ref={fileInputRef}
          className='hidden'
          multiple={false}
          onChange={(e) => handleFileUpload(e.target.files)}
        />
        <div className='text-center'>
          {docTypeError && (
            <p style={docTypeError ? { color: theme.danger } : {}}>
              {docTypeError}
            </p>
          )}
          <p
            className='text-gray-500'
            style={docTypeError ? { color: theme.danger } : {}}
          >
            Drag & drop files here, or click to select files
          </p>
        </div>
      </div>
      {fileDetails && (
        <div className='mt-4 rounded-md border border-gray-200 bg-gray-50 p-4'>
          <p>
            <strong>Name:</strong> {fileDetails?.fileName}
          </p>
          <p>
            <strong>Size:</strong> {fileDetails?.fileSize} bytes
          </p>
          <p>
            <strong>Type:</strong> {fileDetails?.mimeType}
          </p>
        </div>
      )}
      {fileDetails && (
        <div
          onClick={() => {
            setFileDetails(null);
            handleFile(null, '');
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          }}
          className={`mt-4 cursor-pointer text-right font-bold text-primary-70`}
        >
          Clear
        </div>
      )}
    </div>
  );
}

export default FileUpload;
