import RootLayout from '../../../layouts';
import { Productivity } from './Productivity';

const ProductivityPage = () => {
  return (
    <RootLayout>
      <Productivity />
    </RootLayout>
  );
};

export default ProductivityPage;
