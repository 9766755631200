import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActivityView } from '../../components/common/ActivityView';
import { AttachmentsTable } from '../../components/common/AttachmentsPivot';
import ConversationView from '../../components/common/ConversationView';
import GoogleMapsView from '../../components/common/GoogleMapsView';
import NotesView from '../../components/common/NotesView';
import { ResumeView } from '../../components/common/ResumeView';
import { ListsDetail } from '../../components/common/listsDetail';
import { useQueryParams } from '../../hooks/useQueryParams';
import { APIContact } from '../../types/contacts';
import { TabsParams } from '../../utils/tabs';
import { ContactDetails } from './ContactDetails';
import { Pipelines } from './Pipelines';

export function ContactTabs({
  contact,
  onContactSave,
  tabId,
  panelBaseRoute,
}: {
  contact: APIContact;
  onContactSave?: () => void;
  tabId: string | undefined;
  panelBaseRoute?: string;
}) {
  const navigate = useNavigate();
  const { queryParamsString } = useQueryParams();
  const contactTabs = useContactTabs({ contact, onContactSave });
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [activeTabId, setActiveTabId] = useState(tabId ?? contactTabs[0].id);

  const activePanel = contactTabs.find((tab) => tab.id === activeTabId);

  const handleTabClick = (tabId: string) => {
    setActiveTabId(tabId);

    if (panelBaseRoute) {
      if (tabId === contactTabs[0].id) {
        navigate(
          `${panelBaseRoute}/${contact.contactId}${queryParamsString ? `?${queryParamsString}` : ''}`,
          { replace: true },
        );
      } else {
        navigate(
          `${panelBaseRoute}/${contact.contactId}/${tabId}${queryParamsString ? `?${queryParamsString}` : ''}`,
          { replace: true },
        );
      }
    }
  };

  useEffect(() => {
    if (!containerRef.current) return;

    containerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [activeTabId]);

  return (
    <div className='grid size-full grid-cols-[1fr] grid-rows-[auto,1fr] justify-center overflow-hidden border-neutral-40'>
      <div
        role='tablist'
        className='flex w-full justify-center gap-6 overflow-x-auto pb-2'
      >
        {contactTabs.map((tab) => {
          const isActive = tab.id === activeTabId;

          return (
            <button
              key={tab.id}
              role='tab'
              id={tab.id}
              aria-selected={isActive}
              className='relative h-fit w-fit shrink-0'
              onClick={() => handleTabClick(tab.id)}
            >
              <span
                className={`${isActive ? 'after:absolute after:-bottom-[8px] after:block after:h-[3px] after:w-0 after:animate-expand-link after:bg-dark-blue' : ''}`}
              >
                {tab.tabName}
              </span>
            </button>
          );
        })}
      </div>
      <div
        role='tabpanel'
        className='overflow-auto border-t border-neutral-40 pt-4'
        aria-labelledby={activeTabId}
        ref={containerRef}
      >
        {activePanel?.component}
      </div>
    </div>
  );
}

function useContactTabs({
  contact,
  onContactSave,
}: {
  contact: APIContact;
  onContactSave?: () => void;
}) {
  return [
    {
      tabName: 'Details',
      component: (
        <ContactDetails contact={contact} onContactSave={onContactSave} />
      ),
      id: 'details',
    },
    {
      tabName: 'Resume',
      component: (
        <ResumeView
          tableId={contact.contactId}
          tableName={'contacts'}
          mimeType={contact.mimeType}
          url={contact.presignedUrl}
        />
      ),
      id: 'resume',
    },
    {
      tabName: 'Activities',
      component: (
        <ActivityView
          url='/contactactivities'
          params={{ contactId: contact.contactId }}
          paginationKey={TabsParams.ActivitiesTab}
        />
      ),
      id: 'activities',
    },
    {
      tabName: 'Conversation',
      component: (
        <ConversationView
          url={`/contacts/${contact.contactId}/comms`}
          typeKey={TabsParams.ConversationsTabType}
          paginationKey={TabsParams.ConversationsTabPage}
        />
      ),
      id: 'conversation',
    },
    {
      tabName: 'Notes',
      component: (
        <NotesView entityId={contact.contactId} entityName='contacts' />
      ),
      id: 'notes',
    },
    {
      tabName: 'Attachments',
      component: (
        <AttachmentsTable
          tableId={contact.contactId}
          tableName={'contacts'}
          url={`/attachments/${contact.contactId}`}
        />
      ),
      id: 'attachments',
    },
    {
      tabName: 'Pipelines',
      component: <Pipelines contact={contact} />,
      id: 'pipelines',
    },
    {
      tabName: 'Lists',
      component: <ListsDetail page='contacts' listId={contact?.contactId} />,
      id: 'lists',
    },
    {
      tabName: 'Map',
      component: (
        <GoogleMapsView
          primaryPinKey='contacts'
          url={'/contacts/' + contact.contactId + '/map'}
        />
      ),
      id: 'map',
    },
  ];
}
