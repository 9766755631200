import { format } from 'date-fns';
import { parseISO } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { CalendarIcon } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';

import { Button } from '../../components/common/Button';
import Modal from '../../components/common/ModalRadix';
import Select from '../../components/common/SelectNew';
import { Calendar } from '../../components/ui/calendar';
import { inputClass } from '../../components/ui/input';
import { Label } from '../../components/ui/label';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '../../components/ui/popover';
import { Textarea } from '../../components/ui/textarea';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { cn } from '../../lib/utils';
import { APIJobContactPipelinePosition } from '../../types/pipeline';
import { generateTimeOptions } from '../../utils/helpers';

export const EditableDateTimePicker = ({
  editInterviewDate,
  handleClose,
  pipelineRecord,
  refetchPipelineData,
}: {
  pipelineRecord: APIJobContactPipelinePosition;
  editInterviewDate: {
    date: string | null;
    type: 'virtualInterviewDate' | 'inPersonInterviewDate';
  };
  handleClose: () => void;
  refetchPipelineData: () => void;
}) => {
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [note, setNote] = useState('');
  const [time, setTime] = useState('');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  useEffect(() => {
    if (!editInterviewDate.date) return;

    const parsedDate = parseISO(editInterviewDate.date);
    setDate(parsedDate);
    setTime(format(parsedDate, 'HH:mm'));
  }, [editInterviewDate]);

  const timeOptions = useMemo(() => {
    return generateTimeOptions();
  }, []);

  const { mutate: updateCandidatePosition, isPending } = useCustomMutation({
    method: 'patch',
    onSuccess: () => {
      refetchPipelineData();
      handleClose();
    },
    onSuccessMessage: 'Interview date updated!',
  });

  function handleSubmit() {
    const updatedInfo: any = {};

    if (note) {
      updatedInfo.activityNote = note;
    }

    if (date && time) {
      const dateTimeString = `${date.toISOString().split('T')[0]}T${time}:00`;
      const utcDate = fromZonedTime(dateTimeString, 'America/Chicago');
      const formattedDate = format(utcDate, "yyyy-MM-dd'T'HH:mm:ssXXX");

      if (editInterviewDate.type === 'virtualInterviewDate') {
        updatedInfo.virtualInterviewDate = formattedDate;
      } else {
        updatedInfo.inPersonInterviewDate = formattedDate;
      }
    }

    updateCandidatePosition({
      url: `/jobcontactpipelinepositions/${pipelineRecord.jobContactPipelinePositionId}`,
      body: { ...pipelineRecord, ...updatedInfo },
    });
  }

  return (
    <Modal open onOpenChange={handleClose}>
      <Modal.Content
        title={`Edit: ${editInterviewDate.type === 'virtualInterviewDate' ? 'Phone/Virtual' : 'In-Person'} Interview Date`}
      >
        <div className='space-y-8'>
          <div className='space-y-4'>
            <div className='flex items-center gap-2'>
              <div className='flex flex-col gap-6'>
                <Label>
                  Select date <span className='text-error'>*</span>
                </Label>
                <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
                  <PopoverTrigger asChild>
                    <div
                      className={cn(
                        inputClass,
                        'flex min-w-48 cursor-pointer items-center',
                      )}
                    >
                      {date ? (
                        format(date, 'PPP')
                      ) : (
                        <span className=' text-neutral'>Pick a date</span>
                      )}
                      <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                    </div>
                  </PopoverTrigger>
                  <PopoverContent className='bg-white'>
                    <Calendar
                      mode='single'
                      selected={date}
                      onSelect={(date) => {
                        setDate(date);
                        setIsCalendarOpen(false);
                      }}
                    />
                  </PopoverContent>
                </Popover>
              </div>
              <Select
                label='Time of interview (CST)'
                options={timeOptions}
                value={time}
                onChange={setTime}
                classNames={{ label: 'text-sm leading-none mt-2' }}
              />
            </div>
            <div>
              <Label className='font-medium'>
                Note <span className='text-error'>*</span>
              </Label>
              <Textarea
                placeholder='Add a note about why their interview date is being changed...'
                value={note}
                onChange={(e) => setNote(e.target.value)}
                className='placeholder:text-neutral'
              />
            </div>
          </div>
          <div className='flex justify-between'>
            <Button variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={!date || !time || isPending || !note}
              isLoading={isPending}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default EditableDateTimePicker;
