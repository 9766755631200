import { useState, useCallback, useEffect } from 'react';

import { useDebounce } from '../utils/hooks';
import { useQueryParams } from './useQueryParams';

interface UseDebouncedQueryParamOptions {
  key: string;
  initialValue?: string;
  debounceTime?: number;
  minLength?: number;
}

export function useDebouncedQueryParam({
  key,
  initialValue = '',
  debounceTime = 500,
  minLength = 0,
}: UseDebouncedQueryParamOptions) {
  const { queryParams, updateQueryParam, removeQueryParam } = useQueryParams();
  const [inputValue, setInputValue] = useState(
    queryParams[key] || initialValue,
  );

  const debouncedSetQueryParam = useDebounce((value: string) => {
    if (value.length >= minLength) {
      updateQueryParam(key, value, { resetPageOnChange: true });
    } else {
      removeQueryParam(key);
    }
  }, debounceTime);

  useEffect(() => {
    setInputValue(queryParams[key] || '');
  }, [queryParams, key]);

  const handleInputChange = useCallback(
    (value: string) => {
      setInputValue(value);
      debouncedSetQueryParam(value);
    },
    [debouncedSetQueryParam],
  );

  return [inputValue, handleInputChange] as const;
}
