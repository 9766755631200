import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Link, useBlocker } from 'react-router-dom';

import CurrencyInput from '../../../components/common/CurrencyInput';
import {
  Table,
  Header,
  HeaderCell,
  Body,
  Row,
  RowCell,
} from '../../../components/common/Table';
import { useCustomMutation } from '../../../hooks/useCustomMutation';
import { APIUser } from '../../../types/users';
import { formatToUSPhoneNumber } from '../../../utils/helpers';

interface IUsersTable {
  data: APIUser[];
  hasFilters?: boolean;
  showEarningsCells?: boolean;
  isEditing: boolean;
  isSaving: boolean;
  isCancelling: boolean;
  setIsSaving: any;
  setIsCancelling: any;
}

interface IBillingDataDict {
  [uuid: string]: {
    accountsReceivable?: number | string;
    billingsMonth?: number | string;
    billingsYear?: number | string;
    cashIn?: number | string;
  };
}

const formatNumber = (val: number) =>
  `${val}`.replace(/\d(?=(?:\d{3})+$)/g, '$&,');

const setDefaultValue = (val?: number | null) =>
  val ? formatNumber(val) : undefined;

export const UsersTable = ({
  data,
  hasFilters,
  showEarningsCells,
  isEditing,
  isSaving,
  setIsSaving,
  isCancelling,
  setIsCancelling,
}: IUsersTable) => {
  const [currentCell, setCurrentCell] = useState(0);
  const [currentRow, setCurrentRow] = useState(0);
  const [billingData, setBillingData] = useState<IBillingDataDict>({});
  const tableRef = useRef<any>();

  const { mutate: submitChanges } = useCustomMutation({
    method: 'patch',
    onSuccess: () => {},
  });

  const maxCellLength = 3;
  const inputCellOffset = 2;

  // Only block navigation if cells visible + isEditing.
  useBlocker(({ currentLocation, nextLocation }) => {
    if (!showEarningsCells) return false;

    if (nextLocation !== currentLocation && isEditing) {
      return !window.confirm('Unsaved changes, proceed?') as any; // Should return bool??
    }

    return false;
  });

  useEffect(() => {
    // Drills down into the input element.
    // Table > Row > Cell > NavigationInput Component > Input
    tableRef?.current?.children[currentRow]?.children?.[
      currentCell + inputCellOffset
    ]?.children[0]?.children[1].select();
  }, [currentCell, currentRow]);

  useEffect(() => {
    const saveChanges = async () => {
      try {
        const usersToUpdate = Object.keys(billingData).map((key: string) => {
          const user = billingData[key];
          return submitChanges({
            url: `/admin/users/${key}`,
            body: {
              ...user,
            },
          });
        });

        await Promise.all(usersToUpdate);

        setIsSaving(false);
        setBillingData({});
      } catch (error) {
        console.error(error);
        setIsSaving(false);
      }
    };

    if (isSaving) {
      saveChanges();
    }
  }, [isSaving, setIsSaving, billingData, submitChanges, setBillingData]);

  useEffect(() => {
    if (isCancelling) {
      // Need to iterate over each part of the table, reset to defaultValue.
      data.forEach((row, i) => {
        if (row?.userId && billingData[row?.userId]) {
          for (let j = 0; j < 4; j++) {
            const currentInput = tableRef?.current?.children[i]?.children?.[
              j + inputCellOffset
            ]?.children[0]?.children[1] as HTMLInputElement;
            currentInput.value = currentInput.defaultValue;
          }
        }
      });
      setIsCancelling(false);
      setBillingData({});
    }
  }, [
    isCancelling,
    setIsCancelling,
    data,
    inputCellOffset,
    billingData,
    tableRef,
    setBillingData,
  ]);

  const tableBody = useMemo(() => {
    const handleChange = (e: any, userId: string, key: string) => {
      setBillingData((prev: any) => {
        if (!prev[userId]) {
          prev[userId] = {};
        }

        // Sanitize the value being sent to API.
        const saveValue = e?.target?.value.replace(/,/g, '');
        prev[userId][key] = saveValue !== '' ? saveValue : 0;
        return prev;
      });
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      // This isn't comprehensive enough, need to prevent 0 on first input.
      if (e?.key === '0' && !(e?.target as HTMLInputElement)?.value) {
        e.preventDefault();
        return;
      }

      if (
        e?.key !== 'Tab' &&
        e?.key !== 'ArrowRight' &&
        e?.key !== 'ArrowLeft' &&
        e?.key !== 'ArrowUp' &&
        e?.key !== 'ArrowDown'
      ) {
        return;
      }

      e.preventDefault();

      if (e?.key === 'Tab') {
        setCurrentCell((prev) => (prev + 1 > maxCellLength ? 0 : prev + 1));
        if (currentCell + 1 > maxCellLength) {
          const nextRowIndex =
            currentRow + 1 > data?.length - 1 ? 0 : currentRow + 1;
          setCurrentRow(nextRowIndex);
        }
        return;
      } else if (e?.key === 'ArrowRight') {
        setCurrentCell((prev) => (prev + 1 > maxCellLength ? 0 : prev + 1));
      } else if (e?.key === 'ArrowLeft') {
        setCurrentCell((prev) => (prev - 1 < 0 ? maxCellLength : prev - 1));
      } else if (e?.key === 'ArrowUp') {
        const nextRowIndex =
          currentRow - 1 < 0 ? data?.length - 1 : currentRow - 1;
        setCurrentRow(nextRowIndex);
      } else if (e?.key === 'ArrowDown') {
        const nextRowIndex =
          currentRow + 1 > data?.length - 1 ? 0 : currentRow + 1;
        setCurrentRow(nextRowIndex);
      }
    };

    return data?.map((row, key) => {
      return (
        <Row
          key={key}
          tabIndex={0}
          className={`cursor-default ${!row?.active && 'bg-neutral-20'}`}
        >
          <RowCell scope='row' style={{ borderLeftWidth: 0 }}>
            <span>{row.fullName}</span>
          </RowCell>
          <RowCell>{row.emailAddress}</RowCell>
          {showEarningsCells && (
            <>
              <RowCell>
                <CurrencyInput
                  disabled={!isEditing || hasFilters}
                  className='text-right'
                  onKeyDownCapture={(e) => handleKeyDown(e)}
                  onFocus={() => {
                    setCurrentCell(0);
                    setCurrentRow(key);
                  }}
                  defaultValue={setDefaultValue(row?.billingsMonth)}
                  onChange={(e: any) =>
                    handleChange(e, row.userId as string, 'billingsMonth')
                  }
                />
              </RowCell>
              <RowCell>
                <CurrencyInput
                  disabled={!isEditing || hasFilters}
                  className='text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
                  onKeyDownCapture={(e) => handleKeyDown(e)}
                  onFocus={() => {
                    setCurrentCell(1);
                    setCurrentRow(key);
                  }}
                  defaultValue={setDefaultValue(row?.billingsYear)}
                  onChange={(e: any) => {
                    handleChange(e, row.userId as string, 'billingsYear');
                  }}
                />
              </RowCell>
              <RowCell>
                <CurrencyInput
                  disabled={!isEditing || hasFilters}
                  className='text-right'
                  onKeyDownCapture={(e) => handleKeyDown(e)}
                  onFocus={() => {
                    setCurrentCell(2);
                    setCurrentRow(key);
                  }}
                  defaultValue={setDefaultValue(row?.accountsReceivable)}
                  onChange={(e: any) =>
                    handleChange(e, row.userId as string, 'accountsReceivable')
                  }
                />
              </RowCell>
              <RowCell>
                <CurrencyInput
                  disabled={!isEditing || hasFilters}
                  className='text-right'
                  onKeyDownCapture={(e) => handleKeyDown(e)}
                  onFocus={() => {
                    setCurrentCell(3);
                    setCurrentRow(key);
                  }}
                  defaultValue={setDefaultValue(row?.cashIn)}
                  onChange={(e: any) =>
                    handleChange(e, row.userId as string, 'cashIn')
                  }
                />
              </RowCell>
            </>
          )}
          <RowCell>
            {row.phone ? formatToUSPhoneNumber(row.phone) : '-'}
          </RowCell>
          <RowCell style={{ borderRightWidth: 0 }}>{row.extension}</RowCell>
          <RowCell>
            {row.directDial1 ? formatToUSPhoneNumber(row.directDial1) : '-'}
          </RowCell>
          <RowCell style={{ borderRightWidth: 0 }}>
            {row.admin ? 'Admin' : 'User'}
          </RowCell>
          <RowCell style={{ borderRightWidth: 0 }}>
            {row.active ? 'Active' : 'Inactive'}
          </RowCell>
          <StatesRow row={row} />
          <RowCell>
            <Link
              to={`/admin/users/${row?.userId}/edit`}
              className='flex items-center gap-2 self-start bg-white px-2 py-1 font-bold text-primary'
            >
              <span>Edit</span>
            </Link>
          </RowCell>
        </Row>
      );
    });
  }, [
    data,
    currentCell,
    currentRow,
    maxCellLength,
    setCurrentRow,
    setCurrentCell,
    setBillingData,
    hasFilters,
    showEarningsCells,
    isEditing,
  ]);

  return (
    <>
      <Table
        classNames={{ table: 'relative table-fixed', container: 'size-full' }}
      >
        <Header
          classNames={{
            thead: 'sticky top-0 shadow-sm z-20',
          }}
        >
          <HeaderCell className='w-[12rem]'>Name</HeaderCell>
          <HeaderCell className='w-[18rem]'>Email</HeaderCell>
          {showEarningsCells && (
            <>
              <HeaderCell className='w-[7rem]'>Month</HeaderCell>
              <HeaderCell className='w-[7rem]'>Year</HeaderCell>
              <HeaderCell className='w-[7rem]'>A.R.</HeaderCell>
              <HeaderCell className='w-[7rem]'>Cash-In</HeaderCell>
            </>
          )}
          <HeaderCell className='w-[10rem]'>Company Phone</HeaderCell>
          <HeaderCell className='w-[10rem]'>Ext.</HeaderCell>
          <HeaderCell className='w-[10rem]'>Direct Dial</HeaderCell>
          <HeaderCell className='w-[10rem]'>Role</HeaderCell>
          <HeaderCell className='w-[10rem]'>Status</HeaderCell>
          <HeaderCell className='w-[30rem]'>Access by States</HeaderCell>
          <HeaderCell className='w-[6rem]'>Actions</HeaderCell>
        </Header>
        <Body ref={tableRef}>{tableBody}</Body>
      </Table>
    </>
  );
};

const StatesRow = ({ row }: { row: any }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <RowCell
      key={isExpanded + row?.userId}
      className={
        isExpanded
          ? `overflow-visible whitespace-normal text-wrap break-words ${!(row.admin || !row.assignedStates) && 'cursor-pointer'}`
          : `truncate ${!(row.admin || !row.assignedStates) && 'cursor-pointer'}`
      }
      style={{ borderRightWidth: 0 }}
      onClick={() => {
        setIsExpanded(!isExpanded);
      }}
    >
      {row.admin || !row.assignedStates ? '(all)' : row.assignedStates}
    </RowCell>
  );
};
