import { useState } from 'react';

import { CommonSpinner, Stack } from '.';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { APIAttachments } from '../../react-app-env';
import { formatDateString } from '../../utils/helpers';
import AddAttachmentsModal from '../modals/AddAttachmentsModal';
import { AddIcon } from './Icons';
import { Body, Header, HeaderCell, Row, RowCell, Table } from './Table';

export function AttachmentsTable({
  url = '',
  tableId = '',
  tableName = '',
}: {
  url?: string;
  collapse?: boolean;
  attachments?: APIAttachments[];
  loading?: boolean;
  tableId: string;
  tableName: string;
}) {
  const [isAddAttachmentsModalOpen, setIsAddAttachmentsModalOpen] =
    useState(false);

  const {
    data,
    isLoading,
    refetch: refetchAttachments,
  } = useCustomQuery<APIAttachments[]>({
    url,
  });
  const attachmentsData = data?.data;

  return (
    <>
      {isLoading && (
        <div className='flex size-full items-center justify-center gap-4'>
          <CommonSpinner size='md' />{' '}
        </div>
      )}

      {!isLoading && (
        <div className='flex flex-col'>
          <div className='flex justify-end border-b pb-4'>
            <button
              onClick={() => setIsAddAttachmentsModalOpen(true)}
              className='flex items-center gap-2 rounded border px-2 py-1 hover:bg-gray-100'
            >
              <AddIcon size={16} />
              <span>Add</span>
            </button>
          </div>
          <div>
            {attachmentsData?.length ? (
              <Table id='attachments-table' classNames={{ table: 'table' }}>
                <Header>
                  <HeaderCell>Description</HeaderCell>
                  <HeaderCell>File Name</HeaderCell>
                  <HeaderCell>Date</HeaderCell>
                </Header>
                <Body>
                  {attachmentsData?.map((a, key) => {
                    const googleDocURL = `https://docs.google.com/viewer?url=${encodeURIComponent(a.presignedUrl)}`;

                    return (
                      <Row
                        onClick={() => {
                          window.open(
                            googleDocURL,
                            '_blank',
                            'noopener,noreferrer',
                          );
                        }}
                        className='cursor-pointer'
                        key={key}
                      >
                        <RowCell scope='row' style={{ borderLeftWidth: 0 }}>
                          <Stack horizontal verticalAlign='center' spacing={8}>
                            {/* <input type="checkbox" /> */}

                            {a.description}
                          </Stack>
                        </RowCell>
                        <RowCell scope='row' style={{ borderLeftWidth: 0 }}>
                          {a.fileName}
                        </RowCell>
                        <RowCell scope='row' style={{ borderLeftWidth: 0 }}>
                          {formatDateString(a.insertDate)}
                        </RowCell>
                      </Row>
                    );
                  })}
                </Body>
              </Table>
            ) : (
              <div className='w-full pt-4 text-center text-xl'>
                No attachments found
              </div>
            )}
          </div>
        </div>
      )}

      {isAddAttachmentsModalOpen && (
        <AddAttachmentsModal
          isOpen={isAddAttachmentsModalOpen}
          onDismiss={() => setIsAddAttachmentsModalOpen(false)}
          tableId={tableId}
          tableName={tableName}
          refetchAttachments={refetchAttachments}
        />
      )}
    </>
  );
}
