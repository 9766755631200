import { CaretDownIcon, CheckIcon } from '@radix-ui/react-icons';
import { CommandLoading } from 'cmdk';
import { XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';

import { CommonSpinner } from '.';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../../components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/ui/popover';
import { cn } from '../../lib/utils';

type Item = {
  id: string;
  title: string;
  disabled?: boolean;
};

// TODO:
// Clean up props. Right now we're typecasting when using this as a multi-select. The value being passed in should always be an array.
export function MultiSelectDropdownFilter({
  handleChange,
  placeholder,
  items,
  disabled,
  value,
  classNames,
  isChecked,
  setSearchValue,
  isLoading,
  directValue,
  isClearable,
  onClear,
  isMultiSelect = false,
  customOption: CustomOption,
  customValueText,
}: {
  disabled?: boolean;
  placeholder?: string;
  handleChange: (value: string | string[], arg1?: string) => void;
  value: string[];
  items: Item[];
  isChecked?: boolean;
  setSearchValue?: (value: string) => void;
  isLoading?: boolean;
  directValue?: string;
  isClearable?: boolean;
  onClear?: () => void;
  isMultiSelect?: boolean;
  customValueText?: string;
  customOption?: (props: any) => JSX.Element;
  classNames?: {
    container?: string;
    label?: string;
    trigger?: string;
    value?: string;
    content?: string;
    item?: string;
  };
}) {
  const [open, setOpen] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>(
    isMultiSelect ? value || [] : [],
  );

  const selectedValue =
    selectedKeys?.length > 0
      ? items?.find((item: { id: any }) => item?.id === selectedKeys[0])
      : null;

  const valueLengthText =
    selectedKeys?.length > 1 ? ` +${selectedKeys.length - 1}` : '';

  useEffect(() => {
    // workaround for the typecasting
    const arrayVal = Array.isArray(value) ? value : [value];

    setSelectedKeys(arrayVal);
  }, [value]);

  return (
    <>
      <div
        className={cn(
          'flex w-full max-w-full flex-col gap-2',
          classNames?.container ?? '',
        )}
      >
        <div>
          <Popover open={open} onOpenChange={!disabled ? setOpen : () => {}}>
            <PopoverTrigger disabled={disabled} asChild>
              <div
                aria-expanded={open}
                aria-disabled={disabled}
                className={cn(
                  `${open && 'border-sky-500 ring-sky-500'} flex h-8 w-full max-w-full cursor-pointer items-center justify-between rounded-[6px] border border-neutral-40 bg-white px-2 py-1 text-left text-sm font-medium text-secondary-80 shadow-sm hover:border-neutral-60 ${isChecked && 'bg-secondary-80 text-white'}`,
                  classNames?.trigger,
                )}
              >
                <span className='block truncate'>
                  {CustomOption && selectedValue?.title ? (
                    <CustomOption {...selectedValue} />
                  ) : (
                    directValue ||
                    (selectedValue?.title ? (
                      selectedValue.title + (customValueText || valueLengthText)
                    ) : (
                      <span className='text-neutral-60'>
                        {placeholder || 'Select...'}
                      </span>
                    ))
                  )}
                </span>
                <span className='flex items-center'>
                  {isClearable && (
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        onClear && onClear();
                      }}
                      className='ml-2 flex'
                    >
                      <XIcon size={14} className='mt-0.5' />
                    </span>
                  )}
                  <CaretDownIcon
                    className={`h-6 w-6 shrink-0 opacity-40 ${isClearable ? 'ml-0' : 'ml-2'}`}
                  />
                </span>
              </div>
            </PopoverTrigger>
            <PopoverContent
              align='start'
              className='popover-content-width-full w-full bg-white p-0'
            >
              <Command
                filter={(value, search) => {
                  if (setSearchValue) {
                    return 1;
                  } else {
                    const valueLower = value.toLowerCase();
                    const searchLower = search.toLowerCase();
                    if (valueLower.includes(searchLower)) return 1;
                    return 0;
                  }
                }}
              >
                {setSearchValue ? (
                  <CommandInput
                    onValueChange={setSearchValue}
                    placeholder={placeholder}
                    className='h-9'
                  />
                ) : (
                  <CommandInput placeholder={placeholder} className='h-9' />
                )}
                <CommandList>
                  {isLoading ? (
                    <CommandLoading>
                      <div className='flex size-full items-center justify-center gap-1 p-2 text-sm'>
                        <CommonSpinner />
                      </div>
                    </CommandLoading>
                  ) : (
                    <CommandEmpty>No options</CommandEmpty>
                  )}
                  <CommandGroup>
                    {items?.map((data: Item) => {
                      const isActive = (
                        isMultiSelect ? selectedKeys : value
                      )?.includes(data?.id);

                      return (
                        <CommandItem
                          key={data.id}
                          value={data?.title}
                          disabled={data?.disabled}
                          onSelect={() => {
                            if (isMultiSelect) {
                              const valueCopy = Array.isArray(value)
                                ? [...value]
                                : [value];

                              const newValues = valueCopy.includes(data.id)
                                ? valueCopy.filter((key) => key !== data.id)
                                : [...valueCopy, data.id];

                              handleChange(newValues);
                            } else {
                              setSearchValue
                                ? handleChange(data.id, data?.title)
                                : handleChange(data.id);
                              setOpen(false);
                            }
                          }}
                          className={`hover:bg-gray-100 ${isActive && 'bg-gray-300'}`}
                        >
                          {CustomOption && data ? (
                            <CustomOption {...data} />
                          ) : (
                            data.title
                          )}{' '}
                          <CheckIcon
                            className={cn(
                              'ml-auto h-4 w-4',
                              isActive ? 'opacity-100' : 'opacity-0',
                            )}
                          />
                        </CommandItem>
                      );
                    })}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </>
  );
}
