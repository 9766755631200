import { CircleXIcon, PlusIcon } from 'lucide-react';

import { IAction } from '.';
import { ZipCodeSelect } from '../../pages/requisitions/AddRequisition';
import { APIAddress } from '../../types/common';
import { APIRequisition } from '../../types/requisitions';
import { theme } from '../../utils/theme';
import { Card, CardContent } from '../ui/card';
import { Checkbox } from '../ui/checkbox';
import { Label } from '../ui/label';
import { Separator } from '../ui/seperator';
import AddressInput from './AddressInput';
import { Button } from './Button';
import TextInput from './TextInput';

export function AdditionalLocationVariants({
  state,
  errors,
  dispatch,
  disabled,
}: {
  state: APIRequisition;
  errors: any;
  dispatch: React.Dispatch<IAction> | undefined;
  disabled?: boolean;
}) {
  const handleVariantAddressChange = (
    address: Partial<APIAddress>,
    index: number,
  ) => {
    const currentVariants = state.jobPostVariants || [];
    const newVariants = [...currentVariants];

    newVariants[index] = {
      ...newVariants[index],
      city: address.city ?? '',
      state: address.state ?? '',
      customPostalCode: '',
      postalCode: address.postalCode ?? '',
      latitude: address.latitude ?? null,
      longitude: address.longitude ?? null,
    };

    dispatch?.({
      type: 'set',
      value: { jobPostVariants: newVariants },
    });
  };

  const handleVariantTitleChange = (index: number, value: string) => {
    const currentVariants = state.jobPostVariants || [];
    const newVariants = [...currentVariants];

    newVariants[index] = {
      ...newVariants[index],
      jobTitle: value,
    };
    dispatch?.({
      type: 'set',
      value: { jobPostVariants: newVariants },
    });
  };

  const handleUseJobTitleChange = (index: number, checked: boolean) => {
    const currentVariants = state.jobPostVariants || [];
    const newVariants = [...currentVariants];

    newVariants[index] = {
      ...newVariants[index],
      useJobTitle: checked,
    };

    dispatch?.({
      type: 'set',
      value: { jobPostVariants: newVariants },
    });
  };

  const handleVariantZipcodeChange = (index: number) => (value: string) => {
    const currentVariants = state.jobPostVariants || [];
    const newVariants = [...currentVariants];

    newVariants[index] = {
      ...newVariants[index],
      customPostalCode: value,
    };
    dispatch?.({
      type: 'set',
      value: { jobPostVariants: newVariants },
    });
  };

  const addVariant = () => {
    const currentVariants = state.jobPostVariants || [];
    const newVariants = [
      ...currentVariants,
      {
        activeVariant: false,
        city: '',
        deleted: false,
        jobTitle: '',
        useJobTitle: false,
        latitude: null,
        longitude: null,
        postalCode: '',
        state: '',
      },
    ];

    dispatch?.({
      type: 'set',
      value: { jobPostVariants: newVariants },
    });
  };

  const removeVariant = (index: number) => {
    const currentVariants = state.jobPostVariants || [];
    const newVariants = currentVariants.filter((_, i) => i !== index);

    dispatch?.({
      type: 'set',
      value: { jobPostVariants: newVariants },
    });
  };

  return (
    <div className='mt-2 w-full space-y-2'>
      <Separator />
      {(state.jobPostVariants || []).length > 0 && (
        <p className='font-semibold'>
          Ad Location(s)
          <span
            className='pl-[4px]'
            style={{ color: theme.danger, fontSize: 16 }}
          >
            *
          </span>
        </p>
      )}
      <div className='space-y-4'>
        {(state.jobPostVariants || []).map((variant, index) => {
          return (
            <Card className='relative' key={index}>
              <CardContent className='space-y-4 py-4 pl-4 pr-8'>
                <div className='space-y-1'>
                  <div className='flex items-center gap-2'>
                    <Checkbox
                      className='h-3 w-3'
                      checked={!!variant.useJobTitle}
                      onCheckedChange={(checked) => {
                        handleUseJobTitleChange(index, checked as boolean);
                      }}
                      disabled={disabled || !state.jobTitle}
                      id={`use-job-title-${index}`}
                    />
                    <Label
                      htmlFor={`use-job-title-${index}`}
                      className='text-xs'
                    >
                      Same as job title
                    </Label>
                  </div>
                  <TextInput
                    required
                    placeholder='Ad title...'
                    onChange={(e) => {
                      handleVariantTitleChange(index, e.target.value);
                    }}
                    disabled={disabled || variant.useJobTitle}
                    value={
                      variant.useJobTitle
                        ? state.jobTitle || ''
                        : variant.jobTitle
                    }
                    errorMessage={errors?.jobPostVariants?.[index]?.jobTitle}
                    id={`ad-title-${index}`}
                  />
                </div>

                <div className='flex w-full items-start gap-2'>
                  <div className='min-w-0 flex-1'>
                    <AddressInput
                      required
                      checkRegion
                      handleClear={() => {
                        handleVariantAddressChange({}, index);
                      }}
                      handleChange={(address) =>
                        handleVariantAddressChange(address, index)
                      }
                      selectedAddress={{
                        city: variant.city,
                        state: variant.state,
                        postalCode: variant.postalCode,
                      }}
                      errorMessage={errors?.jobPostVariants?.[index]?.city}
                      disabled={disabled}
                      hideLabel
                      placeholder='Ad location...'
                    />
                  </div>

                  <ZipCodeSelect
                    city={variant.city}
                    state={variant.state}
                    postalCode={variant.postalCode}
                    customPostalCode={variant.customPostalCode}
                    handleChange={handleVariantZipcodeChange(index)}
                    errorMessage={errors?.jobPostVariants?.[index]?.postalCode}
                    hideLabel
                  />
                </div>
              </CardContent>

              <div className='absolute right-2 top-4'>
                <Button
                  disabled={index === 0 || disabled}
                  type='button'
                  variant='ghost'
                  onClick={() => removeVariant(index)}
                  className='p-0'
                >
                  <CircleXIcon
                    className={`h-4 w-4 ${index === 0 || disabled ? 'text-neutral-40' : 'text-error'}`}
                  />
                </Button>
              </div>
            </Card>
          );
        })}
      </div>
      <div>
        <Button
          variant='outline'
          onClick={addVariant}
          className='text-sm'
          disabled={disabled}
        >
          <div className='flex items-center'>
            <PlusIcon className='mr-2 h-4 w-4' />
            <span>Add location</span>
          </div>
        </Button>
      </div>
    </div>
  );
}
