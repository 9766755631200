import { forwardRef, useCallback } from 'react';
import { useNavigation } from 'react-router-dom';

import { useQueryParams } from '../../hooks/useQueryParams';
import { cn } from '../../lib/utils';
import { ChevronLeftIcon } from './Icons';

const Pag = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav
    role='navigation'
    aria-label='pagination'
    className={cn('flex', className)}
    {...props}
  />
);
Pag.displayName = 'Pagination';

const PaginationContent = forwardRef<
  HTMLUListElement,
  React.ComponentProps<'ul'>
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn('flex flex-row items-center gap-1', className)}
    {...props}
  />
));
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(
  ({ className, ...props }, ref) => (
    <li ref={ref} className={cn('', className)} {...props} />
  ),
);
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
  isActive?: boolean;
} & React.ComponentProps<'button'>;

const PaginationLink = ({
  className,
  isActive,
  ...props
}: PaginationLinkProps) => (
  <button
    aria-current={isActive ? 'page' : undefined}
    className={cn(
      {
        primary: isActive,
        secondary: !isActive,
      },
      'font-bold',
      className,
    )}
    {...props}
  />
);
PaginationLink.displayName = 'PaginationLink';

const PaginationPrevious = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label='Go to previous page'
    className={cn('gap-1 pl-2.5', className)}
    {...props}
  >
    <ChevronLeftIcon className='h-4 w-4' />
    <span className='sr-only'>Previous</span>
  </PaginationLink>
);

PaginationPrevious.displayName = 'PaginationPrevious';

const PaginationNext = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink
    aria-label='Go to next page'
    className={cn('gap-1 pr-2.5', className)}
    {...props}
  >
    <span className='sr-only'>Next</span>
    <ChevronLeftIcon
      style={{ transform: 'rotate(180deg)' }}
      className='h-4 w-4'
    />
  </PaginationLink>
);
PaginationNext.displayName = 'PaginationNext';

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<'span'>) => (
  <span
    aria-hidden
    className={cn('flex h-9 w-9 items-center justify-center', className)}
    {...props}
  >
    ...
    <span className='sr-only'>More pages</span>
  </span>
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

export function Pagination({
  pages,
  paginationKey = 'pageNumber',
  itemsPerPage = 25,
  currentItemsCount,
  handleLocalPageChange,
  localPage,
}: {
  pages?: number;
  paginationKey?: string;
  itemsPerPage?: number;
  currentItemsCount?: number;
  localPage?: number;
  handleLocalPageChange?: (page: number) => void;
}) {
  const { queryParams, setQueryParams } = useQueryParams();
  const { state } = useNavigation();

  const loading = state === 'loading';

  const current = localPage
    ? localPage
    : queryParams[paginationKey]
      ? Number(queryParams[paginationKey])
      : 1;

  const isUnknownTotalPages =
    pages === undefined && currentItemsCount !== undefined;

  const isFirstPage = current === 1;
  const isLastPage = isUnknownTotalPages
    ? currentItemsCount! < itemsPerPage
    : current === pages;

  const handlePrev = useCallback(() => {
    if (isFirstPage) return;

    if (handleLocalPageChange) {
      handleLocalPageChange(current - 1);
    } else {
      setQueryParams({
        ...queryParams,
        [paginationKey]: String(current - 1),
      });
    }
  }, [
    paginationKey,
    current,
    queryParams,
    setQueryParams,
    handleLocalPageChange,
    isFirstPage,
  ]);

  const handleNext = useCallback(() => {
    if (isLastPage) return;

    if (handleLocalPageChange) {
      handleLocalPageChange(current + 1);
    } else {
      setQueryParams({
        ...queryParams,
        [paginationKey]: String(current + 1),
      });
    }
  }, [
    paginationKey,
    current,
    queryParams,
    setQueryParams,
    handleLocalPageChange,
    isLastPage,
  ]);

  const handleNumberClick = useCallback(
    (page: number) => {
      const newParams = { ...queryParams };
      if (page === 1 && newParams[paginationKey]) {
        delete newParams[paginationKey];
      } else {
        newParams[paginationKey] = String(page);
      }

      if (handleLocalPageChange) {
        handleLocalPageChange(page);
      } else {
        setQueryParams(newParams);
      }
    },
    [paginationKey, queryParams, setQueryParams, handleLocalPageChange],
  );

  if (isUnknownTotalPages && currentItemsCount === undefined) return null;
  if (!isUnknownTotalPages && pages === undefined) return null;

  const pageArray = isUnknownTotalPages
    ? [current]
    : current <= 2
      ? Array.from(Array(Number(pages) + 1).keys()).splice(1, 3)
      : current > Number(pages) - 1
        ? [current - 2, current - 1, current]
        : [current - 1, current, current + 1];

  return (
    <Pag>
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            disabled={loading || isFirstPage}
            onClick={handlePrev}
          />
        </PaginationItem>
        {pageArray.map((v) => (
          <PaginationItem key={v}>
            <PaginationLink
              disabled={loading}
              onClick={() => handleNumberClick(v)}
              isActive={v === current}
            >
              {v}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem>
          <PaginationNext
            disabled={loading || isLastPage}
            onClick={handleNext}
          />
        </PaginationItem>
      </PaginationContent>
    </Pag>
  );
}
