import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EmptyState } from '../../components/common';
import {
  ContentHeader,
  ContentContainer,
  ContentInnerContainer,
} from '../../components/common/Content';
import { WarningIcon } from '../../components/common/Icons';
import PageContent from '../../components/common/PageContent';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { usePageTitle } from '../../hooks/usePageTitle';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useQueryParamsChange } from '../../hooks/useQueryParamsChange';
import { useTableSelection } from '../../hooks/useTableSelection';
import { PageTypes } from '../../types/pages';
import { APIRequisition } from '../../types/requisitions';
import { generateBaseTableApiParams } from '../../utils/api';
import { formatContentHeaderSubLabel } from '../../utils/contentHelpers';
import { removeTabsParamsAndReturnQueryString } from '../../utils/tabs';
import { RequisitionView } from './RequisitionView';
import RequisitionsFilters from './RequisitionsFilters';
import RequisitionsTable from './RequisitionsTable';

export function Requisitions() {
  const { queryParams } = useQueryParams();

  const { me, refetchUsers } = useGlobalState();

  const { requisitionId, tabId } = useParams();

  const navigate = useNavigate();

  const {
    data: response,
    refetch,
    isError,
    error,
    isLoading,
    isFetching,
  } = useCustomQuery<APIRequisition[]>({
    url: '/jobs',
    params: {
      ...generateBaseTableApiParams(queryParams, 'datePosted'),
      includeVariants: true,
    },
  });

  const { data: requisitions = [], numberofrecords, pages } = response ?? {};

  const reqsForSelection = useMemo(() => {
    return requisitions.map((req) => ({
      id: req.jobId,
      isInList: false,
      disabled: false,
    }));
  }, [requisitions]);
  const totalFilteredCount = numberofrecords;
  const { clearSelectedItems, handleSelectionChange, ...checkboxProps } =
    useTableSelection(reqsForSelection, totalFilteredCount);

  const handleClearSelection = useCallback(() => {
    clearSelectedItems();
  }, [clearSelectedItems]);
  useQueryParamsChange(queryParams, handleClearSelection);

  const pageTitleContact = useMemo(() => {
    return requisitions?.find((r) => r.jobId === requisitionId);
  }, [requisitions, requisitionId]);

  usePageTitle(
    pageTitleContact
      ? `${pageTitleContact.jobTitle} • Requistions`
      : 'Requisitions',
  );

  const contentHeaderSubLabel = useMemo(() => {
    return formatContentHeaderSubLabel({
      label: 'requisitions',
      rowsCount: numberofrecords ?? 0,
      sponsoredAdLimit: me?.sponsoredAdLimit ?? 0,
      sponsoredAdsCount: me?.sponsoredAdsCount ?? 0,
    });
  }, [numberofrecords, me?.sponsoredAdLimit, me?.sponsoredAdsCount]);

  const handleRowClick = (req: APIRequisition, event: any) => {
    if (req.jobId) {
      handleSelectionChange(req.jobId, event.shiftKey);
    }
  };

  if (error) {
    return (
      <EmptyState
        error={isError}
        icon={WarningIcon}
        title={error.message ?? 'Error'}
        link={
          <button onClick={() => window.location.reload()} className='blue'>
            Refresh Page
          </button>
        }
      />
    );
  }

  return (
    <>
      <ContentContainer>
        <ContentHeader
          title='Requisitions'
          subLabel={contentHeaderSubLabel}
          link={{ to: '/requisitions/add', label: 'Add Requisition' }}
        />
        <ContentInnerContainer>
          <PageContent
            contentType={PageTypes.Requisitions}
            data={requisitions}
            pages={pages}
            isLoading={isLoading}
            TableComponent={RequisitionsTable}
            handleRowClick={handleRowClick}
            FiltersComponent={RequisitionsFilters}
            isFetchingNewTableData={isFetching}
            checkboxProps={{
              clearSelectedItems,
              handleSelectionChange,
              ...checkboxProps,
              currentFilters: generateBaseTableApiParams(
                queryParams,
                'dateEntered',
              ),
            }}
          />
        </ContentInnerContainer>
      </ContentContainer>

      <RequisitionView
        isOpen={!!requisitionId}
        requisitionId={requisitionId}
        requisitions={requisitions}
        onClose={() => {
          const queryParamsString =
            removeTabsParamsAndReturnQueryString(queryParams);

          navigate(
            `/requisitions${queryParamsString ? `?${queryParamsString}` : ''}`,
          );
        }}
        onRequisitionSave={() => {
          refetchUsers?.();
          refetch();
        }}
        tabId={tabId}
        panelBaseRoute={`/requisitions`}
      />
    </>
  );
}
