import { QueryParams } from '../hooks/useQueryParams';
import { PageTypes } from '../types/pages';
import { tabsParamsList } from './tabs';

export function generateBaseTableApiParams(
  queryParams: QueryParams,
  defaultOrderBy: string,
  listId?: string,
) {
  const params = { ...queryParams };

  tabsParamsList.forEach((tabParam) => {
    if (params[tabParam]) {
      delete params[tabParam];
    }
  });

  if (!params.query) {
    if (!params.orderBy) {
      params.orderBy = defaultOrderBy;
    }

    if (!params.orderByDirection) {
      params.orderByDirection = 'DESC';
    }
  }

  if (params.entityType) {
    delete params.entityType;
  }

  if (params.latlong && !params.radiusMiles) {
    params.radiusMiles = '100';
  }

  if (listId) {
    params.isInList = listId;
  }

  return params;
}

export function generateListDetailsQueryParams({
  stage,
  type,
  queryParams,
  ignoreList = [],
}: {
  stage: string | undefined;
  type: string | undefined;
  queryParams: QueryParams;
  ignoreList: string[];
}) {
  const newParams: any = { ...queryParams };

  if (stage) {
    newParams.stage = stage;
  }

  if (type) {
    newParams.type = type === PageTypes.Requisitions ? 'jobs' : type;
  }

  if (ignoreList.length) {
    ignoreList.forEach((param) => {
      if (newParams[param]) {
        delete newParams[param];
      }
    });
  }

  return newParams;
}
