import { format, fromZonedTime } from 'date-fns-tz';
import { useState } from 'react';

import { useGlobalState } from '../../context/GlobalContext';
import { useToast } from '../../hooks/use-toast';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import useInvalidateQuery from '../../hooks/useInvalidateQuery';
import { ListType } from '../../types/lists';
import { PageTypes } from '../../types/pages';
import { CheckboxProps } from '../../types/table';
import { Button } from '../common/Button';
import DatePicker from '../common/DatePicker';
import Modal from '../common/ModalRadix';
import Select from '../common/SelectNew';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { getTableKey } from './AddToListModal';

interface CreateListAddItemsModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedItems: Set<string> | undefined;
  getSelectionPayload: CheckboxProps['getSelectionPayload'];
  itemType: PageTypes;
  currentFilters: CheckboxProps['currentFilters'];
  clearSelectedItems: CheckboxProps['clearSelectedItems'];
}

const sixMonths = new Date();
sixMonths.setMonth(sixMonths.getMonth() + 6);

function CreateListAddItemsModal({
  isOpen,
  onClose,
  selectedItems,
  getSelectionPayload,
  itemType,
  currentFilters,
  clearSelectedItems,
}: CreateListAddItemsModalProps) {
  const { users } = useGlobalState();
  const { toast } = useToast();
  const invalidate = useInvalidateQuery();

  const [listName, setListName] = useState('');
  const [description, setDescription] = useState('');
  const [listFilterType, setListFilterType] = useState('');
  const [userAssignedId, setUserAssignedId] = useState('');
  const [shareWithUsers, setShareWithUsers] = useState('all');
  const [expiresAt, setExpiresAt] = useState<Date | undefined>(undefined); // ugh this entire thing sucks.

  const [isSaving, setIsSaving] = useState(false);
  const { mutateAsync: genericPost } = useCustomMutation({
    method: 'post',
  });

  async function handleSaveItems() {
    setIsSaving(true);

    let formattedDate = undefined;

    if (expiresAt) {
      const expiresAtDate = fromZonedTime(
        new Date(expiresAt).toISOString(),
        'America/Chicago',
      );
      formattedDate = format(expiresAtDate, "yyyy-MM-dd'T'HH:mm:ssXXX").replace(
        'T',
        ' ',
      );
    }
    let listId;
    try {
      const response = await genericPost({
        url: '/lists',
        body: {
          description: listName,
          memo: description,
          template: listFilterType,
          shared: shareWithUsers === 'all',
          userId: userAssignedId,
          expiresAt: formattedDate,
        },
      });
      listId = response.data.listId;

      toast({
        description: 'List successfully created',
        duration: 2000,
      });

      invalidate([`/lists`], { refetchType: 'none' });
    } catch (error) {
      console.error('Error saving items to lists:', error);
      toast({
        title: 'Error!',
        description: 'Failed to create new list. Please try again.',
        variant: 'destructive',
        duration: 5000,
      });
      onClose();
      return;
    }

    if (!listId || (!selectedItems?.size && !getSelectionPayload)) {
      toast({
        title: 'Error!',
        description: 'Something went wrong. Please try again.',
        variant: 'destructive',
        duration: 5000,
      });
      return;
    }

    try {
      const selectionPayload = getSelectionPayload?.();
      const tableKey = getTableKey(itemType);

      if (selectionPayload?.mode === 'allFiltered') {
        const filters = { ...currentFilters };

        // escape hatch for state filter - the backend expects an array no matter what but if we only have one state in our query params, we pass it as a string
        if (filters?.state) {
          if (!Array.isArray(filters.state)) {
            filters.state = [filters.state];
          }
        }

        const filterPayload = {
          [tableKey]: filters,
        };
        await genericPost({
          url: `/lists/${listId}/batchitems`,
          body: {
            ...filterPayload,
            removalIds: selectionPayload.blacklistedIds,
          },
        });

        toast({
          description: `All filtered items added to "${listName}" list`,
          duration: 5000,
        });
      } else {
        const formattedItems = Array.from(selectedItems ?? []).map(
          (itemId) => ({
            tableId: itemId,
            tableName: tableKey,
          }),
        );

        await genericPost({
          url: `/lists/${listId}/items`,
          body: formattedItems,
        });

        toast({
          description: `${selectedItems?.size} items added to "${listName}" list`,
          duration: 5000,
        });
      }

      clearSelectedItems?.();
    } catch (error) {
      console.error('Error adding items to list:', error);
      toast({
        title: 'Error!',
        description: 'Failed to add items to new list',
        variant: 'destructive',
        duration: 5000,
      });
    } finally {
      setIsSaving(false);
      onClose();
    }
  }

  const listTypeOptions = Object.values(ListType).map((value) => ({
    value: value,
    label: value,
  }));

  const userAssignedOptions = users.map((user) => ({
    value: user.userId!,
    label: user.fullName!,
  }));

  return (
    <Modal open={isOpen} onOpenChange={onClose}>
      <Modal.Content
        title='Create list'
        // className='h-[90vh] w-[80vw] max-w-[3000px]'
      >
        <div className='flex w-full flex-col gap-4'>
          <div className='flex flex-col gap-2'>
            <Label className='text-secondary-80'>
              List name <span className='text-error'>*</span>
            </Label>
            <Input
              placeholder='Enter list name...'
              className='text-secondary-80'
              value={listName}
              onChange={(e) => setListName(e.target.value)}
            />
          </div>

          <div className='flex flex-col gap-2'>
            <Label className='text-secondary-80'>
              List type <span className='text-error'>*</span>
            </Label>
            <Select
              placeholder='List type'
              options={listTypeOptions}
              value={listFilterType}
              onChange={(value) => {
                setListFilterType(value);
              }}
              showClearOption
              classNames={{
                container: 'max-w-full',
              }}
            />
          </div>

          <div className='flex flex-col gap-2'>
            <Label className='text-secondary-80'>User assigned</Label>
            <Select
              placeholder='User assigned'
              options={userAssignedOptions}
              value={userAssignedId}
              onChange={(value) => {
                setUserAssignedId(value);
              }}
              showClearOption
              classNames={{
                container: 'max-w-full',
              }}
            />
          </div>

          <div className='flex flex-col gap-2'>
            <Label className='text-secondary-80'>
              Description <span className='text-error'>*</span>
            </Label>
            <Input
              placeholder='Description for list...'
              className='text-secondary-80'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className='flex flex-col gap-2'>
            <Label className='text-secondary-80'>
              Share <span className='text-error'>*</span>
            </Label>
            <Select
              options={[
                {
                  value: 'all',
                  label: 'Share with all users',
                },
                {
                  value: 'private',
                  label: 'Private',
                },
              ]}
              value={shareWithUsers}
              onChange={(value) => {
                setShareWithUsers(value);
              }}
              classNames={{
                container: 'max-w-full',
              }}
            />
          </div>
          <div className='flex flex-col gap-2'>
            <Label className='text-secondary-80'>Select Expiry Date</Label>
            <DatePicker
              hideLabel
              value={expiresAt}
              onChange={(date: Date | undefined) => setExpiresAt(date)}
              classNames={{ trigger: 'w-full' }}
              onClear={() => setExpiresAt(undefined)}
              disabledDates={{
                before: new Date(),
                after: sixMonths,
              }}
            />
          </div>

          <div className='mt-8 flex w-full items-center justify-end gap-4'>
            <Button variant='secondary' onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={handleSaveItems}
              isLoading={isSaving}
              disabled={
                !listName ||
                !description ||
                !shareWithUsers ||
                !listFilterType ||
                isSaving
              }
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default CreateListAddItemsModal;
