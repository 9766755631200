import { activityTitleMap, ActivityType } from '../../data/activityTitleMap';
import { useReadMore } from '../../hooks/useReadMore';
import { formatText, removeDateFromDescription } from '../../utils/activities';
import { convertUTCToLocal } from '../../utils/helpers';
import { theme } from '../../utils/theme';
import { EmailIcon } from './Icons';
import { Stack } from './index';

interface IActivity {
  text?: string;
  description: string;
  assignee: string;
  date: string;
  type: ActivityType;
  result?: string;
}

export const getActivityIcon = (type: string, result?: string) => {
  return (
    activityTitleMap[
      (type?.toLowerCase() +
        result?.replace('-', '')?.toLowerCase()) as ActivityType
    ]?.icon ||
    activityTitleMap[type?.toLowerCase() as ActivityType]?.icon ||
    'question_mark'
  );
};

export function Activity({ activity }: { activity: IActivity }) {
  const { description, assignee, date, type, result } = activity;

  const { contentRef, isExpanded, isTruncated, toggleExpand } =
    useReadMore(description);

  const title =
    activityTitleMap[
      (type?.toLowerCase() +
        result?.replace('-', '')?.toLowerCase()) as ActivityType
    ]?.text ||
    activityTitleMap[type?.toLowerCase() as ActivityType]?.text ||
    '';

  const icon = getActivityIcon(type, result);

  return (
    <div className='gray-border flex w-full justify-between gap-5 rounded-md p-4'>
      {icon ? (
        <div>
          <div className='material-symbols-outlined'>{icon}</div>
        </div>
      ) : (
        <div className='w-6'></div>
      )}
      <div className='flex w-full flex-col gap-2'>
        {!!title && <span>{title}</span>}
        {description && (
          <span
            className={`${isExpanded ? '' : 'line-clamp-6'} text-sm`}
            ref={contentRef}
          >
            {formatText(removeDateFromDescription(description))}
          </span>
        )}
        {isTruncated && (
          <button
            onClick={toggleExpand}
            className='w-fit text-sm font-medium text-link'
          >
            {isExpanded ? 'Read less' : 'Read more'}
          </button>
        )}
        <div className='flex justify-between'>
          <span className='text-xs text-neutral-500'>{assignee}</span>
          <span className='text-xs text-neutral-500' style={{ minWidth: 40 }}>
            {convertUTCToLocal(date)}
          </span>
        </div>
      </div>
    </div>
  );
}

export function EmailActivity({
  description,
  from,
  date,
  read,
}: {
  description: string;
  from: string;
  date: string;
  read?: boolean;
}) {
  return (
    <Stack className='gray-border rounded-md p-2 px-4'>
      <Stack horizontal spacing={8}>
        <Stack>
          <div
            className='gray-border'
            style={{
              backgroundColor: read ? '#939393' : theme.richBlue,
              padding: 2,
              borderRadius: 4,
            }}
          >
            <EmailIcon size={20} color='white' />
          </div>
        </Stack>
        <Stack spacing={8}>
          <span className='text-sm'>{description}</span>
          <Stack horizontal horizontalAlign='space-between'>
            <span className='text-xs text-neutral-500'>From: {from}</span>
            <span className='text-xs text-neutral-500'>{date}</span>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
