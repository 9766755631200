import { Dialpad } from '@azure/communication-react';
import { E164Number } from 'libphonenumber-js';
import React, { useState, useEffect, useReducer } from 'react';
import Draggable from 'react-draggable';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { IAction, commonReducer } from '../../components/common';
import { Button } from '../../components/common/Button';
import { APIContact } from '../../types/contacts';
import { EmptyUser } from '../common/Icons';
import AddContactView from './AddCallContact';

const validateRequiredFields = (
  state: Partial<APIContact>,
  errors: Partial<Record<keyof APIContact, string>>,
) => {
  if (!state.firstName) errors.firstName = 'First name is required';
  if (!state.lastName) errors.lastName = 'Last name is required';
};

const validateContactMethods = (
  state: Partial<APIContact>,
  errors: Partial<Record<keyof APIContact, string>>,
) => {
  if (!state.phone) errors.phone = 'Phone number is required';
  if (state.phone && !isPossiblePhoneNumber(state.phone))
    errors.phone = 'Phone number not valid';
};

export const validateContact = (state: Partial<APIContact>) => {
  const errors: Partial<Record<keyof APIContact, string>> = {};
  validateRequiredFields(state, errors);
  validateContactMethods(state, errors);
  return errors;
};
const initialState: Partial<APIContact> = {
  firstName: '',
  lastName: '',
  phone: undefined,
};

export function Pad({
  callName,
  callStatus,
  number,
  setNumber,
  isMinimized,
  setIsMinimized,
  isDialPadView,
  setIsDialPadView,
  isQuestionContactView,
  setIsQuestionContactView,
  isAddContactView,
  setIsAddContactView,
  handleHangUp,
  handleDialpadKeyPress,
  buttonReceiveCall,
  handleAnswerCall,
  isCalling,
  errorPhoneNumber,
  saveContact,
  handleQuestion,
  isFocused,
  setIsFocused,
}: {
  callName: string;
  callStatus: string;
  number: string;
  setNumber: (number: string) => void;
  isMinimized: boolean;
  setIsMinimized: (isMinimized: boolean) => void;
  isDialPadView: boolean;
  setIsDialPadView: (isDialPadView: boolean) => void;
  isQuestionContactView: boolean;
  setIsQuestionContactView: (isQuestionContactView: boolean) => void;
  isAddContactView: boolean;
  setIsAddContactView: (isAddContactView: boolean) => void;
  handleHangUp: () => void;
  handleDialpadKeyPress: (buttonValue: string) => void;
  buttonReceiveCall: boolean;
  handleAnswerCall: () => void;
  isCalling: boolean;
  errorPhoneNumber: boolean;
  saveContact: (
    firstName: string | null,
    lastName: string | null,
    phone: E164Number | null,
  ) => void;
  handleQuestion: (value: string) => void;
  isFocused: boolean;
  setIsFocused: (isFocused: boolean) => void;
}) {
  const [state, dispatch]: [APIContact, React.Dispatch<IAction>] = useReducer(
    commonReducer,
    initialState,
  );
  const [{ errors }, errorDispatch]: [any, React.Dispatch<IAction>] =
    useReducer(commonReducer, { errors: {} });
  const nodeRef = React.useRef(null);
  const [bounds, setBounds] = useState({
    left: 15,
    right: 0,
    bottom: 0,
    top: 0,
  });

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const handleBack = () => setIsDialPadView(false);
  const handleGridIconClick = () => setIsDialPadView(true);
  const handleMaximize = () => {
    setIsMinimized(false);
    setTimeout(() => setBounds(maxDialPadPosition()), 500);
  };

  const save = (
    firstName: string | null,
    lastName: string | null,
    phone: E164Number | null,
  ) => {
    const errors = validateContact({ firstName, lastName, phone });
    if (Object.values(errors).length) {
      errorDispatch({ path: 'errors', value: errors });
      return;
    }
    saveContact(firstName, lastName, phone);
    cleanAddContact();
    setIsQuestionContactView(false);
    setIsAddContactView(false);
  };

  const handleChange = (path: keyof APIContact) => (value: any) => {
    if (errors[path]) {
      if (path === 'phone') {
        errorDispatch({ type: 'remove', path: 'errors.phone' });
      } else {
        errorDispatch({ type: 'remove', path: `errors.${path}` });
      }
    }
    if (path == 'phone') {
      setNumber(value);
    }
    dispatch({ path, value });
  };

  const cleanAddContact = () => {
    dispatch({ path: 'firstName', value: '' });
    dispatch({ path: 'lastName', value: '' });
    dispatch({ path: 'phone', value: undefined });

    setIsAddContactView(false);
    setIsQuestionContactView(false);
  };

  const formattedNumber: E164Number = isCalling
    ? (number as E164Number)
    : number.startsWith('+')
      ? (number as E164Number)
      : (`+${number}` as E164Number);

  const SimpleView = () => (
    <div
      id={isCalling ? 'activeCall' : 'waitingCall'}
      className='relative flex w-72 max-w-80 flex-col items-center justify-center rounded-b-lg border border-zinc-300 bg-white p-4 shadow-2xl'
    >
      <EmptyUser size={48} className='flex-shrink-0' />
      <div className='mb-2 text-ellipsis text-center text-2xl text-gray-500'>
        {callName}
      </div>
      <div className='mb-2 text-center text-lg text-gray-400'>{callStatus}</div>
      <div className='flex gap-5 pb-0 pt-1'>
        {buttonReceiveCall && (
          <button
            className='h-[48px] w-[48px] rounded-full border border-neutral-60'
            onClick={handleAnswerCall}
          >
            <span className='material-symbols-outlined text-base font-semibold text-neutral-60'>
              call
            </span>
          </button>
        )}
        <button
          className='h-12 w-12 rounded-full border border-neutral-60'
          onClick={handleHangUp}
        >
          <span className='material-symbols-outlined text-base font-semibold text-neutral-60'>
            call_end
          </span>
        </button>
        <button
          className='h-12 w-12 rounded-full border border-neutral-60'
          onClick={handleGridIconClick}
        >
          <span className='material-symbols-outlined text-base font-semibold text-neutral-60'>
            dialpad
          </span>
        </button>
      </div>
    </div>
  );

  const QuestionView = () => (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'>
      <div className='flex w-full max-w-sm flex-col rounded-lg bg-white p-6 shadow-lg'>
        <div className='flex items-center justify-between'>
          <h3 className='text-xl font-semibold text-gray-800'>Add Contact</h3>
          <button
            onClick={() => setIsQuestionContactView(false)}
            className='text-gray-500 hover:text-gray-800'
          >
            <span className='material-symbols-outlined'>close</span>
          </button>
        </div>
        <div className='mt-4'>
          <p>
            <span>
              The contact does not exist. It is necessary to add it. Would you
              like to continue with the process?{' '}
            </span>
          </p>
        </div>
        <div className='mt-6 flex justify-end gap-4'>
          <Button variant='secondary' onClick={() => cleanAddContact()}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsAddContactView(true);
              setIsQuestionContactView(false);
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    </div>
  );

  const maxDialPadPosition = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const dialpadElement = document.getElementById('dialpad');
    const rightPos = !dialpadElement
      ? width
      : width - dialpadElement.offsetWidth;
    const bottomPos = !dialpadElement ? 10 : -dialpadElement.offsetHeight;
    return {
      left: 15,
      right: rightPos,
      bottom: bottomPos,
      top: -height,
    };
  };

  useEffect(() => {
    setBounds(maxDialPadPosition());
    const handleResize = () => setBounds(maxDialPadPosition());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Draggable
      axis={isMinimized ? 'x' : 'both'}
      defaultPosition={{ x: 1275, y: -650 }}
      bounds={bounds}
      nodeRef={nodeRef}
      cancel='input, textarea'
    >
      <div
        ref={nodeRef}
        id='dialpad'
        style={{
          position: isMinimized ? 'fixed' : 'absolute',
          bottom: isMinimized ? -640 : 'auto',
          left: isMinimized ? 0 : 'auto',
          zIndex: 51,
          cursor: isMinimized ? 'grab' : 'default',
        }}
      >
        <div className='flex flex-col rounded-lg border bg-white shadow-lg'>
          <div className='flex w-72 max-w-80 cursor-pointer items-center justify-between rounded-t-lg bg-neutral-20 p-2'>
            <div className='text-lg font-semibold text-gray-800'>Dial Pad</div>
            {!isMinimized ? (
              <button
                className='flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg bg-gray-100 shadow-2xl focus:outline-none'
                onClick={() => setIsMinimized(true)}
              >
                —
              </button>
            ) : (
              <button
                className='flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg bg-gray-100 shadow-2xl focus:outline-none'
                onClick={handleMaximize}
              >
                ⬜
              </button>
            )}
          </div>

          {isMinimized ? (
            <div></div>
          ) : isDialPadView ? (
            <div
              id={isCalling ? 'activeCall' : 'waitingCall'}
              className='relative flex w-72 max-w-80 flex-col items-center justify-center rounded-b-lg border border-zinc-300 bg-white p-4 shadow-2xl'
            >
              <EmptyUser size={48} className='flex-shrink-0' />
              <div className='mb-2 text-ellipsis text-center text-2xl text-gray-500'>
                {callName}
              </div>
              <div
                className={`mb-2.5 h-12 w-full rounded-lg bg-gray-100 p-2.5 text-center text-2xl shadow-inner ${isFocused ? 'ring-2 ring-blue-500' : ''}`}
                onClick={handleFocus}
                onBlur={handleBlur}
                tabIndex={0}
              >
                {formattedNumber || ''}
              </div>

              {!errorPhoneNumber && (
                <div className='mb-2 text-lg text-gray-400'>{callStatus}</div>
              )}
              {errorPhoneNumber && (
                <div className='mb-2 text-lg text-red-400'>
                  {'Phone number not valid'}
                </div>
              )}

              <Dialpad
                dialpadMode='dtmf'
                longPressTrigger='mouseAndTouch'
                showDeleteButton
                onClickDialpadButton={handleDialpadKeyPress}
              />
              <div className='flex gap-5 pt-1'>
                {buttonReceiveCall && (
                  <button
                    className='h-[48px] w-[48px] rounded-full border border-neutral-60'
                    onClick={handleAnswerCall}
                  >
                    <span className='material-symbols-outlined text-base font-semibold text-neutral-60'>
                      call
                    </span>
                  </button>
                )}

                {isCalling && (
                  <>
                    <button
                      className='h-12 w-12 rounded-full border border-neutral-60'
                      onClick={handleHangUp}
                    >
                      <span className='material-symbols-outlined text-base font-semibold text-neutral-60'>
                        call_end
                      </span>
                    </button>
                    <button
                      className='h-12 w-12 rounded-full border border-neutral-60'
                      onClick={handleBack}
                    >
                      <span className='material-symbols-outlined text-base font-semibold text-neutral-60'>
                        home
                      </span>
                    </button>
                  </>
                )}

                {!isCalling && (
                  <button
                    className='h-12 w-12 rounded-full border border-neutral-60'
                    onClick={() => handleQuestion(formattedNumber)}
                  >
                    <span className='material-symbols-outlined text-base font-semibold text-neutral-60'>
                      call
                    </span>
                  </button>
                )}
              </div>

              {isQuestionContactView && <QuestionView />}

              <AddContactView
                isOpen={isAddContactView}
                onClose={() => cleanAddContact()}
                onSave={() =>
                  save(
                    state.firstName,
                    state.lastName,
                    state.phone || formattedNumber,
                  )
                }
                state={{
                  firstName: state.firstName,
                  lastName: state.lastName,
                  phone: state.phone || formattedNumber,
                }}
                errors={errors}
                handleChange={handleChange}
              />
            </div>
          ) : (
            <SimpleView />
          )}
        </div>
      </div>
    </Draggable>
  );
}
