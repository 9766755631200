import ActiveScaleInput from '../../components/filters/ActiveScaleInput';
import ListTypesSelect from '../../components/filters/ListTypeSelect';
import SearchInput from '../../components/filters/SearchInput';
import ShowExpiredInput from '../../components/filters/ShowExpiredInput';
import UsersInput from '../../components/filters/UsersInput';

export function ListFilters() {
  return (
    <>
      <SearchInput containerClassName='w-96' />
      <UsersInput />
      <ListTypesSelect />
      <ShowExpiredInput />
      <ActiveScaleInput />
    </>
  );
}
