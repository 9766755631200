import { ResponsivePie } from '@nivo/pie';

function CashInToBillingRatio({
  cashIn,
  billing,
}: {
  cashIn: number;
  billing: number;
}) {
  const isEnabled = false;
  const sponsoredPercentage = (cashIn / billing) * 100;

  const data = [
    {
      id: 'Cash-in',
      label: 'Cash-in',
      value: cashIn,
      color: '#533BE4',
    },
    {
      id: 'Not billed yet',
      label: 'Not billed yet',
      value: billing - cashIn,
      color: '#F0EEFF',
    },
  ];

  return (
    <div className='flex size-full min-h-[238px] flex-col rounded-lg border border-neutral-40 bg-white p-4'>
      <div className='flex flex-nowrap items-center justify-between'>
        <div className='mr-4 flex items-center truncate'>
          <span className='text-lg font-bold text-gray-500'>
            Cash-in to Billing Ratio (%)
          </span>
        </div>
      </div>
      {isEnabled ? (
        <div className='grid grid-cols-1 items-center gap-4 sm:grid-cols-[1fr,1fr]'>
          <div className='relative mt-2 flex items-center justify-center'>
            <div className='h-44 w-52'>
              <ResponsivePie
                data={data}
                margin={{ top: 10, right: 10, bottom: 30, left: 10 }}
                innerRadius={0.85}
                padAngle={0.7}
                startAngle={0}
                cornerRadius={0}
                activeOuterRadiusOffset={8}
                colors={['#533BE4', '#F0EEFF']}
                enableArcLinkLabels={false}
                enableArcLabels={false}
                legends={[
                  {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 30,
                    itemsSpacing: 0,
                    itemWidth: 80,
                    itemHeight: 15,
                    itemTextColor: '#000',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 10,
                    symbolShape: 'circle',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemTextColor: '#000',
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
            <div className='absolute flex flex-col items-center'>
              <span className='text-2xl font-bold'>
                {sponsoredPercentage.toFixed(0)}
              </span>
            </div>
          </div>
          <div className='mt-4 flex gap-4 sm:flex-col'>
            <div className='text-sm text-secondary-80'>
              <div className='mb-1'>Cash-in ($)</div>
              <div className='text-base font-bold'>{cashIn}</div>
            </div>
            <div className='text-sm text-secondary-80'>
              <div className='mb-1'>Billing ($)</div>
              <div className='text-base font-bold'>{billing}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className='flex size-full items-center justify-center text-lg font-medium text-gray-500'>
          Coming soon
        </div>
      )}
    </div>
  );
}

export default CashInToBillingRatio;
