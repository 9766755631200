import { format } from 'date-fns';
import { CalendarIcon, XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';

import { cn } from '../../lib/utils';
import { Calendar } from '../ui/calendar';
import { inputClass } from '../ui/input';
import { Label } from '../ui/label';
import { Popover, PopoverTrigger, PopoverContent } from '../ui/popover';
import { ErrorMessage } from './Error';

function DatePicker({
  value,
  onChange,
  onClear,
  hideLabel,
  placeholder,
  className,
  classNames,
  label,
  errorMessage,
  disabledDates,
}: {
  value?: Date | undefined;
  onChange: (date: Date | undefined) => void;
  onClear: () => void;
  hideLabel?: boolean;
  placeholder?: string;
  className?: string;
  classNames?: {
    trigger?: string;
  };
  label?: string;
  errorMessage?: string;
  disabledDates?: {
    before: Date;
    after: Date;
  };
}) {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [date, setDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <div className='flex flex-col gap-2'>
      {!hideLabel && (
        <Label>
          {label ? (
            <div className='font-basis font-sans text-base font-semibold'>
              {label} <span className='text-error'>*</span>
            </div>
          ) : (
            <>
              Select date <span className='text-error'>*</span>
            </>
          )}
        </Label>
      )}
      <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
        <PopoverTrigger asChild>
          <div
            className={cn(
              inputClass,
              'flex min-w-28 cursor-pointer items-center bg-white',
              className,
              classNames?.trigger,
            )}
          >
            {date ? (
              <div className='flex w-full items-center justify-between gap-1'>
                <span className='truncate'>{format(date, 'MM/dd/yyyy')}</span>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setDate(undefined);
                    onClear();
                  }}
                >
                  <XIcon className='mt-0.5' size={14} />
                </button>
              </div>
            ) : (
              <span className=' text-neutral-60'>
                {placeholder ?? 'Pick a date'}
              </span>
            )}
            {!date && <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />}
          </div>
        </PopoverTrigger>
        <PopoverContent className='w-full bg-white'>
          <Calendar
            mode='single'
            selected={date}
            onSelect={(date) => {
              setDate(date);
              setIsCalendarOpen(false);
              onChange?.(date);
            }}
            {...(disabledDates
              ? { modifiers: { disabled: disabledDates } }
              : null)}
          />
        </PopoverContent>
      </Popover>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
}

export default DatePicker;
