import { ChevronDown } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ListBulkActions from '../../components/bulk-actions/ListBulkActions';
import { getActivityIcon } from '../../components/common/Activity';
import { BaseTable, Column } from '../../components/common/BaseTable';
import { Button } from '../../components/common/Button';
import OrganizationName from '../../components/common/OrganizationName';
import PhoneNumber from '../../components/common/PhoneNumber';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import { useGlobalState } from '../../context/GlobalContext';
import { useQueryParams } from '../../hooks/useQueryParams';
import { APIOrganization } from '../../types/organizations';
import { PageTypes } from '../../types/pages';
import { CheckboxProps } from '../../types/table';
import { legendColors } from '../../utils/constant';
import { setFieldIfEmpty, convertUTCToLocal } from '../../utils/helpers';

interface OrganizationsTableProps {
  data: APIOrganization[];
  handleRowClick: (value: APIOrganization) => void;
  className?: string;
  checkboxProps?: CheckboxProps;
  hideBulkActions?: boolean;
}

export function OrganizationsTable({
  data,
  handleRowClick,
  className,
  checkboxProps,
  hideBulkActions = false,
}: OrganizationsTableProps) {
  const { users, inActiveUsers } = useGlobalState();
  const navigate = useNavigate();
  const { queryParamsString } = useQueryParams();
  const queryParams = new URLSearchParams(queryParamsString);

  // Filter data based on engagementColor
  const filteredData = queryParams.get('engagementColor')
    ? data.filter(
        (contact) =>
          contact.engagementColor === queryParams.get('engagementColor'),
      )
    : data;

  const allUsers = useMemo(() => {
    return (users && inActiveUsers && [...users, ...inActiveUsers]) ?? [];
  }, [inActiveUsers, users]);

  const toggleColumnVisibility = (c: Column<APIOrganization>) => {
    const index = columns.map((column) => column.key).indexOf(c.key);
    setColumns((prevState) => {
      prevState[index].visible = !prevState[index].visible;
      return [...prevState];
    });
  };

  // Set initial visibility state for columns
  useEffect(() => {
    setColumns((prevState) =>
      prevState.map((column) => ({
        ...column,
        visible: true, // Set all columns to visible by default
      })),
    );
  }, []);

  const [columns, setColumns] = useState<Column<APIOrganization>[]>([
    {
      label: 'Activity',
      key: 'engagementColor',
      sortEnabled: false,
      className: 'w-[4rem]',
      render: (org) => (
        <center>
          <div
            className={`relative h-5 w-5 rounded-full transition-all`}
            style={{ backgroundColor: org.engagementColor }}
          >
            <span className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xs font-bold text-white'>
              <>
                {
                  legendColors.find((c) => c.color === org.engagementColor)
                    ?.label
                }
              </>
            </span>
          </div>
        </center>
      ),
    },
    {
      label: 'Organization',
      key: 'organizationName',
      sortEnabled: true,
      className: 'w-[30rem] ',
      render: (org, queryParamsString) => (
        <OrganizationName
          hasContract={!!org.contract}
          name={org.organizationName}
          className='text-link hover:text-[#167dff] hover:underline'
          onClick={() => {
            navigate(
              `/organizations/${org.organizationId}${queryParamsString ? `?${queryParamsString}` : ''}`,
            );
          }}
        />
      ),
    },
    {
      label: 'Phone',
      key: 'phone',
      sortEnabled: false,
      className: 'w-[12rem]',
      render: (org) => <PhoneNumber phoneNumber={org.phone} />,
    },
    {
      label: 'City/State',
      key: 'city',
      sortEnabled: true,
      className: 'w-[12rem]',
      render: (org) => {
        const region = org.city ? `${org.city}, ${org.state}` : org.state;
        return setFieldIfEmpty(region);
      },
    },
    {
      label: 'User Assigned',
      key: 'userName',
      sortEnabled: false,
      className: 'w-[9rem]',
      render: (org) => {
        const user =
          allUsers?.find((u) => u.userId === org.userId)?.fullName ??
          org.userName;
        return setFieldIfEmpty(user);
      },
    },
    {
      label: 'Date Added',
      key: 'dateEntered',
      sortEnabled: true,
      className: 'w-[14rem]',
      render: (org) =>
        setFieldIfEmpty(convertUTCToLocal(org.dateEntered || '')),
    },
    {
      label: 'Last Activity Date',
      key: 'dateLastActivity',
      sortEnabled: true,
      className: 'w-[14rem]',
      render: (org) => (
        <div className='flex items-center'>
          <div className='material-symbols-outlined mr-2'>
            {getActivityIcon(
              org.lastActivityType || '',
              org.lastActivityResult || '',
            )}
          </div>
          {setFieldIfEmpty(convertUTCToLocal(org.dateLastActivity || ''))}
        </div>
      ),
    },
  ]);

  return (
    <>
      <div className='z-10 flex w-full'>
        <DropdownMenu>
          <DropdownMenuTrigger className='m-2'>
            <Button variant='ghost'>
              <div className='flex items-center gap-1'>
                <ChevronDown size={16} /> Columns
              </div>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {columns.map((c: Column<APIOrganization>) => (
              <DropdownMenuCheckboxItem
                checked={c.visible}
                onCheckedChange={() => toggleColumnVisibility(c)}
                key={c.key}
              >
                {c.label}
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <BaseTable
        data={filteredData ? filteredData : data}
        columns={columns}
        idKey='organizationId'
        defaultSortKey='dateEntered'
        handleRowClick={handleRowClick}
        className={className}
        checkboxProps={checkboxProps}
      />

      {!hideBulkActions && checkboxProps && (
        <ListBulkActions
          checkboxProps={checkboxProps}
          itemType={PageTypes.Organizations}
        />
      )}
    </>
  );
}

export default OrganizationsTable;
