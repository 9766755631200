import { useMemo, useState } from 'react';

import {
  Table,
  Header,
  HeaderCell,
  Body,
  Row,
  RowCell,
} from '../../../components/common/Table';
import { useGlobalState } from '../../../context/GlobalContext';
import { cn } from '../../../lib/utils';
import { convertSecondsToHHMM, setFieldIfEmpty } from '../../../utils/helpers';
import { APIProActiveCallTimeReport } from './ProactiveCallEffort';

type SortKey =
  | ''
  | 'name'
  | 'pce'
  | 'outboundTime'
  | 'inboundTime'
  | 'totalTime'
  | 'contact'
  | 'org'
  | 'others'
  | 'emailsSent';
type SortDirection = 'asc' | 'desc';

export function ProactiveCallEffortsTable({
  records,
  isExternalSelected,
}: {
  records: APIProActiveCallTimeReport[];
  isExternalSelected: boolean;
}) {
  const { findUserById } = useGlobalState();
  const [sortKey, setSortKey] = useState<SortKey>('totalTime');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

  const sortedRecords = useMemo(() => {
    if (!sortKey) return records;

    return [...records].sort((a, b) => {
      let aValue: any, bValue: any;

      switch (sortKey) {
        case 'name':
          aValue = findUserById(a.userId)?.fullName || '';
          bValue = findUserById(b.userId)?.fullName || '';
          break;
        case 'emailsSent':
          aValue = a.totalOutboundEmails;
          bValue = b.totalOutboundEmails;
          break;
        case 'contact':
          aValue = a.contactUniqueCallCount;
          bValue = b.contactUniqueCallCount;
          break;
        case 'org':
          aValue = a.orgTotalCallCount;
          bValue = b.orgTotalCallCount;
          break;
        case 'others':
          aValue = a.otherTotalCallCount;
          bValue = b.otherTotalCallCount;
          break;
        case 'outboundTime':
          aValue = a.totalOutboundCallTime;
          bValue = b.totalOutboundCallTime;
          break;
        case 'inboundTime':
          aValue = a.totalInboundCallTime;
          bValue = b.totalInboundCallTime;
          break;
        case 'totalTime':
          aValue = a.totalCallTime;
          bValue = b.totalCallTime;
          break;
        case 'pce':
          aValue = (a.totalOutboundCallTime / a.totalCallTime) * 100;
          bValue = (b.totalOutboundCallTime / b.totalCallTime) * 100;
          break;
        default:
          return 0;
      }

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [records, sortKey, sortDirection, findUserById]);

  const handleSort = (key: SortKey) => {
    if (key === sortKey) {
      const sortDir = sortDirection === 'asc' ? 'desc' : 'asc';

      setSortDirection(sortDir);
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };

  const tableBody = useMemo(() => {
    return sortedRecords?.map((record, key) => {
      const user = findUserById && findUserById(record?.userId);

      const pcePercentage = (
        (record.totalOutboundCallTime / record.totalCallTime) *
        100
      ).toFixed(1);

      return (
        <Row key={key} tabIndex={0} className='cursor-pointer'>
          <RowCell className='flex items-center justify-center border-l-0'>
            {key + 1}
          </RowCell>
          <RowCell className='border-l-0'>
            {setFieldIfEmpty(user?.fullName)}
          </RowCell>
          <RowCell className='border-l-0'>{record.totalOutboundEmails}</RowCell>
          <RowCell className='border-l-0'>
            {setFieldIfEmpty(record.contactTotalCallCount)} /{' '}
            {setFieldIfEmpty(record.contactUniqueCallCount)}
          </RowCell>
          <RowCell className='border-l-0'>
            {setFieldIfEmpty(record.orgTotalCallCount)} /{' '}
            {setFieldIfEmpty(record.orgUniqueCallCount)}
          </RowCell>
          <RowCell className='border-l-0'>
            {setFieldIfEmpty(record.otherTotalCallCount)} /{' '}
            {setFieldIfEmpty(record.otherUniqueCallCount)}
          </RowCell>
          <RowCell>
            {convertSecondsToHHMM(record.totalOutboundCallTime)}
          </RowCell>
          <RowCell className='border-l-0'>
            {convertSecondsToHHMM(record.totalInboundCallTime)}
          </RowCell>
          <RowCell
            className={`border-l-0 ${isExternalSelected && 'bg-secondary-10'}`}
          >
            {convertSecondsToHHMM(record.totalCallTime)}
          </RowCell>
          <RowCell className='border-l-0'>
            {setFieldIfEmpty(pcePercentage)}%
          </RowCell>
        </Row>
      );
    });
  }, [findUserById, isExternalSelected, sortedRecords]);

  const tableHeader = [
    { label: '#', key: '', className: 'w-[2rem]' },
    { label: 'Name', key: 'name', className: 'w-[12rem]' },
    { label: 'Emails Sent', key: 'emailsSent', className: 'w-[5rem]' },
    { label: 'Contact Calls / Unique', key: 'contact', className: 'w-[5rem]' },
    { label: 'Orgs Calls / Unique', key: 'org', className: 'w-[5rem]' },
    { label: 'Other Calls / Unique', key: 'others', className: 'w-[5rem]' },
    { label: 'Outbound Time', key: 'outboundTime', className: 'w-[5rem]' },
    { label: 'Inbound Time', key: 'inboundTime', className: 'w-[5rem]' },
    { label: 'Total Time', key: 'totalTime', className: 'w-[5rem]' },
    { label: 'PCE', key: 'pce', className: 'w-[5rem]' },
  ];

  return (
    <Table
      classNames={{
        table: 'relative table-fixed w-auto min-w-full',
      }}
    >
      <Header
        classNames={{
          thead: 'sticky top-0 shadow-sm',
        }}
      >
        {tableHeader.map((header) => {
          return (
            <HeaderCell
              key={header?.key}
              sortKey={header?.key}
              isSorting
              className={cn(`${header.className} cursor-pointer  opacity-100`)}
              currentLocalSortKey={sortKey}
              currentLocalSortDirection={sortDirection}
              handleLocalSort={() => handleSort(header.key as SortKey)}
            >
              {header?.label}
            </HeaderCell>
          );
        })}
      </Header>

      <Body>{tableBody}</Body>
    </Table>
  );
}
