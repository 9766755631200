import { FormEvent, useCallback, useReducer } from 'react';
import { Link } from 'react-router-dom';

import { useHideRingCentralComponent } from '../../hooks/useHideRingCentralComponent';
import { usePageTitle } from '../../hooks/usePageTitle';
import { api } from '../../services/api';
import { theme } from '../../utils/theme';
import { CommonSpinner, Stack, TextInput, commonReducer } from '../common';
import { LockIcon } from '../common/Icons';

const initialOptions = {
  loading: false,
  error: false,
  email: '',
  success: false,
};

export function PasswordReset() {
  usePageTitle('Reset Password • mlee Pulse');
  useHideRingCentralComponent();

  const [{ loading, error, email, success }, reducer] = useReducer(
    commonReducer,
    initialOptions,
  );

  const handleSubmit = useCallback(
    async (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();

      try {
        const res = await fetch(
          api.apiBase + `/login/requestPasswordReset?emailAddress=${email}`,
        );

        if (!res.ok) {
          reducer({
            type: 'set',
            value: {
              error: true,
              loading: false,
            },
          });
        } else {
          reducer({
            type: 'set',
            value: {
              success: true,
              loading: false,
            },
          });
        }
      } catch (e) {
        reducer({
          type: 'set',
          value: {
            error: true,
            loading: false,
          },
        });
      }
    },
    [email],
  );

  const handleChange = useCallback(
    (path: string) => (value: any) => {
      reducer({ path, value });
    },
    [],
  );

  return success ? (
    <Success />
  ) : (
    <main className='h-screen p-0'>
      <Stack
        verticalAlign='center'
        horizontalAlign='center'
        verticalFill
        className='p-8 sm:p-16'
      >
        <form
          onSubmit={handleSubmit}
          className='relative flex w-full flex-col justify-center sm:w-1/2'
        >
          <Stack horizontalAlign='center' className='mb-8'>
            <div
              style={{
                borderRadius: 500,
                border: ` 3px solid ${theme.purple}`,
                padding: 20,
              }}
            >
              <LockIcon size={64} color={theme.purple} />
            </div>
          </Stack>
          <h1 className='purple text-center font-semibold text-blue-900'>
            Trouble logging in?
          </h1>
          <p className='text-center font-medium text-gray-600'>
            Enter your email and we&apos;ll send you a<br /> link to get back
            into your account.
          </p>
          <TextInput
            required
            label='Email Address'
            type='email'
            handleChange={handleChange('email')}
          />
          {error && (
            <p
              className='slide-in m-0 text-sm text-red-900'
              style={{ position: 'absolute', bottom: 38 }}
            >
              Something went wrong, please try again
            </p>
          )}
          <button type='submit' className='primary mt-8'>
            {loading ? (
              <CommonSpinner key='spinner' />
            ) : (
              <span key='Login'>Send login link</span>
            )}
          </button>
        </form>
        <Link to='/login' className='mt-4 text-blue-900'>
          Back to login
        </Link>
      </Stack>
    </main>
  );
}

function Success() {
  return (
    <main className='slide-in grid h-screen items-center p-0 px-8 sm:px-16'>
      <Stack horizontalAlign='center' spacing={24}>
        <h1
          className='blue relative z-10 text-center text-4xl font-medium tracking-tight sm:text-5xl'
          style={{ width: 300 }}
        >
          <svg
            style={{ top: 50 }}
            aria-hidden='true'
            viewBox='0 0 418 42'
            className='absolute left-0 h-[0.58em] w-full'
            preserveAspectRatio='none'
            fill={theme.lightPurple}
          >
            <path d='M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z'></path>
          </svg>
          <span className='relative'>Success</span>
        </h1>
        <p className='text-center text-2xl font-light'>
          A reset link has been sent to your account!
        </p>
        <Link to='/login' className='blue mt-4 underline'>
          Back to login
        </Link>
      </Stack>
    </main>
  );
}
