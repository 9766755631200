import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActivityView } from '../../components/common/ActivityView';
import { AttachmentsTable } from '../../components/common/AttachmentsPivot';
import GoogleMapsView from '../../components/common/GoogleMapsView';
import NotesView from '../../components/common/NotesView';
import { ListsDetail } from '../../components/common/listsDetail';
import { useQueryParams } from '../../hooks/useQueryParams';
import { APIRequisition } from '../../types/requisitions';
import { TabsParams } from '../../utils/tabs';
import { RequisitionDetails } from './RequisitionDetails';

export function RequisitionsTabs({
  requisition,
  onRequisitionSave,
  tabId,
  panelBaseRoute,
}: {
  requisition: APIRequisition;
  onRequisitionSave: () => void;
  tabId?: string | undefined;
  panelBaseRoute?: string;
}) {
  const navigate = useNavigate();
  const { queryParamsString } = useQueryParams();
  const requisitionsTabs = useRequisitionsTabs({
    requisition,
    onRequisitionSave,
  });
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [activeTabId, setActiveTabId] = useState(
    tabId ?? requisitionsTabs[0].id,
  );

  const activePanel = requisitionsTabs.find((tab) => tab.id === activeTabId);

  const handleTabClick = (tabId: string) => {
    setActiveTabId(tabId);

    if (panelBaseRoute) {
      if (tabId === requisitionsTabs[0].id) {
        navigate(
          `${panelBaseRoute}/${requisition.jobId}${queryParamsString ? `?${queryParamsString}` : ''}`,
          { replace: true },
        );
      } else {
        navigate(
          `${panelBaseRoute}/${requisition.jobId}/${tabId}${queryParamsString ? `?${queryParamsString}` : ''}`,
          { replace: true },
        );
      }
    }
  };

  useEffect(() => {
    if (!containerRef.current) return;

    containerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [activeTabId]);

  return (
    <div className='grid size-full grid-cols-[1fr] grid-rows-[auto,1fr] justify-center overflow-hidden border-neutral-40'>
      <div
        role='tablist'
        className='flex w-full justify-center gap-6 overflow-x-auto pb-2 pt-4'
      >
        {requisitionsTabs.map((tab) => {
          const isActive = tab.id === activeTabId;

          return (
            <button
              key={tab.id}
              role='tab'
              id={tab.id}
              aria-selected={isActive}
              className='relative h-fit w-fit shrink-0'
              onClick={() => handleTabClick(tab.id)}
            >
              <span
                className={`${isActive ? 'after:absolute after:-bottom-[8px] after:block after:h-[3px] after:w-0 after:animate-expand-link after:bg-dark-blue' : ''}`}
              >
                {tab.tabName}
              </span>
            </button>
          );
        })}
      </div>
      <div
        role='tabpanel'
        className='overflow-auto border-t border-neutral-40 pt-4'
        aria-labelledby={activeTabId}
        ref={containerRef}
      >
        {activePanel?.component}
      </div>
    </div>
  );
}

function useRequisitionsTabs({
  requisition,
  onRequisitionSave,
}: {
  onRequisitionSave: () => void;
  requisition: APIRequisition;
}) {
  return [
    {
      tabName: 'Details',
      component: (
        <RequisitionDetails
          onRequisitionSave={onRequisitionSave}
          requisition={requisition}
        />
      ),
      id: 'requisition-details',
    },
    {
      tabName: 'Activities',
      component: (
        <ActivityView
          url='/jobactivities'
          params={{ jobId: requisition.jobId }}
          paginationKey={TabsParams.ActivitiesTab}
        />
      ),
      id: 'activities',
    },
    {
      tabName: 'Notes',
      component: (
        <NotesView
          entityId={requisition.jobId}
          entityName='requisitions'
          paginationKey={TabsParams.NotesTab}
        />
      ),
      id: 'notes',
    },
    {
      tabName: 'Attachments',
      component: (
        <AttachmentsTable
          tableId={requisition.jobId}
          tableName={'jobs'}
          url={`/attachments/${requisition.jobId}`}
        />
      ),
      id: 'attachments',
    },
    {
      tabName: 'Lists',
      component: <ListsDetail page='jobs' listId={requisition.jobId} />,
      id: 'lists',
    },
    {
      tabName: 'Map',
      component: (
        <GoogleMapsView
          primaryPinKey='jobs'
          url={'/jobs/' + requisition.jobId + '/map'}
        />
      ),
      id: 'map',
    },
  ];
}
