import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../components/common/Button';
import {
  ContentContainer,
  ContentHeader,
} from '../../components/common/Content';
import DatePickerRange from '../../components/common/DatePickerRange';
import { HotSheetsTable } from '../../components/common/HotSheetsTable';
import { LinkOutIcon } from '../../components/common/Icons';
import { MultiSelectDropdownFilter } from '../../components/common/MultiSelectDropdownFilter';
import { Pagination } from '../../components/common/Pagination';
import OrganizationInput from '../../components/filters/OrganizationInput';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { usePageTitle } from '../../hooks/usePageTitle';
import { useQueryParams } from '../../hooks/useQueryParams';
import { APIHotSheet } from '../../types/api';
import { APIRequisition } from '../../types/requisitions';
import {
  formatCurrency,
  formatDateRange,
  getDateRangeForPeriod,
} from '../../utils/helpers';
import { pipelineFilterOptions } from '../admin/hot-sheets/HotSheets';
import ApplicationAdsChart from './ApplicationAdsChart';
import Communication from './Communication';
import F2FIntvwPlacementRatio from './F2FIntvwPlacementRatio';
import GoalStatus from './GoalStatus';
import ProactiveCallEffortRatio from './ProactiveCallEffortRatio';
import TodayActivities from './TodayActivities';

export type DashboardPageState = {
  selectedRow?: APIRequisition;
  isFiltersOpen: boolean;
  search: string;
};

export function Dashboard() {
  const { queryParams, setQueryParams } = useQueryParams();

  const [timeFrame, setTimeFrame] = useState<string>(
    (queryParams?.timeFrame as string) || 'week',
  );

  const { me } = useGlobalState();

  usePageTitle('Productivity');

  const timeFrames = [
    {
      id: 'week',
      label: 'Current Week',
    },
    {
      id: 'month',
      label: 'Current Month',
    },
    {
      id: 'quarter',
      label: 'Current Quarter',
    },
    {
      id: 'year',
      label: 'YTD',
    },
  ];

  const { startDate, endDate } = getDateRangeForPeriod('week');

  return (
    <>
      <ContentContainer className='m-auto w-full overflow-auto'>
        <div className='grid flex-none grid-cols-12 gap-6 overflow-x-auto'>
          <div className='col-span-12 sm:col-span-6 xl:col-span-3'>
            <GoalStatus />
          </div>
          <div className='col-span-12 sm:col-span-6 xl:col-span-9'>
            <TodayActivities userId={me?.userId} />
          </div>
        </div>

        <div className='flex flex-none flex-col items-start justify-between gap-2 overflow-x-hidden sm:flex-row sm:items-center sm:gap-0'>
          <ContentHeader
            title='Performance'
            subLabel={
              <div>
                <span className='font-bold'>
                  {queryParams?.startDate && queryParams?.endDate
                    ? formatDateRange(
                        queryParams?.startDate as string,
                        queryParams?.endDate as string,
                      )
                    : formatDateRange(startDate as string, endDate as string)}
                </span>
              </div>
            }
          />
          <div className='flex flex-wrap items-center justify-start gap-2 sm:justify-end sm:gap-4'>
            {timeFrames?.map((item, key) => {
              const isChecked = timeFrame === item?.id;
              return (
                <label
                  onClick={() => {
                    setTimeFrame(item?.id);
                    const newParams = { ...queryParams };
                    if (item?.id === 'today') {
                      delete newParams.startDate;
                      delete newParams.endDate;
                      delete newParams.timeFrame;
                    } else {
                      const { startDate, endDate } = getDateRangeForPeriod(
                        item?.id,
                      );

                      newParams.startDate = startDate;
                      newParams.endDate = endDate;
                      newParams.timeFrame = item?.id;
                    }
                    setQueryParams(newParams);
                  }}
                  key={item?.id}
                  htmlFor='my-jobs-checkbox'
                  className={`h-[31px] cursor-pointer rounded-md border border-neutral-40 px-2 py-1 text-sm font-normal shadow-sm ${isChecked ? 'bg-secondary-80 text-white' : 'bg-white text-secondary-80'}`}
                  role='checkbox'
                  aria-checked={isChecked}
                  tabIndex={key + 1}
                >
                  {item?.label}
                </label>
              );
            })}
          </div>
        </div>

        <div className='mb-2 grid flex-none grid-cols-12 gap-6 overflow-x-auto border-b pb-8'>
          <div className='col-span-12 sm:col-span-6 2xl:col-span-3'>
            <ApplicationAdsChart />
          </div>
          <div className='col-span-12 sm:col-span-6 2xl:col-span-3'>
            <F2FIntvwPlacementRatio />
          </div>
          <div className='col-span-12 sm:col-span-6 2xl:col-span-3'>
            <ProactiveCallEffortRatio />
          </div>
          <div className='col-span-12 sm:col-span-6 2xl:col-span-3'>
            <Communication userId={(me?.userId as string) || ''} />
          </div>
        </div>
        <div className='flex flex-none justify-between'>
          <ContentHeader title='Current Requisitions' subLabel={''} />
          <Link
            to={`/dashboard/`}
            className='flex items-center gap-2 self-start rounded border bg-white px-2 py-1 font-medium'
          >
            <LinkOutIcon size={20} />
            <span>View all</span>
          </Link>
        </div>
        <HotSheet />
      </ContentContainer>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function HotSheet() {
  const [sortKey, setSortKey] = useState<string>('pipelinePositionOrder');
  const [pipelinePositionOrder, setPipelinePositionOrder] =
    useState<string>('4');
  const [maxPipelinePositionOrder, setMaxPipelinePositionOrder] = useState('8');
  const [sortDirection, setSortDirection] = useState<string>('ASC');
  const [page, setPage] = useState<number>(1);
  const { queryParams, setQueryParams } = useQueryParams();

  const { data: response, isLoading } = useCustomQuery<APIHotSheet>({
    url: '/reports/hotsheet',
    params: {
      ...(sortKey && { orderBy: sortKey }),
      ...(sortDirection && { orderByDirection: sortDirection }),
      ...(page > 1 && { pageNumber: page }),
      ...(pipelinePositionOrder
        ? { pipelinePositionOrder }
        : { pipelinePositionOrder: '0' }),
      ...(maxPipelinePositionOrder
        ? { maxPipelinePositionOrder }
        : { maxPipelinePositionOrder: '8' }),
      ...queryParams,
    },
    options: { placeholderData: (prevData) => prevData },
  });

  const clearFilters = () => {
    setSortKey('');
    setSortDirection('');
    setPipelinePositionOrder('');
    setMaxPipelinePositionOrder('');
    setPage(1);
  };
  const hasFilters =
    !!sortKey || !!sortDirection || !!pipelinePositionOrder || page > 1;
  const hotSheets = response?.data.hotSheetReportData || [];

  const { potentialCommissionTop, potentialCommissionBottom } =
    response?.data || {};

  const upToFilterOptions = useMemo(() => {
    const minPipelineStatus = pipelinePositionOrder;
    return pipelineFilterOptions.map((o) => {
      return {
        id: o.value,
        title: o.text,
        disabled: minPipelineStatus ? o.value < minPipelineStatus : false,
      };
    });
  }, [pipelinePositionOrder]);

  return (
    <>
      <div className='size-full rounded-md border border-neutral-40 bg-white p-4'>
        <div className='flex size-full flex-col gap-4'>
          <div className='flex items-center justify-between gap-8'>
            <div className='flex items-center gap-4'>
              <h3 className='text-medium col-span-2 text-xl'>Hot Sheet</h3>
              <div className='flex h-8 items-center justify-center gap-2 rounded-md border border-neutral-40 bg-neutral-20 p-1'>
                <MultiSelectDropdownFilter
                  classNames={{
                    container: 'w-44',
                    trigger: 'h-6 bg-white text-black',
                  }}
                  items={pipelineFilterOptions.map((o) => {
                    return { id: o.value, title: o.text };
                  })}
                  handleChange={(pipelinePositionOrder) => {
                    if (
                      maxPipelinePositionOrder &&
                      pipelinePositionOrder > maxPipelinePositionOrder
                    ) {
                      setMaxPipelinePositionOrder('');
                    }

                    setPipelinePositionOrder(pipelinePositionOrder as string);
                    setPage(1);
                  }}
                  onClear={() => {
                    setPipelinePositionOrder('');
                    setPage(1);
                  }}
                  value={[pipelinePositionOrder as string]}
                  isClearable={!!pipelinePositionOrder}
                  isChecked={!!pipelinePositionOrder}
                  placeholder='Min Pipeline Status'
                />
                <div>up to</div>
                <MultiSelectDropdownFilter
                  classNames={{
                    container: 'w-44',
                    trigger: 'h-6 bg-white text-black',
                  }}
                  items={upToFilterOptions}
                  handleChange={(maxPipelinePositionOrder) => {
                    setMaxPipelinePositionOrder(
                      maxPipelinePositionOrder as string,
                    );
                    setPage(1);
                  }}
                  onClear={() => {
                    setMaxPipelinePositionOrder('');
                    setPage(1);
                  }}
                  value={[maxPipelinePositionOrder as string]}
                  isClearable={!!maxPipelinePositionOrder}
                  isChecked={!!maxPipelinePositionOrder}
                  placeholder='Max pipeline status'
                />
              </div>
              <div className='flex flex-wrap items-center justify-start gap-2 overflow-y-hidden sm:justify-end sm:gap-4'>
                <DatePickerRange
                  startParam='startDate'
                  endParam='endDate'
                  tooltipText={'Pick a range.'}
                  singleDay
                  formatDate={false}
                />
              </div>
              <div className='flex flex-wrap items-center justify-start gap-2 overflow-y-hidden sm:justify-end sm:gap-4'>
                <OrganizationInput />
              </div>
              {!!potentialCommissionTop && !!potentialCommissionBottom && (
                <p className='text-lg'>
                  <span className='font-semibold'>Est Fee Total: </span>
                  <span>{formatCurrency(potentialCommissionBottom)}</span>
                  <span> - </span>
                  <span>{formatCurrency(potentialCommissionTop)}</span>
                </p>
              )}
            </div>
            <div className='flex items-center gap-2'>
              {hasFilters ? (
                <Button
                  variant='ghost'
                  className='text-sm'
                  onClick={() => {
                    setQueryParams({});
                    clearFilters();
                  }}
                >
                  Remove filters
                </Button>
              ) : null}
            </div>
          </div>
          <div className='size-full overflow-hidden'>
            {isLoading ? (
              <div className='size-full animate-pulse bg-slate-300' />
            ) : hotSheets?.length > 0 ? (
              <HotSheetsTable
                data={hotSheets}
                localSort={{
                  sortKey,
                  sortDirection,
                  setSortKey,
                  setSortDirection,
                }}
                hideAdminColumns
              />
            ) : (
              <div className='flex size-full items-center justify-center text-xl'>
                No data found
              </div>
            )}
          </div>
          {!!response?.pages && response?.pages > 1 && (
            <Pagination
              pages={response?.pages}
              localPage={page}
              handleLocalPageChange={setPage}
            />
          )}
        </div>
      </div>
    </>
  );
}
