import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type ContentHeaderProps = {
  title: string;
  subLabel: string | React.ReactNode;
  link?: {
    to: string;
    label: string;
  };
  links?: {
    to: string;
    label: string;
  }[];
  ContentHeaderButton?: any;
  children?: any;
};

export function ContentHeader({
  title,
  subLabel,
  link,
  links,
  ContentHeaderButton,
  children,
}: ContentHeaderProps) {
  return (
    <header className='flex items-center justify-between'>
      <div>
        <h1 className='font-medium'>{title}</h1>
        <div className='text-sm text-primary-70'>{subLabel}</div>
      </div>
      <div className='flex gap-4'>
        {ContentHeaderButton && <ContentHeaderButton />}
        {children}
        {link && (
          <div className='flex gap-2'>
            <Link to={link.to} className='primary font-medium'>
              {link.label}
            </Link>
          </div>
        )}
        {links && (
          <div className='flex gap-2'>
            {links.map((link, index) => (
              <div key={index} className='flex gap-2'>
                <Link to={link.to} className='secondary font-medium'>
                  + {link.label}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </header>
  );
}

export function ContentContainer({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        `flex h-full w-full flex-col gap-4 overflow-hidden p-6`,
        className,
      )}
    >
      {children}
    </div>
  );
}

export function ContentInnerContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className='flex size-full gap-2 overflow-hidden'>
      <div className='size-full flex-1 overflow-hidden rounded-lg'>
        <div className='flex size-full flex-col gap-4 rounded-lg border border-neutral-40 bg-white p-4 shadow-lg'>
          {children}
        </div>
      </div>
    </div>
  );
}
