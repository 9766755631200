import { useEffect } from 'react';

export function useHideRingCentralComponent() {
  useEffect(() => {
    const checkRCAdapter = () => {
      if (typeof (window as any).RCAdapter !== 'undefined') {
        (window as any).RCAdapter.setClosed(true);
      } else {
        setTimeout(checkRCAdapter, 100);
      }
    };

    checkRCAdapter();
  }, []);

  return {
    showRingCentralComponent: () => {
      if (typeof (window as any).RCAdapter !== 'undefined') {
        (window as any).RCAdapter.setClosed(false);
      }
    },
  };
}
