import { ResponsivePie } from '@nivo/pie';

import { useCustomQuery } from '../../../hooks/useCustomQuery';
import { useQueryParams } from '../../../hooks/useQueryParams';
import colors from '../../../styles/colors';

type APIF2FIntvwPlacementRatio = {
  summary: {
    numberOfInterviews: number;
    numberOfPlacements: number;
    ratio: number;
  };
};

function F2FIntvwPlacementRatio() {
  const { queryParams } = useQueryParams();

  const { data: response, isLoading } =
    useCustomQuery<APIF2FIntvwPlacementRatio>({
      url: '/admin/reports/interviewtoplacements',
      params: {
        ...(queryParams.startDate && { startDate: queryParams.startDate }),
        ...(queryParams.endDate && { endDate: queryParams.endDate }),
      },
    });

  const { data } = response ?? {};

  const { numberOfInterviews = 0, numberOfPlacements = 0 } =
    data?.summary ?? {};

  const ratio = (data?.summary?.ratio ?? 0) * 100;

  const graphData = [
    {
      id: 'Placements',
      value: numberOfPlacements,
      color: colors.darkPurple,
    },
    {
      id: 'F2F Interviews',
      value: numberOfInterviews - numberOfPlacements,
      color: colors.lightPurple,
    },
  ];

  return (
    <div className='flex h-[16rem] w-full flex-col gap-4 rounded-lg border border-neutral-40 bg-white p-4'>
      {isLoading && (
        <div className='size-full animate-pulse rounded-md bg-gray-400' />
      )}
      {!isLoading && (
        <>
          <div className='flex size-full flex-col'>
            <p className='truncate text-lg font-bold'>
              F2F Interview to Placement Ratio
            </p>
            <div className='justify-left flex size-full items-center'>
              <div className='relative flex size-full items-center justify-center'>
                <div className='size-full'>
                  <ResponsivePie
                    data={graphData}
                    margin={{ top: 10, right: 10, bottom: 32, left: 10 }}
                    innerRadius={0.85}
                    padAngle={0.7}
                    startAngle={0}
                    cornerRadius={0}
                    activeOuterRadiusOffset={8}
                    colors={[colors.darkPurple, colors.lightPurple]}
                    enableArcLinkLabels={false}
                    enableArcLabels={false}
                    isInteractive={false}
                    legends={[
                      {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 30,
                        itemsSpacing: 16,
                        itemWidth: 80,
                        itemHeight: 15,
                        itemTextColor: '#000',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000',
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </div>
                <span className='absolute left-1/2 top-1/2 -mt-2 flex h-fit w-fit -translate-x-1/2 -translate-y-1/2 transform items-center justify-center text-4xl'>
                  {!Number.isNaN(ratio) && `${ratio.toFixed(0)}%`}
                </span>
              </div>
              <div className='flex flex-col gap-4'>
                <div className='font-medium text-secondary-80'>
                  <div className='text-sm'>F2F Interviews</div>
                  <div className=''>{numberOfInterviews}</div>
                </div>
                <div className='font-medium text-secondary-80'>
                  <div className='text-sm'>Placements</div>
                  <div className=''>{numberOfPlacements}</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default F2FIntvwPlacementRatio;
