import { Menu } from 'lucide-react';
import { useCallback } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { Dropdown } from '../../assets/fonts/Icons/icons';
import { useGlobalState } from '../../context/GlobalContext';
import { usePhone } from '../../context/PhoneContext';
import { api } from '../../services/api';
import colors from '../../styles/colors';
import { Sheet, SheetContent, SheetTrigger, SheetClose } from '../ui/sheet';
import AppLauncher from './AppLauncher';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from './Dropdown';
import { MleeIcon, UserIcon } from './Icons';

const navItems = [
  {
    label: 'Dashboard',
    href: '/',
  },
  {
    label: 'Contacts',
    href: '/contacts',
  },
  {
    label: 'Organizations',
    href: '/organizations',
  },
  {
    label: 'Requisitions',
    href: '/requisitions?status=Available',
  },
  {
    label: 'Lists',
    href: '/lists',
  },
  {
    label: 'Admin',
    href: '/admin',
    subNavItems: [
      {
        label: 'Hot Sheets',
        href: '/admin/hot-sheets?pipelinePositionOrder=4&maxPipelinePositionOrder=8',
      },
      { label: 'Company Productivity', href: '/admin/productivity' },
      { label: 'Users', href: '/admin/users' },
    ],
  },
];

const PageHeader = () => {
  const navigate = useNavigate();
  const { me } = useGlobalState();
  const { setCallPhone } = usePhone();

  const handleLogout = useCallback(async () => {
    await api.restful('/logout');

    localStorage.removeItem('user');

    const info: any = {
      activeDialPad: false,
    };
    setCallPhone(info);

    navigate('/login');
  }, [navigate, setCallPhone]);

  return (
    <header className='flex w-full flex-col gap-4 border-b-2 border-b-neutral-40 bg-white px-6 py-2 pt-4'>
      <div className='m-auto flex w-full max-w-[3000px] flex-col gap-4 '>
        <div className='flex items-center justify-between'>
          <Link to='/'>
            <MleeIcon width={100} />
          </Link>
          <DropdownMenu>
            <DropdownMenuTrigger className='hidden sm:block'>
              <UserIcon backgroundColor={colors.secondary.DEFAULT} size={32} />
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className='bg-white'
              collisionPadding={{ right: 20 }}
            >
              <div>
                <AppLauncher />
              </div>
              <DropdownMenuItem onClick={handleLogout}>
                Log Out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Sheet>
            <SheetTrigger className='sm:hidden'>
              <Menu size={28} />
            </SheetTrigger>
            <SheetContent side='left' className='bg-white'>
              <div className='bg-white p-6'>
                <nav className='sm:hidden'>
                  <ul className='flex flex-col gap-4'>
                    {navItems
                      .flatMap((item) =>
                        item.subNavItems ? item.subNavItems : item,
                      )
                      .filter((item) =>
                        item.label === 'Admin' ? me?.admin : true,
                      )
                      .map((item, idx) => {
                        return (
                          <li
                            className='relative flex-initial'
                            key={`${item.label}-${idx}`}
                          >
                            <SheetClose asChild>
                              <div>
                                <NavLink
                                  to={item.href}
                                  className={({ isActive }) => {
                                    return isActive ||
                                      (window.location.pathname || '') ===
                                        item?.href
                                      ? 'border-b-[3px] border-dark-blue'
                                      : '';
                                  }}
                                  end
                                >
                                  {item.label}
                                </NavLink>
                              </div>
                            </SheetClose>
                          </li>
                        );
                      })}
                  </ul>
                </nav>
              </div>
            </SheetContent>
          </Sheet>
        </div>
        <nav className='hidden sm:block'>
          <ul className='flex gap-6'>
            {navItems
              .filter((item) => (item.label === 'Admin' ? me?.admin : true))
              .map((item, idx) => {
                return (
                  <li className='relative' key={`${item.label}-${idx}`}>
                    {item?.subNavItems?.length ? (
                      <DropdownMenu>
                        <DropdownMenuTrigger className='hidden sm:block'>
                          <div className='relative'>
                            <div
                              className={
                                item?.subNavItems
                                  ?.map((item) => item.href)
                                  .some((path) => {
                                    if (window.location.pathname === '/')
                                      return false;

                                    return path.includes(
                                      window.location.pathname,
                                    );
                                  })
                                  ? 'flex after:absolute after:-bottom-[9px] after:block after:h-[3px] after:w-0 after:animate-expand-link after:bg-dark-blue'
                                  : 'flex'
                              }
                            >
                              {item.label}
                              <img src={Dropdown} alt='dropdown arrow' />
                            </div>
                          </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                          className='ml-[90px] bg-white'
                          collisionPadding={{ right: 20 }}
                        >
                          {item?.subNavItems?.map((item) => (
                            <NavLink key={item?.label} to={item.href} end>
                              <DropdownMenuItem>{item?.label}</DropdownMenuItem>
                            </NavLink>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    ) : (
                      <NavLink
                        to={item.href}
                        className={({ isActive }) =>
                          isActive || window.location.pathname === item?.href
                            ? 'after:absolute after:-bottom-[9px] after:block after:h-[3px] after:w-0 after:animate-expand-link after:bg-dark-blue'
                            : ''
                        }
                        end
                      >
                        <div className=''>{item.label}</div>
                      </NavLink>
                    )}
                  </li>
                );
              })}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default PageHeader;
