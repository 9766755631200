import {
  Stack as MSStack,
  IStackProps,
  IDropdownProps,
  Dropdown as DD,
  Spinner,
  ComboBox,
  IComboBoxProps,
} from '@fluentui/react';
import set from 'lodash/set';
import unset from 'lodash/unset';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useGlobalState } from '../../context/GlobalContext';
import { cn } from '../../lib/utils';
import { theme } from '../../utils/theme';
import { Button } from './Button';
import { ErrorMessage } from './Error';
import { SearchIcon } from './Icons';
import { Select } from './Select';

export * from './Error';

export interface IAction {
  type?: 'remove' | 'update' | 'set' | 'reset';
  path?: string;
  value?: any;
}

export const commonReducer = (state: any, action: IAction) => {
  switch (action.type) {
    case 'remove': {
      const removedState = { ...state };
      if (action.path) unset(removedState, action.path);
      return removedState;
    }

    case 'update':
      return set({ ...state }, action.path || '', action.value);

    case 'set': {
      const newState = { ...state, ...action.value };
      return newState;
    }

    case 'reset': {
      return action.value;
    }

    default: {
      const newState = { ...state };
      return set(newState, action.path || '', action.value);
    }
  }
};

interface IStack extends IStackProps {
  spacing?: number;
  children: React.ReactNode;
}

export function Stack({ spacing, ...props }: IStack) {
  let tokens;
  if (typeof props.tokens === 'object') {
    tokens = props.tokens;
  }
  return (
    <MSStack
      tokens={{
        childrenGap: spacing,
        maxHeight: tokens?.maxHeight,
        maxWidth: tokens?.maxWidth,
        padding: tokens?.padding,
      }}
      {...props}
    >
      {props.children}
    </MSStack>
  );
}

interface SearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  containerClassNames?: string;
}

export function Search(props: SearchProps) {
  const classNames = useMemo(() => {
    const classNames = ['relative block'];
    if (props.className) classNames.push(props.className);
    return classNames.join(' ');
  }, [props.className]);

  return (
    <label className={classNames}>
      <span className='sr-only'>Search</span>
      <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
        <SearchIcon size={18} />
      </span>
      <input
        {...props}
        className='block w-full rounded-md border border-neutral-40 bg-white py-1 pl-9 pr-3 font-normal shadow-sm placeholder:italic placeholder:text-secondary-80 focus:border-secondary-80 focus:outline-none focus:ring-1 focus:ring-secondary-80 sm:text-sm'
        placeholder='Search'
        type='search'
        name='search'
      />
    </label>
  );
}

interface ITextInput
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  htmlFor?: string;
  placeholder?: string;
  textArea?: boolean;
  srOnly?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  handleChange?: (value: any) => void;
  errorMessage?: string;
}

/**
 * @deprecated: Use TextInput from src/components/common/TextInput.tsx
 */
export function TextInput({
  placeholder,
  type = 'text',
  htmlFor,
  label,
  srOnly,
  required,
  fullWidth,
  handleChange,
  textArea: ta,
  errorMessage,
  prefix,
  ...props
}: ITextInput) {
  const screenReader = srOnly ? 'sr-only' : '';

  const hc = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      handleChange && handleChange(ev.target.value);
    },
    [handleChange],
  );

  const hca = useCallback(
    (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      handleChange && handleChange(ev.target.value);
    },
    [handleChange],
  );

  return (
    <Stack
      styles={{
        root: { width: fullWidth ? '100%' : 'initial' },
      }}
    >
      {label && (
        <label
          className={`p ${screenReader}`}
          htmlFor={(htmlFor ?? label).replaceAll(' ', '')}
        >
          {label}
          {required && (
            <span style={{ color: theme.danger, paddingLeft: 4 }}>*</span>
          )}
        </label>
      )}
      {ta ? (
        <textarea
          placeholder={placeholder}
          className={cn(
            `block w-full flex-grow rounded-md border border-slate-300 bg-white px-3 py-1 shadow-sm placeholder:text-neutral-600 focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm`,
            props.className,
          )}
          id={(htmlFor ?? label).replaceAll(' ', '')}
          required={required}
          onChange={hca}
          value={props.value}
          style={props.style}
          disabled={props.disabled}
        />
      ) : (
        <div className='relative'>
          {prefix && (
            <div className='pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3.5'>
              #
            </div>
          )}
          <input
            type={type}
            placeholder={placeholder}
            className={cn(
              `block w-full rounded-md border border-slate-300 bg-white py-1 shadow-sm placeholder:text-neutral-600 focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm ${prefix ? 'pl-10 pr-3' : 'px-3'}`,
              props.className,
            )}
            id={(htmlFor ?? label).replaceAll(' ', '')}
            required={required}
            value={props.value}
            {...props}
            onChange={hc}
          />
        </div>
      )}
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </Stack>
  );
}

const StyledDropdownWrapper = styled<any>(Stack)`
  .ms-Dropdown-title {
    background-color: ${({ $disabled }) => ($disabled ? '#F9F9F9' : 'white')};
  }
  .ms-Dropdown-title:hover {
    border: ${({ $disabled }) =>
      $disabled ? '1px solid #E0E0E0 !important' : '1px solid black'};
  }
`;

export type DropdownProps = IDropdownProps & {
  color?: string;
  fullWidth?: boolean;
  clearable?: boolean;
  activeUserOnly?: boolean;
  disabledOption?: boolean;
};

export function Dropdown({ errorMessage, ...props }: DropdownProps) {
  return (
    <StyledDropdownWrapper $disabled={props.disabled}>
      <DD
        styles={{
          root: {
            borderRadius: 6,
            width: props.fullWidth ? '100%' : 'initial',
          },
          title: {
            border: '1px solid #E0E0E0;',
            borderRadius: 6,
            height: 30,
          },
          label: {
            color: props.color ?? 'black',
            fontSize: 16,
          },
          dropdownItemsWrapper: {
            maxHeight: 240,
          },
        }}
        {...props}
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </StyledDropdownWrapper>
  );
}

const ComboBoxWrapper = styled<any>(Stack)`
  input:hover {
    border: initial !important;
  }
  .ms-ComboBox,
  input,
  button {
    background-color: ${({ $disabled }) =>
      $disabled ? '#F9F9F9 !important' : 'initial'};
  }
  .ms-ComboBox {
    border-radius: 6px !important;

    button {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  .ms-ComboBox::after {
    border: 1px solid #e0e0e0 !important;
    border-radius: 6px !important;
  }
  .ms-ComboBox:hover::after {
    border: ${({ $disabled }) =>
      $disabled
        ? '1px solid #E0E0E0 !important'
        : '1px solid black !important'};
  }
`;

export function MultiSelect(props: IComboBoxProps) {
  return (
    <ComboBoxWrapper $disabled={props.disabled}>
      <ComboBox
        styles={{
          label: {
            color: 'black',
            fontSize: 16,
          },
        }}
        {...props}
      />
    </ComboBoxWrapper>
  );
}

const Progress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  .progress-bar2 {
    height: 7px;
    border-radius: 30px;
    /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05)); */
    transition: 0.4s linear;
    transition-property: width, background-color;
  }

  .progress-moved .progress-bar2 {
    background-color: #167dff;
    animation: progressAnimation 6s;
  }
  span {
    font-size: 12px;
  }
`;

export function ProgressBar({ percent }: { percent: number }) {
  return (
    <Progress>
      <div
        className='progress2 progress-moved'
        style={{ width: `${percent}%` }}
      >
        <div className='progress-bar2'></div>
      </div>
      <span>{percent}%</span>
    </Progress>
  );
}

interface IButtonGroup {
  items: {
    text: string;
    onClick?: any;
    icon: any;
  }[];
  collapse?: boolean;
}
export function BlackButtonGroup({ items, collapse }: IButtonGroup) {
  return (
    <div className='flex items-center'>
      {items.map(({ icon, text, onClick }, i) => (
        <div key={i} className='flex justify-end'>
          <button
            className={`flex items-center gap-2 rounded border px-2 py-1 hover:bg-gray-100  ${
              i !== items.length - 1 ? 'border-r-2' : ''
            }`}
            onClick={onClick}
          >
            {icon} {!collapse && text}
          </button>
        </div>
      ))}
    </div>
  );
}

interface ICardWrapperButtons
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
}

const CardButton = styled.button`
  span {
    font-size: 14px;
  }

  &[aria-selected='true'] {
    background-color: #f2edff;
  }
`;

export const CardWrapperButton = ({
  children,
  ...props
}: ICardWrapperButtons) => {
  return (
    <CardButton className='rounded-lg bg-gray-100 p-4' {...props}>
      {children}
    </CardButton>
  );
};

interface IDetailsBox extends IStackProps {
  edit: boolean;
  toggleEdit: () => void;
  onSubmit: () => void;
  title: string;
  disableEdit?: boolean;
  children: React.ReactNode;
  isPending: boolean;
}

export function DetailsBox({
  title,
  edit,
  toggleEdit,
  onSubmit,
  children,
  disableEdit,
  isPending,
  ...props
}: IDetailsBox) {
  return (
    <Stack className='gray-border rounded-md p-4' spacing={12} {...props}>
      <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
        <span className='truncate text-lg font-bold'>{title}</span>
        {disableEdit ? null : edit ? (
          <Stack horizontal spacing={8} className='fade-in'>
            <Button variant='secondary' onClick={toggleEdit}>
              Cancel
            </Button>
            <Button onClick={onSubmit}>Save</Button>
          </Stack>
        ) : (
          <Button
            variant='ghost'
            onClick={toggleEdit}
            isLoading={isPending}
            disabled={isPending}
          >
            <span className='text-link underline'>Edit</span>
          </Button>
        )}
      </Stack>

      {children}
    </Stack>
  );
}

export function CommonSpinner({
  size,
  className,
}: {
  size?: 'lg' | 'md' | 'sm';
  className?: string;
}) {
  const styles = useMemo(() => {
    const sizes = {
      default: {
        width: 20,
        height: 20,
        borderWidth: 1.5,
      },
      sm: {
        width: 40,
        height: 40,
        borderWidth: 3,
      },
      md: {
        width: 60,
        height: 60,
        borderWidth: 4.5,
      },
      lg: {
        width: 100,
        height: 100,
        borderWidth: 8,
      },
    };
    return sizes[size ?? 'default'];
  }, [size]);

  return (
    <Spinner
      className={className ?? ''}
      styles={{
        circle: {
          borderColor: `${theme.purple} #dbcdfd #dbcdfd`,
          ...styles,
        },
      }}
    />
  );
}

export const EmptyWrapper = styled(Stack).attrs({
  className: 'bg-white m-10 mt-5 gray-border shadow',
})`
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export function EmptyState({
  header,
  icon,
  link,
  title,
  error,
  iconSize = 24,
  className = '',
  handleClear,
}: {
  icon: React.FC<any>;
  header?: React.ReactNode;
  link: React.ReactNode;
  title: string;
  error?: boolean;
  noWrapper?: boolean;
  className?: string;
  iconSize?: number;
  handleClear?: () => void;
}) {
  const Icon = icon;

  return (
    <div
      className={cn(
        `flex size-full flex-col items-center justify-center`,
        className,
      )}
    >
      {header && header}
      <div className='flex flex-col items-center justify-center gap-2 rounded-md bg-white p-6'>
        <div className='slide-in rounded-full bg-gray-100 p-2'>
          <Icon size={iconSize} color={error ? theme.danger : '#868686'} />
        </div>
        <h1 className='slide-in text-xl font-bold'>{title}</h1>
        <div className='slide-in'>{link}</div>
        {handleClear && (
          <Button variant='outline' onClick={handleClear}>
            Clear filters
          </Button>
        )}
      </div>
    </div>
  );
}

interface IClearButton
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

export const ClearFilters = (props: IClearButton) => {
  return (
    <button
      style={{
        position: 'absolute',
        top: 20,
        right: 50,
        zIndex: 2,
      }}
      type='button'
      className='clear-btn p-2 py-1'
      {...props}
    >
      Clear All
    </button>
  );
};

export function UsersDropdown(props: Omit<DropdownProps, 'options'>) {
  const { users, inActiveUsers } = useGlobalState();

  const allUsers =
    (props.activeUserOnly
      ? users
      : users && inActiveUsers && [...users, ...inActiveUsers]) ?? [];

  const activeUser = props.selectedKey
    ? allUsers.find((user) => user.userId === props.selectedKey)
    : undefined;

  return (
    <Select
      options={allUsers.map((u) => ({
        value: u.userId,
        label: u.active ? u.fullName : `${u.fullName} (Inactive)`,
        userId: u.userId,
        email: u.emailAddress,
        isDisabled: props.disabledOption && !u.active ? true : false,
      }))}
      required={props.required}
      label='User Assigned'
      errorMessage={props.errorMessage}
      isDisabled={props.disabled}
      placeholder={props.placeholder ?? ''}
      value={
        props.selectedKey && activeUser
          ? {
              value: activeUser.userId,
              label: activeUser.active
                ? activeUser.fullName
                : `${activeUser.fullName} (Inactive)`,
            }
          : ''
      }
      onChange={(user: any) =>
        props.onChange &&
        props?.onChange(
          undefined as unknown as React.FormEvent<HTMLDivElement>, // added empty event to pass to onChange function to make it work for all places
          {
            key: user.userId,
            text: user.label,
            email: user.email,
          } as any, // added any to make existing onChange functions work without any change
        )
      }
    />
  );
}
