import { useQueryParams } from '../../hooks/useQueryParams';
import { ListType } from '../../types/lists';
import { MultiSelectDropdownFilter } from '../common/MultiSelectDropdownFilter';

function ListTypesSelect() {
  const { queryParams, updateQueryParam, removeQueryParam } = useQueryParams();

  const listTypeOptions = Object.values(ListType).map((value) => ({
    id: value,
    title: value,
  }));

  return (
    <MultiSelectDropdownFilter
      classNames={{ container: 'w-40' }}
      items={listTypeOptions}
      handleChange={(type) =>
        updateQueryParam('template', type, {
          multiple: false,
          resetPageOnChange: true,
        })
      }
      value={[(queryParams?.template || '') as string]}
      isClearable={!!queryParams?.template}
      onClear={() => removeQueryParam('template')}
      isChecked={!!queryParams?.template}
      placeholder={'List type'}
    />
  );
}

export default ListTypesSelect;
