import { CaretDownIcon, CheckIcon } from '@radix-ui/react-icons';
import { CommandLoading } from 'cmdk';
import { useEffect, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';

import { ErrorMessage } from '../../components/common';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../../components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/ui/popover';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { cn } from '../../lib/utils';
import { AddContact } from '../../pages/contacts/AddContact';
import { ContactDetails } from '../../pages/contacts/ContactDetails';
import { APIContact } from '../../types/contacts';
import { maskPhoneNumber } from '../../utils/phone';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../ui/alert-dialog';

export type StructuredTitle = {
  titleId: string;
  parentTitleId: string;
  nchcrId: string;
  mainSpecialty: string;
  title: string;
  parentNchcrId: string;
};

export function ContactSelectDropdown({
  errorMessage,
  handleChange,
  disabled,
  value,
  label,
  classNames,
  required,
  selectedContactId,
  onClear,
  organization,
}: {
  errorMessage?: string;
  disabled?: boolean;
  handleChange: (contact: APIContact) => void;
  onClear: () => void;
  value: string | null;
  label?: string;
  classNames?: {
    container?: string;
    label?: string;
    trigger?: string;
    value?: string;
    content?: string;
    item?: string;
  };
  required?: boolean;
  selectedContactId?: string | null;
  organization: {
    organizationId: string | null;
    organizationName: string | null;
    organizationHasContract: boolean;
  };
}) {
  const { isAdmin } = useGlobalState();

  const [open, setOpen] = useState(false);
  const [showAddContactAlert, setShowAddContactAlert] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [addNewContact, setAddNewContact] = useState(false);
  const [currentOrganizationId, setCurrentOrganizationId] = useState<
    string | null
  >(organization?.organizationId);

  const [searchValue, setSearchValue] = useDebounceValue('', 500);

  const { data, isLoading } = useCustomQuery<APIContact[]>({
    url: `/contacts`,
    params: {
      ...(searchValue && { query: searchValue }),
      ...(organization?.organizationId && {
        organizationId: organization?.organizationId,
      }),
    },
    enabled: !!organization?.organizationId,
    options: { staleTime: 0 },
  });

  const contacts = data?.data;

  useEffect(() => {
    if (!currentOrganizationId && organization?.organizationId) {
      setCurrentOrganizationId(organization?.organizationId);
    }

    if (currentOrganizationId && !organization?.organizationId) {
      setCurrentOrganizationId(null);
      setSelectedContact({});
      onClear();
    }

    if (
      currentOrganizationId &&
      currentOrganizationId !== organization?.organizationId
    ) {
      setCurrentOrganizationId(organization?.organizationId);
      setSelectedContact({});
      onClear();
    }
  }, [organization?.organizationId, currentOrganizationId, onClear]);

  const handleContactSave = (contact: APIContact) => {
    handleChange(contact);
    setOpen(false);
    setSearchValue('');
    setAddNewContact(false);
    setShowAddContactAlert(false);
  };

  const formatContactInfo = (data: APIContact, isAdmin: boolean) => {
    const email = data?.emailAddress || '';
    const phone = data.phone ? maskPhoneNumber(data.phone, isAdmin) : '';
    const phoneExt = data.phoneExt ? `x${data.phoneExt}` : '';

    return [email, `${phone}${phoneExt}`].filter(Boolean).join(' - ');
  };

  const formatNameAndOrganization = (data: APIContact) => {
    const fullName = `${data.firstName || ''} ${data.lastName || ''}`.trim();
    const organizationName = data?.organizationName || '';

    return [fullName, organizationName].filter(Boolean).join(', ');
  };

  return (
    <>
      <div
        className={cn(
          'mt-4 flex w-full max-w-full flex-col gap-1',
          classNames?.container ?? '',
        )}
      >
        {label && (
          <div className='relative w-fit'>
            <div className={cn('font-bold', classNames?.label)}>{label}</div>
            {required && (
              <div className='absolute -right-2 top-0 text-error'>*</div>
            )}
          </div>
        )}
        <div>
          <Popover open={open} onOpenChange={!disabled ? setOpen : () => {}}>
            <PopoverTrigger disabled={disabled} asChild>
              <div
                aria-expanded={open}
                aria-disabled={disabled}
                className={cn(
                  `${open && 'border-sky-500 ring-sky-500'} flex h-8 w-full min-w-0 max-w-full items-center justify-between text-nowrap rounded-[6px] border border-slate-300 bg-white px-2 py-1 text-left shadow-sm placeholder:text-neutral-600 hover:border-neutral sm:text-sm ${disabled && 'bg-neutral-2 text-neutral-48 hover:border-slate-300'}`,
                )}
              >
                <div className='w-full min-w-0 flex-1 truncate'>
                  {value || 'Select contact...'}
                </div>
                <CaretDownIcon className='ml-2 h-6 w-6  shrink-0 opacity-40' />
              </div>
            </PopoverTrigger>
            <PopoverContent
              align='start'
              className='popover-content-width-full w-full bg-white p-0'
            >
              <Command
                filter={() => {
                  return 1;
                }}
              >
                <CommandInput
                  onValueChange={setSearchValue}
                  placeholder='Search a contact...'
                  className='h-9'
                />
                <CommandList>
                  {isLoading && (
                    <CommandLoading>
                      <div className='p-2 text-sm'>Processing…</div>
                    </CommandLoading>
                  )}

                  {contacts?.length === 0 && !isLoading && (
                    <CommandEmpty>
                      <div className='flex flex-col items-center gap-2'>
                        <div>{`No matching contacts found under ${organization.organizationName}`}</div>
                        <div
                          className='primary flex max-w-36 items-center gap-2 font-medium'
                          onClick={(e) => {
                            e.preventDefault();
                            setAddNewContact(true);
                            setShowAddContactAlert(true);
                            setSelectedContact({});
                          }}
                        >
                          + Add Contact
                        </div>
                      </div>
                    </CommandEmpty>
                  )}
                  {contacts && (
                    <CommandGroup>
                      {contacts?.map((data) => {
                        const isSelected =
                          selectedContactId === data?.contactId;
                        return (
                          <CommandItem
                            key={data?.contactId}
                            value={data?.contactId}
                            onSelect={(currentValue) => {
                              const contact = contacts?.find(
                                (contact) =>
                                  contact?.contactId === currentValue,
                              );

                              if (contact) {
                                if (
                                  !contact?.firstName ||
                                  (!contact?.emailAddress && !contact?.phone)
                                ) {
                                  setAddNewContact(false);
                                  setSelectedContact(contact);
                                  setShowAddContactAlert(true);
                                } else {
                                  handleChange(contact);
                                  setOpen(false);
                                  setSearchValue('');
                                }
                              }
                            }}
                            className={`${isSelected && 'bg-neutral-40'}`}
                          >
                            <div className='w-[93%]'>
                              <div className='w-full truncate font-medium text-neutral-80'>
                                {formatNameAndOrganization(data)}
                              </div>
                              <div className='gap-2 truncate opacity-70'>
                                {formatContactInfo(data, isAdmin)}
                              </div>
                            </div>

                            <CheckIcon
                              className={cn(
                                'ml-auto h-4 w-4',
                                isSelected ? 'opacity-100' : 'opacity-0',
                              )}
                            />
                          </CommandItem>
                        );
                      })}
                    </CommandGroup>
                  )}
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          {errorMessage && <ErrorMessage message={errorMessage} />}
        </div>
      </div>

      <AlertDialog
        open={showAddContactAlert}
        onOpenChange={() => setShowAddContactAlert(false)}
      >
        <AlertDialogContent className='max-h-[90vh] overflow-auto bg-white px-2'>
          <AlertDialogHeader>
            <AlertDialogTitle className='px-4'>
              {addNewContact ? 'Create Contact' : 'Update Contact'}
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogDescription>
            <div className='flex flex-col gap-4'>
              {!addNewContact && (
                <p className='px-4'>
                  Name, Email address and Phone Number is mandatory, Please
                  complete missing details to select this contact.
                </p>
              )}
              {addNewContact ? (
                <AddContact
                  contact={selectedContact as APIContact}
                  isUsedAsComponent
                  onCancel={() => setShowAddContactAlert(false)}
                  onSubmit={(contact) => handleContactSave(contact)}
                  organizationOverride={{
                    organizationId: organization?.organizationId,
                    organizationName: organization?.organizationName,
                    organizationHasContract:
                      organization?.organizationHasContract,
                    organizationInputDisabled: true,
                  }}
                />
              ) : (
                <ContactDetails
                  isUsedAsComponent
                  onCancel={() => setShowAddContactAlert(false)}
                  contact={selectedContact as APIContact}
                  onContactSave={(contact) =>
                    handleContactSave(contact as APIContact)
                  }
                />
              )}
            </div>
          </AlertDialogDescription>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
