import axios from 'axios';
import { createContext, useContext, useRef } from 'react';

import { apiBase } from '../hooks/useCustomMutation';

export type RingCentralContextType = {
  fetchMatchedContacts: (phoneNumbers: string[]) => Promise<
    | {
        [k: string]: RingCentralContact[];
      }
    | undefined
  >;
};

type RingCentralContact = {
  id: string;
  type: string;
  name: string;
  phoneNumbers: Array<{ phoneNumber: string; phoneType: string }>;
  hidden: boolean;
};

export const RingCentralContext = createContext<
  RingCentralContextType | undefined
>(undefined);

const mapNumberToContact = (map: Map<string, RingCentralContact[] | null>) => {
  return Array.from(map.entries()).reduce(
    (acc, [key, value]) => {
      if (value !== null && value.length > 0) {
        acc[key] = value;
      }
      return acc;
    },
    {} as { [k: string]: RingCentralContact[] },
  );
};

export function RingCentralProvider({ children }: any) {
  const matchedContactsRef = useRef<Map<string, any>>(new Map());

  const fetchMatchedContacts = async (phoneNumbers: string[]) => {
    const matchedContacts = matchedContactsRef.current;
    const newPhoneNumbers = phoneNumbers.filter(
      (number) => !matchedContacts.has(number),
    );

    if (newPhoneNumbers.length === 0) {
      if (matchedContacts.size > 0) {
        return mapNumberToContact(matchedContacts);
      } else {
        return undefined;
      }
    }

    try {
      const res = await axios.post(`${apiBase}/phonelookup`, newPhoneNumbers, {
        withCredentials: true,
      });

      newPhoneNumbers.forEach((phoneNumber) => {
        const foundNumber = res.data.find(
          (contact: any) => contact.phoneNumber === phoneNumber,
        );

        const formattedContact = foundNumber
          ? [
              {
                id: foundNumber.id,
                type: 'ContactService',
                name: foundNumber.name,
                phoneNumbers: [
                  {
                    phoneNumber: foundNumber.phoneNumber,
                    phoneType: 'direct',
                  },
                ],
                hidden: true,
              },
            ]
          : null;

        matchedContacts.set(phoneNumber, formattedContact);
      });

      return mapNumberToContact(matchedContacts);
    } catch (error) {
      return mapNumberToContact(matchedContacts);
    }
  };

  return (
    <RingCentralContext.Provider value={{ fetchMatchedContacts }}>
      {children}
    </RingCentralContext.Provider>
  );
}

export function useRingCentralContext() {
  const context = useContext(RingCentralContext);

  if (context === undefined) {
    throw new Error(
      'useRingCentralContext must be used within a RingCentralProvider',
    );
  }

  return context;
}
