import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import {
  ArrowUpRightIcon,
  BuildingIcon,
  Newspaper,
  PhoneIcon,
} from 'lucide-react';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import ListBulkActions from '../../components/bulk-actions/ListBulkActions';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '../../components/common/Dropdown';
import { ThreeThingIcon } from '../../components/common/Icons';
import { Checkbox } from '../../components/ui/checkbox';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../components/ui/tooltips';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { useQueryParams } from '../../hooks/useQueryParams';
import colors from '../../styles/colors';
import '../../styles/value_gradient_circle.css';
import { APIContact } from '../../types/contacts';
import { PageTypes } from '../../types/pages';
import {
  APIPipeline,
  APIJobContactPipelinePosition,
} from '../../types/pipeline';
import { CheckboxProps } from '../../types/table';
import { capitalizeName, formatDateString } from '../../utils/helpers';
import { ContactView } from '../contacts/ContactView';
import EditableDateTimePicker from './EditInterviewDateModal';
import ViewAIAnalysisObject from './ViewAIAnalysis';
import ViewResumeSummaryObject from './ViewResumeSummary';

interface LocalCheckboxProps extends CheckboxProps {
  isItemSelected: (id: string) => boolean;
  isItemDisabled: (id: string) => boolean;
  handleSelectionChange: (id: string) => void;
}

export default function ContactCard({
  contact,
  dropdownId,
  setDropdownId,
  setIsDraggingEnabled,
  setOutOfProcessId,
  pipelineRecord,
  hideContact = false,
  checkboxProps,
}: {
  contact: APIContact;
  dropdownId: string | null;
  setDropdownId: any;
  setIsDraggingEnabled: any;
  setOutOfProcessId?: React.Dispatch<React.SetStateAction<string | null>>;
  pipelineRecord: APIJobContactPipelinePosition;
  hideContact?: boolean;
  checkboxProps: LocalCheckboxProps;
}) {
  const { queryParams, setQueryParams } = useQueryParams();
  const { id } = useParams();
  const { refetch: refetchPipelineData } = useCustomQuery<APIPipeline>({
    url: `/jobs/${id}/pipeline`,
    params: { limit: 100 },
  });

  const [isContactOpen, setIsContactOpen] = useState(false);
  const [editInterviewDate, setEditInterviewDate] = useState<{
    date: string | null;
    type: 'virtualInterviewDate' | 'inPersonInterviewDate';
  } | null>(null);

  const [viewAIAnalysis, setViewAIAnalysis] = useState<{
    pipelineRecord: APIJobContactPipelinePosition | null;
  } | null>(null);

  const [viewResumeSummary, setViewResumeSummary] = useState<{
    contact: APIContact | null;
  } | null>(null);

  const virtualInterviewDate = useMemo(() => {
    if (!pipelineRecord.virtualInterviewDate) return null;
    const convertedDate = toZonedTime(
      pipelineRecord.virtualInterviewDate,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );

    return (
      <Tooltip>
        <TooltipTrigger className='w-full'>
          <div className='flex items-center gap-2 truncate text-gray-500'>
            <PhoneIcon size={16} className='flex-shrink-0' />
            <span className='truncate text-sm'>
              {format(convertedDate, 'MMM do, yyyy')}
            </span>
          </div>
        </TooltipTrigger>
        <TooltipContent className='font-medium text-neutral-80'>
          Phone/Virtual Interview:{' '}
          {format(convertedDate, 'MMM do, yyyy @ h:mm a')}
        </TooltipContent>
      </Tooltip>
    );
  }, [pipelineRecord.virtualInterviewDate]);

  const inPersonInterviewDate = useMemo(() => {
    if (!pipelineRecord.inPersonInterviewDate) return null;
    const convertedDate = toZonedTime(
      pipelineRecord.inPersonInterviewDate,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );

    return (
      <Tooltip>
        <TooltipTrigger className='w-full'>
          <div className='flex items-center gap-2 truncate text-gray-500'>
            <BuildingIcon size={16} className='flex-shrink-0' />
            <span className='truncate text-sm'>
              {format(convertedDate, 'MMM do, yyyy')}
            </span>
          </div>
        </TooltipTrigger>
        <TooltipContent className='font-medium text-neutral-80'>
          In-Person Interview: {format(convertedDate, 'MMM do, yyyy @ h:mm a')}
        </TooltipContent>
      </Tooltip>
    );
  }, [pipelineRecord.inPersonInterviewDate]);

  const isDropdownOpen = dropdownId === contact.contactId;
  const showDateDropdownOptions =
    pipelineRecord.pipelinePosition === 'Phone/Virtual Interview' ||
    pipelineRecord.pipelinePosition === 'In-Person Interview' ||
    pipelineRecord.pipelinePosition === 'Offer Pending' ||
    pipelineRecord.pipelinePosition === 'Placed';

  return (
    <>
      <div
        className={`w-full  cursor-move items-start gap-3 rounded border border-neutral-40 bg-white px-3 py-2  ${hideContact ? 'hidden' : 'flex'}`}
      >
        <div className='flex w-full items-center gap-3 truncate'>
          <table>
            <tbody>
              <tr>
                <td
                  style={{ border: 'white' }}
                  align={'center'}
                  valign={'middle'}
                >
                  <Checkbox
                    id={contact.contactId}
                    checked={checkboxProps.isItemSelected(contact.contactId)}
                    disabled={checkboxProps.isItemDisabled(contact.contactId)}
                    onClick={() => {
                      checkboxProps.handleSelectionChange(contact.contactId);
                      setQueryParams({
                        ...queryParams,
                        selectedContacts: checkboxProps.isItemSelected(
                          contact.contactId,
                        )
                          ? Array.isArray(queryParams.selectedContacts)
                            ? queryParams.selectedContacts.filter(
                                (id: string) => id !== contact.contactId,
                              )
                            : []
                          : [
                              ...(Array.isArray(queryParams.selectedContacts)
                                ? queryParams.selectedContacts
                                : []),
                              contact.contactId,
                            ],
                      });
                    }}
                    className='mb-6' // This is a temporary fix for the checkbox alignment
                  />
                </td>
              </tr>
              <tr>
                <td style={{ border: 'white' }} align={'center'} valign={'top'}>
                  {pipelineRecord.applicantAIMatchScore != null && (
                    <>
                      <span
                        className='text-1xl cursor-pointer'
                        onClick={(e) => {
                          e.preventDefault();
                          setViewAIAnalysis({
                            pipelineRecord: pipelineRecord,
                          });
                        }}
                        style={{ color: 'blue' }}
                      >
                        <span
                          className='number-container'
                          style={{
                            backgroundColor:
                              pipelineRecord.applicantAIMatchScore > 70
                                ? 'rgb(94, 195, 127)'
                                : 'red',
                          }}
                        >
                          {' '}
                          {pipelineRecord.applicantAIMatchScore ?? '\u00A0'}
                        </span>
                      </span>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <div className='flex flex-col items-start gap-2 truncate'>
            <button
              onClick={() => {
                setIsContactOpen(true);
                setIsDraggingEnabled(false);
              }}
              className='w-full truncate text-left font-medium text-link underline'
            >
              {capitalizeName(`${contact.firstName} ${contact.lastName}`)}
            </button>
            <div className='w-full truncate text-left text-sm text-gray-500'>
              {contact.title}
            </div>
            {virtualInterviewDate}
            {inPersonInterviewDate}
            <div className='text-xs'>
              {formatDateString(pipelineRecord.insertDate)}
            </div>
          </div>
        </div>

        {
          <ListBulkActions
            checkboxProps={checkboxProps}
            itemType={PageTypes.Contacts}
          />
        }

        <DropdownMenu
          onOpenChange={(open) => {
            setDropdownId(open ? contact.contactId : null);
            setIsDraggingEnabled(!open);
          }}
          open={isDropdownOpen}
          modal={false}
        >
          <DropdownMenuTrigger className='px-1 text-neutral-40' asChild>
            <div className='cursor-pointer px-1.5 py-1'>
              <ThreeThingIcon color={colors.neutral.DEFAULT} />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            onClick={(e) => e.preventDefault()}
            className='bg-white'
            collisionPadding={24}
          >
            <DropdownMenuItem
              onClick={(e) => {
                e.preventDefault();
                setOutOfProcessId?.(contact.contactId);
                setIsDraggingEnabled(true);
              }}
              className='flex gap-2'
            >
              <ArrowUpRightIcon size={16} />
              <span>Out of process</span>
            </DropdownMenuItem>

            {contact.resumeSummary != null && (
              <>
                <DropdownMenuItem
                  className='flex gap-2'
                  onClick={(e) => {
                    e.preventDefault();
                    setViewResumeSummary({
                      contact: contact,
                    });
                  }}
                >
                  <Newspaper size={16} />
                  <span>View AI resume summary</span>
                </DropdownMenuItem>
              </>
            )}

            {showDateDropdownOptions && (
              <>
                <DropdownMenuItem
                  className='flex gap-2'
                  onClick={(e) => {
                    e.preventDefault();
                    setEditInterviewDate({
                      date: pipelineRecord?.virtualInterviewDate,
                      type: 'virtualInterviewDate',
                    });
                  }}
                >
                  <PhoneIcon size={16} />
                  <span>
                    {virtualInterviewDate
                      ? 'Edit phone/virtual interview'
                      : 'Add phone/virtual interview'}
                  </span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className='flex gap-2'
                  onClick={(e) => {
                    e.preventDefault();
                    setEditInterviewDate({
                      date: pipelineRecord?.inPersonInterviewDate,
                      type: 'inPersonInterviewDate',
                    });
                  }}
                >
                  <BuildingIcon size={16} />
                  {inPersonInterviewDate
                    ? 'Edit in-person interview'
                    : 'Add in-person interview'}
                </DropdownMenuItem>
              </>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <ContactView
        isOpen={isContactOpen}
        contactId={contact.contactId}
        onClose={() => {
          setIsContactOpen(false);
          setIsDraggingEnabled(true);
        }}
        onContactSave={() => refetchPipelineData()}
      />

      {editInterviewDate && (
        <EditableDateTimePicker
          editInterviewDate={editInterviewDate}
          pipelineRecord={pipelineRecord}
          handleClose={() => {
            setEditInterviewDate(null);
          }}
          refetchPipelineData={refetchPipelineData}
        />
      )}

      {viewAIAnalysis && (
        <ViewAIAnalysisObject
          viewAIAnalysis={viewAIAnalysis}
          pipelineRecord={pipelineRecord}
          handleClose={() => {
            setViewAIAnalysis(null);
          }}
        />
      )}

      {viewResumeSummary && (
        <ViewResumeSummaryObject
          viewResumeSummary={viewResumeSummary}
          contact={contact}
          handleClose={() => {
            setViewResumeSummary(null);
          }}
        />
      )}
    </>
  );
}
