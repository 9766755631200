import { APIJobContactPipelinePosition } from '../types/pipeline';

export const sortJobContactPipelinePositions = (
  positions: APIJobContactPipelinePosition[],
) => {
  return positions.sort(
    (a, b) =>
      new Date(a.updateDate ?? 0).getTime() -
      new Date(b.updateDate ?? 0).getTime(),
  );
};
