import { RadiusIcon } from 'lucide-react';
import { useState, useEffect } from 'react';

import AddressInput from '../../components/common/AddressInput';
import NumberFilterInput from '../../components/common/NumberFilterInput';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../components/ui/tooltips';
import { useDebouncedQueryParam } from '../../hooks/useDebouncedQueryParams';
import { useQueryParams } from '../../hooks/useQueryParams';
import { APIAddress } from '../../types/common';

function RadiusSearchInput() {
  const { queryParams, removeQueryParam, updateQueryParam } = useQueryParams();
  const [radiusMiles, setRadiusMiles] = useDebouncedQueryParam({
    key: 'radiusMiles',
    minLength: 1,
  });
  // default to 100 miles

  const [addressState, setAddressState] = useState<APIAddress | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!queryParams.latlong) {
      setAddressState(undefined);
    }
  }, [queryParams.latlong]);

  const handleAddressChange = (address: APIAddress) => {
    updateQueryParam('latlong', `${address.latitude},${address.longitude}`);
    setAddressState(address);
  };

  const handleAddressClear = () => {
    removeQueryParam('latlong');
  };

  const handleRadiusChange = (value: number | null) => {
    setRadiusMiles(value ? value.toString() : '');
  };

  return (
    <div className='flex h-8 items-center justify-center gap-2 rounded-md border border-neutral-40 bg-neutral-20 p-1'>
      <Tooltip>
        <TooltipTrigger className='flex w-fit items-center'>
          <RadiusIcon size={18} className='text-neutral-60' />
        </TooltipTrigger>
        <TooltipContent>
          {'Search by address and distance from address'}
        </TooltipContent>
      </Tooltip>
      <AddressInput
        selectedAddress={addressState}
        handleChange={handleAddressChange}
        handleClear={handleAddressClear}
        hideLabel
        hideIcon
        classNames={{
          container: 'w-64',
          input: 'h-6',
        }}
      />
      <NumberFilterInput
        disabled={!addressState}
        value={radiusMiles ? Number(radiusMiles) : null}
        onChange={handleRadiusChange}
        classNames={{
          input: 'h-6 w-24',
          inactiveWithValue:
            'border-neutral-40 bg-white text-black hover:border-neutral-60',
        }}
        renderValue={(value: number | null) => {
          if (value) {
            return `${value}mi`;
          }
          return 'Radius (mi)';
        }}
      />
    </div>
  );
}

export default RadiusSearchInput;
