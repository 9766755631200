import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { apiBase } from '../../hooks/useCustomQuery';
import { useQueryParams } from '../../hooks/useQueryParams';
import { APIUser } from '../../types/users';

type OAuthLoginResponse = {
  access_token: string;
  expires_in: number;
  token_type: string;
  user: APIUser;
};

function AuthCallback() {
  const { queryParams } = useQueryParams();
  const hasFired = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    const ringCentralContainer = document.getElementById('rc-widget');
    if (ringCentralContainer) {
      ringCentralContainer.style.display = 'none';
    }
    const ringCentralContainerWidget = document.getElementById(
      'rc-widget-container',
    );
    if (ringCentralContainerWidget) {
      ringCentralContainerWidget.style.display = 'contents';
    }

    return () => {
      if (ringCentralContainer) {
        ringCentralContainer.style.display = 'block';
      }
      if (ringCentralContainerWidget) {
        ringCentralContainerWidget.style.display = 'flex';
      }
    };
  }, []);

  useEffect(() => {
    if (hasFired.current) return;

    handleAuthCallback();
    hasFired.current = true;
    // eslint-disable-next-line
  }, []);

  function successHandler({ user }: { user: APIUser }) {
    sessionStorage.removeItem('auth_state');
    sessionStorage.removeItem('auth_nonce_verifier');
    sessionStorage.removeItem('code_verifier');

    localStorage.setItem('user', JSON.stringify(user));

    navigate('/');
  }

  async function handleAuthCallback() {
    const code = queryParams.code;
    const state = queryParams.state;

    if (state !== sessionStorage.getItem('auth_state')) {
      return navigate('/login?error=auth');
    }

    if (!code) {
      return navigate('/login?error=auth');
    }

    let tokenResponse;
    try {
      tokenResponse = await exchangeCodeForTokens(code as string);
    } catch (error) {
      console.error('Error exchanging code for tokens:', error);
      return navigate('/login?error=no');
    }

    try {
      const body = {
        id_token: tokenResponse.id_token,
        nonce: sessionStorage.getItem('auth_nonce_verifier'),
      };

      const response = await axios.post<OAuthLoginResponse>(
        `${apiBase}/login/oauth`,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
          withCredentials: true,
        },
      );

      if (response.status === 200) {
        successHandler({ user: response.data.user });
      }
    } catch (error) {
      return navigate('/login?error=noAccount');
    }
  }

  async function exchangeCodeForTokens(code: string) {
    const tokenEndpoint = 'https://auth.crm.mlee.com/oauth2/token';
    const codeVerifier = sessionStorage.getItem('code_verifier');

    if (!codeVerifier) {
      return navigate('/login?error=auth');
    }

    const params = new URLSearchParams({
      grant_type: 'authorization_code',
      code,
      client_id: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
      redirect_uri: process.env.REACT_APP_COGNITO_REDIRECT_URI as string,
      code_verifier: codeVerifier,
      scope: 'openid email',
    });

    const response = await axios.post(tokenEndpoint, params, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });

    return response.data;
  }

  return <div>Processing authentication...</div>;
}

export default AuthCallback;
