import React, { useMemo } from 'react';

import { EmptyState, CommonSpinner } from '../../components/common';
import { Button } from '../../components/common/Button';
import { SearchIcon } from '../../components/common/Icons';
import { Pagination } from '../../components/common/Pagination';
import { useQueryParams } from '../../hooks/useQueryParams';
import { PageTypes } from '../../types/pages';
import { CheckboxProps, HandleRowClick } from '../../types/table';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltips';

interface PageContentProps<T, FP = any, TP = any> {
  contentType: PageTypes;
  data: T[];
  pages: number | undefined;
  isLoading: boolean;
  FiltersComponent: React.ComponentType<FP>;
  filtersProps?: FP;
  TableComponent: React.ComponentType<TP & { hideBulkActions: boolean }>;
  tableProps?: TP;
  isFetchingNewTableData: boolean;
  handleRowClick: HandleRowClick<T>;
  modal?: {
    isModalMode?: boolean;
    queryParamIgnoreKeys?: string[];
    handleModalSubmit: () => void;
    handleModalClose: () => void;
    isSubmitPending: boolean;
  };
  checkboxProps?: CheckboxProps;
  hideRemoveFiltersButton?: boolean;
}

function PageContent<T>({
  contentType,
  data,
  pages,
  isLoading,
  isFetchingNewTableData,
  modal,
  checkboxProps,
  FiltersComponent,
  filtersProps,
  TableComponent,
  tableProps,
  handleRowClick,
  hideRemoveFiltersButton = false,
}: PageContentProps<T>) {
  const {
    isModalMode = false,
    queryParamIgnoreKeys = [],
    handleModalSubmit,
    handleModalClose,
    isSubmitPending,
  } = modal ?? {};

  const { queryParams, resetQueryParams, setQueryParams, queryParamsSize } =
    useQueryParams();

  const hasActiveFilters = useMemo(() => {
    if (isModalMode) {
      const activeParams = Object.keys(queryParams).filter(
        (key) => !queryParamIgnoreKeys.includes(key),
      );
      return activeParams.length > 0;
    }

    return !!queryParamsSize;
  }, [isModalMode, queryParams, queryParamIgnoreKeys, queryParamsSize]);

  const checkboxItemsSelected = checkboxProps?.getSelectedCount();
  const hasExceededLimit = (checkboxItemsSelected ?? 0) > 1000;

  return (
    <>
      <div className='flex h-fit justify-between'>
        <div className='flex w-full flex-wrap items-end gap-x-4 gap-y-2'>
          <FiltersComponent {...filtersProps} />
        </div>
        {!hideRemoveFiltersButton && (
          <Button
            variant='ghost'
            className={`text-sm ${!hasActiveFilters && 'cursor-default select-none opacity-0'}`}
            onClick={() => {
              if (isModalMode) {
                setQueryParams({
                  entityType: queryParams.entityType,
                });
              } else {
                resetQueryParams();
              }
            }}
          >
            Remove filters
          </Button>
        )}
      </div>
      {isLoading ? (
        <div className='flex size-full items-center justify-center'>
          <CommonSpinner size='sm' />
        </div>
      ) : (
        <>
          {data?.length > 0 ? (
            <div className='relative size-full overflow-hidden rounded border'>
              <div className='size-full overflow-auto'>
                <TableComponent
                  data={data}
                  handleRowClick={(
                    row: T,
                    e:
                      | React.MouseEvent<HTMLTableRowElement, MouseEvent>
                      | React.KeyboardEvent,
                  ) => handleRowClick(row, e)}
                  checkboxProps={checkboxProps}
                  hideCopyButton={
                    isModalMode && contentType === PageTypes.Requisitions
                  }
                  hideBulkActions={isModalMode}
                  {...tableProps}
                />
              </div>
              {isFetchingNewTableData && (
                <>
                  <div className='absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-black opacity-5'></div>
                  <CommonSpinner
                    className='absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center'
                    size='sm'
                  />
                </>
              )}
            </div>
          ) : (
            <EmptyState
              icon={SearchIcon}
              title='No results found'
              link={
                <div className='flex'>
                  <span style={{ maxWidth: 300, textAlign: 'center' }}>
                    No results matched your search criteria. Try searching for
                    something else.
                  </span>
                </div>
              }
            />
          )}
        </>
      )}
      <div className='flex flex-1 items-end justify-between'>
        {!!pages && pages > 1 ? (
          <Pagination pages={pages} />
        ) : (
          <div className='h-4 w-4' />
        )}
        {isModalMode && (
          <div className='flex items-center gap-4'>
            {!!checkboxItemsSelected && (
              <p>
                <span>Items selected: </span>
                {hasExceededLimit ? (
                  <Tooltip>
                    <TooltipTrigger>
                      <span className='text-sm font-medium text-error'>
                        1000+
                      </span>
                    </TooltipTrigger>
                    <TooltipContent>
                      You have selected more than 1000 items. Bulk actions are
                      limited to 1000 items.
                    </TooltipContent>
                  </Tooltip>
                ) : (
                  <span className='text-sm font-medium'>
                    ({checkboxItemsSelected})
                  </span>
                )}
              </p>
            )}
            <Button variant='secondary' onClick={() => handleModalClose?.()}>
              Cancel
            </Button>
            <Button
              onClick={handleModalSubmit}
              isLoading={isSubmitPending}
              disabled={
                isSubmitPending || !checkboxItemsSelected || hasExceededLimit
              }
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default PageContent;
