import { Link } from 'react-router-dom';

import { Stack } from '.';
import { useHideRingCentralComponent } from '../../hooks/useHideRingCentralComponent';
import RootLayout from '../../layouts';

interface INotFound {
  code?: string;
  message?: string;
  description?: string;
  href?: string;
  linkText?: string;
}

export function NotFoundPage() {
  useHideRingCentralComponent();
  return (
    <RootLayout allowOverflow>
      <NotFound />
    </RootLayout>
  );
}

export function NotFound({
  message,
  description,
  code,
  href,
  linkText,
}: INotFound) {
  return (
    <Stack
      horizontalAlign='center'
      verticalAlign='center'
      styles={{ root: { height: '100vh' } }}
    >
      <p className='text-base font-semibold text-blue-900 sm:text-5xl'>
        {code || '404'}
      </p>
      <h1 className='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
        {message || 'Page not found'}
      </h1>
      <p className='text-gray- mt-6 text-base text-xl leading-7'>
        {description || "Sorry, we couldn't find the page you're looking for."}
      </p>
      <div className='mt-10 flex items-center justify-center gap-x-6'>
        <Link
          to={href || '/'}
          className='rounded-sm bg-blue-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-200 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-900'
        >
          {linkText || 'Go back home'}
        </Link>
      </div>
    </Stack>
  );
}
