import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { formatDateTimeInput } from '../utils/helpers';

export interface QueryParams {
  [key: string]: string | string[];
}

interface QueryParamOptions {
  multiple?: boolean;
  formatDate?: boolean;
  resetPageOnChange?: boolean;
  extraParams?: { [key: string]: string | undefined };
}

export function useQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => {
    return queryStringToObject(searchParams);
  }, [searchParams]);

  const queryParamsString = useMemo(() => {
    return objectToQueryString(queryParams);
  }, [queryParams]);

  const resetQueryParams = () => {
    setSearchParams('');
  };

  const updateQueryParam = useCallback(
    (
      key: string,
      value: string | string[],
      options: QueryParamOptions = {},
    ) => {
      const {
        multiple = false,
        formatDate = false,
        resetPageOnChange = true,
        extraParams = {},
      } = options;

      const updatedParams = { ...queryParams };

      // Check if the value is an array, and handle it accordingly
      if (Array.isArray(value)) {
        // Remove the key from the queryParams and add each value separately
        delete updatedParams[key];

        value.forEach((val) => {
          updatedParams[`${key}`] = updatedParams[`${key}`]
            ? [...updatedParams[`${key}`], val]
            : [val];
        });
      } else if (multiple) {
        updatedParams[key] = handleMultiSelect(
          key,
          value as string,
          queryParams[key] as string[],
        );
      } else {
        if (value === queryParams[key]) {
          delete updatedParams[key];
        } else {
          updatedParams[key] = value;
        }
      }

      if (formatDate) {
        updatedParams[key] = formatDateValue(updatedParams[key] as string);
      }

      if (resetPageOnChange) {
        delete updatedParams['pageNumber'];
      }

      if (extraParams && typeof extraParams === 'object') {
        Object.entries(extraParams).forEach(([paramKey, value]) => {
          if (value === undefined) {
            delete updatedParams[paramKey];
          } else {
            updatedParams[paramKey] = value;
          }
        });
      }

      const newQueryString = objectToQueryString(updatedParams);
      setSearchParams(newQueryString);
    },
    [queryParams, setSearchParams],
  );

  const removeQueryParam = (key: string, extraParams: string[] = []) => {
    let queryString = objectToQueryString(queryParams);
    const urlSearchParams = new URLSearchParams(queryString);
    urlSearchParams.delete(key);

    // Remove any extraParams provided
    extraParams.forEach((param) => {
      urlSearchParams.delete(param);
    });

    queryString = urlSearchParams.toString();
    setSearchParams(queryString);
  };

  return {
    resetQueryParams,
    queryParams,
    searchParams,
    queryParamsSize: searchParams.size,
    setQueryParams: setSearchParams,
    queryParamsString,
    updateQueryParam,
    removeQueryParam,
  };
}

function handleMultiSelect(
  key: string,
  value: string,
  currentValues: string[],
): string[] {
  if (!Array.isArray(currentValues)) {
    currentValues = currentValues ? [currentValues] : [];
  }

  return currentValues.includes(value)
    ? currentValues.filter((v) => v !== value)
    : [...currentValues, value];
}

function formatDateValue(value: string): string {
  if (value && value.includes('-')) {
    return formatDateTimeInput(value);
  }
  return value;
}

export function queryStringToObject(
  searchParams: URLSearchParams,
): Record<string, string | string[]> {
  const params: Record<string, string | string[]> = {};

  searchParams.forEach((value, key) => {
    if (params[key]) {
      if (Array.isArray(params[key])) {
        (params[key] as string[]).push(value);
      } else {
        params[key] = [params[key] as string, value];
      }
    } else {
      params[key] = value;
    }
  });

  return params;
}

export function objectToQueryString(
  obj: Record<string, string | string[]>,
): string {
  const parts: string[] = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) =>
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(v)}`),
      );
    } else {
      parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  });
  return parts.join('&');
}
