import { LoaderCircleIcon, MessageCircleIcon, XIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { CommonSpinner } from '../../components/common';
import { PhoneIcon } from '../../components/common/Icons';
import OrganizationName from '../../components/common/OrganizationName';
import PhoneNumber from '../../components/common/PhoneNumber';
import {
  DropdownMenu,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import { Sheet, SheetClose, SheetContent } from '../../components/ui/sheet';
import { useGlobalState } from '../../context/GlobalContext';
import { usePhone } from '../../context/PhoneContext';
import { useRingCentralContext } from '../../context/RingCentralContext';
import { useToast } from '../../hooks/use-toast';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { APIContact } from '../../types/contacts';
import {
  capitalizeName,
  setFieldIfEmpty,
  setRegion,
} from '../../utils/helpers';
import { generatePhoneLink } from '../../utils/phone';
import { ContactTabs } from './ContactsTabs';

export function ContactView({
  contactId,
  contacts,
  isOpen,
  onClose,
  onContactSave,
  tabId,
  panelBaseRoute,
}: {
  contactId: string | undefined | null;
  contacts?: APIContact[];
  isOpen: boolean;
  onClose: () => void;
  onContactSave: () => void;
  tabId?: string;
  panelBaseRoute?: string;
}) {
  const { me } = useGlobalState();
  const { toast } = useToast();
  const { setCallPhone } = usePhone();
  const preLoadedContact = contacts?.find((c) => c.contactId === contactId);
  const { fetchMatchedContacts } = useRingCentralContext();

  const [isPhoneLoading, setIsPhoneLoading] = useState(false);

  const {
    data,
    isLoading: isContactLoading,
    refetch,
  } = useCustomQuery<APIContact>({
    url: `/contacts/${contactId}`,
    enabled: isOpen && !!contactId && !preLoadedContact,
  });

  const contact = preLoadedContact ?? data?.data;

  const primaryPhoneLink = useMemo(() => {
    return generatePhoneLink(contact?.phone ?? '');
  }, [contact]);

  const workPhoneLink = useMemo(() => {
    return generatePhoneLink(contact?.workPhone ?? '');
  }, [contact]);

  const handleACSPhoneClick = async (phone: string) => {
    setIsPhoneLoading(true);

    setTimeout(() => {
      setIsPhoneLoading(false);
    }, 1500);

    const activeCall = document.getElementById('activeCall');

    if (activeCall) {
      toast({
        title: 'Info!',
        description: 'There is a call in progress.',
        variant: 'default',
        duration: 15000,
      });
    }

    if (contact && me && phone) {
      if (me.phone == '' && me.directDial1 == '') {
        toast({
          title: 'Info',
          description: 'You need to set up a phone number to call.',
          variant: 'destructive',
          duration: 15000,
        });
        return;
      }

      const info = {
        activeDialPad: true,
        contactId: contactId || '',
        contactUserId: contact.userId || '',
        contactFirstName: contact.firstName || '',
        contactLastName: contact.lastName || '',
        contactOrganizationId: contact.organizationId || '',
        contactPhone: phone,
        contactExtension: contact.phoneExt || '',
        userId: me.userId || '',
        userName: me.userName || '',
        fullName: me.fullName || '',
        emailAddress: me.emailAddress || '',
        userPhone: me.phone || me.directDial1 || '',
      };
      setCallPhone(info);
    }
    setIsPhoneLoading(false);
  };

  const handleSmsClick = async (phone: string) => {
    setIsPhoneLoading(true);
    const iframe: any = document.querySelector('#rc-widget-adapter-frame');

    await fetchMatchedContacts([phone]);

    if (typeof (window as any).RCAdapter !== 'undefined') {
      (window as any).RCAdapter.setMinimized(false);
    }

    if (iframe?.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: 'rc-adapter-new-sms',
          phoneNumber: phone,
          conversation: true,
        },
        '*',
      );
    } else {
      console.error('RingCentral widget iframe not found or not accessible');
    }
    setIsPhoneLoading(false);
  };

  return (
    <Sheet
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose?.();
        }
      }}
      modal={false}
    >
      <SheetContent
        className='w-full overflow-auto bg-white md:max-w-[1200px]'
        hideCloseButton
        showCustomOverlay
      >
        <div className='size-full overflow-hidden'>
          {isContactLoading && (
            <div className='flex size-full items-center justify-center'>
              <CommonSpinner size='md' />
            </div>
          )}

          {!isContactLoading && !!contact && (
            <div
              className=' grid size-full gap-4 overflow-hidden'
              style={{ gridTemplateRows: 'auto 1fr' }}
            >
              <div className='flex flex-col gap-4 rounded-t-lg border border-neutral-40 bg-light-blue p-4'>
                <div className='flex justify-between'>
                  <div className='text-xl font-bold'>
                    {capitalizeName(
                      `${contact?.firstName} ${contact?.lastName}`,
                    )}
                  </div>
                  <SheetClose>
                    <XIcon />
                  </SheetClose>
                </div>
                <div className='grid grid-cols-3 gap-2'>
                  <div className='flex flex-col text-sm'>
                    <b>Title</b>
                    <span className='overflow-hidden truncate'>
                      {setFieldIfEmpty(contact?.title)}
                    </span>
                  </div>
                  <div className='flex flex-col text-sm'>
                    <b>Phone</b>
                    <div className='overflow-hidden truncate'>
                      <PhoneNumber
                        phoneNumber={contact.phone}
                        extension={contact?.phoneExt}
                      />
                    </div>
                  </div>
                  <div className='flex flex-col text-sm'>
                    <b>City/State</b>
                    <span className='overflow-hidden truncate'>
                      {setRegion(contact?.city ?? '', contact?.state ?? '')}
                    </span>
                  </div>
                </div>
                <div className='grid grid-cols-3 gap-2'>
                  <div className='flex flex-col text-sm'>
                    <b>Organization</b>
                    <Link to={`/organizations/${contact.organizationId}`}>
                      <OrganizationName
                        hasContract={!!contact?.orgHasContract}
                        name={contact?.organizationName}
                      />
                    </Link>
                  </div>
                  <div className='flex flex-col text-sm'>
                    <b>Email</b>
                    <span className='overflow-hidden truncate'>
                      {setFieldIfEmpty(contact?.emailAddress)}
                    </span>
                  </div>
                  <div className='flex flex-col text-sm'>
                    <b>Address</b>
                    <span className='overflow-hidden truncate'>
                      {setFieldIfEmpty(contact?.address1)}
                    </span>
                  </div>
                </div>
                <div className='flex justify-center gap-6'>
                  {contact?.phone && !contact?.workPhone && (
                    <>
                      <button
                        className='flex flex-col items-center text-sm text-primary-70'
                        onClick={() => {
                          handleACSPhoneClick(primaryPhoneLink);
                        }}
                      >
                        <div className='rounded-full border border-primary-70 p-2'>
                          {isPhoneLoading ? (
                            <LoaderCircleIcon
                              size={20}
                              className='animate-spin'
                            />
                          ) : (
                            <PhoneIcon size={20} />
                          )}
                        </div>
                        <span>Phone</span>
                      </button>
                      <button
                        className='flex flex-col items-center text-sm text-primary-70'
                        onClick={() => {
                          handleSmsClick(primaryPhoneLink);
                        }}
                      >
                        <div className='rounded-full border border-primary-70 p-2'>
                          {isPhoneLoading ? (
                            <LoaderCircleIcon
                              size={20}
                              className='animate-spin'
                            />
                          ) : (
                            <MessageCircleIcon strokeWidth={1.5} size={20} />
                          )}
                        </div>
                        <span>Text</span>
                      </button>
                    </>
                  )}
                  {contact?.phone && contact?.workPhone && (
                    <>
                      <DropdownMenu modal={false}>
                        <DropdownMenuTrigger>
                          <div className='flex flex-col items-center text-sm text-primary-70'>
                            <div className='rounded-full border border-primary-70 p-2'>
                              <PhoneIcon size={20} />
                            </div>
                            <span>Phone</span>
                          </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuGroup>
                            {contact?.phone && (
                              <DropdownMenuItem
                                className='flex flex-col items-start gap-0'
                                onClick={() => {
                                  handleACSPhoneClick(primaryPhoneLink);
                                }}
                                disabled={isPhoneLoading}
                              >
                                <span className='text-xs'>Primary phone</span>
                                <PhoneNumber
                                  phoneNumber={contact.phone}
                                  extension={contact?.phoneExt}
                                />
                              </DropdownMenuItem>
                            )}
                            {contact?.workPhone && (
                              <DropdownMenuItem
                                className='flex flex-col items-start gap-0'
                                onClick={() => {
                                  handleACSPhoneClick(workPhoneLink);
                                }}
                                disabled={isPhoneLoading}
                              >
                                <span className='text-xs'>Work phone</span>
                                <PhoneNumber
                                  phoneNumber={contact.workPhone}
                                  extension={contact?.workExt}
                                />
                              </DropdownMenuItem>
                            )}
                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>

                      <DropdownMenu modal={false}>
                        <DropdownMenuTrigger>
                          <div className='flex flex-col items-center text-sm text-primary-70'>
                            <div className='rounded-full border border-primary-70 p-2'>
                              <MessageCircleIcon strokeWidth={1.5} size={20} />
                            </div>
                            <span>Text</span>
                          </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuGroup>
                            {contact?.phone && (
                              <DropdownMenuItem
                                className='flex flex-col items-start gap-0'
                                onClick={() => {
                                  handleSmsClick(primaryPhoneLink);
                                }}
                                disabled={isPhoneLoading}
                              >
                                <span className='text-xs'>Primary phone</span>
                                <PhoneNumber
                                  phoneNumber={contact.phone}
                                  extension={contact?.phoneExt}
                                />
                              </DropdownMenuItem>
                            )}
                            {contact?.workPhone && (
                              <DropdownMenuItem
                                className='flex flex-col items-start gap-0'
                                onClick={() => {
                                  handleSmsClick(workPhoneLink);
                                }}
                                disabled={isPhoneLoading}
                              >
                                <span className='text-xs'>Work phone</span>
                                <PhoneNumber
                                  phoneNumber={contact.workPhone}
                                  extension={contact?.workExt}
                                />
                              </DropdownMenuItem>
                            )}
                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </>
                  )}
                </div>
              </div>

              <div className='size-full overflow-hidden'>
                <ContactTabs
                  contact={contact}
                  onContactSave={() => {
                    refetch();
                    onContactSave();
                  }}
                  tabId={tabId}
                  panelBaseRoute={panelBaseRoute}
                />
              </div>
            </div>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
}
