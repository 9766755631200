import { useDebounceValue } from 'usehooks-ts';

import { useCustomQuery } from '../../hooks/useCustomQuery';
import { useQueryParams } from '../../hooks/useQueryParams';
import { APIOrganization } from '../../types/organizations';
import { MultiSelectDropdownFilter } from '../common/MultiSelectDropdownFilter';
import OrganizationName from '../common/OrganizationName';

function OrganizationInput() {
  const { searchParams, queryParams, updateQueryParam, removeQueryParam } =
    useQueryParams();

  const [organizationSearch, setOrganizationSearch] = useDebounceValue('', 500);

  const { data: organizationsData, isLoading: isLoadingOrganization } =
    useCustomQuery<APIOrganization[]>({
      url: `/organizations`,
      params: organizationSearch
        ? { organizationName: organizationSearch }
        : {},
    });

  const organizations = organizationsData?.data ?? [];

  return (
    <MultiSelectDropdownFilter
      classNames={{ container: 'w-72' }}
      isClearable={!!queryParams?.organizationId}
      items={organizations?.map((s) => ({
        id: s.organizationId,
        title: s.organizationName as string,
      }))}
      isLoading={isLoadingOrganization}
      setSearchValue={setOrganizationSearch}
      handleChange={(organizationId, organizationName) => {
        updateQueryParam('organizationId', organizationId, {
          multiple: false,
          resetPageOnChange: true,
          extraParams: { organizationName: organizationName || '' },
        });
        setOrganizationSearch('');
      }}
      onClear={() => {
        removeQueryParam('organizationId', ['organizationName']);
      }}
      directValue={searchParams?.get('organizationName') || ''}
      value={[queryParams?.organizationId as string]}
      isChecked={!!queryParams?.organizationId}
      placeholder='Organization'
      customOption={(props: any) => {
        const { title, hasContract } = props;
        return (
          <OrganizationName hasContract={hasContract} name={title} size={16} />
        );
      }}
    />
  );
}

export default OrganizationInput;
