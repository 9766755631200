export const ActivityTypes = [
  {
    activityType: 'CALL',
    result: 'Voicemail',
    displayText: 'Called - Left Voicemail',
  },
  { activityType: 'MEETING', result: 'Set', displayText: 'Meeting - Set' },
  {
    activityType: 'MEETING',
    result: 'Conducted',
    displayText: 'Meeting - Conducted',
  },
  { activityType: 'CONTRACT', result: 'Sent', displayText: 'Contract - Sent' },
  {
    activityType: 'CONTRACT',
    result: 'Executed',
    displayText: 'Contract - Executed',
  },
  {
    activityType: 'CONTRACT',
    result: 'Follow-up',
    displayText: 'Contract - Follow Up',
  },
  {
    activityType: 'CONTRACT',
    result: 'Pending',
    displayText: 'Contract - Pending',
  },
  {
    activityType: 'CONTRACT',
    result: 'Rejected',
    displayText: 'Contract - Rejected',
  },
  {
    activityType: 'CHECKIN',
    result: `Prospect`,
    displayText: 'Check-in: Prospect',
  },
  {
    activityType: 'CHECKIN',
    result: `Client`,
    displayText: 'Check-in: Client',
  },
  {
    activityType: 'JOBORDER',
    result: 'Received',
    displayText: 'Job Order - Received',
  },
  { activityType: 'OTHER', result: ``, displayText: 'Other' },
];
