import React, { useRef, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { GlobalStateProvider } from '../../context/GlobalContext';
import { usePhone } from '../../context/PhoneContext';
import { Caller } from './Caller';

interface CallerRef {
  callContact: () => void;
  createIncomingCallConfiguration: () => void;
}

const Phone: React.FC = () => {
  const { callPhone, setCallPhone } = usePhone();
  const callerRef = useRef<CallerRef>(null);
  const createdIncomingCallConfiguration = useRef(false);

  const callContact = () => {
    if (callerRef.current) {
      callerRef.current.callContact();
    }
  };

  const cleanInfo = () => {
    const info = {
      activeDialPad: true,
      contactId: '',
      contactUserId: '',
      contactFirstName: '',
      contactLastName: '',
      contactOrganizationId: '',
      contactPhone: '',
      contactExtension: '',
      userId: '',
      userName: '',
      fullName: '',
      emailAddress: '',
      userPhone: '',
      smallView: false,
      maximizeView: false,
    };
    setCallPhone(info);
  };

  useEffect(() => {
    if (callerRef.current && !createdIncomingCallConfiguration.current) {
      callerRef.current.createIncomingCallConfiguration();
      createdIncomingCallConfiguration.current = true;
    }
  }, []);

  useEffect(() => {
    if (callPhone && callPhone.activeDialPad) {
      callContact();
    }
  }, [callPhone]);

  if (!localStorage.getItem('user')) {
    return <></>;
  }

  return (
    <BrowserRouter>
      <GlobalStateProvider>
        <Caller
          ref={callerRef}
          callInfo={callPhone}
          cleanCallInfo={cleanInfo}
        />
      </GlobalStateProvider>
    </BrowserRouter>
  );
};

export default Phone;
