import { Checkbox, IDropdownOption } from '@fluentui/react';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import {
  Dropdown,
  IAction,
  TextInput,
  UsersDropdown,
  commonReducer,
} from '../../components/common';
import AddressInput from '../../components/common/AddressInput';
import { Button } from '../../components/common/Button';
import PhoneInput from '../../components/common/PhoneInput';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import useInvalidateQuery from '../../hooks/useInvalidateQuery';
import { IAddress } from '../../react-app-env';
import { APIOrganization } from '../../types/organizations';
import { formatDateToBackend } from '../../utils/helpers';

const initialOptions = { errors: {} };

export function OrganizationDetails({
  organization: o,
  invalidatePath,
}: {
  organization: APIOrganization;
  invalidatePath?: string;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const { me } = useGlobalState();
  const invalidate = useInvalidateQuery();

  const { mutate: saveDetails, isPending } = useCustomMutation({
    onSuccess: async () => {
      if (invalidatePath) {
        invalidate([invalidatePath], { refetchType: 'all' });
      }

      setIsEditing(false);
    },
    method: 'put',
  });

  const [org, dispatch]: [APIOrganization, React.Dispatch<IAction>] =
    useReducer(commonReducer, {});

  const [{ errors }, setErrors] = useReducer(commonReducer, initialOptions);

  const resetOrgState = () => {
    dispatch({ type: 'reset', value: o });
    setErrors({ type: 'reset', value: { errors: {} } });
  };

  const handleChange = useCallback(
    (path: keyof APIOrganization) => (value: any) => {
      if (errors[path]) {
        setErrors({ type: 'remove', path: `errors[${path}]` });
      }

      dispatch({ path, value });
    },
    [errors],
  );

  const handleAddressChange = useCallback((address: IAddress) => {
    dispatch({ type: 'set', value: address });
  }, []);

  const handleDropdownChange = useCallback(
    (path: string) =>
      (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption<any> | undefined,
      ) => {
        dispatch({ path, value: option?.key });
      },
    [],
  );

  const handleOrgSubmit = async () => {
    const apiBody = {
      ...org,
    };

    // convert ISO 8601 format to MySQL format
    if (org.dateSigned) {
      const date = new Date(org.dateSigned);
      apiBody.dateSigned = formatDateToBackend(date);
    }

    apiBody.feePercentage = apiBody?.feePercentage
      ? apiBody?.feePercentage / 100
      : null;

    await saveDetails({
      url: `/organizations/${org.organizationId}`,
      body: apiBody,
    });
  };

  const feeInputValue = useMemo(() => {
    if (isEditing) {
      return org?.feePercentage ?? '';
    } else {
      return org?.feePercentage
        ? `${org?.feePercentage}${org?.feePercentage ? '%' : ''}`
        : '';
    }
  }, [org?.feePercentage, isEditing]);

  useEffect(() => {
    if (o) {
      const orgState = { ...o };

      orgState.feePercentage = orgState?.feePercentage
        ? orgState?.feePercentage * 100
        : null;

      // convert MySQL format to ISO 8601 format
      if (o.dateSigned) {
        orgState.dateSigned = new Date(o.dateSigned)
          .toISOString()
          .split('T')[0];
      }

      dispatch({ type: 'reset', value: orgState });
      setErrors({ type: 'reset', value: { errors: {} } });
    }
  }, [o]);

  return (
    <div className={``}>
      <div
        className={`flex flex-col gap-3 rounded-md border p-4 ${isEditing ? 'border-primary' : 'border-neutral-40'}`}
      >
        <div className='flex items-center justify-between'>
          <div className='text-lg font-bold'>Organization details</div>
          {isEditing ? (
            <div className='flex gap-2'>
              <Button
                variant='secondary'
                onClick={() => {
                  resetOrgState();
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleOrgSubmit}
                isLoading={isPending}
                disabled={isPending}
              >
                Save
              </Button>
            </div>
          ) : (
            <Button variant='ghost' onClick={() => setIsEditing(true)}>
              <span className='blue underline'>Edit</span>
            </Button>
          )}
        </div>
        <TextInput
          label='Organization Name'
          value={org.organizationName ?? ''}
          handleChange={handleChange('organizationName')}
          disabled={!isEditing}
        />
        <div className='flex gap-2'>
          <TextInput
            fullWidth
            label='Industry'
            value={org.industry ?? ''}
            handleChange={handleChange('industry')}
            disabled={!isEditing}
          />
          <TextInput
            fullWidth
            label='Specialty'
            value={org.specialty ?? ''}
            handleChange={handleChange('specialty')}
            disabled={!isEditing}
          />
        </div>
        <Dropdown
          color='black'
          label='Contract'
          disabled={!isEditing}
          onChange={(_e, option) =>
            dispatch({
              path: 'contract',
              value: option?.key === 'yes' ? true : false,
            })
          }
          selectedKey={org.contract ? 'yes' : 'no'}
          options={[
            { text: 'Yes', key: 'yes' },
            { text: 'No', key: 'no' },
          ]}
        />
        <TextInput
          label='Beds'
          type='number'
          disabled={!isEditing}
          value={org.numberOfBeds ?? ''}
          handleChange={handleChange('numberOfBeds')}
          errorMessage={errors.numberOfBeds}
        />
        <TextInput
          label='Annual Revenue'
          type='number'
          disabled={!isEditing}
          value={org.revenue ?? ''}
          handleChange={handleChange('revenue')}
          errorMessage={errors.revenue}
        />
        <PhoneInput
          onChange={handleChange('phone')}
          value={org.phone ?? undefined}
          disabled={!isEditing}
          errorMessage={errors.phone}
          id='organization-phone'
        />
        <AddressInput
          checkRegion
          disabled={!isEditing}
          selectedAddress={{
            address1: org.address1,
            address2: org.address2,
            city: org.city,
            state: org.state,
            postalCode: org.postalCode,
          }}
          handleChange={handleAddressChange}
        />
        <TextInput
          disabled={!isEditing}
          label='Date Signed'
          type='date'
          value={org?.dateSigned ?? ''}
          handleChange={(date) => {
            handleChange('dateSigned')(date || null);
          }}
          errorMessage={errors?.dateSigned}
        />
        <TextInput
          disabled={!isEditing}
          label='Payment Terms'
          value={org.paymentTerms ?? ''}
          handleChange={handleChange('paymentTerms')}
          errorMessage={errors?.paymentTerms}
        />
        <TextInput
          disabled={!isEditing}
          type={isEditing ? 'number' : 'string'}
          label='Fee (%)'
          value={feeInputValue}
          handleChange={handleChange('feePercentage')}
          errorMessage={errors.feePercentage}
        />
        <TextInput
          disabled={!isEditing}
          label='Late Fee'
          value={org.lateFee ?? ''}
          handleChange={handleChange('lateFee')}
          errorMessage={errors?.lateFee}
        />
        <TextInput
          disabled={!isEditing}
          label='Guarantee'
          value={org.guarantee ?? ''}
          handleChange={handleChange('guarantee')}
          errorMessage={errors.guarantee}
        />
        <Checkbox
          label='Do Not Call'
          disabled={!isEditing}
          checked={org.doNotCall ?? false}
          onChange={(_e: any, checked?: boolean) => {
            dispatch({ path: 'doNotCall', value: checked });
          }}
        />
        <UsersDropdown
          required
          disabled={!isEditing}
          selectedKey={org.userId ?? me?.userId}
          onChange={handleDropdownChange('userId')}
          errorMessage={errors.userId}
          disabledOption
        />
        {isEditing ? (
          <TextInput
            label='Website URL'
            disabled={!isEditing}
            value={org.url ?? ''}
            handleChange={handleChange('url')}
            errorMessage={errors?.url}
          />
        ) : (
          <div>
            <span className='label'>Website URL</span>
            <div
              className='text-link hover:cursor-pointer'
              onClick={() => {
                if (org?.url) {
                  console.log(org.url);
                  const re = new RegExp('^(http|https)://', 'i');
                  const hasProtocol = re.test(org.url);
                  window.open(
                    hasProtocol ? org.url : `https://${org.url}`,
                    '_blank',
                  );
                }
              }}
            >
              {org.url ?? ''}
            </div>
          </div>
        )}
        <TextInput
          label='Career Page URL'
          disabled={!isEditing}
          value={org.careerPageURL ?? ''}
          handleChange={handleChange('careerPageURL')}
          errorMessage={errors?.careerPageURL}
        />
      </div>
    </div>
  );
}
