import { useEffect, useRef } from 'react';

import { useRingCentralContext } from '../../context/RingCentralContext';

function RingCentralListener() {
  const ringCentralRegistered = useRef(false);
  const { fetchMatchedContacts } = useRingCentralContext();

  useEffect(() => {
    const handleMessage = async (e: any) => {
      const data = e.data;

      if (data) {
        switch (data.type) {
          case 'rc-login-status-notify': {
            if (!ringCentralRegistered.current) {
              ringCentralRegistered.current = true;

              const iframe: HTMLIFrameElement | null = document.querySelector(
                '#rc-widget-adapter-frame',
              );

              if (iframe && iframe.contentWindow) {
                iframe.contentWindow.postMessage(
                  {
                    type: 'rc-adapter-register-third-party-service',
                    service: {
                      name: 'CustomContacts',
                      contactMatchPath: '/contacts/match',
                      contactMatchTtl: 5 * 60 * 1000,
                      contactNoMatchTtl: 2 * 60 * 1000,
                    },
                  },
                  '*',
                );
              } else {
                console.error(
                  'Iframe not found or contentWindow not accessible',
                );
              }
            }
            break;
          }

          case 'rc-post-message-request': {
            if (data.path === '/contacts/match') {
              const phoneNumbers = data.body.phoneNumbers;

              const contacts = await fetchMatchedContacts(phoneNumbers);

              const iframe: HTMLIFrameElement | null = document.querySelector(
                '#rc-widget-adapter-frame',
              );

              if (iframe && iframe.contentWindow) {
                if (contacts) {
                  iframe.contentWindow?.postMessage(
                    {
                      type: 'rc-post-message-response',
                      responseId: data.requestId,
                      response: {
                        data: contacts,
                      },
                    },
                    '*',
                  );
                } else {
                  console.error(
                    'Iframe not found or contentWindow not accessible',
                  );
                }
              }
            }

            break;
          }
          default:
            break;
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [fetchMatchedContacts]);

  return null;
}

export default RingCentralListener;
