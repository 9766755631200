import { useState } from 'react';

import EmailContactsModal from '../../pages/contacts/EmailContactsModal';
import { PageTypes } from '../../types/pages';
import { CheckboxProps } from '../../types/table';
import AddToListModal from './AddToListModal';
import BulkActions, { BulkAction } from './BulkActions';
import CreateListAddItemsModal from './CreateListAddItemsModal';

function ListBulkActions({
  checkboxProps,
  itemType,
}: {
  checkboxProps: CheckboxProps;
  itemType: PageTypes;
}) {
  const [isBulkActionModalOpen, setIsBulkActionModalOpen] = useState<
    'add' | 'create' | 'email' | null
  >(null);

  const itemsCount = checkboxProps.getSelectedCount();
  const bulkActions = [
    {
      label: 'Add to list',
      type: 'primary',
      onClickHandler: async () => {
        setIsBulkActionModalOpen('add');
      },
    },
    {
      label: 'Create new list',
      type: 'primary',
      onClickHandler: async () => {
        setIsBulkActionModalOpen('create');
      },
    },
    // TODO: Update when API is updated.
    // ...(itemType === 'contacts'
    //   ? [
    //       {
    //         label: 'Send email',
    //         type: 'primary',
    //         onClickHandler: async () => {
    //           setIsBulkActionModalOpen('email');
    //         },
    //       },
    //     ]
    //   : []),
  ] as BulkAction[];

  return (
    <>
      <BulkActions bulkActions={bulkActions} selectedItemsCount={itemsCount} />

      {isBulkActionModalOpen === 'add' && (
        <AddToListModal
          isOpen={isBulkActionModalOpen === 'add'}
          onClose={() => setIsBulkActionModalOpen(null)}
          selectedItems={checkboxProps?.selectionState?.newlySelected}
          getSelectionPayload={checkboxProps?.getSelectionPayload}
          getSelectedCount={checkboxProps?.getSelectedCount}
          currentFilters={checkboxProps?.currentFilters}
          clearSelectedItems={checkboxProps?.clearSelectedItems}
          itemType={itemType}
        />
      )}
      {isBulkActionModalOpen === 'create' && (
        <CreateListAddItemsModal
          isOpen={isBulkActionModalOpen === 'create'}
          onClose={() => setIsBulkActionModalOpen(null)}
          selectedItems={checkboxProps?.selectionState?.newlySelected}
          getSelectionPayload={checkboxProps?.getSelectionPayload}
          currentFilters={checkboxProps?.currentFilters}
          clearSelectedItems={checkboxProps?.clearSelectedItems}
          itemType={itemType}
        />
      )}
      {isBulkActionModalOpen === 'email' && (
        <EmailContactsModal
          open={isBulkActionModalOpen === 'email'}
          setIsBulkActionModalOpen={setIsBulkActionModalOpen} // Need to make generic.
          itemsCount={itemsCount}
        />
      )}
    </>
  );
}

export default ListBulkActions;
