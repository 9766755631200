import * as React from 'react';

import { cn } from '../../lib/utils';

export const inputClass =
  'ring-offset-background focus-visible:ring-ring flex h-8 w-full rounded-md border border-neutral-40 px-3 py-2 text-sm ring-neutral-40 file:border-0 file:bg-transparent file:text-sm file:font-medium font-medium placeholder:text-neutral-60 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-default disabled:opacity-90 hover:border-neutral-60 disabled:hover:border-neutral-40 disabled:cursor-not-allowed  shadow-sm ';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...rest }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputClass, className)}
        ref={ref}
        {...rest}
      />
    );
  },
);
Input.displayName = 'Input';

export { Input };
