import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { useEffect, useMemo, useState } from 'react';

import { Button } from '../../components/common/Button';
import DatePicker from '../../components/common/DatePicker';
import Modal from '../../components/common/ModalRadix';
import Select from '../../components/common/SelectNew';
import { Label } from '../../components/ui/label';
import { Textarea } from '../../components/ui/textarea';
import { DialogState } from '../../hooks/usePipelineDragEnd';
import { APIPipeline } from '../../types/pipeline';
import { capitalizeName } from '../../utils/helpers';
import { generateTimeOptions } from '../../utils/helpers';

export function CandidateMovedModal({
  dialogState,
  handleDialogSubmit,
  handleDialogCancel,
  pipelineData,
  isApiPending,
}: {
  dialogState: DialogState;
  handleDialogSubmit: (additionalInfo: any) => void;
  handleDialogCancel: () => void;
  pipelineData: APIPipeline | undefined;
  isApiPending: boolean;
}) {
  const [note, setNote] = useState('');
  const [noteRequired, setNoteRequired] = useState(false);
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [time, setTime] = useState<string>('');
  const contact = useMemo(() => {
    return (pipelineData?.contacts ?? []).find(
      (c) => c.contactId === dialogState.candidateData?.contactId,
    );
  }, [pipelineData, dialogState.candidateData]);

  const isShowInterviewOptionsEnabled =
    dialogState.newPosition?.pipelinePosition === 'Phone/Virtual Interview' ||
    dialogState.newPosition?.pipelinePosition === 'In-Person Interview';

  function handleSubmit() {
    const additionalInfo: any = {};

    if (note) {
      additionalInfo.activityNote = note;
    }

    if (isShowInterviewOptionsEnabled && date && time) {
      const dateTimeString = `${date.toISOString().split('T')[0]}T${time}:00`;
      const utcDate = fromZonedTime(dateTimeString, 'America/Chicago');
      const formattedDate = format(utcDate, "yyyy-MM-dd'T'HH:mm:ssXXX");

      if (
        dialogState.newPosition?.pipelinePosition === 'Phone/Virtual Interview'
      ) {
        additionalInfo.virtualInterviewDate = formattedDate;
      } else {
        additionalInfo.inPersonInterviewDate = formattedDate;
      }
    }

    handleDialogSubmit(additionalInfo);
  }

  function closeModal() {
    handleDialogCancel();
  }

  const timeOptions = useMemo(() => {
    if (!isShowInterviewOptionsEnabled) return [];

    return generateTimeOptions();
  }, [isShowInterviewOptionsEnabled]);

  const isMoveButtonDisabled = isShowInterviewOptionsEnabled
    ? !date || !time || isApiPending
    : !note || isApiPending;

  useEffect(() => {
    if (
      dialogState?.newPosition?.pipelinePositionOrder &&
      dialogState.newPosition.pipelinePositionOrder >= 5
    ) {
      setNoteRequired(true);
      return;
    } else if (
      dialogState?.candidateData?.pipelinePositionOrder &&
      dialogState?.newPosition?.pipelinePositionOrder &&
      dialogState.newPosition.pipelinePositionOrder <
        dialogState.candidateData.pipelinePositionOrder
    ) {
      setNoteRequired(true);
    } else {
      setNoteRequired(false);
    }
  }, [dialogState, setNoteRequired]);

  return (
    <Modal open={dialogState.isOpen} onOpenChange={closeModal}>
      <Modal.Content
        title={`Move candidate to: ${dialogState.newPosition?.pipelinePosition}`}
      >
        <div>
          <div className='font-medium'>
            <span className=' font-normal'>Candidate selected:</span>{' '}
            <span>
              {capitalizeName(`${contact?.firstName} ${contact?.lastName}`)}
            </span>
          </div>
        </div>
        <div className='flex flex-col gap-8'>
          <div className='flex flex-grow flex-col gap-4'>
            {isShowInterviewOptionsEnabled && (
              <div className='flex items-center gap-4'>
                <DatePicker
                  onChange={(date) => {
                    setDate(date);
                  }}
                  onClear={() => {
                    setDate(undefined);
                  }}
                  className='min-w-48'
                />
                <Select
                  label='Time of interview (CST)'
                  options={timeOptions}
                  value={time}
                  onChange={setTime}
                  classNames={{ label: 'text-sm leading-none mb-2' }}
                />
              </div>
            )}
            <div>
              <Label className='font-medium'>
                Note
                {noteRequired && <span className='text-error'>*</span>}
              </Label>
              <Textarea
                placeholder='Add a note about why this candidate is moving to this stage...'
                value={note}
                onChange={(e) => setNote(e.target.value)}
                className='placeholder:text-neutral'
              />
            </div>
          </div>
          <div className='flex justify-between'>
            <Button variant='secondary' onClick={closeModal}>
              Cancel
            </Button>
            <div className='flex items-center gap-2'>
              {!isShowInterviewOptionsEnabled && !noteRequired && (
                <Button
                  disabled={!!note || isApiPending}
                  isLoading={!!note && isApiPending}
                  onClick={handleSubmit}
                  variant='ghost'
                  className='text-xs text-link'
                >
                  Move Without Note
                </Button>
              )}
              <Button
                disabled={isMoveButtonDisabled || (noteRequired && !note)}
                isLoading={!note && isApiPending}
                onClick={handleSubmit}
              >
                Move
              </Button>
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
