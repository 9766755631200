import React from 'react';

import { useQueryParams } from '../../hooks/useQueryParams';
import { legendColors } from '../../utils/constant';

function ActiveScaleInput() {
  const { queryParams, updateQueryParam, removeQueryParam } = useQueryParams();

  const handleColorClick = (color: string | string[]) => {
    if (queryParams.engagementColor === color) {
      removeQueryParam('engagementColor');
    } else {
      updateQueryParam('engagementColor', color, { resetPageOnChange: false });
    }
  };

  return (
    <div className='-p-16 flex flex-wrap items-center rounded-md border border-gray-200 pl-6 pr-11 text-center'>
      <div className='m-2 mt-1.5 text-center text-sm text-gray-500'>
        Active Scale (High to low)
      </div>
      <div className='flex gap-2'>
        {legendColors.map(({ label, color }) => (
          <div key={color} className='group relative flex items-center'>
            <button
              onClick={() => handleColorClick(color)}
              className={`relative h-5 w-5 rounded-full transition-all ${
                queryParams.engagementColor === color
                  ? 'ring-2 ring-primary ring-offset-2'
                  : 'hover:scale-110'
              }`}
              style={{ backgroundColor: color }}
              aria-label={`Filter by engagement level ${label}`}
            >
              <span className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-xs font-bold text-white'>
                {label}
              </span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ActiveScaleInput;
