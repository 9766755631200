import { useState } from 'react';

import { EmptyState } from '../../../components/common';
import { SearchIcon } from '../../../components/common/Icons';
import Select from '../../../components/common/SelectNew';
import { useCustomQuery } from '../../../hooks/useCustomQuery';
import { useQueryParams } from '../../../hooks/useQueryParams';
import ProactiveCallEffortChart from './ProactiveCallEffortChart';
import { ProactiveCallEffortsTable } from './ProactiveCallEffortTable';

export interface APIProActiveCallTimeReport {
  userId: string | null;
  totalCallTime: number;
  totalOutboundCallTime: number;
  totalInboundCallTime: number;
  totalCallCount: number;

  contactInboundCallTime: number;
  contactOutboundCallTime: number;
  contactTotalCallCount: number;
  contactUniqueCallCount: number;

  orgInboundCallTime: number;
  orgOutboundCallTime: number;
  orgTotalCallCount: number;
  orgUniqueCallCount: number;

  otherInboundCallTime: number;
  otherOutboundCallTime: number;
  otherTotalCallCount: number;
  otherUniqueCallCount: number;

  totalOutboundEmails: number;
}

function ProactiveCallEffort() {
  const { queryParams } = useQueryParams();
  const [selectedView, setSelectedView] = useState<string | number>(0);
  const [callTimeOption, setCallTimeOption] = useState('external');

  const { data: response, isLoading } = useCustomQuery<
    APIProActiveCallTimeReport[]
  >({
    url: '/admin/reports/comms',
    params: {
      domain: callTimeOption,
      startDate: queryParams?.startDate,
      endDate: queryParams?.endDate,
      interval: queryParams?.timeFrame || 'day',
    },
  });

  const proActiveReportData = response?.data;

  const isExternalSelected = callTimeOption === 'external';

  return (
    <div className='flex h-[500px] w-full flex-col gap-5 rounded-lg border border-neutral-40 bg-white p-4'>
      <div className='flex'>
        <div className='flex w-full items-center justify-between gap-2'>
          <div className='flex items-center gap-4'>
            <div className={`text-nowrap text-lg font-bold`}>
              Communications
            </div>
            <Select
              options={[
                { label: 'External', value: 'external' },
                { label: 'Internal', value: 'internal' },
                { label: 'All', value: 'all' },
              ]}
              value={callTimeOption}
              onChange={setCallTimeOption}
              classNames={{
                trigger: 'min-w-24 shadow-sm h-8',
              }}
            />
          </div>
          <div className='flex gap-2'>
            <label
              onClick={() => setSelectedView(0)}
              htmlFor='available-jobs-checkbox'
              className={`h-auto cursor-pointer rounded-md border border-neutral-40 px-2 py-1.5 text-sm font-normal shadow-sm ${selectedView === 0 ? 'bg-secondary-80 text-white' : 'bg-white text-secondary-80'}`}
              role='checkbox'
              aria-checked={selectedView === 0}
              tabIndex={0}
            >
              Bar chart view
            </label>
            <label
              onClick={() => setSelectedView(1)}
              htmlFor='available-jobs-checkbox'
              className={`h-auto cursor-pointer rounded-md border border-neutral-40 px-2 py-1.5 text-sm font-normal shadow-sm ${selectedView === 1 ? 'bg-secondary-80 text-white' : 'bg-white text-secondary-80'}`}
              role='checkbox'
              aria-checked={selectedView === 1}
              tabIndex={1}
            >
              Table view
            </label>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className='size-full h-[325px] animate-pulse rounded-md bg-gray-400' />
      ) : (
        <>
          {!proActiveReportData?.length ? (
            <EmptyState
              icon={SearchIcon}
              title='No results found'
              link={
                <div className='flex'>
                  <span style={{ maxWidth: 300, textAlign: 'center' }}>
                    No results found.
                  </span>
                </div>
              }
            />
          ) : (
            <>
              {selectedView === 0 && (
                <div className='size-full'>
                  <ProactiveCallEffortChart
                    records={proActiveReportData}
                    isExternalSelected={isExternalSelected}
                  />
                </div>
              )}
              {selectedView === 1 && (
                <div className='w-full overflow-auto rounded border'>
                  <ProactiveCallEffortsTable
                    records={proActiveReportData}
                    isExternalSelected={isExternalSelected}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ProactiveCallEffort;
