import { ArrowUpRightIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Table,
  Header,
  HeaderCell,
  Body,
  Row,
  RowCell,
} from '../../components/common/Table';
import { useGlobalState } from '../../context/GlobalContext';
import { useQueryParams } from '../../hooks/useQueryParams';
import { ContactView } from '../../pages/contacts/ContactView';
import { OrganizationView } from '../../pages/organizations/OrganizationView';
import { RequisitionView } from '../../pages/requisitions/RequisitionView';
import { HotSheetReportData } from '../../types/api';
import { formatCurrency, setFieldIfEmpty } from '../../utils/helpers';
import { removeTabsParams } from '../../utils/tabs';
import { formatDate } from '../../utils/time';
import OrganizationName from './OrganizationName';

export function HotSheetsTable({
  data,
  refetch,
  localSort,
  hideAdminColumns = false,
}: {
  data: HotSheetReportData[];
  refetch?: () => void;
  localSort?: {
    sortKey: string;
    sortDirection: string;
    setSortKey: (key: string) => void;
    setSortDirection: (direction: string) => void;
  };
  hideAdminColumns?: boolean;
}) {
  const { findUserById } = useGlobalState();
  const { queryParams, setQueryParams } = useQueryParams();
  const [contactId, setContactId] = useState<string | null>(null);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [requisitionId, setRequisitionId] = useState<string | null>(null);

  const tableHeader = useMemo(
    () => generateTableHeader(hideAdminColumns),
    [hideAdminColumns],
  );

  const tableBody = useMemo(
    () =>
      data?.map((record) => {
        const user = findUserById(record?.userId);
        const key = `${record.candidateId}-${record.jobId}`;

        return (
          <Row key={key} tabIndex={0} className='w w- cursor-default'>
            <RowCell className='border-l-0'>
              <button
                onClick={() => setOrganizationId(record?.organizationId)}
                className='w-full truncate text-left text-link hover:text-[#167dff] hover:underline'
              >
                <OrganizationName
                  hasContract={!!record?.contract}
                  name={record?.organizationName}
                />
              </button>
            </RowCell>
            <RowCell>
              {setFieldIfEmpty(record?.city)}, {setFieldIfEmpty(record?.state)}
            </RowCell>
            <RowCell>
              {record?.feePercentage
                ? `${(record?.feePercentage * 100).toFixed(0)}%`
                : '-'}
            </RowCell>
            <RowCell>
              {record?.hiringContactId ? (
                <button
                  onClick={() => setContactId(record?.hiringContactId)}
                  className='w-full truncate text-left text-link hover:text-[#167dff] hover:underline'
                >
                  {record?.hiringContactName}
                </button>
              ) : record?.hiringContactName ? (
                <span className='w-full truncate text-left'>
                  {record?.hiringContactName}
                </span>
              ) : (
                <span className='w-full truncate text-left'>-</span>
              )}
            </RowCell>
            <RowCell>
              <button
                onClick={() => setRequisitionId(record?.jobId)}
                className='w-full truncate text-left text-link hover:text-[#167dff] hover:underline'
              >
                {setFieldIfEmpty(record?.jobTitle)}
              </button>
            </RowCell>
            <RowCell>
              {setFieldIfEmpty(
                `${record?.salaryBottom ? `${formatCurrency(record?.salaryBottom)}` : '-'}`,
              )}
            </RowCell>
            <RowCell>
              {setFieldIfEmpty(
                `${record?.salaryTop ? `${formatCurrency(record?.salaryTop)}` : '-'}`,
              )}
            </RowCell>
            {!hideAdminColumns && (
              <RowCell>{setFieldIfEmpty(user?.fullName)}</RowCell>
            )}
            <RowCell>
              <button
                onClick={() => {
                  setContactId(record?.candidateId);
                }}
                className='w-full truncate text-left text-link hover:text-[#167dff] hover:underline'
              >
                {setFieldIfEmpty(record?.candidateName)}
              </button>
            </RowCell>
            <RowCell>
              {setFieldIfEmpty(
                `${record?.commissionBottom ? `${formatCurrency(record?.commissionBottom)}` : '-'}`,
              )}
            </RowCell>
            <RowCell>
              {setFieldIfEmpty(
                `${record?.commissionTop ? `${formatCurrency(record?.commissionTop)}` : '-'}`,
              )}
            </RowCell>
            <RowCell className='group'>
              <Link
                to={`/pipeline/${record?.jobId}`}
                target='_blank'
                className='text-link'
              >
                {record?.pipelinePosition ? (
                  <div className='flex w-full items-center justify-between gap-1'>
                    {record.pipelinePosition}
                    <ArrowUpRightIcon className='hidden group-hover:block' />
                  </div>
                ) : (
                  '-'
                )}
              </Link>
            </RowCell>
            <RowCell>{formatDate(record.virtualInterviewDate)}</RowCell>
            <RowCell>{formatDate(record?.inPersonInterviewDate)}</RowCell>
            <RowCell className='w- border-r-0'>
              {formatDate(record?.lastPositionMoveDate)}
            </RowCell>
          </Row>
        );
      }),
    [data, findUserById, hideAdminColumns],
  );

  return (
    <>
      <div className='size-full overflow-auto rounded border border-neutral-40'>
        <Table
          classNames={{
            table: 'relative table-fixed h-auto',
            container: '',
          }}
        >
          <Header
            classNames={{
              thead: 'sticky top-0 shadow-sm',
            }}
          >
            {tableHeader.map((header) => {
              return (
                <HeaderCell
                  isSorting
                  key={header?.label}
                  sortKey={header?.key}
                  className={`${header.className}`}
                  currentLocalSortKey={localSort?.sortKey}
                  currentLocalSortDirection={localSort?.sortDirection}
                  defaultSortKey='pipelinePositionOrder'
                  {...(localSort && {
                    handleLocalSort: ({
                      key,
                      direction,
                    }: {
                      key: string;
                      direction: string;
                    }) => {
                      localSort?.setSortKey(key);
                      localSort?.setSortDirection(direction);
                    },
                  })}
                >
                  {header?.label}
                </HeaderCell>
              );
            })}
          </Header>

          <Body>{tableBody}</Body>
        </Table>
      </div>

      {contactId && (
        <ContactView
          isOpen={!!contactId}
          contactId={contactId}
          onClose={() => {
            removeTabsParams(queryParams, setQueryParams);
            setContactId(null);
          }}
          onContactSave={() => {
            refetch?.();
          }}
        />
      )}

      {organizationId && (
        <OrganizationView
          isOpen={!!organizationId}
          organizationId={organizationId}
          onClose={() => {
            removeTabsParams(queryParams, setQueryParams);
            setOrganizationId(null);
          }}
          // TODO: Need to mnove to the onSave pattern from above (the ContactView component)
          invalidatePath='/admin/reports/hotsheet'
        />
      )}

      {requisitionId && (
        <RequisitionView
          isOpen={!!requisitionId}
          requisitionId={requisitionId}
          onClose={() => {
            removeTabsParams(queryParams, setQueryParams);
            setRequisitionId(null);
          }}
          onRequisitionSave={() => {
            refetch?.();
          }}
        />
      )}
    </>
  );
}

function generateTableHeader(hideAdminColumns = false) {
  return [
    {
      label: 'Organization',
      key: 'organizationName',
      className: 'w-72',
    },
    { label: 'City/State', key: '', className: 'w-32' },
    {
      label: 'Fee %',
      key: 'feePercentage',
      className: 'w-24',
    },
    {
      label: 'Hiring Manager',
      key: '',
      className: 'w-40',
    },
    {
      label: 'Job Title',
      key: '',
      className: 'w-80',
    },
    {
      label: 'Salary Low',
      key: 'salaryBottom',
      className: 'w-40',
    },
    {
      label: 'Salary High',
      key: 'salaryTop',
      className: 'w-40',
    },
    ...(!hideAdminColumns
      ? [
          {
            label: 'User Assigned',
            key: '',
            className: 'w-32',
          },
        ]
      : []),
    {
      label: 'Candidate Name',
      key: '',
      className: 'w-40',
    },
    {
      label: 'Lower Fee',
      key: 'commissionBottom',
      className: 'w-32',
    },
    {
      label: 'Upper Fee',
      key: 'commissionTop',
      className: 'w-32',
    },
    {
      label: 'Pipeline Status',
      key: 'pipelinePositionOrder',
      className: 'w-56',
    },
    {
      label: 'Phone/Virtual Intvw',
      key: 'virtualInterviewDate',
      className: 'w-48',
    },
    {
      label: 'In-Person Intvw',
      key: 'inPersonInterviewDate',
      className: 'w-44',
    },
    {
      label: 'Last Position Move',
      key: 'lastPositionMoveDate',
      className: 'w-48',
    },
  ];
}
