export type Providers = 'Google' | 'Entra';

type AuthParams = {
  state: string;
  nonceVerifier: string;
  nonce: string;
  codeVerifier: string;
  codeChallenge: string;
};

const sha256 = async (str: string) => {
  return await crypto.subtle.digest('SHA-256', new TextEncoder().encode(str));
};

const generateNonce = (): string => {
  return crypto.randomUUID();
};

const base64URLEncode = (buffer: ArrayBuffer): string => {
  return btoa(String.fromCharCode(...new Uint8Array(buffer)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

export const generateAuthParams = async (): Promise<AuthParams> => {
  const state = generateNonce();
  const nonceVerifier = generateNonce();
  const nonce = base64URLEncode(await sha256(nonceVerifier));
  const codeVerifier = generateNonce();
  const codeChallenge = base64URLEncode(await sha256(codeVerifier));

  return { state, nonceVerifier, nonce, codeVerifier, codeChallenge };
};

export const constructAuthUrl = (
  params: AuthParams,
  provider: Providers,
): string => {
  const urlParams = new URLSearchParams({
    response_type: 'code',
    client_id: process.env.REACT_APP_COGNITO_CLIENT_ID!,
    redirect_uri: process.env.REACT_APP_COGNITO_REDIRECT_URI!,
    state: params.state,
    nonce: params.nonce,
    code_challenge: params.codeChallenge,
    code_challenge_method: 'S256',
    scope: 'openid email',
    identity_provider: provider,
  });

  return `https://auth.crm.mlee.com/oauth2/authorize?${urlParams.toString()}`;
};
