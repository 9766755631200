import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { APIProvider } from '@vis.gl/react-google-maps';
import axios from 'axios';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import RingCentralListener from './components/integrations/RingCentralListener';
import Phone from './components/phone/Phone';
import { Toaster } from './components/ui/toaster';
import { TooltipProvider } from './components/ui/tooltips';
import { PhoneProvider } from './context/PhoneContext';
import { RingCentralProvider } from './context/RingCentralContext';
import { useToast } from './hooks/use-toast';
import reportWebVitals from './reportWebVitals';
import { routes } from './routes';
import './styles/deprecated-stylesheet.css';
import './styles/index.css';

const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';

const App = () => {
  const { toast } = useToast();

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 1000 * 60 * 3,
          retry: 1,
          placeholderData: (prevData: any) => prevData,
        },
      },
      queryCache: new QueryCache({
        onError: (error) => {
          if (axios.isAxiosError(error)) {
            if (
              error.response?.status === 401 &&
              (error.request.responseURL.includes('api.pulse') ||
                error.request.responseURL.includes('api.crm') ||
                error.request.responseURL.includes('localhost'))
            ) {
              if (!window.location.pathname.includes('/login')) {
                router.navigate(
                  '/login?redirectUrl=' +
                    encodeURI(
                      window.location.pathname + window.location.search,
                    ),
                );
              }
            } else {
              toast({
                title: 'Error!',
                description: 'Error fetching data!',
                variant: 'destructive',
                duration: 15000,
              });
            }
          }
        },
      }),
    }),
  );

  const router = createBrowserRouter(routes.filter((r) => r.enabled));

  return (
    <APIProvider
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY!}
      libraries={['core', 'routes']}
    >
      <RingCentralProvider>
        <QueryClientProvider client={queryClient.current}>
          <TooltipProvider>
            <PhoneProvider>
              <RouterProvider router={router} />
              {isDev && <ReactQueryDevtools initialIsOpen={false} />}
              <RingCentralListener />
              <Phone />
              <Toaster />
            </PhoneProvider>
          </TooltipProvider>
        </QueryClientProvider>
      </RingCentralProvider>
    </APIProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
