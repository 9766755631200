import { useCallback, useState, useRef, useMemo } from 'react';

export type SelectionMode = 'individual' | 'allFiltered';

export type SelectionState = {
  mode: SelectionMode;
  newlySelected: Set<string>;
  blacklistedIds: Set<string>;
};

export function useTableSelection(
  items: { id: string; isInList: boolean; disabled: boolean }[],
  totalFilteredCount?: number,
) {
  const [selectionState, setSelectionState] = useState<SelectionState>({
    mode: 'individual',
    newlySelected: new Set(),
    blacklistedIds: new Set(),
  });

  const lastClickedRef = useRef<string | null>(null);

  const toggleSelectionMode = useCallback(() => {
    setSelectionState((prevState) => ({
      mode: prevState.mode === 'individual' ? 'allFiltered' : 'individual',
      newlySelected: new Set(),
      blacklistedIds: new Set(),
    }));
  }, []);

  const handleSelectionChange = useCallback(
    (selectedId: string, isShiftKey: boolean) => {
      setSelectionState((prevState) => {
        const lastClicked = lastClickedRef.current;

        if (prevState.mode === 'allFiltered') {
          const blacklistedIds = new Set(prevState.blacklistedIds);

          if (isShiftKey && lastClicked) {
            const lastIndex = items.findIndex((i) => i.id === lastClicked);
            const currentIndex = items.findIndex((i) => i.id === selectedId);

            if (lastIndex !== -1 && currentIndex !== -1) {
              const [start, end] =
                lastIndex < currentIndex
                  ? [lastIndex, currentIndex]
                  : [currentIndex, lastIndex];

              const shouldBlacklist = !blacklistedIds.has(selectedId);

              for (let i = start; i <= end; i++) {
                const id = items[i].id;
                if (!items[i].disabled) {
                  if (shouldBlacklist) {
                    blacklistedIds.add(id);
                  } else {
                    blacklistedIds.delete(id);
                  }
                }
              }
            }
          } else {
            if (blacklistedIds.has(selectedId)) {
              blacklistedIds.delete(selectedId);
            } else if (!items.find((i) => i.id === selectedId)?.disabled) {
              blacklistedIds.add(selectedId);
            }
          }

          lastClickedRef.current = selectedId;
          return {
            ...prevState,
            blacklistedIds,
          };
        }

        // Individual selection mode
        const newlySelected = new Set(prevState.newlySelected);

        if (isShiftKey && lastClicked) {
          const lastIndex = items.findIndex((i) => i.id === lastClicked);
          const currentIndex = items.findIndex((i) => i.id === selectedId);

          if (lastIndex !== -1 && currentIndex !== -1) {
            const [start, end] =
              lastIndex < currentIndex
                ? [lastIndex, currentIndex]
                : [currentIndex, lastIndex];

            const shouldSelect = !newlySelected.has(selectedId);

            for (let i = start; i <= end; i++) {
              const id = items[i].id;
              if (shouldSelect && !items[i].disabled) {
                newlySelected.add(id);
              } else {
                newlySelected.delete(id);
              }
            }
          }
        } else {
          if (newlySelected.has(selectedId)) {
            newlySelected.delete(selectedId);
          } else if (!items.find((i) => i.id === selectedId)?.disabled) {
            newlySelected.add(selectedId);
          }
        }

        lastClickedRef.current = selectedId;
        return {
          ...prevState,
          newlySelected,
        };
      });
    },
    [items],
  );

  const isItemSelected = useCallback(
    (id: string) => {
      const item = items.find((i) => i.id === id);
      if (!item) return false;

      if (item.isInList) return true;

      if (selectionState.mode === 'allFiltered') {
        return !selectionState.blacklistedIds.has(id);
      }

      return selectionState.newlySelected.has(id);
    },
    [items, selectionState],
  );

  const isAllItemsOnCurrentPageInList = useMemo(() => {
    return items.every((i) => i.isInList);
  }, [items]);

  const isAllCheckedOnCurrentPage = useMemo(() => {
    if (selectionState.mode === 'allFiltered') {
      return items.every((i) => !selectionState.blacklistedIds.has(i.id));
    }
    return items.every((i) => isItemSelected(i.id));
  }, [isItemSelected, items, selectionState]);

  const isItemDisabled = useCallback(
    (id: string) => {
      const item = items.find((i) => i.id === id);
      return item?.isInList ?? false;
    },
    [items],
  );

  const getSelectedCount = useCallback(() => {
    if (selectionState.mode === 'allFiltered') {
      // we only want to enable the bulk actions if the total count is less than 1000
      if ((totalFilteredCount ?? 0) <= 1000) {
        return (totalFilteredCount ?? 0) - selectionState.blacklistedIds.size;
      }

      return totalFilteredCount ?? 0;
    }
    return selectionState.newlySelected.size;
  }, [selectionState, totalFilteredCount]);

  const getSelectionPayload = useCallback(() => {
    if (selectionState.mode === 'allFiltered') {
      return {
        mode: 'allFiltered' as const,
        blacklistedIds: Array.from(selectionState.blacklistedIds),
      };
    }
    return {
      mode: 'individual' as const,
      selectedIds: Array.from(selectionState.newlySelected),
    };
  }, [selectionState]);

  const clearSelectedItems = useCallback(() => {
    setSelectionState({
      mode: 'individual',
      newlySelected: new Set(),
      blacklistedIds: new Set(),
    });
  }, []);

  return {
    selectionState,
    clearSelectedItems,
    handleSelectionChange,
    toggleSelectionMode,
    isItemSelected,
    isItemDisabled,
    isAllCheckedOnCurrentPage,
    isAllItemsOnCurrentPageInList,
    getSelectionPayload,
    getSelectedCount,
  };
}
