import RootLayout from '../../layouts';
import ListDetails from './ListDetailScreen';

const ListsPage = () => {
  return (
    <RootLayout>
      <ListDetails />
    </RootLayout>
  );
};

export default ListsPage;
