import { SetURLSearchParams } from 'react-router-dom';

import { objectToQueryString, QueryParams } from '../hooks/useQueryParams';

export enum TabsParams {
  ContactsTabTablePage = 'contactsTabTablePage',
  ActivitiesTab = 'activitiesTabPage',
  NotesTab = 'notesTabPage',
  ConversationsTabPage = 'conversationsTabPage',
  ConversationsTabType = 'conversationsTabType',
  ConversationsTabSort = 'conversationsTabSort',
  ConversationsTabOrderBy = 'conversationsTabOrderBy',
  ConversationsTabUserId = 'conversationsTabUserId',
  RequisitionsTabTablePage = 'requisitionsTabTablePage',
  RequisitionsTabTableStatus = 'requisitionsTabTableStatus',
}

// TODO:
// Short on time, need to think of a cleaner solution for handling the edge cases between the two below

export const tabsParamsList = Object.values(TabsParams);

/**
 * Function to remove tabs params from the query params object and return the new query string.
 * Useful on the main level pages where we need to update the url when closing the (contact/org/req) views
 * @param queryParams
 * @returns
 */
export const removeTabsParamsAndReturnQueryString = (
  queryParams: QueryParams,
) => {
  const newParams = { ...queryParams };

  tabsParamsList.forEach((tabParam) => {
    if (newParams[tabParam]) {
      delete newParams[tabParam];
    }
  });

  const newQueryParamString = objectToQueryString(newParams);

  return newQueryParamString;
};

/**
 * Function for removing tabs params only
 * @param queryParams
 * @param setSearchParams
 */
export const removeTabsParams = (
  queryParams: QueryParams,
  setSearchParams: SetURLSearchParams,
) => {
  const newParams = { ...queryParams };

  tabsParamsList.forEach((tabParam) => {
    if (newParams[tabParam]) {
      delete newParams[tabParam];
    }
  });

  const newQueryParamString = objectToQueryString(newParams);
  setSearchParams(newQueryParamString);
};
