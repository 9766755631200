import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getTableKey } from '../../components/bulk-actions/AddToListModal';
import Modal from '../../components/common/ModalRadix';
import PageContent from '../../components/common/PageContent';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import useInvalidateQuery from '../../hooks/useInvalidateQuery';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useTableSelection } from '../../hooks/useTableSelection';
import { PageTypes } from '../../types/pages';
import { generateBaseTableApiParams } from '../../utils/api';
import { Stages } from '../../utils/constant';
import ContactsFilters from '../contacts/ContactsFilters';
import ContactsTable from '../contacts/ContactsTable';
import OrganizationsFilters from '../organizations/OrganizationFilters';
import OrganizationsTable from '../organizations/OrganizationsTable';
import RequisitionsFilters from '../requisitions/RequisitionsFilters';
import RequisitionsTable from '../requisitions/RequisitionsTable';

type EntityConfig = {
  apiUrl: string;
  FiltersComponent: React.ComponentType<any>;
  TableComponent: React.ComponentType<any>;
  rowId: string;
  entityName: string;
  entityNamePlural: string;
  idField: string;
  defaultOrderBy: string;
};

const entityConfigs: Record<string, EntityConfig> = {
  [PageTypes.Contacts]: {
    apiUrl: '/contacts',
    FiltersComponent: ContactsFilters,
    TableComponent: ContactsTable,
    rowId: 'contactId',
    entityName: 'contact',
    entityNamePlural: 'contacts',
    idField: 'contactId',
    defaultOrderBy: 'dateEntered',
  },
  [PageTypes.Organizations]: {
    apiUrl: '/organizations',
    FiltersComponent: OrganizationsFilters,
    TableComponent: OrganizationsTable,
    rowId: 'organizationId',
    entityName: 'organization',
    entityNamePlural: 'organizations',
    idField: 'organizationId',
    defaultOrderBy: 'dateEntered',
  },
  [PageTypes.Requisitions]: {
    apiUrl: '/jobs',
    FiltersComponent: RequisitionsFilters,
    TableComponent: RequisitionsTable,
    rowId: 'jobId',
    entityName: 'requisition',
    entityNamePlural: 'requisitions',
    idField: 'jobId',
    defaultOrderBy: 'datePosted',
  },
};

type AddEntityModalProps = {
  isOpen: boolean;
  handleClose: (
    entityType: PageTypes,
    resetParams: boolean,
    successfulSave: boolean,
  ) => void;
  entityType: PageTypes;
  setListCounts: React.Dispatch<
    React.SetStateAction<{
      contactCount: number;
      orgCount: number;
      jobCount: number;
    } | null>
  >;
};

function AddEntityModal({
  isOpen,
  handleClose,
  entityType,
  setListCounts,
}: AddEntityModalProps) {
  const config = entityConfigs[entityType];

  const { id } = useParams();

  const { queryParams } = useQueryParams();

  const currentFilters = generateBaseTableApiParams(
    queryParams,
    config.defaultOrderBy,
    id,
  );

  const {
    data: response,
    isLoading,
    isFetching,
  } = useCustomQuery<any[]>({
    url: config.apiUrl,
    params: currentFilters,
    options: { staleTime: 0 },
  });

  const { data: entities = [], pages, numberofrecords } = response ?? {};

  const itemsForSelection = useMemo(
    () =>
      entities.map((entity) => ({
        id: entity[config.idField],
        isInList: !!entity.isInList,
        disabled: !!entity.isInList,
      })),
    [entities, config.idField],
  );
  const totalFilteredCount = numberofrecords;
  const { selectionState, handleSelectionChange, ...checkboxProps } =
    useTableSelection(itemsForSelection, totalFilteredCount);

  const invalidate = useInvalidateQuery();

  const { mutate: addEntities, isPending } = useCustomMutation({
    onSuccess: (resp: any) => {
      invalidate([`/lists/${id}`], { refetchType: 'active' });
      invalidate([`/lists/${id}`], { refetchType: 'none' });
      invalidate([`/lists`], { refetchType: 'none' });

      if (resp.data) {
        setListCounts({
          contactCount: resp.data.contactCount,
          orgCount: resp.data.organizationCount,
          jobCount: resp.data.jobCount,
        });
      }

      checkboxProps.clearSelectedItems?.();
      handleClosing({ resetParams: true, successfulSave: true });
    },
    onSuccessMessage: `${checkboxProps.getSelectedCount()} ${
      checkboxProps.getSelectedCount() > 1
        ? config.entityNamePlural
        : config.entityName
    } added to list`,
    method: 'post',
  });

  function handleClosing({
    resetParams = false,
    successfulSave,
  }: { resetParams?: boolean; successfulSave?: boolean } = {}) {
    // invalidate the data fetching in this component so that we get fresh data when reopening this component
    invalidate([`/${entityType}`]);
    handleClose(entityType, !!resetParams, !!successfulSave);
  }

  const handleSubmit = async () => {
    const tableKey = getTableKey(entityType);

    const selectionPayload = checkboxProps.getSelectionPayload?.();
    if (selectionPayload?.mode === 'allFiltered') {
      const filters = { ...currentFilters };

      // escape hatch for state filter - the backend expects an array no matter what but if we only have one state in our query params, we pass it as a string
      if (filters?.state) {
        if (!Array.isArray(filters.state)) {
          filters.state = [filters.state];
        }
      }

      const filterPayload = {
        [tableKey]: filters,
      };

      await addEntities({
        url: `/lists/${id}/batchitems`,
        body: {
          ...filterPayload,
          removalIds: selectionPayload.blacklistedIds,
          stage: Stages.toInterview,
        },
      });
    } else {
      const formattedItems = Array.from(selectionPayload.selectedIds ?? []).map(
        (itemId) => ({
          tableId: itemId,
          tableName: tableKey,
          stage: Stages.toInterview,
        }),
      );

      await addEntities({
        url: `/lists/${id}/items`,
        body: formattedItems,
      });
    }
  };

  const FiltersComponent = config.FiltersComponent;
  const TableComponent = config.TableComponent;

  return (
    <Modal
      open={isOpen}
      onOpenChange={() => {
        handleClosing();
      }}
    >
      <Modal.Content
        title={`Add ${config.entityNamePlural} to List`}
        className='h-[90vh] w-[80vw] max-w-[3000px] gap-6'
      >
        <PageContent
          contentType={entityType}
          data={entities}
          pages={pages}
          isLoading={isLoading}
          TableComponent={TableComponent}
          handleRowClick={(row, event) =>
            handleSelectionChange(row[config.rowId], event.shiftKey)
          }
          FiltersComponent={FiltersComponent}
          modal={{
            isModalMode: true,
            queryParamIgnoreKeys: ['entityType'],
            isSubmitPending: isPending,
            handleModalSubmit: handleSubmit,
            handleModalClose: handleClosing,
          }}
          checkboxProps={{
            selectionState,
            handleSelectionChange,
            currentFilters,
            ...checkboxProps,
          }}
          isFetchingNewTableData={isFetching}
        />
      </Modal.Content>
    </Modal>
  );
}

export default AddEntityModal;
