import { IconButton, TooltipHost } from '@fluentui/react';

import { JiraIcon } from './Icons';

const apps = [
  {
    name: 'Outlook',
    icon: 'OutlookLogo',
    url: 'https://outlook.office.com/mail/inbox',
  },
  { name: 'Teams', icon: 'TeamsLogo', url: 'https://teams.microsoft.com' },
  { name: 'Word', icon: 'WordLogo', url: 'https://www.office.com/launch/word' },
  {
    name: 'Excel',
    icon: 'ExcelLogo',
    url: 'https://www.office.com/launch/excel',
  },
  {
    name: 'PowerPoint',
    icon: 'PowerPointLogo',
    url: 'https://www.office.com/launch/powerpoint',
  },
  {
    name: 'Jira',
    icon: 'JiraLogo',
    url: 'https://mlee-inc.atlassian.net/jira/your-work',
    customIcon: JiraIcon,
  },
];

const AppLauncher = () => {
  return (
    <div className='apps-waffle-menu flex flex-row items-center p-2'>
      {apps.map((app) => (
        <TooltipHost content={app.name} key={app.name}>
          <>
            {app.customIcon ? (
              <IconButton
                onRenderIcon={() => <app.customIcon size={16} />}
                title={app.name}
                ariaLabel={app.name}
                onClick={() => window.open(app.url, '_blank')}
              />
            ) : (
              <IconButton
                iconProps={{ iconName: app.icon }}
                title={app.name}
                ariaLabel={app.name}
                onClick={() => window.open(app.url, '_blank')}
              />
            )}
          </>
        </TooltipHost>
      ))}
    </div>
  );
};

export default AppLauncher;
