import { IDropdownOption } from '@fluentui/react';
import { useQueryClient } from '@tanstack/react-query';
import { E164Number } from 'libphonenumber-js/types';
import React, { useEffect, useReducer, useState } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  Dropdown,
  IAction,
  TextInput,
  commonReducer,
} from '../../components/common';
import AddressInput from '../../components/common/AddressInput';
import { Button } from '../../components/common/Button';
import PhoneInput from '../../components/common/PhoneInput';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../components/ui/alert-dialog';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import RootLayout from '../../layouts';
import { IAddress } from '../../react-app-env';
import { APIAddress } from '../../types/common';
import { APIOrganization } from '../../types/organizations';
import { validateAddress } from '../../utils/googleHelpers';
import { isValidEmail } from '../../utils/helpers';
import { ContingencyAgreementDetails } from './ContingencyAgreementDetails';

type AddOrganizationState = {
  feePercentage: number | null;
  organizationName: string;
  specialty: string;
  phone: E164Number | undefined;
  email: string;
  url?: string;
  careerPageURL?: string;
  numberOfBeds?: number;
  patientDays?: number;
  revenue?: number;
  userId?: string;
  dateSigned?: string; // 2024-04-04 15:48:00
  paymentTerms?: string;
  lateFee?: string;
  guarantee?: string;
} & APIAddress;

const Container = styled.div.attrs({
  className: 'px-8 sm:px-16',
})`
  max-width: 600px;
`;

const initialState: AddOrganizationState = {
  feePercentage: null,
  organizationName: '',
  specialty: '',
  address1: '',
  city: '',
  state: '',
  postalCode: '',
  phone: undefined,
  url: '',
  email: '',
};

const validate = (state: AddOrganizationState) => {
  const errors: Partial<Record<keyof AddOrganizationState, string>> = {};
  if (!state.organizationName)
    errors.organizationName = 'Organization name is required';
  if (!state.specialty) errors.specialty = 'Specialty is required';
  if (!validateAddress(state)) errors.address1 = 'Address is required';
  if (!state.userId) errors.userId = 'User is required';
  if (state.email && !isValidEmail(state.email))
    errors.email = 'Email address not valid';
  if (!state.phone) errors.phone = 'Phone number is required';
  if (state.phone && !isPossiblePhoneNumber(state.phone)) {
    errors.phone = 'Invalid phone number';
  }
  return errors;
};

export function AddOrganizationPage() {
  return (
    <RootLayout allowOverflow>
      <AddOrganization />
    </RootLayout>
  );
}

export function AddOrganization() {
  const navigate = useNavigate();
  const { users, me } = useGlobalState();
  const queryClient = useQueryClient();
  const [showDuplicateOrgAlert, setShowDuplicateOrgAlert] = useState(false);
  const [duplicateOrg, setDuplicateOrg] = useState<APIOrganization | null>(
    null,
  );

  const [state, dispatch]: [AddOrganizationState, React.Dispatch<IAction>] =
    useReducer(commonReducer, initialState);
  const [{ errors }, errorsDispatch]: [any, React.Dispatch<IAction>] =
    useReducer(commonReducer, { errors: {} });

  const { mutate: submitForm, isPending } = useCustomMutation({
    onError: (error: any) => {
      if (error?.response?.status === 422) {
        const message = error?.response?.data?.message;
        const parsedMessage: APIOrganization | null = message
          ? JSON.parse(message)?.object
          : null;
        setDuplicateOrg(parsedMessage);
        setShowDuplicateOrgAlert(true);
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['/organizations'],
        type: 'all',
      });
      navigate('/organizations');
    },
    onSuccessMessage: 'Organization added successfully!',
  });

  const handleChange = (path: keyof APIOrganization) => (value: any) => {
    if (errors[path]) {
      errorsDispatch({ type: 'remove', path: `errors.${path}` });
    }

    dispatch({ path, value });
  };

  const handleDropdownChange =
    (path: string) =>
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption<any> | undefined,
    ) => {
      if (errors[path]) {
        errorsDispatch({ type: 'remove', path: `errors[${path}]` });
      }

      dispatch({ path, value: option?.key });
    };

  const handleAddressChange = (address: IAddress) => {
    if (errors.address1) {
      errorsDispatch({ type: 'remove', path: 'errors.address1' });
    }

    dispatch({ type: 'set', value: address });
  };

  const handleSubmit = async () => {
    const errors = validate(state);
    if (Object.values(errors).length) {
      errorsDispatch({ path: 'errors', value: errors });
      return;
    }

    const apiBody = { ...state };
    apiBody.feePercentage = apiBody?.feePercentage
      ? apiBody?.feePercentage / 100
      : null;

    // convert ISO 8601 format to MySQL format
    if (state.dateSigned) {
      const date = new Date(state.dateSigned);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
      const day = String(date.getDate()).padStart(2, '0');

      const datetime = `${year}-${month}-${day} 00:00:00`;
      apiBody.dateSigned = datetime;
    }
    if (apiBody.dateSigned === null) {
      apiBody.dateSigned = '0000-00-00 00:00:00';
    }

    await submitForm({ url: '/organizations', body: apiBody });
  };

  useEffect(() => {
    if (!state.userId && me) {
      dispatch({
        type: 'set',
        value: {
          userId: me.userId,
        },
      });
    }
  }, [state.userId, me]);

  return (
    <>
      <div className='flex size-full flex-col items-center justify-start p-4'>
        <Container className='h-fit w-full min-w-[1200px] overflow-auto rounded-md border border-neutral-40 bg-white p-4 shadow-md'>
          <h1 className='my-8 text-2xl font-bold'>Add Organization</h1>
          <div className='flex gap-4'>
            <div className='flex w-full flex-col gap-4 rounded-md border border-neutral-40 bg-white p-4 shadow-md'>
              <TextInput
                required
                label='Organization'
                handleChange={handleChange('organizationName')}
                errorMessage={errors.organizationName}
              />
              <AddressInput
                required
                checkRegion
                handleChange={handleAddressChange}
                selectedAddress={{
                  address1: state.address1,
                  city: state.city,
                  state: state.state,
                  postalCode: state.postalCode,
                }}
                errorMessage={errors.address1}
              />
              <TextInput
                required
                label='Specialty'
                handleChange={handleChange('specialty')}
                errorMessage={errors.specialty}
              />

              <PhoneInput
                required
                value={state.phone}
                onChange={handleChange('phone')}
                errorMessage={errors.phone}
                id='add-organization-phone'
              />
              <TextInput
                label='Organization Website URL'
                value={state.url}
                handleChange={handleChange('url')}
                errorMessage={errors.url}
              />
              <TextInput
                label='Career Page URL'
                value={state.careerPageURL}
                handleChange={handleChange('careerPageURL')}
                errorMessage={errors.careerPageURL}
              />
              <TextInput
                label='Beds'
                type='number'
                value={state.numberOfBeds}
                handleChange={handleChange('numberOfBeds')}
                errorMessage={errors.numberOfBeds}
              />
              <Dropdown
                required
                color='black'
                label='User Assigned'
                selectedKey={state.userId ?? ''}
                options={
                  (users ?? []).map((u) => ({
                    key: u.userId,
                    text: u.fullName,
                  })) as any
                }
                onChange={handleDropdownChange('userId')}
                errorMessage={errors.userId}
              />
              <TextInput
                label='Annual Revenue'
                type='number'
                value={state.revenue}
                handleChange={handleChange('revenue')}
                errorMessage={errors.revenue}
              />
            </div>
            <ContingencyAgreementDetails
              values={{
                dateSigned: state.dateSigned,
                paymentTerms: state.paymentTerms,
                lateFee: state.lateFee,
                feePercentage: state.feePercentage,
                guarantee: state.guarantee,
              }}
              onChangeHandler={handleChange}
              errors={errors}
            />
          </div>

          <div className='my-8 flex items-center justify-between'>
            <Button onClick={() => navigate(-1)} className='secondary'>
              Back
            </Button>
            <Button
              onClick={handleSubmit}
              isLoading={isPending}
              disabled={isPending}
            >
              Save
            </Button>
          </div>
        </Container>
      </div>

      <AlertDialog
        open={showDuplicateOrgAlert}
        onOpenChange={() => setShowDuplicateOrgAlert(false)}
      >
        <AlertDialogContent className='bg-white'>
          <AlertDialogHeader>
            <AlertDialogTitle>Duplicate Organization</AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogDescription>
            <div className='flex flex-col gap-4'>
              <p>
                {/* TODO: API response only returns with "websight" even if phone # */}
                Organization with that phone number or website already exists.
              </p>
              <div className='flex flex-col gap-2 rounded-md bg-neutral-40 p-2'>
                <p className='font-bold'>{duplicateOrg?.organizationName}</p>
              </div>
            </div>
          </AlertDialogDescription>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                setShowDuplicateOrgAlert(false);
              }}
            >
              Close
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                setShowDuplicateOrgAlert(false);
                window.open(
                  `/organizations/${duplicateOrg?.organizationId}`,
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
            >
              View Organization
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
