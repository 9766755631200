import { RefetchOptions } from '@tanstack/react-query';
import { createContext, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCustomQuery } from '../hooks/useCustomQuery';
import { APIUser } from '../types/users';

export interface IGlobalContext {
  findUserById: (userId: string | undefined | null) => APIUser | undefined;
  users: APIUser[];
  inActiveUsers: APIUser[];
  me: APIUser | undefined;
  refetchUsers: (options?: RefetchOptions) => void;
  isAdmin: boolean;
}

export const GlobalContext = createContext<IGlobalContext | undefined>(
  undefined,
);

export function GlobalStateProvider({ children }: any) {
  const navigate = useNavigate();
  const { data, isLoading, isError, refetch } = useCustomQuery<APIUser[]>({
    url: '/users',
    params: { includeInactive: true },
    enabled: !!localStorage.getItem('user'),
  });

  const users = data?.data?.filter((u) => u.active) ?? [];
  const inActiveUsers = data?.data?.filter((u) => !u.active) ?? [];

  useEffect(() => {
    const user = localStorage.getItem('user');
    const parsedUser = user ? JSON.parse(user) : {};

    if (!parsedUser.userId && !isLoading && !isError) {
      navigate('/login');
    }
  }, [navigate, isLoading, isError]);

  const findUserById = (userId: string | undefined | null) => {
    if (!userId) return undefined;

    return [...users, ...inActiveUsers]?.find(
      (user) => user?.userId === userId,
    );
  };

  const refetchUsers = useCallback(async () => {
    const currentUser = localStorage.getItem('user');
    const parsedUser = currentUser ? JSON.parse(currentUser) : {};

    if (!parsedUser.userId) {
      return navigate('/login');
    }

    const response = await refetch();

    const updatedUser = response.data?.data.find(
      (u) => u.userId === parsedUser.userId,
    );

    localStorage.setItem('user', JSON.stringify(updatedUser));
  }, [refetch, navigate]);

  const storedUser = localStorage.getItem('user')
    ? (JSON.parse(localStorage.getItem('user') || '') as APIUser)
    : undefined;

  const globalState = {
    refetchUsers,
    users: users?.filter((u) => !u.api) ?? [],
    inActiveUsers: inActiveUsers?.filter((u) => !u.api) ?? [],
    me: storedUser,
    findUserById,
    isAdmin: !!storedUser?.admin,
  };

  return (
    <GlobalContext.Provider value={globalState}>
      {children}
    </GlobalContext.Provider>
  );
}

export function useGlobalState() {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }

  return context;
}
