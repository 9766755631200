import { useMemo } from 'react';

import { CommonSpinner, EmptyState } from '../../../components/common';
import { Button } from '../../../components/common/Button';
import {
  ContentContainer,
  ContentHeader,
  ContentInnerContainer,
} from '../../../components/common/Content';
import DatePickerRange from '../../../components/common/DatePickerRange';
import { HotSheetsTable } from '../../../components/common/HotSheetsTable';
import { SearchIcon } from '../../../components/common/Icons';
import { MultiSelectDropdownFilter } from '../../../components/common/MultiSelectDropdownFilter';
import { Pagination } from '../../../components/common/Pagination';
import OrganizationInput from '../../../components/filters/OrganizationInput';
import { useGlobalState } from '../../../context/GlobalContext';
import { useCustomQuery } from '../../../hooks/useCustomQuery';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { APIHotSheet } from '../../../types/api';
import { formatCurrency } from '../../../utils/helpers';

export const pipelineFilterOptions = [
  { value: '1', text: 'Inbox' },
  { value: '2', text: 'Screening' },
  { value: '3', text: 'Responded' },
  { value: '4', text: 'Presented' },
  { value: '5', text: 'Phone/Virtual Interview' },
  { value: '6', text: 'In-Person Interview' },
  { value: '7', text: 'Offer Pending' },
  { value: '8', text: 'Placed' },
];

function getApiQueryParams(queryParams: any) {
  const apiParams = { ...queryParams };

  if (!apiParams.pipelinePositionOrder) {
    apiParams.pipelinePositionOrder = 0;
  }

  if (!apiParams.maxPipelinePositionOrder) {
    apiParams.maxPipelinePositionOrder = 8;
  }

  if (!apiParams.orderBy) {
    apiParams.orderBy = 'pipelinePositionOrder';
    apiParams.orderByDirection = 'ASC';
  }

  return apiParams;
}

function HotSheets() {
  const {
    queryParams,
    setQueryParams,
    queryParamsSize,
    updateQueryParam,
    removeQueryParam,
  } = useQueryParams();

  const { users, me } = useGlobalState();
  const {
    data: response,
    isLoading,
    refetch,
  } = useCustomQuery<APIHotSheet>({
    url: '/admin/reports/hotsheet',
    params: getApiQueryParams(queryParams),
    options: { placeholderData: (prevData) => prevData },
  });

  const hotSheetsData = response?.data.hotSheetReportData || [];
  const { potentialCommissionTop, potentialCommissionBottom } =
    response?.data || {};

  const upToFilterOptions = useMemo(() => {
    const minPipelineStatus = queryParams?.pipelinePositionOrder;
    return pipelineFilterOptions.map((o) => {
      return {
        id: o.value,
        title: o.text,
        disabled: minPipelineStatus ? o.value < minPipelineStatus : false,
      };
    });
  }, [queryParams?.pipelinePositionOrder]);

  return (
    <>
      <ContentContainer>
        <ContentHeader title='Hot Sheet' subLabel='' />
        <ContentInnerContainer>
          <div className='flex h-fit items-start justify-between'>
            <div className='flex flex-wrap items-center gap-4'>
              <MultiSelectDropdownFilter
                classNames={{ container: 'w-48' }}
                items={users
                  .sort((a, b) =>
                    a.userId === me?.userId
                      ? -1
                      : b.userId === me?.userId
                        ? 1
                        : 0,
                  )
                  .map((s) => ({
                    id: s.userId as string,
                    title: s.active
                      ? (s.fullName as string)
                      : `${s.fullName} (Inactive)`,
                  }))}
                handleChange={(userId) => {
                  updateQueryParam('userId', userId, {
                    multiple: false,
                    resetPageOnChange: true,
                  });
                }}
                onClear={() => {
                  removeQueryParam('userId');
                }}
                isClearable={!!queryParams?.userId}
                value={[queryParams?.userId as string]}
                isChecked={!!queryParams?.userId}
                placeholder='User assigned'
              />
              <div className='flex h-8 items-center justify-center gap-2 rounded-md border border-neutral-40 bg-neutral-20 p-1'>
                <MultiSelectDropdownFilter
                  classNames={{
                    container: 'w-44',
                    trigger: 'h-6 bg-white text-black',
                  }}
                  items={pipelineFilterOptions.map((o) => {
                    return { id: o.value, title: o.text };
                  })}
                  handleChange={(pipelinePositionOrder) => {
                    updateQueryParam(
                      'pipelinePositionOrder',
                      pipelinePositionOrder,
                      {
                        multiple: false,
                        resetPageOnChange: true,
                        extraParams:
                          queryParams.maxPipelinePositionOrder &&
                          queryParams.maxPipelinePositionOrder <=
                            pipelinePositionOrder
                            ? { maxPipelinePositionOrder: undefined }
                            : {},
                      },
                    );
                  }}
                  onClear={() => {
                    removeQueryParam('pipelinePositionOrder');
                  }}
                  value={[queryParams?.pipelinePositionOrder as string]}
                  isClearable={!!queryParams?.pipelinePositionOrder}
                  isChecked={!!queryParams?.pipelinePositionOrder}
                  placeholder='Min pipeline status'
                />
                <div>up to</div>
                <MultiSelectDropdownFilter
                  classNames={{
                    container: 'w-44',
                    trigger: 'h-6 bg-white text-black',
                  }}
                  items={upToFilterOptions}
                  handleChange={(maxPipelinePositionOrder) => {
                    updateQueryParam(
                      'maxPipelinePositionOrder',
                      maxPipelinePositionOrder,
                      {
                        multiple: false,
                        resetPageOnChange: true,
                      },
                    );
                  }}
                  onClear={() => {
                    removeQueryParam('maxPipelinePositionOrder');
                  }}
                  value={[queryParams?.maxPipelinePositionOrder as string]}
                  isClearable={!!queryParams?.maxPipelinePositionOrder}
                  isChecked={!!queryParams?.maxPipelinePositionOrder}
                  placeholder='Max pipeline status'
                />
              </div>
              <div className='flex flex-wrap items-center justify-start gap-2 overflow-y-hidden sm:justify-end sm:gap-4'>
                <DatePickerRange
                  startParam='startDate'
                  endParam='endDate'
                  tooltipText={'Pick a range.'}
                  singleDay
                  formatDate={false}
                />
              </div>
              <div className='flex flex-wrap items-center justify-start gap-2 overflow-y-hidden sm:justify-end sm:gap-4'>
                <OrganizationInput />
              </div>
              {!!potentialCommissionTop && !!potentialCommissionBottom && (
                <p className='text-lg'>
                  <span className='font-semibold'>Est Fee Total: </span>
                  <span>{formatCurrency(potentialCommissionBottom)}</span>
                  <span> - </span>
                  <span>{formatCurrency(potentialCommissionTop)}</span>
                </p>
              )}
            </div>
            <div className='flex items-center gap-2'>
              {queryParamsSize ? (
                <Button
                  variant='ghost'
                  className='text-sm'
                  onClick={() => {
                    setQueryParams({});
                  }}
                >
                  Remove filters
                </Button>
              ) : null}
            </div>
          </div>
          {isLoading ? (
            <div className='flex size-full items-center justify-center'>
              <CommonSpinner size='md' />
            </div>
          ) : (
            <>
              {hotSheetsData.length > 0 ? (
                <>
                  <HotSheetsTable data={hotSheetsData} refetch={refetch} />
                </>
              ) : (
                <EmptyState
                  noWrapper
                  icon={SearchIcon}
                  title='No results found'
                  link={
                    <div className='flex'>
                      <span style={{ maxWidth: 300, textAlign: 'center' }}>
                        No results matched your search criteria. Try searching
                        for something else.
                      </span>
                    </div>
                  }
                />
              )}
            </>
          )}
          {!!response?.pages && response.pages > 1 && (
            <Pagination pages={response.pages} />
          )}
        </ContentInnerContainer>
      </ContentContainer>
    </>
  );
}

export default HotSheets;
