import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../components/ui/alert-dialog';

interface BulkUpdateJobModalProps {
  isBulkActionModalOpen: boolean;
  setIsBulkActionModalOpen: any;
  handleConfirmAction: () => void;
}

const BulkUpdateJobModal = ({
  isBulkActionModalOpen,
  setIsBulkActionModalOpen,
  handleConfirmAction,
}: BulkUpdateJobModalProps) => {
  return (
    <>
      <AlertDialog
        open={!!isBulkActionModalOpen}
        onOpenChange={() => setIsBulkActionModalOpen(null)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action will <strong>{isBulkActionModalOpen}</strong> Indeed
              sponsorship for the selected items.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setIsBulkActionModalOpen(null)}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmAction}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default BulkUpdateJobModal;
