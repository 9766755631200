import { ResponsiveBar } from '@nivo/bar';
import { useMemo } from 'react';

import { useCustomQuery } from '../../../hooks/useCustomQuery';

type ApiDataItem = {
  state: string;
  region: string;
  totalJobs: number;
  indeedSponsoredCount: number;
};

const colorShades = [
  '#3F007D',
  '#662A9B',
  '#8046AF',
  '#8D54B9',
  '#9A62C3',
  '#A770CD',
  '#B47ED7',
  '#C18CE1',
  '#CE9AEB',
  '#DBA8F5',
  '#E8B6FF',
  '#F0C4FF',
  '#F8D2FF',
  '#FFF0FF',
];

function AdsByRegionsAndStates({ isDisabled }: { isDisabled: boolean }) {
  const { data: response, isLoading } = useCustomQuery<ApiDataItem[]>({
    url: '/admin/reports/adsbystate',
  });
  const { data } = response ?? {};

  const { transformedData, sortedStates, colorAssignments } = useMemo(() => {
    return (
      transformApiData(data) ?? {
        transformedData: null,
        sortedStates: [],
        colorAssignments: [],
      }
    );
  }, [data]);

  const maxValue = useMemo(() => {
    return transformedData
      ? Math.max(...transformedData.flatMap((d) => d.total))
      : 0;
  }, [transformedData]);

  const roundedMaxValue = Math.ceil(maxValue / 5) * 5;

  return (
    <div className='flex h-[16rem] w-full flex-col justify-between gap-4 rounded-lg border border-neutral-40 bg-white p-4'>
      <div className='flex items-center'>
        <div
          className={`truncate text-lg font-bold ${isDisabled && 'text-gray-500'}`}
        >
          Requisitions by Regions and States
        </div>
      </div>
      {isLoading && (
        <div className='size-full animate-pulse rounded-md bg-gray-400' />
      )}
      {!isLoading && (
        <>
          {isDisabled && (
            <div className='flex size-full items-center justify-center text-lg font-medium text-gray-500'>
              Not available for selected time range
            </div>
          )}
          {!isDisabled && transformedData && (
            <div className='size-full'>
              <ResponsiveBar
                data={transformedData}
                keys={sortedStates}
                indexBy='region'
                valueScale={{ type: 'linear', min: 0, max: roundedMaxValue }}
                margin={{ top: 10, right: 10, bottom: 24, left: 60 }}
                padding={0.5}
                layout='horizontal'
                axisTop={null}
                axisRight={null}
                enableLabel={false}
                colors={({ id }) => {
                  return colorAssignments[id as string];
                }}
                axisBottom={{
                  tickSize: 0,
                  tickPadding: 0,
                  tickRotation: 0,
                }}
                axisLeft={{
                  tickSize: 0,
                  tickPadding: 10,
                  tickRotation: 0,
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AdsByRegionsAndStates;

type TransformedDataItem = {
  region: string;
  [state: string]: number | string;
  total: number;
};

type ColorAssignment = { [state: string]: string };

function transformApiData(apiData?: ApiDataItem[]): {
  transformedData: TransformedDataItem[];
  sortedStates: string[];
  colorAssignments: ColorAssignment;
} | null {
  if (!apiData) return null;

  const stateData: { [state: string]: number } = {};
  const transformedData: { [key: string]: TransformedDataItem } = {};
  const colorAssignments: ColorAssignment = {};

  apiData.forEach((item) => {
    const { region, state, totalJobs } = item;

    if (!transformedData[region]) {
      transformedData[region] = { region, total: 0 };
    }

    transformedData[region][state] = totalJobs;
    transformedData[region].total += totalJobs;

    stateData[state] = totalJobs;
  });

  Object.values(transformedData).forEach((regionData) => {
    const stateEntries = Object.entries(regionData)
      .filter(([key]) => key !== 'region' && key !== 'total')
      .sort((a, b) => (b[1] as number) - (a[1] as number));

    stateEntries.forEach(([state], idx) => {
      colorAssignments[state] = colorShades[idx];
    });
  });

  const sortedStates = Object.entries(stateData)
    .sort((a, b) => b[1] - a[1])
    .map(([state]) => state);

  return {
    transformedData: Object.values(transformedData).sort(
      (a, b) => b.total - a.total,
    ),
    sortedStates,
    colorAssignments,
  };
}
