import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function useRouteChange() {
  const location = useLocation();
  const prevLocationRef = useRef(location);

  useEffect(() => {
    prevLocationRef.current = location;
  }, [location]);

  const hasRouteChanged =
    prevLocationRef.current.pathname !== location.pathname;

  return { hasRouteChanged };
}
