import RootLayout from '../../layouts';
import { Contacts } from './Contacts';

const ContactsPage = () => {
  return (
    <RootLayout>
      <Contacts />
    </RootLayout>
  );
};

export default ContactsPage;
