import { E164Number } from 'libphonenumber-js/types';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import {
  IAction,
  TextInput as TextInputDeprecated,
  commonReducer,
} from '../../components/common';
import AddressInput from '../../components/common/AddressInput';
import { Button } from '../../components/common/Button';
import OrganizationSelect from '../../components/common/OrganizationSelect';
import PhoneInput from '../../components/common/PhoneInput';
import TextInput from '../../components/common/TextInput';
import { Label } from '../../components/ui/label';
import { Textarea } from '../../components/ui/textarea';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { IAddress } from '../../react-app-env';
import { APIContact } from '../../types/contacts';
import { isValidEmail } from '../../utils/helpers';
import { StructuredTitleDropdown } from './StructuredTitleDropdown';

export function ContactDetails({
  contact,
  isUsedAsComponent,
  onContactSave,
  onCancel,
}: {
  contact: APIContact;
  isUsedAsComponent?: boolean;
  onContactSave?: (state?: APIContact) => void;
  onCancel?: () => void;
}) {
  const [isEditing, setIsEditing] = useState(isUsedAsComponent);

  const [state, dispatch]: [APIContact, React.Dispatch<IAction>] = useReducer(
    commonReducer,
    {},
  );

  useEffect(() => {
    if (contact) {
      dispatch({
        type: 'reset',
        value: { ...contact, titleId: contact?.titleId || 'Other' },
      });
    }
  }, [contact]);

  const [{ errors }, setErrors] = useReducer(commonReducer, {
    errors: {},
  });

  const { mutate: saveContact, isPending } = useCustomMutation({
    method: 'put',
    onSuccess: () => {
      setIsEditing(false);
      onContactSave?.(state);
    },
    onError: () => {
      onContactSave?.(state);
    },
    onSuccessMessage: 'Contact saved successfully!',
  });

  const resetContact = () => {
    dispatch({ type: 'reset', value: contact });
    setErrors({ type: 'reset', value: { errors: {} } });
  };

  const handleContactSave = () => {
    const errors = validateContactDetails(state, isUsedAsComponent || false);

    if (Object.values(errors).length) {
      setErrors({ path: 'errors', value: errors });
      return;
    }

    saveContact({
      url: '/contacts/' + state.contactId,
      body: {
        ...state,
        titleId: state.titleId === 'Other' ? null : state.titleId,
      },
    });
  };

  const handleChange = useCallback(
    (path: keyof APIContact) => (value: any) => {
      if (errors[path]) {
        setErrors({ type: 'remove', path: `errors.${path}` });
      }
      dispatch({ path, value });
    },
    [errors],
  );

  const handleAddressChange = useCallback((address: IAddress) => {
    dispatch({ type: 'set', value: address });
  }, []);

  const handlePhoneChange = useCallback(
    (value: E164Number | undefined, path: string) => {
      if (errors.phone || errors.workPhone) {
        setErrors({ type: 'remove', path: `errors.${path}` });
      }

      dispatch({ path, value });
    },
    [errors.phone, errors.workPhone],
  );

  const handleSelectChange = useCallback(
    (path: string) => (newValue: any) => {
      if (errors[path]) setErrors({ path: `errors[${path}]`, type: 'remove' });

      dispatch({ type: 'set', value: newValue?.update ?? {} });
    },
    [errors],
  );

  return (
    <div>
      <div
        className={`flex flex-col gap-3 rounded-md border p-4 ${isUsedAsComponent && 'border-none'} ${isEditing && !isUsedAsComponent ? 'border-primary' : 'border-neutral-40'}`}
      >
        {!isUsedAsComponent && (
          <div className='flex items-center justify-between'>
            <div className='text-lg font-bold'>Contact Details</div>
            {isEditing ? (
              <div className='flex gap-2'>
                <Button
                  variant='secondary'
                  onClick={() => {
                    resetContact();
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleContactSave}
                  isLoading={isPending}
                  disabled={isPending}
                >
                  Save
                </Button>
              </div>
            ) : (
              <Button variant='ghost' onClick={() => setIsEditing(true)}>
                <span className='blue underline'>Edit</span>
              </Button>
            )}
          </div>
        )}
        <TextInputDeprecated
          disabled={!isEditing}
          label='First Name'
          required={isEditing}
          handleChange={handleChange('firstName')}
          value={state.firstName ?? ''}
          errorMessage={errors.firstName}
        />
        <TextInputDeprecated
          disabled={!isEditing}
          label='Last Name'
          required={isEditing}
          handleChange={handleChange('lastName')}
          value={state.lastName ?? ''}
          errorMessage={errors.lastName}
        />
        <StructuredTitleDropdown
          label='Job Title'
          disabled={!isEditing}
          required
          errorMessage={errors?.title}
          value={state?.titleId || ''}
          handleChange={(title, titleId) => {
            handleChange('titleId')(titleId);
            handleChange('title')(titleId === 'Other' ? null : title);
          }}
        />
        {((!!state?.title && !state?.titleId) ||
          state?.titleId === 'Other') && (
          <TextInputDeprecated
            disabled={!isEditing || (isEditing && state?.titleId !== 'Other')}
            label=''
            placeholder='Enter other job title...'
            handleChange={handleChange('title')}
            value={state?.title ?? ''}
            errorMessage={
              errors.title &&
              'If "Other" is selected above, a job title must be entered here'
            }
          />
        )}

        <div>
          <Label className='text-1xl font-bold'>Resume Summary</Label>
          <Textarea
            disabled={!isEditing}
            placeholder='Resume Summary...'
            value={state.resumeSummary ?? ''}
            className='placeholder:text-neutral'
            onChange={(e) => handleChange('resumeSummary')(e.target.value)}
          />
        </div>
        <div>
          <Label className='text-1xl font-bold'>Application Summary</Label>
          <Textarea
            disabled={!isEditing}
            placeholder='Application Summary...'
            value={state.applicationSummary ?? ''}
            className='placeholder:text-neutral'
            onChange={(e) => handleChange('applicationSummary')(e.target.value)}
          />
        </div>

        <TextInputDeprecated
          disabled={!isEditing}
          label='School'
          handleChange={handleChange('school')}
          value={state.school ?? ''}
        />
        <TextInputDeprecated
          disabled={!isEditing}
          label='Degree Type'
          handleChange={handleChange('degree')}
          value={state.degree ?? ''}
        />
        <div className='flex items-start gap-2'>
          <div className='flex-1'>
            <PhoneInput
              disabled={!isEditing}
              onChange={(v) => handlePhoneChange(v, 'phone')}
              required={isEditing && !state.emailAddress}
              value={state.phone ?? undefined}
              errorMessage={errors.phone}
              id='contact-phone'
            />
          </div>
          <div className='flex-1'>
            <TextInput
              disabled={!isEditing}
              prefix='#'
              id='contact-phone-ext'
              label='Extension'
              value={state?.phoneExt || ''}
              onChange={(e) => {
                const value = e.target.value;

                if (/^\d{0,6}$/.test(value)) {
                  handleChange('phoneExt')(value);
                }
              }}
              errorMessage={errors.phoneExt}
            />
          </div>
        </div>
        <div className='flex items-start gap-2'>
          <div className='flex-1'>
            <PhoneInput
              disabled={!isEditing}
              onChange={(v) => handlePhoneChange(v, 'workPhone')}
              value={state.workPhone ?? undefined}
              errorMessage={errors.workPhone}
              id='contact-phone'
              label='Work Phone'
            />
          </div>
          <div className='flex-1'>
            <TextInput
              disabled={!isEditing}
              prefix='#'
              id='contact-work-ext'
              label='Extension'
              value={state?.workExt || ''}
              onChange={(e) => {
                const value = e.target.value;

                if (/^\d{0,6}$/.test(value)) {
                  handleChange('workExt')(value);
                }
              }}
              errorMessage={errors.workExt}
            />
          </div>
        </div>
        <TextInputDeprecated
          disabled={!isEditing}
          handleChange={handleChange('emailAddress')}
          label='Email Address'
          required={isEditing && (!state.phone || isUsedAsComponent)}
          value={state.emailAddress ?? ''}
          errorMessage={errors?.emailAddress}
        />
        <AddressInput
          selectedAddress={{
            address1: state.address1 ?? '',
            city: state.city ?? '',
            state: state.state ?? '',
            postalCode: state.postalCode ?? '',
          }}
          disabled={!isEditing}
          handleChange={handleAddressChange}
        />
        <OrganizationSelect
          disabled={isUsedAsComponent || !isEditing}
          value={
            state.organizationId
              ? {
                  label: state.organizationName,
                  id: state.organizationId,
                  contract: !!state.orgHasContract,
                }
              : undefined
          }
          onChange={(value) => {
            handleSelectChange('organizationId')({
              update: {
                organizationName: value.label,
                organizationId: value.id,
                orgHasContract: value.contract,
              },
            });
          }}
          onClear={() => dispatch({ type: 'remove', path: 'organizationId' })}
        />
        {isUsedAsComponent && (
          <div className='mt-16 flex justify-between'>
            <Button
              onClick={() => {
                resetContact();
                onCancel && onCancel();
              }}
              variant='secondary'
            >
              Back
            </Button>
            <Button
              onClick={handleContactSave}
              isLoading={isPending}
              disabled={isPending}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function validateContactDetails(
  contact: APIContact,
  isUsedAsComponent: boolean,
) {
  const errors: Partial<Record<keyof APIContact, string>> = {};
  if (!contact.firstName) errors.firstName = 'First name is required';
  if (!contact.lastName) errors.lastName = 'Last name is required';

  if (!contact.phone && !contact.emailAddress && !isUsedAsComponent) {
    errors.emailAddress = 'Email OR phone number is required';
    errors.phone = 'Email OR phone number is required';
  }

  if (isUsedAsComponent) {
    if (!contact.emailAddress) errors.emailAddress = 'Email is required';
    if (!contact.phone) errors.phone = 'Phone number is required';
  }

  if (contact.emailAddress && !isValidEmail(contact.emailAddress))
    errors.emailAddress = 'Email address not valid';

  if (contact.phone && !isPossiblePhoneNumber(contact.phone))
    errors.phone = 'Not a valid phone number';

  return errors;
}
