import { useState } from 'react';

import { EmptyState, CommonSpinner } from '../../../components/common';
import { Button } from '../../../components/common/Button';
import {
  ContentHeader,
  ContentContainer,
  ContentInnerContainer,
} from '../../../components/common/Content';
import { SearchIcon, WarningIcon } from '../../../components/common/Icons';
import { Pagination } from '../../../components/common/Pagination';
import SearchInput from '../../../components/filters/SearchInput';
import { useGlobalState } from '../../../context/GlobalContext';
import { useCustomQuery } from '../../../hooks/useCustomQuery';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { APIRequisition } from '../../../types/requisitions';
import { UsersTable } from './UsersTable';

export type UsersPageState = {
  selectedRow?: APIRequisition;
  isFiltersOpen: boolean;
  search: string;
};

export function Users() {
  const { me } = useGlobalState();
  const { queryParams, setQueryParams, queryParamsSize } = useQueryParams();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
  const user = localStorage.getItem('user');
  const userParsed = JSON.parse(user as string);
  const showEarningsCells =
    !isProduction ||
    (isProduction &&
      (userParsed?.emailAddress === 'katie@mlr.org' ||
        userParsed?.emailAddress === 'andrew@mlee.com' ||
        userParsed?.emailAddress === 'melissa@mlr.org' ||
        userParsed?.emailAddress === 'amc@mlee.org' ||
        userParsed?.emailAddress === 'lauren@mlr.org'));

  const {
    data: response,
    isError,
    error,
    isLoading,
  } = useCustomQuery({
    url: '/admin/users',
    params: queryParams,
    options: { staleTime: 0 },
  });

  const { data, pages } = response ?? {};
  const isAdminOnlyChecked = queryParams.adminOnly === '1';
  const isInactiveAdminChecked = queryParams.includeInactive === '1';

  usePageTitle('Admin');

  const clearSelected = () => {
    setQueryParams({});
  };

  function handleQuickTabChange(flag: string) {
    const newParams = { ...queryParams };

    if (flag === 'activeOnly') {
      if (isAdminOnlyChecked) {
        delete newParams.adminOnly;
      } else {
        newParams.adminOnly = '1';
      }
    } else {
      if (isInactiveAdminChecked) {
        delete newParams.includeInactive;
      } else {
        newParams.includeInactive = '1';
      }
    }
    setQueryParams(newParams);
  }

  if (error) {
    return (
      <EmptyState
        error={isError}
        icon={WarningIcon}
        title={error.message ?? 'Error'}
        link={
          <button onClick={() => window.location.reload()} className='blue'>
            Refresh Page
          </button>
        }
      />
    );
  }

  return (
    <>
      <ContentContainer>
        <ContentHeader
          title='Admin'
          subLabel={me?.emailAddress}
          link={{ to: '/admin/users/add', label: 'Add User' }}
        />
        <ContentInnerContainer>
          <div className='flex h-fit justify-between'>
            <div className='flex items-center gap-4'>
              <SearchInput />
              <input
                type='checkbox'
                id='admin-only-checkbox'
                onChange={() => handleQuickTabChange('activeOnly')}
                className='size-0! invisible absolute'
              />
              <label
                htmlFor='admin-only-checkbox'
                className={`h-8 cursor-pointer rounded-md border border-neutral-40 px-2 py-1 text-sm font-medium shadow-sm ${isAdminOnlyChecked ? 'bg-secondary-80 text-white' : 'bg-white text-neutral-60'}`}
                role='checkbox'
                aria-checked={isAdminOnlyChecked || false}
                tabIndex={0}
              >
                Admin only
              </label>
              <input
                type='checkbox'
                id='inactive-checkbox'
                onChange={() => handleQuickTabChange('inactive')}
                className='size-0! invisible absolute'
              />
              <label
                htmlFor='inactive-checkbox'
                className={`h-8 cursor-pointer rounded-md border border-neutral-40 px-2 py-1 text-sm font-medium shadow-sm ${isInactiveAdminChecked ? 'bg-secondary-80 text-white' : 'bg-white text-neutral-60'}`}
                role='checkbox'
                aria-checked={isInactiveAdminChecked}
                tabIndex={0}
              >
                Include inactive
              </label>
              {showEarningsCells && !isEditing && (
                <Button
                  disabled={Object.keys(queryParams).length > 0}
                  onClick={() => setIsEditing(true)}
                >
                  Edit Billings
                </Button>
              )}
              {showEarningsCells && isEditing && (
                <div className='flex flex-row gap-2'>
                  <Button
                    onClick={() => {
                      setIsEditing(false);
                      setIsCancelling(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEditing(false);
                      setIsSaving(true);
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
            <div className='flex flex-row gap-2'>
              {queryParamsSize ? (
                <Button
                  variant='ghost'
                  className='text-sm'
                  onClick={clearSelected}
                >
                  Remove filters
                </Button>
              ) : null}
            </div>
          </div>
          {isLoading ? (
            <div className='flex size-full items-center justify-center'>
              <CommonSpinner size='md' />
            </div>
          ) : (
            <>
              {(data ?? []).length ? (
                <div className='w-full overflow-auto rounded border'>
                  <UsersTable
                    data={data}
                    hasFilters={Object.keys(queryParams).length > 0}
                    showEarningsCells={showEarningsCells}
                    isEditing={isEditing}
                    isSaving={isSaving}
                    setIsSaving={setIsSaving}
                    isCancelling={isCancelling}
                    setIsCancelling={setIsCancelling}
                  />
                </div>
              ) : (
                <EmptyState
                  noWrapper
                  icon={SearchIcon}
                  title='No results found'
                  link={
                    <div className='flex'>
                      <span style={{ maxWidth: 300, textAlign: 'center' }}>
                        No results matched your search criteria. Try searching
                        for something else.
                      </span>
                    </div>
                  }
                />
              )}
            </>
          )}
          <Pagination pages={pages} />
        </ContentInnerContainer>
      </ContentContainer>
    </>
  );
}
