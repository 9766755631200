import RootLayout from '../../../layouts';
import HotSheets from './HotSheets';

function HotSheetsPage() {
  return (
    <RootLayout>
      <HotSheets />
    </RootLayout>
  );
}

export default HotSheetsPage;
