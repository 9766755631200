import { useMemo } from 'react';

import { useQueryParams } from '../../hooks/useQueryParams';
import { states } from '../../utils/states';
import { MultiSelectDropdownFilter } from '../common/MultiSelectDropdownFilter';

interface StateInputProps {
  classNames?: {
    container?: string;
  };
}

function StateInput({ classNames }: StateInputProps) {
  const { queryParams, updateQueryParam, removeQueryParam } = useQueryParams();

  const stateItems = useMemo(() => {
    return states.arrayOfState().map((state) => ({
      id: state.key,
      title: state.text,
    }));
  }, []);

  const handleChange = useMemo(
    () => (value: string | string[]) => {
      updateQueryParam('state', value, {
        multiple: true,
        resetPageOnChange: true,
      });
    },
    [updateQueryParam],
  );

  const handleClear = useMemo(
    () => () => {
      removeQueryParam('state');
    },
    [removeQueryParam],
  );

  const isChecked = useMemo(() => !!queryParams.state, [queryParams.state]);

  return (
    <MultiSelectDropdownFilter
      classNames={{ container: classNames?.container || 'w-24' }}
      isMultiSelect
      items={stateItems}
      handleChange={handleChange}
      isClearable={!!queryParams.state}
      onClear={handleClear}
      value={(queryParams.state || []) as string[]}
      isChecked={isChecked}
      placeholder='States'
    />
  );
}

export default StateInput;
