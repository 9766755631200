import { useQueryParams } from '../../hooks/useQueryParams';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltips';
import DatePicker from './DatePicker';

function DatePickerRange({
  startParam,
  endParam,
  Label,
  tooltipText,
  singleDay,
  formatDate,
  onChange,
  onClear,
}: {
  startParam: string;
  endParam: string;
  Label?: any;
  tooltipText?: string;
  singleDay?: boolean;
  formatDate?: boolean;
  onChange?: () => void;
  onClear?: () => void;
}) {
  const { queryParams, updateQueryParam, removeQueryParam } = useQueryParams();
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;

  const startValue = queryParams[startParam]
    ? new Date(
        new Date(queryParams[startParam] as string).getTime() + timezoneOffset,
      )
    : undefined;

  const endValue = queryParams[endParam]
    ? new Date(
        new Date(queryParams[endParam] as string).getTime() + timezoneOffset,
      )
    : undefined;

  return (
    <div className='flex h-8 items-center justify-center gap-2 rounded-md border border-neutral-40 bg-neutral-20 p-1'>
      <Tooltip>
        <TooltipTrigger className='flex w-fit items-center'>
          {Label && <Label size={18} className='text-neutral-60' />}
        </TooltipTrigger>
        <TooltipContent>{tooltipText}</TooltipContent>
      </Tooltip>
      <DatePicker
        value={startValue}
        onChange={(date) => {
          if (date) {
            updateQueryParam(startParam, date.toISOString().split('T')[0], {
              multiple: false,
              formatDate: formatDate ?? true,
              resetPageOnChange: true,
              extraParams: {
                ...(!queryParams[endParam] &&
                  singleDay && {
                    // Set one day forward to give us a proper window if singleDay
                    [`${endParam}`]: new Date(date.setDate(date.getDate() + 1))
                      .toISOString()
                      .split('T')[0],
                  }),
              },
            });
          }

          if (onChange) {
            onChange();
          }
        }}
        onClear={() => {
          removeQueryParam(startParam);

          if (onClear && !queryParams[endParam]) {
            onClear();
          }
        }}
        hideLabel
        placeholder='Start date'
        classNames={{ trigger: 'h-6 w-32' }}
      />
      <DatePicker
        value={endValue}
        onChange={(date) => {
          if (date) {
            updateQueryParam(endParam, date.toISOString().split('T')[0], {
              multiple: false,
              formatDate: formatDate ?? true,
              resetPageOnChange: true,
            });
          }

          if (onChange) {
            onChange();
          }
        }}
        onClear={() => {
          removeQueryParam(endParam);

          if (onClear && !queryParams[startParam]) {
            onClear();
          }
        }}
        hideLabel
        placeholder='Date to'
        classNames={{ trigger: 'h-6 w-32' }}
      />
    </div>
  );
}

export default DatePickerRange;
