import { useToast } from '../../hooks/use-toast';
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from './toast';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        duration,
        ...props
      }) {
        const isSuccess = props.variant !== 'destructive';
        return (
          <Toast
            key={id}
            {...props}
            className={`border ${isSuccess && 'border-success'}`}
            duration={duration ?? 3000}
          >
            <div className='grid gap-1'>
              {title && <ToastTitle className='text-lg'>{title}</ToastTitle>}
              {description && (
                <ToastDescription className='text-base font-bold'>
                  {description}
                </ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
