import { useLocalStorage } from '@uidotdev/usehooks';
import { EllipsisIcon } from 'lucide-react';
import { useState } from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/common/Dropdown';
import {
  Table,
  Header,
  HeaderCell,
  Body,
  Row,
  RowCell,
} from '../../components/common/Table';
import { Toast } from '../../components/toast/Toast';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../components/ui/alert-dialog';
import { Checkbox } from '../../components/ui/checkbox';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/ui/popover';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import useInvalidateQuery from '../../hooks/useInvalidateQuery';
import { useQueryParams } from '../../hooks/useQueryParams';
import {
  APIPipeline,
  APIJobContactPipelinePosition,
} from '../../types/pipeline';
import {
  capitalizeName,
  formatDateString,
  formatDateToBackend,
} from '../../utils/helpers';
import { removeTabsParams } from '../../utils/tabs';
import { ContactView } from '../contacts/ContactView';

function OutOfProcess({
  pipelineAPIresponse,
  refetch,
}: {
  pipelineAPIresponse: APIPipeline | undefined;
  refetch: () => void;
}) {
  const { queryParams, setQueryParams } = useQueryParams();
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [
    onContinueJobContactPipelinePosition,
    setOnContinueJobContactPipelinePosition,
  ] = useState<null | APIJobContactPipelinePosition>(null);
  const [contactId, setContactId] = useState<string | null | undefined>(
    undefined,
  );
  const [hideRestoreToLastStepAlert, setHideRestoreToLastStepAlert] =
    useLocalStorage('hideOutOfProcessAlert', false);
  const invalidateQuery = useInvalidateQuery();
  const [isDontShowAgainButtonChecked, setIsDontShowAgainButtonChecked] =
    useState(false);

  const { mutateAsync: restoreUser } = useCustomMutation({
    method: 'patch',
    onError: () => {
      Toast.error('Failed to restore user(s) to their last step');
    },
    onSuccessMessage: 'User(s) restored to their last step',
  });

  async function handleRestoreToLastStep(
    jobContactPipelinePosition: APIJobContactPipelinePosition,
    bypassAlert = false,
  ) {
    if (!hideRestoreToLastStepAlert && !bypassAlert) {
      setIsAlertDialogOpen(true);
      setOnContinueJobContactPipelinePosition(jobContactPipelinePosition);
      return;
    }

    try {
      await restoreUser({
        url: `/jobcontactpipelinepositions/${jobContactPipelinePosition.jobContactPipelinePositionId}`,
        body: {
          ...jobContactPipelinePosition,
          outOfProcess: false,
          outOfProcessReason: null,
          updateDate: formatDateToBackend(new Date()),
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      refetch();
      setIsAlertDialogOpen(false);
      setOnContinueJobContactPipelinePosition(null);
    }
  }

  const outOfProcessUsers = (
    pipelineAPIresponse?.jobContactPipelinePositions ?? []
  ).filter((position) => !!position.outOfProcess);

  if (outOfProcessUsers.length === 0) {
    return (
      <div className='flex size-full items-start justify-center p-20 text-3xl'>
        No candidates found
      </div>
    );
  }

  return (
    <>
      <div className='flex flex-col gap-4 overflow-hidden p-4'>
        <div className='grid auto-rows-auto'></div>
        <div className='overflow-auto rounded-md border border-neutral-40'>
          <Table classNames={{ table: 'w-full h-auto overflow-auto' }}>
            <Header>
              <HeaderCell>Name</HeaderCell>
              <HeaderCell>Reason</HeaderCell>
              <HeaderCell>Last step</HeaderCell>
              <HeaderCell>Out of process date</HeaderCell>
              <HeaderCell>Notes</HeaderCell>
              <HeaderCell>User</HeaderCell>
              <HeaderCell>Actions</HeaderCell>
            </Header>
            <Body className='border-none'>
              {outOfProcessUsers.map((position) => {
                return (
                  <OutOfProcessRow
                    key={position.jobContactPipelinePositionId}
                    pipelineAPIresponse={pipelineAPIresponse}
                    jobContactPipelinePosition={position}
                    setContactId={setContactId}
                    onRestoreClick={handleRestoreToLastStep}
                  />
                );
              })}
            </Body>
          </Table>
        </div>
      </div>

      <AlertDialog
        open={isAlertDialogOpen}
        onOpenChange={() => setIsAlertDialogOpen(false)}
      >
        <AlertDialogContent className='bg-white'>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Are you sure you want to restore user?
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogDescription>
            This will restore the user(s) to their last step in the pipeline.
          </AlertDialogDescription>
          <div className='flex items-center gap-1'>
            <Checkbox
              checked={isDontShowAgainButtonChecked}
              onCheckedChange={() =>
                setIsDontShowAgainButtonChecked(!isDontShowAgainButtonChecked)
              }
              id='checkbox'
            />
            <div className='text-xs'>Don&apos;t show this again</div>
          </div>
          <AlertDialogFooter className='flex justify-between'>
            <div className='flex gap-4'>
              <AlertDialogCancel
                onClick={() => setOnContinueJobContactPipelinePosition(null)}
              >
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction
                onClick={() => {
                  if (isDontShowAgainButtonChecked) {
                    setHideRestoreToLastStepAlert(true);
                  }

                  if (onContinueJobContactPipelinePosition) {
                    handleRestoreToLastStep(
                      onContinueJobContactPipelinePosition,
                      true,
                    );
                  }
                }}
              >
                Continue
              </AlertDialogAction>
            </div>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <ContactView
        isOpen={!!contactId}
        contactId={contactId}
        onClose={() => {
          removeTabsParams(queryParams, setQueryParams);
          setContactId(null);
        }}
        onContactSave={() => {
          invalidateQuery([`/contacts/${contactId}`]);
          refetch();
        }}
      />
    </>
  );
}

export default OutOfProcess;

function OutOfProcessRow({
  pipelineAPIresponse,
  setContactId,
  jobContactPipelinePosition,
  onRestoreClick,
}: {
  pipelineAPIresponse: APIPipeline | undefined;
  setContactId: (value: string | null | undefined) => void;
  jobContactPipelinePosition: APIJobContactPipelinePosition;
  onRestoreClick: (
    jobContactPipelinePosition: APIJobContactPipelinePosition,
  ) => void;
}) {
  const { users } = useGlobalState();

  const contact = pipelineAPIresponse?.contacts.find(
    (c) => c.contactId === jobContactPipelinePosition.contactId,
  );
  const assignedUser = users?.find((u) => u.userId === contact?.userId);

  return (
    <Row
      className='border-none'
      key={jobContactPipelinePosition.jobContactPipelinePositionId}
    >
      <RowCell
        className='cursor-pointer border-l-0 font-medium text-link underline'
        onClick={() => setContactId(contact?.contactId)}
      >
        {capitalizeName(`${contact?.firstName} ${contact?.lastName}`)}
      </RowCell>
      <RowCell>{jobContactPipelinePosition.outOfProcessReason}</RowCell>
      <RowCell>{jobContactPipelinePosition.pipelinePosition}</RowCell>
      <RowCell>
        {formatDateString(jobContactPipelinePosition.updateDate) || '-'}
      </RowCell>
      <RowCell className='max-w-0 cursor-pointer truncate'>
        <Popover>
          <PopoverTrigger className='w-full truncate text-left'>
            {jobContactPipelinePosition.notes}
          </PopoverTrigger>
          <PopoverContent className='bg-white'>
            {jobContactPipelinePosition.notes}
          </PopoverContent>
        </Popover>
      </RowCell>
      <RowCell className=''>
        {assignedUser?.fullName ?? assignedUser?.userName ?? '-'}
      </RowCell>
      <RowCell className='flex items-center border-r-0'>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <EllipsisIcon />
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className='bg-white'
            collisionPadding={{ right: 20 }}
          >
            <DropdownMenuItem
              onClick={() => onRestoreClick(jobContactPipelinePosition)}
            >
              Restore to last step
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </RowCell>
    </Row>
  );
}
