import { Transition } from '@headlessui/react';
import { AlertCircleIcon, Ellipsis } from 'lucide-react';
import { useState, FC } from 'react';

import { Button } from '../common/Button';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltips';

export type BulkAction = {
  label: string;
  type: 'primary' | 'secondary';
  onClickHandler: () => void;
};

type Props = {
  bulkActions: BulkAction[];
  selectedItemsCount: number;
};

const BulkActions: FC<Props> = ({ bulkActions, selectedItemsCount }) => {
  const [showSecondaryList, setShowSecondaryList] = useState(false);

  const isVisible = selectedItemsCount > 0;

  const hasExceededLimit = selectedItemsCount > 1000;

  const primayBulkActions = bulkActions.filter(
    (bulkAction) => bulkAction.type === 'primary',
  );

  const secondaryBulkActions = bulkActions.filter(
    (bulkAction) => bulkAction.type === 'secondary',
  );

  return (
    <div className='fixed bottom-36 left-0 right-0 z-50 m-auto flex h-fit w-fit items-end justify-center'>
      <Transition
        as='div'
        show={isVisible}
        enter='transition-transform transition-opacity duration-100 ease-out'
        enterFrom='translate-y-5 opacity-0'
        enterTo='translate-y-0 opacity-100'
        leave='transition-transform transition-opacity duration-100 ease-out'
        leaveFrom='translate-y-0 opacity-100'
        leaveTo='translate-y-5 opacity-0'
      >
        <div className='flex flex-col items-center gap-2 rounded-md border  border-neutral-60 bg-white px-2 py-2 shadow-3xl'>
          <p className='font-medium'>
            {!hasExceededLimit && <span>Items selected: </span>}
            {hasExceededLimit ? (
              <Tooltip>
                <TooltipTrigger className='flex items-center gap-1'>
                  <AlertCircleIcon size={16} className='text-error' />
                  <span className='text-sm text-error'>
                    Too many items in filter set
                  </span>
                </TooltipTrigger>
                <TooltipContent>
                  You have selected more than 1000 items. Please narrow your
                  filter set to select fewer items.
                </TooltipContent>
              </Tooltip>
            ) : (
              <span>{selectedItemsCount}</span>
            )}
          </p>
          <div className='flex gap-2'>
            {primayBulkActions.map((bulkAction, index) => (
              <div key={index}>
                <Button
                  type='button'
                  onClick={bulkAction.onClickHandler}
                  variant='outline'
                  className='border-neutral'
                  disabled={hasExceededLimit}
                >
                  {bulkAction.label}
                </Button>
              </div>
            ))}
          </div>
          {!!secondaryBulkActions.length && (
            <div className='relative'>
              <button
                type='button'
                className='z-50 flex h-[34px] items-center rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                onClick={() => setShowSecondaryList(!showSecondaryList)}
              >
                <Ellipsis className='h-4 w-4' />
              </button>

              {!!secondaryBulkActions.length && showSecondaryList && (
                <div
                  className={`pointer-events-auto absolute bottom-[40px] right-[-120px] z-50 mb-2 flex flex-col rounded-md border border-gray-200 bg-white shadow-xl`}
                >
                  {secondaryBulkActions.map((bulkAction, index) => (
                    <button
                      key={index}
                      className='border-b border-gray-200 py-2 pl-2 pr-6 text-left text-xs font-medium hover:bg-gray-100'
                      onClick={bulkAction.onClickHandler}
                    >
                      {bulkAction.label}
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </Transition>
    </div>
  );
};

export default BulkActions;
