import React from 'react';
import Sel, {
  Props,
  StylesConfig,
  components as DefaultComponents,
} from 'react-select';
import Async, { AsyncProps } from 'react-select/async';
import AS, { AsyncCreatableProps } from 'react-select/async-creatable';

import { ErrorMessage, Required, Stack } from '.';

const styles: StylesConfig<any, any, any> = {
  control: (base, props) => {
    const newStyles = Object.assign({}, base);
    newStyles.boxShadow = 'none';
    newStyles.border = `1px solid ${props.isFocused ? 'rgb(14 165 233 / 1)' : '#E0E0E0'} ${props.isFocused ? '!important' : ''}`;
    newStyles.borderRadius = '6px';
    newStyles.height = '30px !important';
    newStyles.minHeight = '30px';
    newStyles.backgroundColor = props.isDisabled ? '#F9F9F9' : '#FFFFFF';

    return newStyles;
  },
  menu: (base) => {
    const newStyles = Object.assign({}, base);

    newStyles.zIndex = 9999999999999;
    return newStyles;
  },
  indicatorsContainer: (base) => {
    const newStyles = Object.assign({}, base);

    newStyles.height = '30px';

    return newStyles;
  },
  input: (base) => {
    const newStyles = Object.assign({}, base);
    newStyles.padding = '0';
    newStyles.margin = '0';

    return newStyles;
  },
};

const CustomOption = (props: any) => {
  const { leadingIcon: IconComponent } = props.selectProps;
  return (
    <DefaultComponents.Option {...props} className='inline-flex'>
      <div className='inline-flex items-center'>
        {typeof IconComponent === 'function'
          ? IconComponent({ props, size: 20 })
          : IconComponent}
        {props.data.label}
      </div>
    </DefaultComponents.Option>
  );
};

interface ISelect extends Props {
  errorMessage?: string;
  label?: string;
  leadingIcon?: any;
}

/**
 *
 * @deprecated use src/components/SelectNew.tsx
 */
export function Select({ errorMessage, label, required, ...props }: ISelect) {
  return (
    <div className='flex flex-col'>
      {label && (
        <span className='label'>
          {label}
          {required && <Required />}
        </span>
      )}
      <Sel
        className='styled-multiselect'
        {...props}
        styles={styles}
        components={{ Option: CustomOption }}
        classNamePrefix='react-select'
      />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
}

interface IAsyncCreatableSelect extends AsyncCreatableProps<any, false, any> {
  errorMessage?: string;
  label?: string;
}

export function AsyncCreatableSelect({
  errorMessage,
  label,
  required,
  ...props
}: IAsyncCreatableSelect) {
  return (
    <Stack>
      {label && (
        <span className='label'>
          {label}
          {required && <Required />}
        </span>
      )}
      <AS className='styled-multiselect' {...props} styles={styles} />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </Stack>
  );
}

interface IAsyncSelect extends AsyncProps<any, any, any> {
  errorMessage?: string;
  label?: string;
}

export function AsyncSelect({
  errorMessage,
  label,
  required,
  ...props
}: IAsyncSelect) {
  return (
    <Stack>
      {label && (
        <span className='label'>
          {label}
          {required && <Required />}
        </span>
      )}
      <Async className='styled-multiselect' {...props} styles={styles} />
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </Stack>
  );
}
