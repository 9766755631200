import { useCustomQuery } from '../../hooks/useCustomQuery';
import { APICommunicationResponse } from './Communication';

function TodayActivities({ userId }: { userId?: string | null }) {
  const { data: response, isLoading } =
    useCustomQuery<APICommunicationResponse>({
      url: `/reports/comms/${userId}`,
      params: {
        interval: 'day', // today?
      },
    });

  const { data } = response ?? {};
  const activitiesData = Object.entries(data?.summary ?? {});

  type TodayActivies = {
    totalCallCount?: string;
    totalOutboundEmails?: string;
    contactUniqueCallCount?: string;
    orgUniqueCallCount?: string;
    contactsAdded?: string;
  };

  const todayActiviesLabelMap = {
    totalCallCount: 'Calls Made',
    totalOutboundEmails: 'Emails Sent',
    contactUniqueCallCount: 'Candidates Contacted',
    orgUniqueCallCount: 'Orgs Contacted',
    contactsAdded: 'Contacts Added',
  };

  return (
    <div className='grid min-h-[200px] gap-5 rounded-lg border border-neutral-40 bg-white p-4'>
      <div className='grid grid-cols-2 items-start justify-between'>
        <div className='flex items-center gap-2'>
          <span className='text-lg font-bold'>Today`s activities</span>
        </div>
      </div>
      {isLoading && (
        <div className='h-14 w-full animate-pulse rounded-md bg-gray-400' />
      )}
      {!isLoading && (
        <div className='flex size-full'>
          {!activitiesData.length && (
            <div className='flex size-full items-center justify-center text-lg font-medium text-gray-500'>
              Not available for selected time range
            </div>
          )}
          {activitiesData.length > 0 && (
            <div className='flex w-full justify-around text-secondary-80'>
              {activitiesData?.map(([key, value]) => {
                if (!todayActiviesLabelMap[key as keyof TodayActivies]) return;
                return (
                  <div
                    className='flex flex-col gap-1 text-secondary-80'
                    key={key}
                  >
                    <div className='text-base font-medium'>
                      {todayActiviesLabelMap[key as keyof TodayActivies]}
                    </div>
                    <div className='text-xl font-medium'>{`${value ?? 0}`}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TodayActivities;
