const baseColors = {
  primary: {
    DEFAULT: '#094CAE',
    70: '#0A408E',
    40: '#E373FE',
    30: '#EDA5FF',
  },
  secondary: {
    DEFAULT: '#533BE4',
    80: '#0F0643',
    60: '#4E00B0',
    50: '#533BE4',
    40: '#9754EB',
    35: '#8874FF',
    30: '#BCB0FF',
    20: '#E0CCF5',
    10: '#F2EDFF',
  },
  tertiary: {
    DEFAULT: '#81D2FF',
    50: '#81D2FF',
    40: '#C3EDFF',
    20: '#F0FBFF',
    10: '#F0FBFF',
  },
  neutral: {
    DEFAULT: '#BDBDBD',
    80: '#000000',
    60: '#868686',
    48: '#848484',
    50: '#767676',
    40: '#E0E0E0',
    20: '#EFF2F1',
    10: '#FFFFFF',
    2: '#F9F9F9',
  },
  success: {
    DEFAULT: '#01A63E',
    10: '#E8F6ED',
  },
  warning: {
    DEFAULT: '#FFC564',
  },
  error: {
    DEFAULT: '#E2000E',
  },
  link: {
    DEFAULT: '#3A65A4',
  },
};

const colors = {
  ...baseColors,
  'dark-blue': '#167DFF',
  'medium-blue': '#4970AA',
  'light-blue': '#F4FCFF',
  purple: '#6610f5',
  darkPurple: '#533BE4',
  lightPurple: '#F0EEFF',
  fucsia: '#C31AEB',
  'accent-foreground': baseColors.secondary['80'],
  'table-header-grey': baseColors.neutral['2'],
  mint: '#48C1B5',
};

export default colors;
