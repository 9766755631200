import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../../lib/utils';
import { Spinner } from './Icons';

const buttonVariants = cva(
  'w-fit h-fit flex items-center justify-center whitespace-nowrap font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 ring-secondary-30 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-80 relative',
  {
    variants: {
      variant: {
        default:
          'bg-secondary text-white hover:bg-secondary-40 disabled:bg-secondary-20',
        secondary:
          'bg-gray-300 text-neutral-80 hover:bg-gray-200 disabled:bg-gray-300',
        outline: 'border  bg-white border-input',
        ghost:
          'bg-transparent text-secondary-80 hover:bg-neutrals-20 disabled:bg-transparent',
        destructive:
          'bg-error text-white hover:bg-error-40 disabled:bg-error-20',
      },
      size: {
        default: 'text-base px-4 py-1',
      },
      radius: {
        default: 'rounded-md',
        rounded: 'rounded-3xl px-4 py-1',
      },
      weight: {
        default: 'font-medium',
        light: 'font-light',
        heavy: 'font-black',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      radius: 'default',
      weight: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      radius,
      weight,
      asChild = false,
      children,
      isLoading,
      ...props
    },
    ref,
  ) => {
    const Component = asChild ? Slot : 'button';

    return (
      <Component
        className={cn(
          buttonVariants({ variant, size, radius, weight }),
          className,
        )}
        ref={ref}
        {...props}
      >
        <span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
          {children}
        </span>
        {isLoading && (
          <div
            className={`font- absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center`}
          >
            <Spinner />
          </div>
        )}
      </Component>
    );
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
