import { E164Number } from 'libphonenumber-js/types';

import CheckInput from '../../components/common/CheckInput';
import DatePickerRange from '../../components/common/DatePickerRange';
import PhoneInput from '../../components/common/PhoneInput';
import ActiveScaleInput from '../../components/filters/ActiveScaleInput';
import CityInput from '../../components/filters/CityInput';
import MinBedsInput from '../../components/filters/MinBedsInput';
import RadiusSearchInput from '../../components/filters/RadiusSearchInput';
import SearchInput from '../../components/filters/SearchInput';
import StateInput from '../../components/filters/StatesInput';
import UsersInput from '../../components/filters/UsersInput';
import { useDebouncedQueryParam } from '../../hooks/useDebouncedQueryParams';
import { useQueryParams } from '../../hooks/useQueryParams';

function OrganizationFilters() {
  const { queryParams } = useQueryParams();

  const [phone, setPhone] = useDebouncedQueryParam({
    key: 'phone',
    minLength: 6,
  });

  const showRelevantSortButton = !!(
    queryParams.query &&
    queryParams.orderBy &&
    queryParams.orderByDirection
  );

  function PlusIcon() {
    return (
      <span className='material-symbols-outlined' style={{ fontSize: '20px' }}>
        add
      </span>
    );
  }

  return (
    <>
      <SearchInput
        containerClassName='w-96'
        showRelevantSortButton={showRelevantSortButton}
        placeholder='Search organization names & cities'
      />
      <PhoneInput
        value={phone as E164Number}
        onChange={(value: E164Number | undefined) => setPhone(value ?? '')}
        placeholder='Phone Number'
        id='search-phone'
        hideLabel
      />
      <CheckInput label='Has contract' param='hasContract' updateValue='true' />
      <MinBedsInput />
      <CityInput />
      <UsersInput />
      <StateInput />
      <RadiusSearchInput />
      <DatePickerRange
        Label={PlusIcon}
        tooltipText='Date added range'
        startParam='insertDateStart'
        endParam='insertDateEnd'
      />
      <ActiveScaleInput />
    </>
  );
}

export default OrganizationFilters;
