import { initializeIcons } from '@fluentui/react/lib/Icons';

/**
 * @deprecated
 * Please use styles/colors.ts
 */
export const theme = {
  blue: '#0A408E',
  lightBlue: 'rgba(240, 251, 255, 0.70)',
  lightPurple: '#F2EDFF',
  red: '#B30E1B',
  purple: '#6610f5',
  richBlue: '#167DFF',
  danger: '#FF1111',
};

export const MOBILE_BREAKPOINT = 600;

initializeIcons();
