import { useState } from 'react';

import { CommonSpinner } from '.';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useReadMore } from '../../hooks/useReadMore';
import colors from '../../styles/colors';
import { NoteRecord } from '../../types/notes';
import { formatNoteText } from '../../utils/activities';
import AddNoteModal from '../modals/AddNoteModal';
import { AddIcon } from './Icons';
import { Pagination } from './Pagination';

function NotesView({
  entityId,
  entityName,
  paginationKey = 'page',
}: {
  entityId: string;
  entityName: string;
  paginationKey?: string;
}) {
  const { isExpanded, isTruncated, toggleExpand } = useReadMore('');
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
  const [editingNote, setEditingNote] = useState<NoteRecord | null>(null);
  const { queryParams } = useQueryParams();

  const {
    data,
    isLoading: isNoteDataLoading,
    refetch: refetchNotesData,
  } = useCustomQuery<NoteRecord[]>({
    url: `/notes/${entityId}`,
    params: {
      ...(queryParams[paginationKey]
        ? { pageNumber: queryParams[paginationKey] }
        : {}),
    },
    enabled: !!entityId,
  });
  const noteData = data?.data;
  const pages = data?.pages;
  const hasNotes = !!noteData && !!noteData.length;

  return (
    <>
      <>
        {isNoteDataLoading && (
          <div className='flex size-full items-center justify-center'>
            <CommonSpinner size='md' />
          </div>
        )}

        {!isNoteDataLoading && (
          <div className='flex size-full flex-col gap-4 overflow-hidden'>
            <div className='flex justify-end border-b pb-4'>
              <button
                onClick={() => setIsAddNoteModalOpen(true)}
                className='flex items-center gap-2 rounded border px-2 py-1 hover:bg-gray-100'
              >
                <AddIcon size={16} />
                <span>Add</span>
              </button>
            </div>
            {hasNotes ? (
              <div className='flex flex-col gap-4 overflow-auto'>
                {noteData.map((note) => (
                  <NoteCard
                    key={note.noteId}
                    note={note}
                    setEditingNote={setEditingNote}
                    setIsAddNoteModalOpen={setIsAddNoteModalOpen}
                  />
                ))}
              </div>
            ) : (
              <div className='self-center text-xl'>No notes found</div>
            )}
            {isTruncated && (
              <button
                onClick={toggleExpand}
                className='w-fit text-sm font-medium text-link'
              >
                {isExpanded ? 'Read less' : 'Read more'}
              </button>
            )}
            {!!pages && pages > 1 && (
              <Pagination pages={pages} paginationKey={paginationKey} />
            )}
          </div>
        )}
      </>

      <AddNoteModal
        isOpen={isAddNoteModalOpen}
        onDismiss={() => setIsAddNoteModalOpen(false)}
        entityId={entityId}
        entityName={entityName}
        editingNote={editingNote}
        setEditingNote={setEditingNote}
        refetchNotesData={refetchNotesData}
      />
    </>
  );
}

export default NotesView;

function NoteCard({
  note,
  setEditingNote,
  setIsAddNoteModalOpen,
}: {
  note: NoteRecord;
  setEditingNote: React.Dispatch<React.SetStateAction<NoteRecord | null>>;
  setIsAddNoteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { contentRef, isExpanded, isTruncated, toggleExpand } = useReadMore(
    note.note,
  );
  const dateString = note.insertDate;
  const utcDateString = dateString + 'Z'; // Indicate that this time is in UTC

  const formattedDate = new Date(utcDateString).toLocaleString();

  const timeNow = Date.now();
  const timeCreated = Date.parse(utcDateString);

  const timeElapsed = timeNow - timeCreated;
  const enabledWindow = 1000 * 60 * 5;
  const inEditWindow = timeElapsed < enabledWindow;

  function handleEditClick() {
    setEditingNote(note);
    setIsAddNoteModalOpen(true);
  }

  return (
    <div className='card' key={note.noteId}>
      <div className='relative flex flex-col gap-2 rounded-md border p-4'>
        {inEditWindow && (
          <div className='absolute right-4 flex items-center justify-between'>
            <button
              className='h-[24px] w-[24px] rounded-full border border-primary-70'
              onClick={handleEditClick}
            >
              <span
                color={colors.primary[70]}
                className='material-symbols-outlined text-base font-semibold text-primary-70'
              >
                edit_square
              </span>
            </button>
          </div>
        )}
        <div
          className={`${isExpanded ? '' : 'line-clamp-6'} w-full break-words text-sm`}
          ref={contentRef}
        >
          {formatNoteText(note.note)}
        </div>
        {isTruncated && (
          <button
            onClick={toggleExpand}
            className='w-fit text-sm font-medium text-link'
          >
            {isExpanded ? 'Read less' : 'Read more'}
          </button>
        )}
        <div className='flex justify-between'>
          <div className='text-xs text-neutral-500'>
            {note.userName?.toLowerCase()}
          </div>
          <div className='ml-4 text-xs text-neutral-500'>{formattedDate}</div>
        </div>
      </div>
    </div>
  );
}
