export enum Stages {
  toInterview = 'To interview',
  toCall = 'To call',
  toDelete = 'To delete',
  toUpdate = 'To update',
  voicemailLeft = 'Voicemail left',
  urgentFollowUp = 'Urgent follow-up',
  feeCollect = 'Fee collect',
  archive = 'Archive',
}

export const legendColors = [
  { label: 'A1', color: '#C31AEBFF' },
  { label: 'B2', color: '#FE5602EE' },
  { label: 'C3', color: '#D20D0DDD' },
  { label: 'D4', color: '#01A63ECC' },
  { label: 'E5', color: '#167DFFBB' },
  { label: 'F6', color: '#533BE4AA' },
  { label: 'G7', color: '#00000099' },
  { label: 'H8', color: '#FFA23988' },
  { label: 'I9', color: '#48C1B577' },
];
