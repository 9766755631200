import { E164Number } from 'libphonenumber-js/types';
import {
  formatPhoneNumber as format,
  isPossiblePhoneNumber,
} from 'react-phone-number-input';

export function generatePhoneLink(phoneNumber: string | null | undefined) {
  if (!phoneNumber) return '';

  phoneNumber = phoneNumber.replace(/[^+\d]+/g, '');

  if (!phoneNumber.startsWith('+')) {
    phoneNumber = `+1${phoneNumber}`;
  }

  return `${phoneNumber}`;
}

export function maskPhoneNumber(
  phoneNumber: E164Number | undefined | null,
  isAdmin?: boolean,
) {
  if (!phoneNumber) return '';

  if (isAdmin) {
    if (isPossiblePhoneNumber(phoneNumber)) {
      return format(phoneNumber);
    } else {
      return phoneNumber;
    }
  }

  const digitsOnly = phoneNumber.replace(/\D/g, '');

  const isUSNumber = digitsOnly.startsWith('1') && digitsOnly.length === 11;

  if (isUSNumber) {
    return `(•••) •••-${digitsOnly.slice(-4)}`;
  } else {
    const maskedPart = '•'.repeat(Math.max(0, digitsOnly.length - 4));
    return `${maskedPart}${digitsOnly.slice(-4)}`;
  }
}
