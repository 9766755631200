import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGlobalState } from '../../context/GlobalContext';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { APIList } from '../../types/lists';
import { setFieldIfEmpty } from '../../utils/helpers';
import AddToListModal from '../modals/AddToListModal';
import { AddIcon } from './Icons';
import { Body, Header, HeaderCell, Row, RowCell, Table } from './Table';
import { CommonSpinner } from './index';

export function ListsDetail({
  listId,
  page,
}: {
  listId: string;
  page: string;
}) {
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
  const navigate = useNavigate();
  const {
    data: response,
    isLoading,
    refetch,
  } = useCustomQuery<APIList[]>({
    url: `/${page}/${listId}/lists`,
    enabled: !!page && !!listId,
  });

  const { users } = useGlobalState();

  const handleRowClick = (listId: string) => {
    listId &&
      navigate(`/lists/${listId}/${page === 'jobs' ? 'requisitions' : page}`);
  };

  const { data: listItems } = response ?? {};

  return (
    <>
      {isLoading && (
        <div className='flex size-full items-center justify-center gap-4'>
          <CommonSpinner size='md' />{' '}
        </div>
      )}

      <div className='flex size-full flex-col gap-4 overflow-hidden'>
        <div className='flex justify-end border-b pb-4'>
          <button
            onClick={() => {
              setIsAddNoteModalOpen(true);
            }}
            className='flex items-center gap-2 rounded border px-2 py-1 hover:bg-gray-100'
          >
            <AddIcon size={16} />
            <span>Add to List</span>
          </button>
        </div>
        {!isLoading && listItems?.length ? (
          <div className='flex'>
            <Table
              id='activities-table'
              classNames={{
                table: 'size-full table table border border-neutral-40',
              }}
            >
              <Header>
                <HeaderCell>List</HeaderCell>
                <HeaderCell>List Type</HeaderCell>
                <HeaderCell>Date Created</HeaderCell>
                <HeaderCell>User assigned</HeaderCell>
              </Header>
              <Body>
                {listItems.map((list, key) => {
                  const user =
                    users?.find((u) => u.userId === list.userId)?.fullName ??
                    list.userName;
                  return (
                    <Row
                      className='cursor-pointer'
                      key={key}
                      onClick={() => handleRowClick(list?.listId || '')}
                    >
                      <RowCell>{setFieldIfEmpty(list.description)}</RowCell>
                      <RowCell>{setFieldIfEmpty(list.template)}</RowCell>

                      <RowCell>{setFieldIfEmpty(list.insertDate)}</RowCell>
                      <RowCell>{setFieldIfEmpty(user)}</RowCell>
                    </Row>
                  );
                })}
              </Body>
            </Table>
          </div>
        ) : (
          <div className='flex w-full justify-center'>
            <span className='text-center text-xl'>No Lists</span>
          </div>
        )}
      </div>

      <AddToListModal
        listAPI={`/${page}/${listId}/lists`}
        isOpen={isAddNoteModalOpen}
        onDismiss={() => setIsAddNoteModalOpen(false)}
        refetchNotesData={refetch}
      />
    </>
  );
}
