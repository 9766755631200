import { useState, useEffect, useCallback, useMemo } from 'react';
import { useRevalidator } from 'react-router-dom';

import { isWebKit } from '../../utils/helpers';
import AddAttachmentsModal from '../modals/AddAttachmentsModal';
import {
  AddIcon, // PrintIcon, ShareIcon, TrashIcon
} from './Icons';
import { BlackButtonGroup, CommonSpinner, Stack } from './index';

export function ResumeView({
  url,
  tableId,
  tableName,
  mimeType,
}: {
  url: string | null;
  loading?: boolean;
  tableId: string;
  tableName: string;
  mimeType?: string | null;
}) {
  const [isResumeLoading, setIsResumeLoading] = useState(!!url);
  const [error, setError] = useState<string | null>(null);
  const [isAddResumeModalOpen, setIsAddResumeModalOpen] = useState(false);
  const revalidator = useRevalidator();

  const add = useCallback(() => {
    setIsAddResumeModalOpen(true);
  }, []);

  useEffect(() => {
    if (url) {
      setIsResumeLoading(true);
    }
  }, [url]);

  const encodedUrl = encodeURIComponent(url ?? '');
  const iFrameUrl =
    'https://view.officeapps.live.com/op/embed.aspx?src=' +
    encodedUrl +
    '&embedded=true';
  const isWebKitBrowser = useMemo(() => {
    return isWebKit();
  }, []);

  const renderDocument = () => {
    if (mimeType === 'application/pdf') {
      return (
        <object
          data={url + '#toolbar=0' ?? ''}
          width='100%'
          height='100%'
          className={`mx-auto ${isResumeLoading ? 'invisible' : 'visible'}`}
          onLoad={() => {
            setIsResumeLoading(false);
          }}
          // This fixes a bug webkit browsers. The type param was causing the embedded pdf to crash.
          {...(isWebKitBrowser ? {} : { type: 'application/pdf' })}
        >
          <p>
            It appears your browser does not support viewing PDF files. You can{' '}
            <a href={url || ''}>download the PDF</a> instead.
          </p>
        </object>
      );
    } else {
      return (
        <iframe
          title='Resume'
          src={iFrameUrl}
          className={`mx-auto ${isResumeLoading ? 'invisible' : 'visible'} `}
          style={{ width: '100%', height: '100%' }}
          onError={() => {
            setError('Error loading resume');
            setIsResumeLoading(false);
          }}
          onChange={() => {}}
          onLoad={() => {
            setIsResumeLoading(false);
          }}
        />
      );
    }
  };

  return (
    <>
      <div className='flex size-full flex-col gap-4'>
        <Stack horizontal horizontalAlign='end'>
          <BlackButtonGroup
            items={[
              {
                icon: <AddIcon size={16} />,
                text: 'Add',
                onClick: add,
              },
              // {
              //   icon: <PrintIcon size={18} />,
              //   text: 'Print',
              //   onClick: print,
              // },
              // {
              //   icon: <TrashIcon size={18} />,
              //   text: 'Delete',
              // },
              // {
              //   icon: <ShareIcon size={20} />,
              //   text: 'Share',
              // },
            ]}
          />
        </Stack>
        {isResumeLoading && (
          <div className='flex size-full items-center justify-center'>
            <CommonSpinner size='md' />
          </div>
        )}
        {error && (
          <div className='flex w-full justify-center text-error'>
            Error loading resume. Please try again.
          </div>
        )}
        {url && renderDocument()}

        {!encodedUrl && (
          <div className='flex flex-col items-center justify-center text-xl'>
            <span>No Resume Found</span>
          </div>
        )}
      </div>

      <AddAttachmentsModal
        attachmentType='resume'
        isOpen={isAddResumeModalOpen}
        onDismiss={() => setIsAddResumeModalOpen(false)}
        tableId={tableId}
        tableName={tableName}
        refetchAttachments={() => {
          revalidator.revalidate();
        }}
      />
    </>
  );
}
