import { useQueryParams } from '../../hooks/useQueryParams';

interface CheckInputProps {
  param: string;
  updateValue: string;
  label: string;
}

function CheckInput({ param, updateValue, label }: CheckInputProps) {
  const { queryParams, updateQueryParam, removeQueryParam } = useQueryParams();
  const isChecked = Boolean(queryParams[param]);

  const handleCheckInput = () => {
    if (isChecked) {
      removeQueryParam(param);
    } else {
      updateQueryParam(param, updateValue);
    }
  };

  return (
    <div className='inline-flex items-center'>
      <input
        type='checkbox'
        id={`${param}-checkbox`}
        className='sr-only'
        onChange={handleCheckInput}
        checked={isChecked}
      />
      <label
        htmlFor={`${param}-checkbox`}
        className={`h-8 cursor-pointer rounded-md border px-2 py-1 text-sm font-medium shadow-sm transition-colors
          ${
            isChecked
              ? 'border-secondary-80 bg-secondary-80 text-white'
              : 'border-neutral-40 bg-white text-neutral-60 hover:border-neutral-60'
          }`}
        role='checkbox'
        aria-checked={isChecked}
        tabIndex={0}
      >
        {label}
      </label>
    </div>
  );
}

export default CheckInput;
