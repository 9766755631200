import { ArrowDownWideNarrowIcon } from 'lucide-react';

import { useDebouncedQueryParam } from '../../hooks/useDebouncedQueryParams';
import {
  objectToQueryString,
  useQueryParams,
} from '../../hooks/useQueryParams';
import { cn } from '../../lib/utils';
import { SearchIcon } from '../common/Icons';
import { Input } from '../ui/input';
import { TooltipContent, TooltipTrigger, Tooltip } from '../ui/tooltips';

const SearchInput = ({
  placeholder = 'Search',
  className = '',
  containerClassName = '',
  showRelevantSortButton = false,
}: {
  placeholder?: string;
  className?: string;
  containerClassName?: string;
  showRelevantSortButton?: boolean;
}) => {
  const { queryParams, setQueryParams } = useQueryParams();

  const [value, setValue] = useDebouncedQueryParam({
    key: 'query',
    minLength: 2,
  });

  const handleReturnToRelevantSort = () => {
    const newParams = { ...queryParams };

    delete newParams.orderBy;
    delete newParams.orderByDirection;

    const queryString = objectToQueryString(newParams);

    setQueryParams(queryString);
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div className={cn('relative flex w-96', containerClassName)}>
          <span className='sr-only'>Search Contacts</span>
          <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
            <SearchIcon />
          </span>
          <Input
            value={value}
            onChange={(event) => {
              const eventValue = event.target.value;
              setValue(eventValue);
            }}
            placeholder={placeholder}
            className={`pl-8 ${className}`}
          />
          <Tooltip>
            <TooltipTrigger
              disabled={!showRelevantSortButton}
              onClick={handleReturnToRelevantSort}
              className={`absolute inset-y-0 right-0 flex items-center pr-2 ${showRelevantSortButton ? 'opacity-1 translate-x-0' : 'translate-x-6 cursor-default opacity-0'} transition-all duration-100`}
            >
              <ArrowDownWideNarrowIcon className='h-4 w-4' />
            </TooltipTrigger>
            {showRelevantSortButton && (
              <TooltipContent className='border border-neutral-40 bg-white shadow-md'>
                Return to relevance sort
              </TooltipContent>
            )}
          </Tooltip>
        </div>
      </TooltipTrigger>
      <TooltipContent className='delay-15 p-1 duration-100'>
        + (means AND) * (wildcard/prefix) - (means NOT) no operator means OR
      </TooltipContent>
      <TooltipContent className='delay-15 absolute mt-10 w-80 p-0 text-center duration-100'>
        Use + - * for search. Eg: +first +last* -clayton*
      </TooltipContent>
    </Tooltip>
  );
};

export default SearchInput;
