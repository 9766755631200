import {
  ArchiveIcon,
  CalendarFoldIcon,
  ChevronDownIcon,
  CircleDollarSignIcon,
  CircleIcon,
  CircleXIcon,
  ClipboardPlusIcon,
  PhoneIcon,
  TriangleAlert,
  VoicemailIcon,
} from 'lucide-react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/common/Dropdown';
import { ListItem } from '../../types/lists';
import { Stages } from '../../utils/constant';

type StageKey = keyof typeof Stages;

export function getStageKey(value: string): string | undefined {
  for (const [key, stageValue] of Object.entries(Stages)) {
    if (stageValue === value) {
      return key;
    }
  }
}

const getIcon = (stageKey: string) => {
  switch (stageKey) {
    case 'toInterview':
      return CalendarFoldIcon;
    case 'toCall':
      return PhoneIcon;
    case 'toDelete':
      return CircleXIcon;
    case 'toUpdate':
      return ClipboardPlusIcon;
    case 'voicemailLeft':
      return VoicemailIcon;
    case 'urgentFollowUp':
      return TriangleAlert;
    case 'feeCollect':
      return CircleDollarSignIcon;
    case 'archive':
      return ArchiveIcon;
    default:
      return CircleIcon;
  }
};

function ListDetailStagesDropdown({
  item,
  handleDropdownChange,
}: {
  item: ListItem;
  handleDropdownChange: any;
}) {
  const currentStage = item.stage || Stages.toInterview;

  const Icon = getIcon(getStageKey(item.stage || '') as StageKey);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className='flex h-6 w-full items-center justify-between px-1 focus:outline-none'>
        <div className='flex w-full items-center justify-between'>
          <div className='inline-flex items-center gap-1'>
            {item.stage ? <Icon size={12} /> : <CalendarFoldIcon size={12} />}
            <span className='w-100'>{currentStage}</span>
          </div>
          <ChevronDownIcon size={16} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='bg-white'>
        {Object.entries(Stages).map(([key, value]) => {
          const OptionIcon = getIcon(key);
          return (
            <DropdownMenuItem
              key={key}
              disabled={item.stage === value}
              onClick={(e) => {
                e.stopPropagation();
                handleDropdownChange(value, item);
              }}
            >
              <div
                className={`inline-flex w-full cursor-pointer items-center gap-1 ${item.stage === value && 'text-gray-500'}`}
              >
                <OptionIcon size={12} />
                <span>{value}</span>
              </div>
            </DropdownMenuItem>
          );
        })}
        <DropdownMenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleDropdownChange('', item);
          }}
        >
          <div className='inline-flex w-[150px] cursor-pointer items-center text-gray-600'>
            Clear Status
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default ListDetailStagesDropdown;
