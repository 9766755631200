import { useMemo } from 'react';

import { CommonSpinner } from '.';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { useReadMore } from '../../hooks/useReadMore';
import { useTabQueryParams } from '../../hooks/useTabQueryParams';
import { formatText } from '../../utils/activities';
import { TabsParams } from '../../utils/tabs';
import Select from '../common/SelectNew';
import { getActivityIcon } from './Activity';
import { Button } from './Button';
import { Pagination } from './Pagination';

interface CommActivity {
  contactId: string;
  contactName: string;
  activityEventTime: string;
  emailSubject: string | null;
  fromTo: string;
  threadId: string | null;
  emailOrder: number | null;
  userId: string | null;
  userFullName: string | null;
  activityType: string;
  direction: string;
  content: string;
}

const commsFilterOptions = [
  { value: 'email', label: 'Email' },
  { value: 'text', label: 'Text' },
  { value: 'call', label: 'Call' },
];

const sortOptions = [
  { value: 'desc', label: 'Newest' },
  { value: 'asc', label: 'Oldest' },
];

interface GroupedComms {
  [key: string]: CommActivity[];
}

export function ConversationView({
  url,
  paginationKey = 'page',
  typeKey,
}: {
  url?: string;
  paginationKey?: string;
  typeKey: string;
}) {
  const { users } = useGlobalState();

  const {
    queryParams,
    updateTabQueryParam,
    removeTabQueryParam,
    clearTabParams,
  } = useTabQueryParams();

  const { data, isLoading } = useCustomQuery<CommActivity[]>({
    url: url as string,
    params: {
      ...(queryParams[paginationKey]
        ? { pageNumber: queryParams[paginationKey] }
        : {}),
      ...(queryParams[typeKey] ? { type: queryParams[typeKey] } : {}),
      ...(queryParams[TabsParams.ConversationsTabOrderBy]
        ? { orderByDirection: queryParams[TabsParams.ConversationsTabOrderBy] }
        : {}),
      ...(queryParams[TabsParams.ConversationsTabUserId]
        ? { userId: queryParams[TabsParams.ConversationsTabUserId] }
        : {}),
    },
  });

  const hasFilters = !!(
    queryParams[paginationKey] ||
    queryParams[typeKey] ||
    queryParams[TabsParams.ConversationsTabUserId] ||
    queryParams[TabsParams.ConversationsTabOrderBy]
  );

  const { data: comms } = data ?? {};

  const groupedComms = useMemo(() => {
    if (!comms) return {};

    return comms.reduce((acc: GroupedComms, item) => {
      const date = new Date(item.activityEventTime);
      const monthYear = date.toLocaleString('default', {
        month: 'long',
        year: 'numeric',
      });

      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }

      acc[monthYear].push(item);
      return acc;
    }, {});
  }, [comms]);

  const usersOptions = useMemo(() => {
    return (
      users?.map((u) => ({
        value: u.userId ?? '',
        label: u.fullName ?? '',
      })) ?? []
    );
  }, [users]);

  const clearSelected = () => {
    clearTabParams();
  };

  return (
    <>
      <div className='flex size-full flex-col gap-4 overflow-hidden'>
        <div className='flex w-full items-center justify-between border-b pb-4'>
          <div className='flex w-full items-center gap-4'>
            <Select
              placeholder='Select a type'
              options={commsFilterOptions}
              value={(queryParams[typeKey] as string) || ''}
              onChange={(value) => {
                if (value) {
                  updateTabQueryParam('ConversationsTabType', value, {
                    multiple: false,
                    resetPageOnChange: true,
                  });
                } else {
                  removeTabQueryParam('ConversationsTabType');
                }
              }}
              showClearOption
            />
            <Select
              placeholder='Select a user'
              options={usersOptions}
              value={
                (queryParams[TabsParams.ConversationsTabUserId] as string) || ''
              }
              onChange={(value) => {
                if (value) {
                  updateTabQueryParam('ConversationsTabUserId', value, {
                    multiple: false,
                    resetPageOnChange: true,
                  });
                } else {
                  removeTabQueryParam('ConversationsTabUserId');
                }
              }}
              showClearOption
            />
            {hasFilters && (
              <Button
                variant='ghost'
                className='text-sm'
                onClick={clearSelected}
              >
                Remove filters
              </Button>
            )}
          </div>
          <div className='flex flex-shrink-0 items-center gap-2'>
            <p>Sorted by</p>
            <Select
              placeholder=''
              options={sortOptions}
              defaultValue='desc'
              value={
                (queryParams[TabsParams.ConversationsTabOrderBy] as string) ||
                ''
              }
              onChange={(value) => {
                if (value) {
                  updateTabQueryParam('ConversationsTabOrderBy', value, {
                    multiple: false,
                  });
                } else {
                  removeTabQueryParam('ConversationsTabOrderBy');
                }
              }}
              classNames={{ container: 'w-24' }}
            />
          </div>
        </div>
        <>
          {isLoading && (
            <div className='flex size-full items-center justify-center'>
              <CommonSpinner size='md' />
            </div>
          )}
        </>
        {!isLoading && (
          <>
            {Object.entries(groupedComms).length > 0 ? (
              <div className='overflow-auto'>
                {Object.entries(groupedComms).map(
                  ([monthYear, activities], idx) => {
                    return (
                      <div key={monthYear}>
                        <h2
                          className={`mb-4 font-normal ${idx !== 0 && 'mt-8'}`}
                        >
                          {monthYear}
                        </h2>
                        <div className='flex w-full flex-col gap-2'>
                          {activities.map((activity, index) => (
                            <Card
                              key={activity.activityEventTime + index}
                              activity={activity}
                            />
                          ))}
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
            ) : (
              <p className='flex size-full items-start justify-center text-xl'>
                No conversations found
              </p>
            )}
          </>
        )}
        <div>
          <Pagination
            paginationKey={paginationKey}
            currentItemsCount={comms?.length ?? 0}
            itemsPerPage={25}
          />
        </div>
      </div>
    </>
  );
}

export default ConversationView;

function Card({ activity }: { activity: CommActivity }) {
  const { contentRef, isExpanded, isTruncated, toggleExpand } = useReadMore(
    activity.content,
  );

  const icon = useMemo(() => {
    let type = activity.activityType;
    if (type === 'EMAIL') {
      type = activity.direction === 'from' ? 'emailrecd' : 'emailTo';
    } else if (type === 'SMS' || type === 'eSMS') {
      type = activity.direction === 'from' ? 'esmsinbound' : 'esmsoutbound';
    }

    return getActivityIcon(type);
  }, [activity.activityType, activity.direction]);

  return (
    <div className='size-full rounded-md border border-neutral-40 p-4'>
      <div className='relative flex size-full flex-col gap-2 pl-8'>
        <p className='font-bold '>{activity.contactName}</p>
        <p className='-mt-2 font-medium text-neutral-50'>{activity.fromTo}</p>
        <p className='font-medium'>{activity.emailSubject}</p>
        <div
          className={`${isExpanded ? '' : 'line-clamp-6'} w-full break-words text-sm`}
          ref={contentRef}
        >
          {formatText(activity.content)}
        </div>
        {isTruncated && (
          <button
            onClick={toggleExpand}
            className='w-fit text-sm font-medium text-link'
          >
            {isExpanded ? 'Read less' : 'Read more'}
          </button>
        )}
        <p className='absolute right-0 top-0 text-neutral-50'>
          {new Date(activity.activityEventTime).toLocaleString()}
        </p>
        <div className='material-symbols-outlined absolute left-0 top-0 mt-0.5'>
          {icon}
        </div>
      </div>
    </div>
  );
}
