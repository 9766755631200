import { useGlobalState } from '../../context/GlobalContext';
import { useQueryParams } from '../../hooks/useQueryParams';
import { MultiSelectDropdownFilter } from '../common/MultiSelectDropdownFilter';

function UsersInput({
  placeholder = 'User assigned',
}: {
  placeholder?: string;
}) {
  const { me, users, inActiveUsers } = useGlobalState();
  const { queryParams, updateQueryParam, removeQueryParam } = useQueryParams();

  const allUsers =
    (users && inActiveUsers && [...users, ...inActiveUsers]) ?? [];
  return (
    <MultiSelectDropdownFilter
      classNames={{ container: 'w-48' }}
      items={allUsers
        .sort((a, b) =>
          a.userId === me?.userId ? -1 : b.userId === me?.userId ? 1 : 0,
        )
        .map((s) => ({
          id: s.userId as string,
          title: s.active ? (s.fullName as string) : `${s.fullName} (Inactive)`,
        }))}
      handleChange={(userId) =>
        updateQueryParam('userId', userId, {
          multiple: false,
          resetPageOnChange: true,
        })
      }
      value={[(queryParams?.userId || '') as string]}
      isClearable={!!queryParams?.userId}
      onClear={() => removeQueryParam('userId')}
      isChecked={!!queryParams?.userId}
      placeholder={placeholder}
    />
  );
}

export default UsersInput;
