import CheckInput from '../common/CheckInput';

const ShowExpiredInput = () => {
  return (
    <div>
      <CheckInput label='Show Expired' param='showExpired' updateValue='true' />
    </div>
  );
};

export default ShowExpiredInput;
