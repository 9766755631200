import { TooltipTrigger, Tooltip, TooltipContent } from '../ui/tooltips';

function TableCellTooltip({ content }: { content: string | null }) {
  if (!content) {
    return <span>-</span>;
  }

  return (
    <Tooltip>
      <TooltipTrigger className='w-full truncate text-left'>
        {content}
      </TooltipTrigger>
      <TooltipContent collisionPadding={{ right: 20 }}>
        {content}
      </TooltipContent>
    </Tooltip>
  );
}

export default TableCellTooltip;
