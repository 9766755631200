import { redirect } from 'react-router-dom';

class API {
  public token?: string;
  apiBase: string;

  constructor() {
    this.apiBase =
      (process.env.REACT_APP_BASE_URL ?? 'http://localhost') + '/v1';
  }
  private buildHeaders() {
    const headers: Record<string, string> = {
      Accept: '*/*',
      'Access-Control-Allow-Credentials': 'true',
    };

    return headers;
  }

  async restful<ReturnType = any>(
    url: string,
    opts?: {
      method?: string;
      header?: boolean;
      body?: any;
      isFormData?: boolean;
    },
  ) {
    try {
      const resp = await fetch(this.apiBase + url, {
        method: opts?.method,
        credentials: 'include',
        headers: opts?.isFormData
          ? this.buildHeaders()
          : {
              Accept: 'application/json',
              'Content-Type':
                opts?.method?.toLowerCase() === 'get'
                  ? 'text/plain;charset=UTF-8'
                  : opts?.header
                    ? `multipart/form-data`
                    : 'application/json',
              'Access-Control-Allow-Credentials': 'true',
            },
        ...(opts?.body
          ? { body: opts?.isFormData ? opts?.body : JSON.stringify(opts?.body) }
          : {}),
      });
      if (!resp.ok) {
        if (resp.status === 401) {
          return redirect(
            '/login?redirectUrl=' +
              encodeURI(window.location.pathname + window.location.search),
          ) as any;
        } else {
          throw new Error(resp.statusText);
        }
      }
      const data = (await resp.json()) as ReturnType;

      return {
        data,
        page: resp.headers.get('pageNumber'),
        offset: resp.headers.get('offset'),
        rows: resp.headers.get('numberOfRecords'),
        pages: resp.headers.get('numberOfPages'),
      };
    } catch (e: any) {
      return {
        hasError: true,
        error: e?.message || 'An unknown error occured',
      };
    }
  }

  // the data object passed to this function should contain an
  // `uploads` field that consists of an array of javascript File objects
  // see https://developer.mozilla.org/en-US/docs/Web/API/File
  async postWithFiles(endpoint: string, data: any) {
    const formData = new FormData();
    const postData = {} as any;
    // postData.uploads = []
    for (const file of data.uploads) {
      formData.append('file', file);
      postData.filename = file.name;
      postData.type = file.docType;
    }
    formData.append('data', JSON.stringify(postData));

    const res = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${this.token ?? ''}`,
        // Accept: 'application/json',
        // 'Content-Type': 'mulipart/form-data'
      },
      method: 'post',
      body: formData,
    });

    return res || {};
  }
}

export const api = new API();
