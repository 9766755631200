import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { EmptyState } from '../../components/common';
import { Button } from '../../components/common/Button';
import {
  ContentContainer,
  ContentInnerContainer,
} from '../../components/common/Content';
import { WarningIcon } from '../../components/common/Icons';
import PageContent from '../../components/common/PageContent';
import {
  AlertDialog,
  AlertDialogContent,
} from '../../components/ui/alert-dialog';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { usePageTitle } from '../../hooks/usePageTitle';
import {
  objectToQueryString,
  useQueryParams,
} from '../../hooks/useQueryParams';
import { useRouteChange } from '../../hooks/useRouteChange';
import { useTableSelection } from '../../hooks/useTableSelection';
import { APIList, ListItem } from '../../types/lists';
import { PageTypes } from '../../types/pages';
import { resetPageNumber } from '../../utils/activities';
import { generateListDetailsQueryParams } from '../../utils/api';
import { removeTabsParamsAndReturnQueryString } from '../../utils/tabs';
import { ContactView } from '../contacts/ContactView';
import EmailContactsModal from '../contacts/EmailContactsModal';
import { AddList } from '../lists/AddList';
import { OrganizationView } from '../organizations/OrganizationView';
import { RequisitionView } from '../requisitions/RequisitionView';
import AddEntityModal from './AddEntityModal';
import { ListAddForms } from './ListAddForms';
import ListContactsTable from './ListContactsTable';
import ListDetailsBulkActions from './ListDetailsBulkActions';
import ListDetailsFilters from './ListDetailsFilters';
import ListOrganizationsTable from './ListOrganizationsTable';
import ListRequisitionsTable from './ListRequisitionsTable';

function ListDetails() {
  const { id: listId, type, entityId, tabId } = useParams();

  const { queryParams, setQueryParams } = useQueryParams();

  const navigate = useNavigate();

  const { hasRouteChanged } = useRouteChange();

  const [showEditModal, setShowEditModal] = useState(false);
  const [showEmailContactsModal, setShowEmailContactsModal] = useState(false);
  const [listCounts, setListCounts] = useState<{
    contactCount: number;
    orgCount: number;
    jobCount: number;
  } | null>(null);

  const { stage: stageQueryParam, entityType } = queryParams;

  const [baseRouteQueryParams, setBaseRouteQueryParams] = useState({});

  const isModalOpen = !!queryParams.entityType;
  // necessary to know this information to set loading state on the table becuase the contacts, orgs, and reqs tables are all different routes but share the same component.
  const [modalClosedWithSave, setModalClosedWithSave] = useState(false);

  const {
    data: response,
    refetch,
    isError,
    error,
    isLoading,
    isFetching,
  } = useCustomQuery<APIList>({
    url: `/lists/${listId}`,
    params: generateListDetailsQueryParams({
      stage: stageQueryParam as string,
      type,
      queryParams,
      ignoreList: ['entityType'],
    }),
    enabled: !!listId && !!type && !isModalOpen,
  });

  const { data: list, pages } = response ?? {};
  const { listItems = [] } = list ?? {};

  const listItemsForSelection = useMemo(
    () =>
      listItems.map((listItem) => ({
        id: listItem.listItemId!,
        isInList: false,
        disabled: false,
      })),
    [listItems],
  );
  const {
    selectionState,
    clearSelectedItems,
    handleSelectionChange,
    ...checkboxProps
  } = useTableSelection(listItemsForSelection);

  usePageTitle(`${response?.data?.description}`);
  useUpdateListCounts({
    response,
    hasRouteChanged,
    setListCounts,
    modalClosedWithSave,
    isFetching,
  });
  useClearSelectedItems({ clearSelectedItems, hasRouteChanged, isModalOpen });
  useResetModalClosedWithSave({
    modalClosedWithSave,
    setModalClosedWithSave,
    isFetching,
  });

  const { mutate: deleteListItem, isPending: isDeleteItemPending } =
    useCustomMutation({
      onSuccess: () => {
        refetch();
      },
      onSuccessMessage: `${type && type.replace(/s(?=[^s]*$)/, '')} removed from list`,
      method: 'delete',
    });

  const { mutate: editListItem } = useCustomMutation({
    onSuccess: () => {
      refetch();
    },
    onSuccessMessage: 'list updated successfully',
    method: 'patch',
  });

  const closeAddEntityModal = (
    entityType: PageTypes,
    resetParams = false,
    successfulSave: boolean,
  ) => {
    const baseRouterQueryString = !resetParams
      ? objectToQueryString(baseRouteQueryParams)
      : null;

    if (successfulSave) {
      setModalClosedWithSave(true);
    }

    navigate(
      `/lists/${listId}/${entityType}${baseRouterQueryString ? `?${baseRouterQueryString}` : ''}`,
    );
  };

  async function removeListItem(
    event: React.MouseEvent,
    listItemId: string | null,
  ) {
    event.stopPropagation();

    if (!listItemId) {
      return;
    }

    // TODO CRM-631 Instead of directly mutating this, we need to use the setQuery function from react-query this could save us a round trip and make the table feel a lot snappier
    // if (response?.data?.listItems) {
    //   response.data.listItems = response?.data?.listItems.filter(
    //     (v) => v.listItemId !== listItemId,
    //   );
    // }

    await deleteListItem({
      url: `/lists/${listId}/items/${listItemId}`,
    });
  }

  async function updateListItem(stage: string, listItem: ListItem) {
    const updatedListItem = { ...listItem, stage };

    // TODO CRM-631 We need to use the setQuery function from react-query this could save us a round trip and make the table feel a lot snappier

    await editListItem({
      url: `/lists/${listId}/items/${listItem.listItemId}`,
      body: updatedListItem,
    });
  }

  if (!type) {
    navigate(`/lists/${listId}/contacts`);
  }

  if (error) {
    return (
      <EmptyState
        error={isError}
        icon={WarningIcon}
        title={error.message ?? 'Error'}
        link={
          <Button onClick={() => window.location.reload()} className='blue'>
            Refresh Page
          </Button>
        }
      />
    );
  }

  const ListTable =
    type === PageTypes.Contacts
      ? ListContactsTable
      : type === PageTypes.Organizations
        ? ListOrganizationsTable
        : ListRequisitionsTable;

  const checkboxPropsForTable =
    type === PageTypes.Requisitions
      ? {
          selectionState,
          clearSelectedItems,
          handleSelectionChange,
          ...checkboxProps,
        }
      : undefined;

  return (
    <>
      <ContentContainer>
        <ListAddForms
          isLoading={isLoading}
          title={`${response?.data?.description}`}
          subLabel={response?.data.memo ?? ''}
          expiresAtDate={response?.data?.expiresAt}
          buttonNames={[
            ...(type === 'contacts' && queryParams.showBulkEmail
              ? [
                  {
                    entity: 'email',
                    label: 'Email Contacts',
                    clickHandler: () => {
                      setShowEmailContactsModal(true);
                    },
                    hideIcon: true,
                    variant: 'ghost',
                  },
                ]
              : []),
            {
              entity: 'edit',
              label: 'Edit',
              clickHandler: () => {
                setShowEditModal(true);
              },
              hideIcon: true,
              variant: 'default',
            },
            {
              entity: 'contact',
              label: 'Contact',
              hideIcon: false,
              clickHandler: () => {
                setBaseRouteQueryParams(queryParams);
                setQueryParams({ entityType: PageTypes.Contacts });
                clearSelectedItems();
              },
            },
            {
              entity: 'organization',
              label: 'Organization',
              hideIcon: false,
              clickHandler: () => {
                setBaseRouteQueryParams(queryParams);
                setQueryParams({ entityType: PageTypes.Organizations });
                clearSelectedItems();
              },
            },
            {
              entity: 'requisition',
              label: 'Requisition',
              hideIcon: false,
              clickHandler: () => {
                setBaseRouteQueryParams(queryParams);
                setQueryParams({ entityType: PageTypes.Requisitions });
                clearSelectedItems();
              },
            },
          ]}
        />
        <ContentInnerContainer>
          <PageContent
            contentType={PageTypes.ListDetails}
            data={listItems}
            pages={pages}
            isLoading={
              isLoading ||
              (hasRouteChanged && isFetching) ||
              (modalClosedWithSave && isFetching)
            }
            handleRowClick={(row, event) => {
              if (row.listItemId) {
                handleSelectionChange(row.listItemId, event.shiftKey);
              }
            }}
            FiltersComponent={ListDetailsFilters}
            filtersProps={{
              contactCount: listCounts?.contactCount ?? 0,
              orgCount: listCounts?.orgCount ?? 0,
              reqCount: listCounts?.jobCount ?? 0,
              selectionState: selectionState,
              clearSelectedItems: clearSelectedItems,
              isBulkActionDisabled: selectionState.newlySelected.size === 0,
              isLoading: isLoading,
            }}
            TableComponent={ListTable}
            tableProps={{
              updateListItem,
              removeListItem,
              isDeleteItemPending: isDeleteItemPending,
              handleEntityClick: (entityId: string) => {
                const queryParamsString =
                  removeTabsParamsAndReturnQueryString(queryParams);
                navigate(
                  `/lists/${listId}/${type}/${entityId}?${queryParamsString ?? ''}`,
                );
              },
            }}
            isFetchingNewTableData={isFetching}
            checkboxProps={checkboxPropsForTable}
            hideRemoveFiltersButton
          />
        </ContentInnerContainer>
      </ContentContainer>

      {entityType && (
        <AddEntityModal
          isOpen
          handleClose={closeAddEntityModal}
          entityType={entityType as PageTypes}
          setListCounts={setListCounts}
        />
      )}

      {type === 'contacts' && entityId && (
        <ContactView
          isOpen={!!entityId}
          contactId={entityId}
          onClose={() => {
            const queryParamsString = resetPageNumber(queryParams);
            if (entityId) {
              navigate(
                `/lists/${listId}/contacts${queryParamsString ? `?${queryParamsString}` : ''}`,
                { replace: true },
              );
            }
          }}
          onContactSave={refetch}
          tabId={tabId}
          panelBaseRoute={`/lists/${listId}/contacts`}
        />
      )}
      {type === 'organizations' && entityId && (
        <OrganizationView
          isOpen={!!entityId}
          organizationId={entityId}
          onClose={() => {
            const queryParamsString =
              removeTabsParamsAndReturnQueryString(queryParams);

            if (entityId) {
              navigate(
                `/lists/${listId}/organizations${queryParamsString ? `?${queryParamsString}` : ''}`,
                { replace: true },
              );
            }
          }}
          tabId={tabId}
          panelBaseRoute={`/lists/${listId}/organizations`}
          invalidatePath={`/lists/${listId}`}
        />
      )}
      {type === 'requisitions' && entityId && (
        <RequisitionView
          isOpen={!!entityId}
          requisitionId={entityId}
          onClose={() => {
            const queryParamsString =
              removeTabsParamsAndReturnQueryString(queryParams);
            navigate(
              `/lists/${listId}/requisitions${queryParamsString ? `?${queryParamsString}` : ''}`,
              { replace: true },
            );
          }}
          onRequisitionSave={refetch}
          tabId={tabId}
          panelBaseRoute={`/lists/${listId}/requisitions`}
        />
      )}

      {checkboxProps && type === 'requisitions' && (
        <ListDetailsBulkActions
          type={type}
          selectionState={selectionState}
          listSize={listCounts?.jobCount as number}
          listId={listId || ''}
          clearSelectedItems={clearSelectedItems}
        />
      )}

      <AlertDialog
        open={showEditModal}
        onOpenChange={() => {
          setShowEditModal(false);
          refetch();
        }}
      >
        <AlertDialogContent className='screen-fill overflow-auto bg-white !p-0'>
          <AddList
            onSave={() => {
              setShowEditModal(false);
              refetch();
            }}
            onCancel={() => setShowEditModal(false)}
            isModal
          />
        </AlertDialogContent>
      </AlertDialog>
      <EmailContactsModal
        setIsBulkActionModalOpen={setShowEmailContactsModal}
        open={showEmailContactsModal}
        itemsCount={listItems?.length ?? 0}
        listId={listId}
      />
    </>
  );
}

export default ListDetails;

function useUpdateListCounts({
  response,
  hasRouteChanged,
  setListCounts,
  modalClosedWithSave,
  isFetching,
}: {
  response: any;
  hasRouteChanged: boolean;
  setListCounts: React.Dispatch<
    React.SetStateAction<{
      contactCount: number;
      orgCount: number;
      jobCount: number;
    } | null>
  >;
  modalClosedWithSave: boolean;
  isFetching: boolean;
}) {
  useEffect(() => {
    if (response && !hasRouteChanged && !modalClosedWithSave && !isFetching) {
      setListCounts({
        contactCount: response?.data?.contactCount ?? 0,
        orgCount: response?.data?.organizationCount ?? 0,
        jobCount: response?.data?.jobCount ?? 0,
      });
    }
  }, [
    response,
    hasRouteChanged,
    setListCounts,
    modalClosedWithSave,
    isFetching,
  ]);

  return null;
}

function useClearSelectedItems({
  clearSelectedItems,
  hasRouteChanged,
  isModalOpen,
}: {
  clearSelectedItems: () => void;
  hasRouteChanged: boolean;
  isModalOpen: boolean;
}) {
  useEffect(() => {
    if (isModalOpen || hasRouteChanged) {
      clearSelectedItems();
    }
  }, [clearSelectedItems, hasRouteChanged, isModalOpen]);
}

// necessary to know this information to set loading state on the table becuase the contacts, orgs, and reqs tables are all different routes but share the same component.
// this useEffect hook just resets the modalClosedWithSave state after the new data has been fetched.
function useResetModalClosedWithSave({
  modalClosedWithSave,
  setModalClosedWithSave,
  isFetching,
}: {
  modalClosedWithSave: boolean;
  setModalClosedWithSave: React.Dispatch<React.SetStateAction<boolean>>;
  isFetching: boolean;
}) {
  useEffect(() => {
    if (modalClosedWithSave) {
      if (!isFetching) {
        setModalClosedWithSave(false);
      }
    }
  }, [modalClosedWithSave, setModalClosedWithSave, isFetching]);
}
