import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { BaseTable, Column } from '../../components/common/BaseTable';
import { Button } from '../../components/common/Button';
import {
  CopyIcon,
  DollarSignIcon,
  IndeedIcon,
} from '../../components/common/Icons';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { ListItem } from '../../types/lists';
import { APIRequisition } from '../../types/requisitions';
import { CheckboxProps } from '../../types/table';
import {
  formatCurrency,
  formatDateString,
  setFieldIfEmpty,
} from '../../utils/helpers';

interface ListRequisitionsTableProps {
  data: ListItem[];
  handleRowClick: (value: ListItem) => void;
  className?: string;
  removeListItem: (
    event: React.MouseEvent<HTMLButtonElement>,
    listItemId: string | null,
  ) => void;
  handleEntityClick: (id: string) => void;
  isDeleteItemPending: boolean;
  checkboxProps: CheckboxProps;
}

export function ListRequisitionsTable({
  data,
  handleRowClick,
  className,
  removeListItem,
  isDeleteItemPending,
  handleEntityClick,
  checkboxProps,
}: ListRequisitionsTableProps) {
  const { users } = useGlobalState();
  const [copyJobId, setCopyJobId] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();
  useLocation();
  const {
    data: response,
    isError,
    isLoading,
  } = useCustomQuery<APIRequisition>({
    url: `/jobs/${copyJobId}`,
    enabled: !!copyJobId,
  });

  React.useEffect(() => {
    if (copyJobId && !isError && !isLoading && response) {
      window.localStorage.setItem('job', JSON.stringify(response?.data));

      if (id) {
        window.localStorage.setItem('listId', id);
      }

      navigate(`/requisitions/add`);
    }
  }, [id, isError, copyJobId, isLoading, navigate, response]);

  const columns: Column<ListItem>[] = [
    {
      label: '',
      key: 'copy',
      sortEnabled: false,
      className: 'w-[3rem]',
      render: (item) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setCopyJobId(item?.tableId || '123');
            }}
            className='hover:text-neutral-50'
          >
            <CopyIcon />
          </div>
        );
      },
    },
    {
      label: 'Status',
      key: 'status',
      sortEnabled: true,
      className: 'w-[10rem]',
      render: (item) => (
        <div className='flex items-center'>
          {setFieldIfEmpty(item.status)}
          {item.status === 'Available' && (
            <>
              {(item.indeedSponsored === 1 || item.indeedSponsored === 2) && (
                <span className='ml-2'>
                  <IndeedIcon />
                </span>
              )}
              {item.indeedSponsored === 2 && (
                <span className='mt-0.5'>
                  <DollarSignIcon />
                </span>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      label: 'Pipeline',
      key: 'numberOfCandidatesInInbox',
      sortEnabled: true,
      className: 'w-[12rem]',
      render: (item) => (
        <div className='flex items-center'>
          <span
            className={`mr-2 flex h-5 w-5 items-center justify-center rounded-full ${item.numberOfCandidatesInInbox != 0 ? 'bg-error text-sm text-white' : 'bg-transparent text-black'} font-medium`}
          >
            {`${item.numberOfCandidatesInInbox}`}
          </span>
          <span className='text-nowrap'>{`Inbox, ${item?.totalCandidates} Total`}</span>
        </div>
      ),
    },
    {
      label: 'Job Title',
      key: 'jobTitle',
      sortEnabled: true,
      className: 'w-[15rem]',
      render: (item) => {
        return (
          <span
            onClick={(e) => {
              e.stopPropagation();

              if (item.tableId) {
                handleEntityClick(item.tableId);
              }
            }}
            className='text-link hover:text-[#167dff] hover:underline'
          >
            {setFieldIfEmpty(item.jobTitle)}
          </span>
        );
      },
    },
    {
      label: 'Organization',
      key: 'organizationName',
      sortEnabled: true,
      className: 'w-[15rem]',
      render: (item) => setFieldIfEmpty(item.organizationName),
    },
    {
      label: 'Date',
      key: 'insertDate',
      sortEnabled: true,
      className: 'w-[10rem]',
      render: (item) => (
        <div className='flex'>
          {item.insertDate
            ? formatDateString(item.insertDate)
            : setFieldIfEmpty(item.insertDate)}
        </div>
      ),
    },
    {
      label: 'City/State',
      key: 'city',
      sortEnabled: true,
      className: 'w-[12rem]',
      render: (item) => {
        const region = item.city ? `${item.city}, ${item.state}` : item.state;
        return setFieldIfEmpty(region);
      },
    },
    {
      label: 'User',
      key: 'userId',
      sortEnabled: false,
      className: 'w-[10rem]',
      render: (item) =>
        users?.find((u) => u.userId === item.userId)?.fullName || '-',
    },
    {
      label: 'Profession',
      key: 'profession',
      sortEnabled: false,
      className: 'w-[10rem]',
      render: (item) => setFieldIfEmpty(item.profession),
    },
    {
      label: 'Salary',
      key: 'salaryBottom',
      sortEnabled: true,
      className: 'w-[12rem]',
      render: (item) => {
        const salaryBottom = item.salaryBottom
          ? formatCurrency(Number(item.salaryBottom))
          : null;
        const salaryTop = item.salaryTop
          ? formatCurrency(Number(item.salaryTop))
          : null;

        if (salaryBottom && salaryTop) return `${salaryBottom} - ${salaryTop}`;
        if (salaryBottom) return salaryBottom;
        if (salaryTop) return salaryTop;
        return '-';
      },
    },
    {
      label: 'Action',
      key: 'action',
      sortEnabled: false,
      className: 'w-[8rem]',
      render: (item) => (
        <Button
          disabled={isDeleteItemPending}
          onClick={(e) => removeListItem(e, item.listItemId)}
          className='mx-auto flex h-6 items-center gap-2 self-start px-2 py-1 font-bold text-primary underline'
          variant='ghost'
        >
          Remove
        </Button>
      ),
    },
  ];

  return (
    <>
      <BaseTable
        data={data}
        columns={columns}
        idKey='listItemId'
        defaultSortKey='insertDate'
        handleRowClick={handleRowClick}
        className={className}
        checkboxProps={checkboxProps}
      />
    </>
  );
}

export default ListRequisitionsTable;
