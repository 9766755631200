import { useEffect, useMemo, useState } from 'react';

import { Button } from '../../components/common/Button';
import Modal from '../../components/common/ModalRadix';
import Select from '../../components/common/SelectNew';
import { Checkbox } from '../../components/ui/checkbox';
import { Label } from '../../components/ui/label';
import { Textarea } from '../../components/ui/textarea';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { APIPipeline } from '../../types/pipeline';
import { capitalizeName, formatDateToBackend } from '../../utils/helpers';
import { sortJobContactPipelinePositions } from '../../utils/pipeline';

const outOfProcessOptions = [
  { value: 'Not Qualified', label: 'Not Qualified' },
  {
    value: 'Viable, Not Interested',
    label: 'Viable, Not Interested',
  },
  { value: 'Turned Down', label: 'Turned Down' },
  { value: 'Offer Declined', label: 'Offer Declined' },
  { value: 'In Another Pipeline', label: 'In Another Pipeline' },
];

export function OutOfProcessModal({
  outOfProcessId,
  setDropdownId,
  setOutOfProcessId,
  pipelineData,
  setPipelineData,
  refetchPipelineData,
  setIsDraggingEnabled,
}: {
  outOfProcessId: string | null;
  setDropdownId: React.Dispatch<React.SetStateAction<string | null>>;
  setOutOfProcessId: React.Dispatch<React.SetStateAction<string | null>>;
  pipelineData: APIPipeline | undefined;
  setPipelineData: React.Dispatch<
    React.SetStateAction<APIPipeline | undefined>
  >;
  setIsDraggingEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  refetchPipelineData: () => void;
}) {
  const [outOfProcessOption, setOutOfProcessOption] = useState('');
  const [outOfProcessNote, setOutOfProcessNote] = useState('');
  const [isStageConfirmed, setIsStageConfirmed] = useState(false);
  const [lastStageId, setLastStageId] = useState('');
  const [noteRequired, setNoteRequired] = useState(false);

  const contact = useMemo(() => {
    return (pipelineData?.contacts ?? []).find(
      (c) => c.contactId === outOfProcessId,
    );
  }, [pipelineData, outOfProcessId]);

  const jobContactPipelinePosition = useMemo(() => {
    return pipelineData?.jobContactPipelinePositions.find(
      (j) => j.contactId === contact?.contactId,
    );
  }, [pipelineData, contact]);

  const stages = useMemo(() => {
    return (
      pipelineData?.pipelinePositions.map((j) => ({
        value: j.pipelinePositionId,
        label: j.pipelinePosition,
      })) ?? []
    );
  }, [pipelineData]);

  const { isPending, mutate: submitOutOfProcess } = useCustomMutation({
    method: 'patch',
    onSuccess: () => {
      refetchPipelineData();
      closeModal();
    },
    onSuccessMessage: 'Candidate moved out of process successfully!',
    onError: (error) => {
      console.error(error);

      refetchPipelineData();
    },
  });

  function handleSubmit() {
    const date = formatDateToBackend(new Date());

    if (!pipelineData) return null;

    const updatedPipelinePositions =
      pipelineData?.jobContactPipelinePositions.map((position) =>
        position.jobContactPipelinePositionId ===
        jobContactPipelinePosition?.jobContactPipelinePositionId
          ? {
              ...position,
              pipelinePosition: pipelineData.pipelinePositions.find(
                (p) => p.pipelinePositionId === lastStageId,
              )?.pipelinePosition as string,
              pipelinePositionId: lastStageId,
              pipelinePositionOrder: pipelineData.pipelinePositions.find(
                (p) => p.pipelinePositionId === lastStageId,
              )?.pipelinePositionOrder as number,
              outOfProcess: true,
              outOfProcessReason: outOfProcessOption,
              notes: outOfProcessNote,
              updateDate: date,
            }
          : position,
      );

    setPipelineData((prev) => ({
      ...prev!,
      jobContactPipelinePositions: sortJobContactPipelinePositions(
        updatedPipelinePositions ?? [],
      ),
    }));

    submitOutOfProcess({
      url: `/jobcontactpipelinepositions/${jobContactPipelinePosition?.jobContactPipelinePositionId}`,
      body: {
        ...jobContactPipelinePosition,
        pipelinePosition: pipelineData.pipelinePositions.find(
          (p) => p.pipelinePositionId === lastStageId,
        )?.pipelinePosition as string,
        pipelinePositionId: lastStageId,
        pipelinePositionOrder: pipelineData.pipelinePositions.find(
          (p) => p.pipelinePositionId === lastStageId,
        )?.pipelinePositionOrder as number,
        outOfProcess: true,
        outOfProcessReason: outOfProcessOption,
        notes: outOfProcessNote,
        updateDate: date,
      },
    });
  }

  function closeModal() {
    setDropdownId(null);
    setOutOfProcessId(null);
    setIsDraggingEnabled(true);
  }

  const isOpen = !!outOfProcessId && !!pipelineData;

  useEffect(() => {
    if (!isOpen) {
      setOutOfProcessOption('');
      setOutOfProcessNote('');
      setIsStageConfirmed(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (jobContactPipelinePosition) {
      setOutOfProcessNote(jobContactPipelinePosition?.notes ?? '');
      setLastStageId(jobContactPipelinePosition?.pipelinePositionId ?? '');

      if (jobContactPipelinePosition.pipelinePositionOrder) {
        setNoteRequired(jobContactPipelinePosition.pipelinePositionOrder >= 5);
      }
    }
  }, [jobContactPipelinePosition]);
  console.log(`isOpen!!`, isOpen);
  return (
    <Modal open={isOpen} onOpenChange={closeModal}>
      <Modal.Content title='Move candidate out of process'>
        <div className='grid auto-rows-auto gap-8'>
          <div className='font-medium'>
            <span className=' font-normal'>Candidate selected:</span>{' '}
            <span>
              {capitalizeName(`${contact?.firstName} ${contact?.lastName}`)}
            </span>
          </div>
          <div className='flex items-baseline gap-8 pb-12'>
            <Select
              required
              label='Reason'
              value={outOfProcessOption}
              onChange={(value) => setOutOfProcessOption(value)}
              options={outOfProcessOptions}
            />
            <div className='flex flex-grow flex-col gap-2'>
              <Label>
                Note
                {noteRequired && <span className=' text-error'>*</span>}
              </Label>
              <Textarea
                placeholder={`Add a note ${noteRequired ? '' : '(optional)'}`}
                value={outOfProcessNote}
                onChange={(e) => setOutOfProcessNote(e.target.value)}
                className='placeholder:text-neutral'
                required={noteRequired}
              />
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <div className='flex items-center gap-2'>
              <Checkbox
                id='confirmation-step'
                checked={isStageConfirmed}
                onCheckedChange={() => setIsStageConfirmed(!isStageConfirmed)}
              />
              <label
                htmlFor='confirmation-step'
                className='relative flex items-center justify-center font-normal'
              >
                <span>
                  I confirm that this candidate is moving out of process at the
                  below stage
                </span>
                <div className='absolute -right-2 -top-[6px] text-error'>*</div>
              </label>
            </div>
            <Select
              label='Stage'
              value={lastStageId}
              disabled={isStageConfirmed}
              onChange={(value) => setLastStageId(value)}
              options={stages}
            />
          </div>
          <div className='flex justify-between'>
            <Button variant='secondary' onClick={closeModal}>
              Cancel
            </Button>
            <Button
              disabled={
                !outOfProcessOption ||
                !isStageConfirmed ||
                isPending ||
                (!outOfProcessNote && noteRequired)
              }
              isLoading={isPending}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
