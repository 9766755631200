import { E164Number } from 'libphonenumber-js/types';
import { LoaderCircleIcon, MessageCircleIcon } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { CommonSpinner } from '../../components/common';
import { ActivityView } from '../../components/common/ActivityView';
import { AttachmentsTable } from '../../components/common/AttachmentsPivot';
import { Button } from '../../components/common/Button';
import ConversationView from '../../components/common/ConversationView';
import GoogleMapsView from '../../components/common/GoogleMapsView';
import {
  CopyIcon,
  PhoneIcon,
  IndeedIcon,
  DollarSignIcon,
} from '../../components/common/Icons';
import { MultiSelectDropdownFilter } from '../../components/common/MultiSelectDropdownFilter';
import NotesView from '../../components/common/NotesView';
import { Pagination } from '../../components/common/Pagination';
import PhoneNumber from '../../components/common/PhoneNumber';
import {
  Body,
  Header,
  HeaderCell,
  Row,
  RowCell,
  Table,
} from '../../components/common/Table';
import { ListsDetail } from '../../components/common/listsDetail';
import {
  AlertDialogHeader,
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogPortal,
} from '../../components/ui/alert-dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/ui/dropdown-menu';
import { useGlobalState } from '../../context/GlobalContext';
import { useRingCentralContext } from '../../context/RingCentralContext';
import { toast } from '../../hooks/use-toast';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useTabQueryParams } from '../../hooks/useTabQueryParams';
import colors from '../../styles/colors';
import { APIContact } from '../../types/contacts';
import { APIOrganization } from '../../types/organizations';
import { APIRequisition } from '../../types/requisitions';
import {
  setFieldIfEmpty,
  setRegion,
  formatDateString,
} from '../../utils/helpers';
import { generatePhoneLink } from '../../utils/phone';
import { TabsParams } from '../../utils/tabs';
import { AddContact } from '../contacts/AddContact';
import { ContactDetails } from '../contacts/ContactDetails';
import { status } from '../requisitions/inputs';
import { OrganizationDetails } from './OrganizationDetails';

export function OrganizationTabs({
  organization,
  tabId,
  panelBaseRoute,
  invalidatePath,
}: {
  organization: APIOrganization;
  tabId: string | undefined;
  panelBaseRoute?: string;
  invalidatePath?: string;
}) {
  const navigate = useNavigate();
  const { me } = useGlobalState();

  const { queryParamsString } = useQueryParams();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const organizationTabs = useOrganizationTabs({
    organization,
    invalidatePath,
  });

  const [activeTabId, setActiveTabId] = useState(
    tabId ?? organizationTabs[0].id,
  );

  const activePanel = organizationTabs.find((tab) => tab.id === activeTabId);

  const handleTabClick = (tabId: string) => {
    setActiveTabId(tabId);
    console.log('test');
    if (panelBaseRoute) {
      if (tabId === organizationTabs[0].id) {
        navigate(
          `${panelBaseRoute}/${organization.organizationId}${queryParamsString ?? ''}`,
          { replace: true },
        );
      } else {
        navigate(
          `${panelBaseRoute}/${organization.organizationId}/${tabId}${queryParamsString ?? ''}`,
          { replace: true },
        );
      }
    }
  };

  useEffect(() => {
    if (!containerRef.current) return;

    containerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [activeTabId]);

  return (
    <div className='grid size-full grid-cols-[1fr] grid-rows-[auto,1fr] justify-center overflow-hidden border-neutral-40'>
      <div
        role='tablist'
        className='flex w-full justify-center gap-6 overflow-x-auto pb-2 pt-4'
      >
        {organizationTabs
          .filter((tab) => tab.tabName !== `${me?.admin ? '' : 'Attachments'}`)
          .map((tab) => {
            const isActive = tab.id === activeTabId;

            return (
              <button
                key={tab.id}
                role='tab'
                id={tab.id}
                aria-selected={isActive}
                className='relative h-fit w-fit shrink-0'
                onClick={() => handleTabClick(tab.id)}
              >
                <span
                  className={`${isActive ? 'after:absolute after:-bottom-[8px] after:block after:h-[3px] after:w-0 after:animate-expand-link after:bg-dark-blue' : ''}`}
                >
                  {tab.tabName}
                </span>
              </button>
            );
          })}
      </div>
      <div
        role='tabpanel'
        className='overflow-auto border-t border-neutral-40 pt-4'
        aria-labelledby={activeTabId}
        ref={containerRef}
      >
        {activePanel?.component}
      </div>
    </div>
  );
}

function useOrganizationTabs({
  organization,
  invalidatePath,
}: {
  organization: APIOrganization;
  invalidatePath?: string;
}) {
  return [
    {
      tabName: 'Details',
      component: (
        <OrganizationDetails
          organization={organization}
          invalidatePath={invalidatePath}
        />
      ),
      id: 'details',
    },
    {
      tabName: 'Contacts',
      component: <ContactsTable organization={organization} />,
      id: 'contacts',
    },
    {
      tabName: 'Activities',
      component: (
        <ActivityView
          url='/organizationactivities'
          params={{ organizationId: organization.organizationId }}
          paginationKey={TabsParams.ActivitiesTab}
        />
      ),
      id: 'activities',
    },
    {
      tabName: 'Conversation',
      component: (
        <ConversationView
          url={`/organizations/${organization.organizationId}/comms`}
          typeKey={TabsParams.ConversationsTabType}
          paginationKey={TabsParams.ConversationsTabPage}
        />
      ),
      id: 'conversation',
    },
    {
      tabName: 'Notes',
      component: (
        <NotesView
          entityId={organization.organizationId}
          entityName='organizations'
          paginationKey={TabsParams.NotesTab}
        />
      ),
      id: 'notes',
    },
    {
      tabName: 'Attachments',
      component: (
        <AttachmentsTable
          tableId={organization.organizationId}
          tableName={'organizations'}
          url={`/attachments/${organization.organizationId}`}
        />
      ),
      id: 'attachments',
    },
    {
      tabName: 'Requistions',
      component: <RequisitionsTable organization={organization} />,
      id: 'requistions',
    },
    {
      tabName: 'Lists',
      component: (
        <ListsDetail
          page='organizations'
          listId={organization.organizationId}
        />
      ),
      id: 'lists',
    },
    {
      tabName: 'Map',
      component: (
        <GoogleMapsView
          url={'/organizations/' + organization.organizationId + '/map'}
          primaryPinKey='organizations'
        />
      ),
      id: 'map',
    },
  ];
}

const PhoneCallAndMessage = ({
  contact,
}: {
  phone: E164Number | null;
  phoneExt: string | null;
  contact: APIContact;
}) => {
  const { fetchMatchedContacts } = useRingCentralContext();

  const [isPhoneLoading, setIsPhoneLoading] = useState(false);

  const primaryPhoneLink = useMemo(() => {
    return generatePhoneLink(contact?.phone ?? '');
  }, [contact]);

  const workPhoneLink = useMemo(() => {
    return generatePhoneLink(contact?.workPhone ?? '');
  }, [contact]);

  const handlePhoneClick = async (phone: string) => {
    setIsPhoneLoading(true);
    const iframe: any = document.querySelector('#rc-widget-adapter-frame');

    await fetchMatchedContacts([phone]);

    if (typeof (window as any).RCAdapter !== 'undefined') {
      (window as any).RCAdapter.setMinimized(false);
    }

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: 'rc-adapter-new-call',
          phoneNumber: phone,
          toCall: true,
        },
        '*',
      );

      setIsPhoneLoading(false);
    } else {
      console.error('RingCentral widget iframe not found or not accessible');
    }
  };

  const handleSmsClick = async (phone: string) => {
    const iframe: any = document.querySelector('#rc-widget-adapter-frame');

    await fetchMatchedContacts([phone]);

    if (typeof (window as any).RCAdapter !== 'undefined') {
      (window as any).RCAdapter.setMinimized(false);
    }

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: 'rc-adapter-new-sms',
          phoneNumber: phone,
          conversation: true,
        },
        '*',
      );

      setIsPhoneLoading(false);
    } else {
      console.error('RingCentral widget iframe not found or not accessible');
    }
  };

  if (!contact?.phone && !contact?.workPhone) {
    return <span> - </span>;
  }

  return (
    <>
      {contact.phone && contact.workPhone ? (
        <>
          <DropdownMenu modal={false}>
            <DropdownMenuTrigger>
              <PhoneIcon size={20} color={colors.primary[70]} />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup>
                {contact?.phone && (
                  <DropdownMenuItem
                    className='flex flex-col items-start gap-0'
                    onClick={() => {
                      handlePhoneClick(primaryPhoneLink);
                    }}
                    disabled={isPhoneLoading}
                  >
                    <span className='text-xs'>Primary phone</span>
                    <PhoneNumber
                      phoneNumber={contact.phone}
                      extension={contact?.phoneExt}
                    />
                  </DropdownMenuItem>
                )}
                {contact?.workPhone && (
                  <DropdownMenuItem
                    className='flex flex-col items-start gap-0'
                    onClick={() => {
                      handlePhoneClick(workPhoneLink);
                    }}
                    disabled={isPhoneLoading}
                  >
                    <span className='text-xs'>Work phone</span>
                    <PhoneNumber
                      phoneNumber={contact.workPhone}
                      extension={contact?.workExt}
                    />
                  </DropdownMenuItem>
                )}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenu modal={false}>
            <DropdownMenuTrigger>
              <MessageCircleIcon
                strokeWidth={1.5}
                size={20}
                color={colors.primary[70]}
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup>
                {contact?.phone && (
                  <DropdownMenuItem
                    className='flex flex-col items-start gap-0'
                    onClick={() => {
                      handleSmsClick(primaryPhoneLink);
                    }}
                    disabled={isPhoneLoading}
                  >
                    <span className='text-xs'>Primary phone</span>
                    <PhoneNumber
                      phoneNumber={contact.phone}
                      extension={contact?.phoneExt}
                    />
                  </DropdownMenuItem>
                )}
                {contact?.workPhone && (
                  <DropdownMenuItem
                    className='flex flex-col items-start gap-0'
                    onClick={() => {
                      handleSmsClick(workPhoneLink);
                    }}
                    disabled={isPhoneLoading}
                  >
                    <span className='text-xs'>Work phone</span>
                    <PhoneNumber
                      phoneNumber={contact.workPhone}
                      extension={contact?.workExt}
                    />
                  </DropdownMenuItem>
                )}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </>
      ) : (
        <>
          <div className='flex items-center gap-2'>
            <button
              className='flex flex-col items-center text-sm text-primary-70'
              onClick={() => {
                handlePhoneClick(generatePhoneLink(contact.phone ?? ''));
              }}
            >
              <div className=''>
                {isPhoneLoading ? (
                  <LoaderCircleIcon size={20} className='animate-spin' />
                ) : (
                  <PhoneIcon size={20} />
                )}
              </div>
            </button>

            <button
              className='flex flex-col items-center text-sm text-primary-70'
              onClick={() => {
                handleSmsClick(generatePhoneLink(contact.phone ?? ''));
              }}
            >
              <div className=''>
                <MessageCircleIcon strokeWidth={1.5} size={20} />
              </div>
            </button>
          </div>
        </>
      )}
      <PhoneNumber phoneNumber={contact.phone} extension={contact.phoneExt} />
    </>
  );
};

function ContactsTable({ organization }: { organization: APIOrganization }) {
  const paginationKey = TabsParams.ContactsTabTablePage;
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [addNewContact, setAddNewContact] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined,
  );
  const [currentContact, setCurrentContact] = useState<APIContact | null>();
  const containerRef = useRef<HTMLDivElement>(null);

  const { queryParams } = useQueryParams();

  const { data, isLoading } = useCustomQuery<APIContact[]>({
    url: '/contacts',
    params: {
      organizationId: organization.organizationId,
      ...(queryParams[paginationKey]
        ? { pageNumber: queryParams[paginationKey] }
        : {}),
    },
  });

  const contacts = data?.data;
  const pages = data?.pages;

  const handleContactSave = (contact: APIContact, isEdit?: boolean) => {
    setAddNewContact(false);
    setShowAddContactModal(false);
    setCurrentContact(null);

    if (isEdit && !!contacts) {
      contacts[currentIndex as number] = { ...contact };
    }
  };

  return (
    <>
      <div
        className='flex size-full flex-col gap-4 overflow-hidden'
        ref={containerRef}
      >
        <div className='flex w-full flex-row-reverse items-center justify-between border-b pb-4'>
          <Button
            onClick={() => {
              setAddNewContact(true);
              setShowAddContactModal(true);
            }}
          >
            + Add Contact
          </Button>
        </div>
        {isLoading && (
          <div className='flex size-full items-center justify-center gap-4'>
            <CommonSpinner size='md' />{' '}
          </div>
        )}

        {!isLoading && contacts?.length ? (
          <div className='flex size-full flex-col justify-between gap-4 overflow-hidden'>
            <div className='w-full overflow-auto'>
              <Table
                id='activities-table'
                classNames={{
                  table: 'table table border border-neutral-40',
                }}
              >
                <Header>
                  <HeaderCell>Name</HeaderCell>
                  <HeaderCell>Title</HeaderCell>
                  <HeaderCell>Email</HeaderCell>
                  <HeaderCell>Phone</HeaderCell>
                  <HeaderCell></HeaderCell>
                </Header>
                <Body>
                  {contacts.map((c, i) => {
                    return (
                      <Row key={c.contactId}>
                        <RowCell scope='row' style={{ borderLeftWidth: 0 }}>
                          <Link to={`/contacts/${c.contactId}`}>
                            {setFieldIfEmpty(`${c.firstName} ${c.lastName}`)}
                          </Link>
                        </RowCell>
                        <RowCell scope='row' style={{ borderLeftWidth: 0 }}>
                          {setFieldIfEmpty(c.title)}
                        </RowCell>
                        <RowCell scope='row' style={{ borderLeftWidth: 0 }}>
                          <div className='flex items-center gap-1'>
                            {setFieldIfEmpty(c?.emailAddress)}
                            <div
                              className='cursor-pointer hover:text-neutral-50'
                              onClick={async (e) => {
                                e.stopPropagation();
                                c?.emailAddress &&
                                  navigator.clipboard.writeText(
                                    c?.emailAddress,
                                  );
                                toast({
                                  title: 'Email copied to clipboard',
                                  duration: 1500,
                                });
                              }}
                            >
                              <CopyIcon />
                            </div>
                          </div>
                        </RowCell>
                        <RowCell scope='row' style={{ borderLeftWidth: 0 }}>
                          <div className='flex items-center gap-2'>
                            <PhoneCallAndMessage
                              contact={c}
                              phone={c?.phone}
                              phoneExt={c?.phoneExt || ''}
                            />
                          </div>
                        </RowCell>
                        <RowCell>
                          <Button
                            onClick={() => {
                              setCurrentContact(c);
                              setShowAddContactModal(true);
                              setCurrentIndex(i);
                            }}
                          >
                            Edit
                          </Button>
                        </RowCell>
                      </Row>
                    );
                  })}
                </Body>
              </Table>
            </div>
            <Pagination pages={pages} paginationKey={paginationKey} />
          </div>
        ) : (
          <div className='flex w-full justify-center'>
            <span className='text-center text-xl'>No Contacts</span>
          </div>
        )}
      </div>

      <AlertDialog
        open={showAddContactModal}
        onOpenChange={() => setShowAddContactModal(false)}
      >
        <AlertDialogPortal container={containerRef as any} />
        <AlertDialogContent className='screen-fill max-h-full overflow-auto bg-white'>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {addNewContact ? 'Create Contact' : 'Update Contact'}
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogDescription>
            <div className='flex flex-col gap-4'>
              {!addNewContact && (
                <p>
                  Name, Email address and Phone Number is mandatory, Please
                  complete missing details to select this contact.
                </p>
              )}
              {addNewContact ? (
                <AddContact
                  organization={organization}
                  isUsedAsComponent
                  onCancel={() => setShowAddContactModal(false)}
                  onSubmit={handleContactSave}
                />
              ) : (
                <ContactDetails
                  isUsedAsComponent
                  onCancel={() => setShowAddContactModal(false)}
                  contact={currentContact as APIContact}
                  onContactSave={(contact) =>
                    handleContactSave(contact as APIContact, true)
                  }
                />
              )}
            </div>
          </AlertDialogDescription>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

function RequisitionsTable({
  organization,
}: {
  organization: APIOrganization;
}) {
  const paginationKey = TabsParams.RequisitionsTabTablePage;
  const requisitionStatus = TabsParams.RequisitionsTabTableStatus;

  const [copyJobId, setCopyJobId] = useState('');
  const { users } = useGlobalState();
  const navigate = useNavigate();
  const { queryParams, updateTabQueryParam, removeTabQueryParam } =
    useTabQueryParams();

  const { data, isLoading } = useCustomQuery<APIRequisition[]>({
    url: '/jobs',
    params: {
      organizationId: organization.organizationId,
      ...(queryParams[paginationKey]
        ? { pageNumber: queryParams[paginationKey] }
        : {}),
      ...(queryParams[requisitionStatus]
        ? { status: queryParams[requisitionStatus] }
        : {}),
      orderBy: 'datePosted',
      orderByDirection: 'DESC',
    },
  });

  const {
    data: response,
    isError,
    isLoading: isLoadingJobs,
  } = useCustomQuery<APIRequisition>({
    url: `/jobs/${copyJobId}`,
    enabled: !!copyJobId,
  });

  useEffect(() => {
    if (copyJobId && !isError && !isLoadingJobs && response) {
      window.localStorage.setItem(
        'job',
        JSON.stringify({
          ...response?.data,
          fromPage: 'organization',
          organizationName: organization?.organizationName,
          organizationId: organization?.organizationId,
          orgHasContract: organization?.contract,
          returnUrl: `/organizations/${organization?.organizationId}/requistions`,
        }),
      );
      navigate(`/requisitions/add`);
    }
  }, [
    copyJobId,
    isError,
    isLoadingJobs,
    navigate,
    organization?.organizationName,
    organization?.organizationId,
    organization?.contract,
    response,
  ]);

  const requisitions = data?.data;
  const pages = data?.pages;

  const handleAddRequisition = () => {
    window.localStorage.setItem(
      'job',
      JSON.stringify({
        ...response?.data,
        fromPage: 'organization',
        organizationName: organization?.organizationName,
        organizationId: organization?.organizationId,
        orgHasContract: organization?.contract,
        returnUrl: `/organizations/${organization?.organizationId}/requistions`,
      }),
    );
    navigate(`/requisitions/add`);
  };

  return (
    <>
      <div className='flex size-full flex-col gap-4 overflow-hidden'>
        <div className='flex w-full items-center justify-between border-b pb-2'>
          <MultiSelectDropdownFilter
            classNames={{ container: 'w-36 mb-2' }}
            items={status.map((s) => ({
              id: s.key as string,
              title: s.text,
            }))}
            isClearable={!!queryParams[requisitionStatus]}
            handleChange={(status) => {
              updateTabQueryParam('RequisitionsTabTableStatus', status, {
                multiple: false,
                resetPageOnChange: true,
              });
            }}
            onClear={() => {
              removeTabQueryParam('RequisitionsTabTableStatus');
            }}
            value={[queryParams[requisitionStatus] as string]}
            isChecked={!!queryParams[requisitionStatus]}
            placeholder='Status'
          />

          <Button onClick={handleAddRequisition}>+ Add Requisition</Button>
        </div>

        {isLoading && (
          <div className='flex size-full items-center justify-center gap-4'>
            <CommonSpinner size='md' />{' '}
          </div>
        )}

        {!isLoading && requisitions?.length ? (
          <div className='flex flex-col gap-2 overflow-auto'>
            <Table
              id='requisitions-table'
              classNames={{ table: 'size-full table border border-neutral-40' }}
            >
              <Header>
                <HeaderCell className='w-[2.5rem]'></HeaderCell>
                <HeaderCell>Date Posted</HeaderCell>
                <HeaderCell className='w-10'>Status</HeaderCell>
                <HeaderCell>Job Title</HeaderCell>
                <HeaderCell>City/State</HeaderCell>
                <HeaderCell>User</HeaderCell>
              </Header>
              <Body>
                {requisitions.map((r: APIRequisition, key) => {
                  const user =
                    users?.find((u) => u.userId === r.userId)?.fullName ??
                    r.userName;

                  return (
                    <Row key={key}>
                      <RowCell className='border-b-0 border-l-0 py-0'>
                        <div
                          className='cursor-pointer'
                          onClick={async (e) => {
                            e.stopPropagation();
                            setCopyJobId(r?.jobId);
                          }}
                        >
                          <CopyIcon />
                        </div>
                      </RowCell>

                      <RowCell>{formatDateString(r.datePosted)}</RowCell>

                      <RowCell className='border-b-0 border-l-0'>
                        <div className='flex items-center'>
                          <span>{setFieldIfEmpty(r.status)}</span>
                          {r.status === 'Available' && (
                            <>
                              {(r.indeedSponsored === 1 ||
                                r.indeedSponsored === 2) && (
                                <span className='ml-2'>
                                  <IndeedIcon />
                                </span>
                              )}
                              {r.indeedSponsored === 2 && (
                                <span className='mt-0.5'>
                                  <DollarSignIcon />
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </RowCell>
                      <RowCell>
                        <Link to={`/requisitions/${r.jobId}`}>
                          {setFieldIfEmpty(r.jobTitle)}
                        </Link>
                      </RowCell>
                      <RowCell>
                        {setFieldIfEmpty(setRegion(r.city, r.state))}
                      </RowCell>
                      <RowCell>{setFieldIfEmpty(user)}</RowCell>
                    </Row>
                  );
                })}
              </Body>
            </Table>
          </div>
        ) : (
          <div className='flex w-full justify-center'>
            <span className='text-center text-xl'>No requisitions</span>
          </div>
        )}
        {!!pages && pages > 1 && (
          <Pagination pages={pages} paginationKey={paginationKey} />
        )}
      </div>
    </>
  );
}
