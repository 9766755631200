import RootLayout from '../../layouts';
import { PipelineContainer } from './Pipeline';

const PipelinePage = () => {
  return (
    <RootLayout>
      <div className='h-full w-auto px-4 py-4'>
        <PipelineContainer />
      </div>
    </RootLayout>
  );
};

export default PipelinePage;
