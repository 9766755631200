import { useCustomQuery } from '../../../hooks/useCustomQuery';
import { useQueryParams } from '../../../hooks/useQueryParams';

type PipelineOverviewData = {
  availablePipelines: number;
  contactedOrganizations: number;
  jobOrders: number;
  contactsAdded: number;
  applicationsReceived: number;
  phoneInterviews: number;
  f2fInterviews: number;
  placements: number;
  replacements: number;
};

const pipelineOverviewLabelMap = {
  availablePipelines: 'Available pipelines',
  contactedOrganizations: 'Contacted orgs',
  jobOrders: 'Job orders',
  contactsAdded: 'Contacts added',
  applicationsReceived: 'Application received',
  phoneInterviews: 'Phone Interviews',
  f2fInterviews: 'F2F interviews',
  placements: 'Placements',
  replacements: 'Replacements',
};

function PipeLineOverview() {
  const { queryParams } = useQueryParams();

  const { data: response, isLoading } = useCustomQuery<PipelineOverviewData>({
    url: `/admin/reports/pipelineoverview`,
    params: {
      ...(queryParams?.startDate && { startDate: queryParams.startDate }),
      ...(queryParams?.endDate && { endDate: queryParams.endDate }),
    },
  });

  const { data } = response ?? {};
  const pipelineData = Object.entries(data ?? {});

  return (
    <div className='grid gap-5 rounded-lg border border-neutral-40 bg-white p-4'>
      <div className='grid grid-cols-2 justify-between'>
        <div className='flex items-center gap-2'>
          <div className='material-symbols-outlined font-bold'>valve</div>
          <span className='text-lg font-bold'>Pipeline Overview</span>
        </div>
      </div>
      {isLoading && (
        <div className='h-14 w-full animate-pulse rounded-md bg-gray-400' />
      )}
      {!isLoading && (
        <>
          {pipelineData.length > 0 && (
            <div className='flex justify-around'>
              {pipelineData?.map(([key, value]) => {
                return (
                  <div
                    className='flex flex-col gap-1 text-secondary-80'
                    key={key}
                  >
                    <div className='text-base font-medium'>
                      {
                        pipelineOverviewLabelMap[
                          key as keyof PipelineOverviewData
                        ]
                      }
                    </div>
                    <div className='text-xl font-medium'>{value}</div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default PipeLineOverview;
