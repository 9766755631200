import { AxisTickProps } from '@nivo/axes';
import { ResponsiveBar, BarDatum, BarTooltipProps } from '@nivo/bar';
import { useMemo } from 'react';

import { useGlobalState } from '../../../context/GlobalContext';
import colors from '../../../styles/colors';
import { APIProActiveCallTimeReport } from './ProactiveCallEffort';

interface DataPoint extends BarDatum {
  outboundCallTime: string;
  inboundCallTime: string;
  user: string;
  totalCallTime: string;
}

function ProactiveCallEffortChart({
  records,
  isExternalSelected,
}: {
  records: APIProActiveCallTimeReport[];
  isExternalSelected: boolean;
}) {
  const { findUserById } = useGlobalState();

  const maxMinutes = useMemo(() => {
    return Math.max(
      ...records.map(
        (r) => r.totalOutboundCallTime / 60 + r.totalInboundCallTime / 60,
      ),
    );
  }, [records]);

  const tickValues = useMemo(() => {
    return generateDynamicTicks(maxMinutes);
  }, [maxMinutes]);

  const yAxisMaxValue = tickValues[tickValues.length - 1];

  const outBoundColor = isExternalSelected
    ? colors.darkPurple
    : colors.secondary[35];
  const inboundColor = colors.secondary[30];

  const mixedGraphData = useMemo(() => {
    return records?.length
      ? records
          ?.filter((r) => r.userId)
          .map((record) => {
            const user = findUserById && findUserById(record?.userId);
            return {
              user: user?.fullName ?? record.userId ?? '',
              outboundCallTime: (record.totalOutboundCallTime / 60).toFixed(0),
              inboundCallTime: (record.totalInboundCallTime / 60).toFixed(0),
              totalCallTime: (record.totalCallTime / 60).toFixed(2),
              random: record.totalOutboundCallTime,
            };
          })
          .sort((a, b) => b.random - a.random)
      : [];
  }, [records, findUserById]);

  return (
    <div className='relative size-full'>
      <ResponsiveBar
        data={mixedGraphData}
        keys={['outboundCallTime', 'inboundCallTime']}
        indexBy='user'
        margin={{ top: 50, right: 20, bottom: 80, left: 70 }}
        padding={0.6}
        valueScale={{ type: 'linear', max: yAxisMaxValue }}
        indexScale={{ type: 'band', round: true }}
        colors={[outBoundColor, inboundColor]}
        axisTop={null}
        gridYValues={tickValues}
        enableLabel={false}
        tooltip={CustomTooltip}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: -45,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          renderTick: CustomYAxis,
          tickValues: tickValues,
        }}
        enableGridY={true}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'top',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: -50,
            itemsSpacing: 48,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
            data: [
              {
                id: 'outboundCallTime',
                label: 'Outbound call time',
                color: outBoundColor,
              },
              {
                id: 'inboundCallTime',
                label: 'Inbound call time',
                color: inboundColor,
              },
            ],
          },
        ]}
      />
      <div
        style={{
          position: 'absolute',
          left: '12px',
          bottom: '48px',
          fontSize: '12px',
          fontWeight: 'bold',
          color: '#333333',
        }}
      >
        HH:MM
      </div>
    </div>
  );
}

export default ProactiveCallEffortChart;

function minutesToTimeString(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours}:${mins.toString().padStart(2, '0')}`;
}

function formatLabel(label: string): string {
  return label
    .replace(/([A-Z])/g, ' $1') // Add space before capital letters
    .replace(/^./, (str) => str.toUpperCase()) // Capitalize first letter
    .trim(); // Remove any leading/trailing spaces
}

function CustomTooltip({ id, value, indexValue }: BarTooltipProps<DataPoint>) {
  return (
    <div
      style={{
        padding: '9px 12px',
        background: 'white',
        border: '1px solid #ccc',
      }}
    >
      <strong>{indexValue}</strong>
      <br />
      {formatLabel(id as string)}: {minutesToTimeString(value as number)}
    </div>
  );
}

function CustomYAxis(props: AxisTickProps<any>) {
  const { x, y, value } = props;
  const timeString = minutesToTimeString(value as number);

  return (
    <g transform={`translate(${x},${y})`}>
      <text x='-9' dy='0.32em' textAnchor='end' fill='currentColor'>
        {timeString}
      </text>
    </g>
  );
}

function generateDynamicTicks(
  maxValue: number,
  minTickCount: number = 4,
  maxTickCount: number = 16,
) {
  const roundedMax = Math.ceil(maxValue);

  let tickInterval = 60;
  let tickCount = Math.ceil(roundedMax / tickInterval);

  while (tickCount < minTickCount && tickInterval > 15) {
    tickInterval /= 2;
    tickCount = Math.ceil(roundedMax / tickInterval);
  }

  while (tickCount > maxTickCount) {
    tickInterval *= 2;
    tickCount = Math.ceil(roundedMax / tickInterval);
  }

  return Array.from({ length: tickCount + 1 }, (_, i) => i * tickInterval);
}
