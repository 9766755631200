import { ChangeEventHandler } from 'react';
import 'react-phone-number-input/style.css';

import { Input } from '../ui/input';
import { ErrorMessage } from './Error';

interface IPhone {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  id: string;
  disabled?: boolean;
  errorMessage?: string;
  required?: boolean;
  placeholder?: string;
  prefix?: string;
  className?: string;
}

function TextInput({
  required = false,
  onChange,
  value,
  disabled,
  errorMessage,
  placeholder = '',
  label,
  prefix,
  id,
  className = '',
}: IPhone) {
  return (
    <div className={className}>
      <div className='flex flex-col gap-1'>
        {!!label && (
          <div>
            <label htmlFor={id} className='relative mb-1 inline'>
              <span>{label}</span>
              {required && (
                <span className='absolute -right-2 font-semibold text-error'>
                  *
                </span>
              )}
            </label>
          </div>
        )}
        <div className='relative '>
          {prefix && (
            <span className='pointer-events-none absolute inset-y-0 left-2 flex items-center'>
              {prefix}
            </span>
          )}
          <Input
            disabled={disabled}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            className={prefix ? 'pl-6' : ''}
          />
        </div>
      </div>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
}

TextInput.displayName = 'TextInput';

export default TextInput;
