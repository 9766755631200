import RootLayout from '../../layouts';
import { Organizations } from './Organizations';

const OrganizationsPage = () => {
  return (
    <RootLayout>
      <Organizations />
    </RootLayout>
  );
};

export default OrganizationsPage;
