import { E164Number } from 'libphonenumber-js/types';
import { useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IAction, TextInput, commonReducer } from '../../../components/common';
import { Button } from '../../../components/common/Button';
import PhoneInput from '../../../components/common/PhoneInput';
import Select from '../../../components/common/SelectNew';
import { Input } from '../../../components/ui/input';
import { Label } from '../../../components/ui/label';
import { useGlobalState } from '../../../context/GlobalContext';
import { listOfStates } from '../../../data/activityTitleMap';
import { useCustomMutation } from '../../../hooks/useCustomMutation';
import { useCustomQuery } from '../../../hooks/useCustomQuery';
import { usePageTitle } from '../../../hooks/usePageTitle';
import RootLayout from '../../../layouts';
import { IAddress } from '../../../react-app-env';
import { APIUser } from '../../../types/users';
import { isValidEmail } from '../../../utils/helpers';
import { getStateName } from '../../../utils/states';

export interface APIC extends IAddress {
  firstName: string;
  lastName: string;
  title?: string;
  phone: string;
  emailAddress?: string;
  resume?: any;
  userId?: string;
}

const initialState: Partial<APIUser> = {
  fullName: '',
  emailAddress: '',
  phone: '' as E164Number,
  extension: '',
  directDial1: undefined,
  directDial2: undefined,
  directDial3: undefined,
  assignedStates: [],
  admin: false,
  active: true,
  sponsoredAdLimit: 30,
};

export const validateUser = (state: Partial<APIUser>) => {
  const errors: Partial<Record<keyof APIUser, string>> = {};

  if (!state.fullName) errors.fullName = 'Name is required';
  if (!state.phone) errors.phone = 'Organization phone number is required';

  if (!state.emailAddress) errors.emailAddress = 'Email address is required';
  else if (!isValidEmail(state.emailAddress))
    errors.emailAddress = 'Email address not valid';

  return errors;
};

export function AddEditUser() {
  return (
    <RootLayout allowOverflow>
      <AddEditUserForm />
    </RootLayout>
  );
}

export function AddEditUserForm() {
  const navigate = useNavigate();
  const [state, dispatch]: [APIUser, React.Dispatch<IAction>] = useReducer(
    commonReducer,
    initialState,
  );

  const { id } = useParams();
  const { me } = useGlobalState();
  const isEditPage = Boolean(id);
  usePageTitle(`${isEditPage ? 'Edit' : 'Add'} Admin`);
  const [{ errors }, errorDispatch]: [any, React.Dispatch<IAction>] =
    useReducer(commonReducer, { errors: {} });

  const { mutate: submitForm, isPending } = useCustomMutation({
    onSuccess: () => {
      navigate('/admin/users');
    },
    onSuccessMessage: isEditPage
      ? 'User updated successfully!'
      : 'User added successfully!',
    method: isEditPage ? 'patch' : 'post',
  });

  const { data, isLoading, isError } = useCustomQuery({
    url: `/admin/users/${id}`,
    enabled: isEditPage && Boolean(id),
    options: { staleTime: 0 },
  });
  const user = data?.data;

  const handleSubmit = async () => {
    const errors = validateUser(state);

    if (Object.values(errors).length) {
      errorDispatch({ path: 'errors', value: errors });
      return;
    }

    const sponsoredAdLimit = state.sponsoredAdLimit
      ? { sponsoredAdLimit: Number(state?.sponsoredAdLimit) }
      : {};

    await submitForm({
      url: isEditPage ? `/admin/users/${id}` : '/admin/users',
      body: {
        ...state,
        assignedStates: state?.assignedStates?.length
          ? state?.assignedStates?.join(',')
          : '',
        role: state?.admin ? 'Admin' : 'User',
        userName: state.userName ?? state.emailAddress,
        active: state.active,
        ...sponsoredAdLimit,
      },
    });
  };

  const handleChange =
    (path: keyof APIUser) => (value: E164Number | undefined | string) => {
      if (errors[path]) {
        errorDispatch({ type: 'remove', path: `errors.${path}` });
      }

      dispatch({ path, value });
    };

  const handleCheckbox = (path: keyof APIUser) => (value: string | boolean) => {
    let modifiedValue: (string | boolean)[] | boolean | string = '';
    if (path === 'assignedStates') {
      modifiedValue = state?.assignedStates?.includes(value as string)
        ? state?.assignedStates?.filter((state) => state !== value)
        : [...(state?.assignedStates || []), value];
    } else if (path === 'admin') {
      modifiedValue = state?.admin ? false : true;
    } else {
      modifiedValue = value;
    }
    if (errors[path]) {
      errorDispatch({ type: 'remove', path: `errors.${path}` });
    }
    dispatch({ path, value: modifiedValue });
  };

  useEffect(() => {
    if (user && !isLoading && !isError) {
      dispatch({
        type: 'set',
        value: {
          fullName: user?.fullName,
          emailAddress: user?.emailAddress,
          extension: user?.extension,
          directDial1: user?.directDial1,
          directDial2: user?.directDial2,
          directDial3: user?.directDial3,
          assignedStates: user?.assignedStates
            ? user?.assignedStates.split(',')
            : [],
          admin: user?.admin,
          userName: user?.userName,
          active: user?.active,
          sponsoredAdLimit: user?.sponsoredAdLimit,
          indeedCampaignId: user?.indeedCampaignId,
          phone: user?.phone,
        },
      });
    }
  }, [user, isError, isLoading]);

  const isDisabledField = isEditPage && !state.active;

  return (
    <>
      <div className='max-w-[600px] px-8 sm:px-16'>
        <div className='flex justify-between'>
          <h1 className='my-8 text-2xl font-bold'>
            {isEditPage ? 'Edit User' : 'Add New User'}
          </h1>
          <div className='flex items-center justify-center gap-3'>
            <div className='flex items-end'>
              <input
                type='checkbox'
                disabled={
                  me?.emailAddress === state?.emailAddress ? true : false
                }
                checked={state?.admin || false}
                onChange={() =>
                  handleCheckbox('admin')(state?.admin ? true : false)
                }
              />
              <label className='text-md ml-2 font-semibold'>Admin</label>
            </div>
            <div className='flex items-end'>
              <input
                type='checkbox'
                disabled={
                  me?.emailAddress === state?.emailAddress ? true : false
                }
                checked={state?.active || false}
                onChange={() =>
                  handleCheckbox('active')(state?.active ? false : true)
                }
              />
              <label className='text-md ml-2 font-semibold'>Active</label>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-4'>
          <TextInput
            required
            disabled={isDisabledField}
            value={state?.fullName || ''}
            label='Name'
            placeholder='Enter full name'
            handleChange={handleChange('fullName')}
            errorMessage={errors.fullName}
          />
          <TextInput
            required
            disabled={isDisabledField}
            value={state?.emailAddress || ''}
            label='Email address'
            placeholder='email@mlr.org'
            handleChange={handleChange('emailAddress')}
            errorMessage={errors.emailAddress}
          />
          <div className='flex flex-col items-end justify-between gap-2 sm:flex-row'>
            <Select
              label='Company Phone Number'
              options={[
                { value: '+15128777000', label: '(512) 877-7000' },
                { value: '+15127952000', label: '(512) 795-2000' },
                { value: '+17028487072', label: '(702) 848-7072' },
              ]}
              value={state.phone || ''}
              onChange={(value) => {
                handleChange('phone')(value);
              }}
              disabled={isDisabledField}
              errorMessage={errors.phone}
              classNames={{
                trigger: 'h-8 bg-white',
                container: 'gap-1',
                label: 'text-base font-semibold',
              }}
            />
            <div className='flex flex-1 flex-col gap-1'>
              <Label className='text-base font-semibold'>Extension #</Label>
              <Input
                value={state?.extension || ''}
                disabled={isDisabledField}
                type='number'
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (/^\d{0,4}$/.test(value)) {
                    handleChange('extension')(value);
                  }
                }}
              />
            </div>
          </div>
          <PhoneInput
            disabled={isDisabledField}
            placeholder='Enter primary direct dial # (required)'
            label='Primary Direct Dial Number'
            onChange={handleChange('directDial1')}
            value={state.directDial1 ?? undefined}
            errorMessage={errors.directDial1}
            id='add-admin-primary-direct-dial'
          />
          <PhoneInput
            disabled={isDisabledField}
            placeholder='Enter secondary direct dial #'
            label='Secondary Direct Dial Number'
            onChange={handleChange('directDial2')}
            value={state.directDial2 ?? undefined}
            errorMessage={errors.directDial2}
            id='add-admin-secondary-direct-dial'
          />
          <PhoneInput
            disabled={isDisabledField}
            placeholder='Enter tertiary direct dial #'
            label='Tertiary Direct Dial Number'
            onChange={handleChange('directDial3')}
            value={state.directDial3 ?? undefined}
            errorMessage={errors.directDial3}
            id='add-admin-tertiary-direct-dial'
          />
          <div className='hadow-sm flex size-full flex-col gap-4'>
            <div className='pt-2 text-xl font-bold'>Sponsored Ads</div>
            <div className='flex flex-col justify-between gap-4 sm:flex-row sm:gap-0 '>
              <div className='mr-0 sm:mr-[30px]'>
                <TextInput
                  disabled={isDisabledField}
                  type='number'
                  value={state?.sponsoredAdLimit || ''}
                  label='User Limit'
                  placeholder='Enter Sponsored Ads Limit'
                  handleChange={handleChange('sponsoredAdLimit')}
                  errorMessage={errors.sponsoredAdLimit}
                />
              </div>
              <div className='w-fit-content sm:w-6/12'>
                <TextInput
                  disabled={isDisabledField}
                  value={state?.indeedCampaignId || ''}
                  label='Indeed Campaign ID'
                  placeholder='Enter Indeed Campaign ID'
                  handleChange={handleChange('indeedCampaignId')}
                  errorMessage={errors.indeedCampaignId}
                  maxLength={15}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {!state?.admin && (
        <div className='mt-8 max-w-[800px] px-8 sm:px-16'>
          <div className='flex size-full flex-col gap-4 rounded-lg border border-neutral-40 bg-white p-4 shadow-sm'>
            <div className='border-b pb-1 pt-2 text-xl font-bold'>
              Data Access
            </div>
            {Object.keys(listOfStates).map((zone) => {
              return (
                <div key={zone}>
                  <div className='mb-2'>
                    <label className='text-sm font-medium text-neutral-60'>
                      {zone}
                    </label>
                  </div>
                  <div className='grid grid-cols-2 gap-3 sm:grid-cols-3'>
                    {listOfStates[zone as keyof typeof listOfStates].map(
                      (stateName, index) => {
                        return (
                          <div
                            key={stateName + zone + index}
                            className='flex items-baseline'
                          >
                            <input
                              disabled={isDisabledField}
                              type='checkbox'
                              checked={state?.assignedStates?.includes(
                                stateName,
                              )}
                              onChange={() =>
                                handleCheckbox('assignedStates')(stateName)
                              }
                            />
                            <label className='text-md ml-2 font-semibold'>
                              {getStateName(stateName)}
                            </label>
                          </div>
                        );
                      },
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className='my-8 flex justify-between px-8 sm:px-16'>
        <Button onClick={() => navigate(-1)} variant='secondary'>
          Back
        </Button>
        <Button
          onClick={handleSubmit}
          isLoading={isPending}
          disabled={isPending}
        >
          {isEditPage ? 'Save' : 'Add'}
        </Button>
      </div>
    </>
  );
}
