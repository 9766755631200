import React, { useCallback, useMemo } from 'react';

import { useQueryParams } from '../../hooks/useQueryParams';
import { CheckboxProps, HandleRowClick } from '../../types/table';
import { Checkbox } from '../ui/checkbox';
import { Table, Header, HeaderCell, Body, Row, RowCell } from './Table';

export interface Column<T> {
  label: string;
  key: string;
  sortEnabled: boolean;
  className?: string;
  render?: (item: T, queryParams?: string) => React.ReactNode;
  visible?: boolean;
}

export interface BaseTableProps<T> {
  data: T[];
  columns: Column<T>[];
  idKey: keyof T;
  handleRowClick: HandleRowClick<T>;
  className?: string;
  defaultSortKey?: string;
  checkboxProps?: CheckboxProps;
}

export function BaseTable<T extends { [key: string]: any }>({
  data,
  columns,
  idKey,
  handleRowClick,
  className = '',
  checkboxProps,
  defaultSortKey,
}: BaseTableProps<T>) {
  const {
    handleSelectionChange,
    toggleSelectionMode,
    isItemSelected,
    isItemDisabled,
  } = checkboxProps ?? {};

  const handleCheckboxChange = useCallback(
    (item: T, event: React.MouseEvent<HTMLTableCellElement>) => {
      const id = String(item[idKey]);
      handleSelectionChange?.(id, event.shiftKey);
    },
    [handleSelectionChange, idKey],
  );
  const { queryParamsString } = useQueryParams();

  const tableBody = useMemo(
    () =>
      data.map((item) => {
        const id = String(item[idKey]);
        // I'll need to think this through here since we'll have the opposite effect when the "check all" checkbox is checked
        const isChecked = !!(id && isItemSelected?.(id));
        const isRowDisabled = !!(id && isItemDisabled?.(id));

        return (
          <Row
            key={id}
            onClick={(e) => handleRowClick(item, e)}
            disabled={isRowDisabled}
            checked={isChecked}
            tabIndex={0}
            className={className}
          >
            {!!checkboxProps && (
              <RowCell
                onClick={(event) => {
                  event.stopPropagation();
                  handleCheckboxChange(item, event);
                }}
              >
                <div className='flex size-full items-center justify-start'>
                  <Checkbox checked={isChecked} disabled={isRowDisabled} />
                </div>
              </RowCell>
            )}
            {columns.map((column, index) => {
              if (column?.visible === false) return;

              return (
                <RowCell key={`${id}-${index}`} className='border-l-0'>
                  {column.render
                    ? column.render(item, queryParamsString)
                    : item[column.key as keyof T]}
                </RowCell>
              );
            })}
          </Row>
        );
      }),
    [
      data,
      columns,
      idKey,
      isItemSelected,
      isItemDisabled,
      className,
      checkboxProps,
      handleCheckboxChange,
      handleRowClick,
      queryParamsString,
    ],
  );

  const tableHeader = [
    ...(checkboxProps
      ? [
          {
            label: (
              <div className='flex size-full items-center justify-start'>
                <Checkbox
                  checked={checkboxProps.selectionState?.mode === 'allFiltered'}
                  onCheckedChange={toggleSelectionMode}
                />
              </div>
            ),
            key: '',
            sortEnabled: false,
            className: 'w-10 opacity-100',
          },
        ]
      : []),
    ...columns,
  ];

  return (
    <Table
      classNames={{
        table: 'relative table-fixed h-fit',
        container: 'size-full',
      }}
    >
      <Header
        classNames={{
          thead: 'sticky top-0 z-20 shadow-sm',
        }}
      >
        {tableHeader.map((header: any) => {
          if (header?.visible === false) return;

          return (
            <HeaderCell
              isSorting
              key={header.key}
              sortKey={header.sortEnabled ? header.key : ''}
              defaultSortKey={defaultSortKey ?? 'dateEntered'}
              className={header.className}
            >
              {header.label}
            </HeaderCell>
          );
        })}
      </Header>
      <Body>{tableBody}</Body>
    </Table>
  );
}
