import { XIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

import { CommonSpinner } from '../../components/common';
import { LinkOutIcon } from '../../components/common/Icons';
import OrganizationName from '../../components/common/OrganizationName';
import { Sheet, SheetClose, SheetContent } from '../../components/ui/sheet';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import colors from '../../styles/colors';
import { APIRequisition } from '../../types/requisitions';
import { formatDateString, setRegion } from '../../utils/helpers';
import { RequisitionsTabs } from './RequisitionTabs';

export function RequisitionView({
  requisitionId,
  requisitions,
  isOpen,
  onClose,
  onRequisitionSave,
  tabId,
  panelBaseRoute,
}: {
  requisitionId: string | undefined;
  isOpen: boolean;
  onClose: () => void;
  onRequisitionSave: () => void;
  requisitions?: APIRequisition[] | undefined;
  tabId?: string | undefined;
  panelBaseRoute?: string;
}) {
  const { users } = useGlobalState();

  const preLoadedRequisition = requisitions?.find(
    (r) => r.jobId === requisitionId,
  );
  const {
    data,
    isLoading: isRequisitionLoading,
    refetch,
  } = useCustomQuery<APIRequisition>({
    url: `/jobs/${requisitionId}`,
    enabled: isOpen && !!requisitionId && !preLoadedRequisition,
  });

  const requisition = preLoadedRequisition ?? data?.data;
  const user =
    users?.find((u) => u.userId === requisition?.userId)?.fullName ??
    requisition?.userName;

  return (
    <Sheet
      open={isOpen}
      onOpenChange={() => {
        onClose();
      }}
      modal={false}
    >
      <SheetContent
        className='w-full overflow-auto bg-white md:max-w-[1200px]'
        hideCloseButton
        showCustomOverlay
      >
        {isRequisitionLoading && (
          <div className='flex size-full items-center justify-center overflow-hidden'>
            <CommonSpinner size='md' />
          </div>
        )}

        {!isRequisitionLoading && !!requisition && (
          <div
            className=' grid size-full gap-4 overflow-hidden'
            style={{ gridTemplateRows: 'auto 1fr' }}
          >
            <div className='flex flex-col gap-4 rounded-t-lg border border-neutral-40 bg-light-blue p-4'>
              <div className='flex justify-between'>
                <div className='text-xl font-bold'>{requisition?.jobTitle}</div>
                <SheetClose>
                  <XIcon />
                </SheetClose>
              </div>
              <div className='flex items-center gap-2'>
                <Link
                  to={`/pipeline/${requisition.jobId}`}
                  className='flex items-center gap-2 self-start border border-primary bg-white px-2 py-1 font-bold text-primary'
                >
                  <span>Pipeline</span>
                  <LinkOutIcon size={16} color={colors.primary.DEFAULT} />
                </Link>
                <div className='flex items-center text-sm'>
                  <div
                    className={`flex items-center ${requisition?.numberOfCandidatesInInbox ? 'gap-1' : 'gap-0'} `}
                  >
                    <span
                      className={`flex h-5 w-5 items-center justify-center rounded-full ${requisition?.numberOfCandidatesInInbox ? 'bg-error text-white' : 'bg-transparent text-black'} text-sm font-medium`}
                    >{`${requisition?.numberOfCandidatesInInbox}`}</span>
                    <span>Inbox</span>
                  </div>
                  <div>{`, ${requisition?.totalCandidates} Total`}</div>
                </div>
              </div>
              <div className='grid grid-cols-3 gap-2'>
                <span className='flex flex-col text-sm'>
                  <b>Organization</b>
                  <Link to={`/organizations/${requisition.organizationId}`}>
                    <OrganizationName
                      hasContract={!!requisition.orgHasContract}
                      name={requisition.organizationName}
                    />
                  </Link>
                </span>
                <span className='flex flex-col text-sm'>
                  <b>City/State</b>
                  <span>
                    {setRegion(requisition?.city, requisition?.state)}
                  </span>
                </span>
                <span className='flex flex-col text-sm'>
                  <b>Status</b>
                  <span>{requisition.status}</span>
                </span>
              </div>
              <div className='grid grid-cols-3 gap-2'>
                <span className='flex flex-col text-sm'>
                  <b>Profession</b>
                  <span>{requisition?.profession}</span>
                </span>
                <span className='flex flex-col text-sm'>
                  <b>User</b>
                  <span>{user}</span>
                </span>
                <span className='flex flex-col text-sm'>
                  <b>Date posted</b>
                  <span>{formatDateString(requisition.datePosted)}</span>
                </span>
              </div>
            </div>
            <>
              <div className='size-full overflow-hidden'>
                <RequisitionsTabs
                  requisition={requisition}
                  onRequisitionSave={() => {
                    refetch();
                    onRequisitionSave();
                  }}
                  tabId={tabId}
                  panelBaseRoute={panelBaseRoute}
                />
              </div>
            </>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
}
