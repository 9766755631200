import CheckInput from '../../components/common/CheckInput';
import { MultiSelectDropdownFilter } from '../../components/common/MultiSelectDropdownFilter';
import ActiveScaleInput from '../../components/filters/ActiveScaleInput';
import CityInput from '../../components/filters/CityInput';
import OrganizationInput from '../../components/filters/OrganizationInput';
import SearchInput from '../../components/filters/SearchInput';
import StateInput from '../../components/filters/StatesInput';
import UsersInput from '../../components/filters/UsersInput';
import { useQueryParams } from '../../hooks/useQueryParams';
import { status } from './inputs';

function RequisitionsFilters() {
  const { queryParams, updateQueryParam, removeQueryParam } = useQueryParams();

  const showRelevantSortButton = !!(
    queryParams.query &&
    queryParams.orderBy &&
    queryParams.orderByDirection
  );

  return (
    <>
      <SearchInput
        containerClassName='w-96'
        showRelevantSortButton={showRelevantSortButton}
        placeholder='Search job titles & cities'
      />
      <MultiSelectDropdownFilter
        classNames={{ container: 'w-36' }}
        items={status.map((s) => ({
          id: s.key as string,
          title: s.text,
        }))}
        isClearable={!!queryParams?.status}
        handleChange={(status) => {
          updateQueryParam('status', status, {
            multiple: false,
            resetPageOnChange: true,
          });
        }}
        onClear={() => {
          removeQueryParam('status');
        }}
        value={[queryParams?.status as string]}
        isChecked={!!queryParams?.status}
        placeholder='Status'
      />
      <CheckInput
        label='Indeed sponsored'
        param='indeedSponsored'
        updateValue='true'
      />
      <OrganizationInput />
      <CityInput />
      <StateInput />
      <UsersInput />
      <ActiveScaleInput />
    </>
  );
}

export default RequisitionsFilters;
