import { IDropdownOption } from '@fluentui/react';
import { MailIcon, PhoneIcon } from 'lucide-react';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  Dropdown,
  ErrorMessage,
  IAction,
  Required,
  TextInput,
  UsersDropdown,
  commonReducer,
} from '../../components/common';
import { AdditionalLocationVariants } from '../../components/common/AdditionalLocationVariants';
import AddressInput from '../../components/common/AddressInput';
import { Button } from '../../components/common/Button';
import { CKEditor } from '../../components/common/CKEditor';
import { ContactSelectDropdown } from '../../components/common/ContactSelectDropdown';
import OrganizationSelect from '../../components/common/OrganizationSelect';
import PhoneNumber from '../../components/common/PhoneNumber';
import { Select as SelectDeprecated } from '../../components/common/Select';
import Select from '../../components/common/SelectNew';
import TextInputNew from '../../components/common/TextInput';
import { Checkbox } from '../../components/ui/checkbox';
import { Label } from '../../components/ui/label';
import { useGlobalState } from '../../context/GlobalContext';
import { useCustomMutation } from '../../hooks/useCustomMutation';
import { useCustomQuery } from '../../hooks/useCustomQuery';
import useInvalidateQuery from '../../hooks/useInvalidateQuery';
import { usePageTitle } from '../../hooks/usePageTitle';
import RootLayout from '../../layouts';
import { APIAddress } from '../../types/common';
import { APIRequisition, JobPostVariant } from '../../types/requisitions';
import {
  formatDateToBackend,
  handleMergeOptions,
  isValidEmail,
} from '../../utils/helpers';
import { theme } from '../../utils/theme';
import { StructuredTitleDropdown } from '../contacts/StructuredTitleDropdown';
import {
  HCENSpecialties,
  jobTypes,
  profession,
  status,
  visaTypes,
  zipRecruiterTypes,
} from './inputs';

export type IRequisition = APIRequisition & {
  customPostalCode?: string;
};

const Container = styled.div.attrs({
  className: 'px-8 sm:px-16',
})`
  /* max-width: 600px; */
  .ms-ComboBox::after {
    border: 1px solid #e0e0e0;
    border-radius: 6px;
  }

  .pcr-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    min-width: 0;

    @media (max-width: 800px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
`;

const initialState: Partial<IRequisition> = {
  jobTitle: '',
  salaryTop: 90000,
  salaryBottom: 80000,
  city: '',
  state: '',
  postalCode: '',
  jobDescription: '',
  numberOfJobs: 1,
  status: String(status[0].key),
  indeedSponsored: 0,
  showOnWeb: true,
  jobType: String(jobTypes[0].key),
  autoRefresh: 'true',
  industry: 'Healthcare',
  featured: true,
  visaType: visaTypes[0],
  split: false,
  newGraduates: true,
  contactPhone: undefined,
  contactEmail: '',
  profession: '',
  jobPostVariants: [
    {
      activeVariant: true,
      city: '',
      deleted: false,
      jobTitle: '',
      latitude: null,
      longitude: null,
      postalCode: '',
      state: '',
    },
  ],
};

type VariantError = {
  city?: string;
  postalCode?: string;
  jobTitle?: string;
};

type RequisitionErrors = Partial<
  Record<keyof IRequisition, string | VariantError[]> & {
    jobPostVariants?: VariantError[];
  }
>;

export const validateRequisition = (state: IRequisition, type?: string) => {
  const errors: RequisitionErrors = {};
  if (type === 'details' || !type) {
    if (!state.jobTitle) errors.jobTitle = 'Job title is required';
    if (!state.city || !state.state) errors.city = 'Address is required';
    if (!state.postalCode && !state.customPostalCode) {
      errors.customPostalCode =
        state.city && state.state
          ? 'Please enter zipcode manually.'
          : 'Postal code is required';
    }
    if (!state.profession) errors.profession = 'Profession is required';
    if (!Number(state.salaryBottom) || !Number(state.salaryTop))
      errors.salaryTop = 'Salary is required';
    if (Number(state.salaryBottom) > Number(state.salaryTop))
      errors.salaryTop =
        'Minimum salary range cannot be greater than upper salary range';
    if (!state.organizationId)
      errors.organizationId = 'Organization is required';
    if (!state.userId) errors.userId = 'User is required';
  }

  if (state.indeedSponsored === undefined || state.indeedSponsored === null)
    errors.indeedSponsored = 'Indeed setting is required';

  if (!state.jobDescription)
    errors.jobDescription = 'Job Description is required';
  else if (state.jobDescription) {
    const value = state?.jobDescription.replace(/<\/?[^>]+(>|$)/g, '');

    const phonePatternE164 = /^\+?[1-9]\d{1,14}$/;
    const phonePatternFormatted = /\(\d{3}\)\s?\d{3}-\d{4}/;
    const phonePatternDashed = /\d{3}-\d{3}-\d{4}/;

    const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    const descriptionHasPhoneNumber =
      phonePatternE164.test(value) ||
      phonePatternFormatted.test(value) ||
      phonePatternDashed.test(value);
    const descriptionHasEmail = emailPattern.test(value);

    const isNotValidLength =
      state?.jobDescription.replace(/<[^>]*>/g, '').trim().length < 129;
    if (isNotValidLength || descriptionHasPhoneNumber || descriptionHasEmail) {
      const allErrors = [];
      if (isNotValidLength) {
        allErrors?.push('128 characters required for job description.');
      }
      if (descriptionHasPhoneNumber) {
        allErrors?.push('Phone numbers are not allowed.');
      }
      if (descriptionHasEmail) {
        allErrors?.push('Email addresses are not allowed.');
      }
      errors.jobDescription = allErrors.join('\n');
    }
  }

  if (type === 'contact' || !type) {
    if (!state.contactName) errors.contactName = 'Contact name is required';

    if (!state.contactPhone && !state.contactEmail)
      errors.contactPhone = 'Either contact phone or email is required';

    if (state.contactEmail && !isValidEmail(state.contactEmail))
      errors.contactEmail = 'Email address not valid';

    if (!state.contactId) errors.contactId = 'Contact is required';
  }
  if (type === 'nchcr' || !type) {
    if (!state.titleId) errors.titleId = 'NCHCR specialty is required';
  }
  if (type === 'web' || !type) {
    if (!Number(state.numberOfJobs))
      errors.numberOfJobs = 'Number of openings is required';
    if (!state.jobType) errors.jobType = 'Job type is required';
  }
  if (type === 'external' || !type) {
    // Add any external checks here
  }

  if (state.jobPostVariants?.length) {
    const variantErrors: VariantError[] = [];

    state.jobPostVariants.forEach((variant, index) => {
      const variantError: VariantError = {};

      if (!variant.city || !variant.state) {
        variantError.city = 'City, State is required';
      }

      if (!variant.postalCode && !variant.customPostalCode) {
        variantError.postalCode =
          variant.city && variant.state
            ? 'Please enter zipcode manually.'
            : 'Postal code is required';
      }

      if (!variant.jobTitle && !variant.useJobTitle) {
        variantError.jobTitle = 'Ad title is required';
      }

      // only add errors if they exist
      if (Object.keys(variantError).length > 0) {
        variantErrors[index] = variantError;
      }
    });

    // only set jobPostVariants errors if there are any
    if (variantErrors.some((error) => Object.keys(error).length > 0)) {
      errors.jobPostVariants = variantErrors;
    }
  }

  return errors;
};

export function AddRequisitionPage() {
  return (
    <RootLayout allowOverflow>
      <AddRequisition />
    </RootLayout>
  );
}

export function AddRequisition() {
  const navigate = useNavigate();
  const { me, refetchUsers } = useGlobalState();
  const invalidate = useInvalidateQuery();
  const [state, dispatch]: [IRequisition, React.Dispatch<IAction>] = useReducer(
    commonReducer,
    {
      ...initialState,
      distributionList: me?.emailAddress ?? '',
      userId: me?.userId,
    },
  );
  const [sponsoredAdOverLimitError, setSponsoredAdOverLimitError] =
    useState(false);
  const [returnUrl, setReturnUrl] = useState('');

  const listId = window?.localStorage?.getItem('listId') || '';
  const requisition = localStorage?.getItem('job');

  usePageTitle('Add Requisition');

  const [{ errors }, optionsReducer]: [any, React.Dispatch<IAction>] =
    useReducer(commonReducer, { errors: {} });

  const { mutate: submitForm, isPending } = useCustomMutation({
    onSuccess: async () => {
      if (listId) {
        refetchUsers?.();
        invalidate([`/lists/${listId}`]);
        navigate(`/lists/${listId}/requisitions`);
      } else {
        refetchUsers?.();
        invalidate([`/jobs`]);
        navigate(returnUrl || '/requisitions');
      }
    },
    onSuccessMessage: 'Requisition added successfully!',
    onError: (error) => {
      if (
        (error as unknown as { response: { status: number } })?.response
          ?.status === 422
      ) {
        setSponsoredAdOverLimitError(true);
      }
    },
  });

  const handleChange = (path: keyof IRequisition) => (value: any) => {
    if (errors[path]) {
      optionsReducer({ type: 'remove', path: `errors.${path}` });
    }

    dispatch({ path, value });
  };

  const handleDropdownChange =
    (path: string) =>
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption<any> | undefined,
    ) => {
      if (path === 'userId') {
        const opt = option as any;

        dispatch({
          type: 'set',
          value: {
            userId: opt?.key,
            distributionList: opt?.email,
          },
        });
      } else dispatch({ path, value: option?.key });
    };

  const handleMultiDropdownChange =
    (path: string) =>
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption<any> | undefined,
    ) => {
      const pathData = state[path as keyof IRequisition] as string;

      const data = handleMergeOptions(pathData, option);
      dispatch({ path, value: data });
    };

  const handleAddressChange = (
    address:
      | (APIAddress & { customPostalCode?: string })
      | { jobPostVariants: JobPostVariant[] },
  ) => {
    if (errors.city) optionsReducer({ type: 'remove', path: 'errors.city' });
    dispatch({ type: 'set', value: address });
  };

  const handleSubmit = async () => {
    const errors = validateRequisition(state);

    setSponsoredAdOverLimitError(false);
    if (Object.values(errors).length) {
      optionsReducer({ path: 'errors', value: errors });
      return;
    }

    const jobPostVariants =
      state.jobPostVariants?.map((variant) => ({
        activeVariant: false,
        city: variant.city,
        deleted: false,
        insertDate: formatDateToBackend(new Date()),
        jobTitle: variant.useJobTitle ? state.jobTitle : variant.jobTitle,
        latitude: variant.latitude,
        longitude: variant.longitude,
        mainVariant: false,
        postalCode: variant.postalCode || variant.customPostalCode || '',
        refreshCount: 0,
        state: variant.state,
      })) ?? [];

    const updatedState = {
      ...state,
      jobDescription: state?.jobDescription,
      postalCode: state.postalCode ?? state.customPostalCode,
      // remove customPostalCode as it was only needed for handling cases where a city, state was selected and not an address
      customPostalCode: undefined,
      jobPostVariants,
    };

    const body = Object.entries(updatedState).reduce((acc, [key, value]) => {
      acc[key] = value === 'true' || value === 'false' ? Boolean(value) : value;
      return acc;
    }, {} as any);

    await submitForm({ url: '/jobs', body });
  };

  const handleSelectChange = (path: string) => (newValue: { update: any }) => {
    if (errors[path])
      optionsReducer({ path: `errors[${path}]`, type: 'remove' });

    dispatch({
      type: 'set',
      value: newValue?.update ?? {},
    });
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('listId');
      localStorage.removeItem('job');
    };
  }, []);

  useEffect(() => {
    if (requisition) {
      const values: APIRequisition & { returnUrl?: string } = Object.assign(
        {},
        JSON.parse(requisition),
      );
      const isFromOrganization = values?.fromPage === 'organization';
      const isFromReqPage = values?.fromPage === 'requisition';

      if (values?.returnUrl) {
        setReturnUrl(values?.returnUrl);
      }

      const unSetItems: (keyof APIRequisition)[] = [
        'status',
        ...(isFromOrganization
          ? []
          : [
              'organizationId' as keyof APIRequisition,
              'organizationName' as keyof APIRequisition,
              'contactName' as keyof APIRequisition,
              'contactId' as keyof APIRequisition,
              'contactPhone' as keyof APIRequisition,
              'contactEmail' as keyof APIRequisition,
            ]),
        ...(isFromOrganization || isFromReqPage
          ? []
          : [
              'address1' as keyof APIRequisition,
              'address2' as keyof APIRequisition,
              'city' as keyof APIRequisition,
              'state' as keyof APIRequisition,
              'postalCode' as keyof APIRequisition,
            ]),
        'userId',
        'indeedSponsored',
        'jobId',
        'insertDate',
        'selectDate',
        'updateDate',
        'lastIndexDate',
        'dateLastActivity',
        'lastActivityResult',
        'lastActivityType',
        'totalCandidates',
        'numberOfCandidatesInInbox',
        'credentials',
        'datePosted',
        'startDate',
        'positionId',
      ];
      unSetItems.forEach((item) => {
        if (item in values) {
          delete values[item];
        }
      });

      dispatch({ type: 'set', value: values });
      // optionsReducer({ type: 'reset', value: { errors: {} } });
    }
  }, [requisition]);

  useEffect(() => {
    // set userId and distributionList to the current user on fresh page load
    if (!state.userId && me) {
      dispatch({
        type: 'set',
        value: {
          userId: me.userId,
          distributionList: me.emailAddress,
        },
      });
    }
  }, [state.userId, me]);

  return (
    <>
      <div className='flex size-full flex-col items-center justify-start p-4'>
        <Container className='h-fit w-full overflow-auto rounded-md border border-neutral-40 bg-white p-4 shadow-md'>
          <h1 className='my-8 text-2xl font-bold'>Add Requisition</h1>
          <div className='pcr-grid'>
            <div className='flex min-w-0 flex-col rounded-md border border-neutral-40 bg-white p-4 shadow-md'>
              <h2 className='text-xl font-bold'>Job Details</h2>
              <hr />
              <JobDetails
                state={state}
                errors={errors}
                handleChange={handleChange}
                handleDropdownChange={handleDropdownChange}
                handleMultiDropdownChange={handleMultiDropdownChange}
                handleOrgChange={handleSelectChange('organizationId')}
                handleAddressChange={handleAddressChange}
                dispatch={dispatch}
              />
            </div>
            <div className='flex min-w-0 flex-col rounded-md border border-neutral-40 bg-white p-4 shadow-md'>
              <h2 className='text-xl font-bold'>Web Posting</h2>
              <hr />
              <WebPostings
                errors={errors}
                state={state}
                handleChange={handleChange}
                handleDropdownChange={handleDropdownChange}
                handleMultiDropdownChange={handleMultiDropdownChange}
              />
            </div>
            <div className='flex min-w-0 flex-col rounded-md border border-neutral-40 bg-white p-4 shadow-md'>
              <h2 className='text-xl font-bold'>NCHCR</h2>
              <hr />
              <NCHCR
                state={state}
                errors={errors}
                handleDropdownChange={handleDropdownChange}
                handleChange={handleChange}
              />
            </div>
            <div className='flex min-w-0 flex-col rounded-md border border-neutral-40 bg-white p-4 shadow-md'>
              <h2 className='text-xl font-bold'>
                Hiring Contact
                <span
                  className='pl-[4px]'
                  style={{ color: theme.danger, fontSize: 16 }}
                >
                  *
                </span>
              </h2>
              <hr />
              <ReqContact
                state={state}
                errors={errors}
                handleChange={handleChange}
              />
            </div>
            <div className='flex min-w-0 flex-col rounded-md border border-neutral-40 bg-white p-4 shadow-md'>
              <h2 className='text-xl font-bold'>External Feeds</h2>
              <hr />
              <ExternalFeeds
                state={state}
                errors={errors}
                dispatch={dispatch}
                handleDropdownChange={handleDropdownChange}
                sponsoredAdOverLimitError={sponsoredAdOverLimitError}
                handleHCENChange={(data) =>
                  handleSelectChange('hcenSpecialty')(
                    data ?? { update: { hcenSpecialty: '' } },
                  )
                }
              />
            </div>
            <div className='flex min-w-0 flex-col rounded-md border border-neutral-40 bg-white p-4 shadow-md'>
              <h2 className='text-xl font-bold'>
                Job Description
                <span
                  className='pl-[4px]'
                  style={{ color: theme.danger, fontSize: 16 }}
                >
                  *
                </span>
              </h2>

              <hr />
              <div className='mt-2'>
                <CKEditor
                  value={state.jobDescription || ''}
                  errorMessage={errors?.jobDescription}
                  onChange={handleChange('jobDescription')}
                />
              </div>
            </div>
          </div>
          <div className='my-8 flex justify-between'>
            <Button
              onClick={() => {
                if (returnUrl) {
                  navigate(returnUrl);
                  return;
                }

                navigate(-1);
              }}
              variant='secondary'
            >
              Back
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={isPending}
              isLoading={isPending}
            >
              Add Requisition
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}

type HandleChange = (path: any) => (value: any) => void;
type HandleDropdownChange = (
  path: string,
) => (
  event: React.FormEvent<HTMLDivElement>,
  option?: IDropdownOption<any> | undefined,
) => void;
type HandleAddressChange = (
  address: APIAddress & { customPostalCode?: string },
) => void;
type HandleSelectChange = (newValue: any) => void;
type ReqFormErrors = Partial<Record<keyof IRequisition, string>>;

interface IJobDetails {
  state: APIRequisition & { customPostalCode?: string };
  errors: ReqFormErrors;
  handleChange: HandleChange;
  handleAddressChange: HandleAddressChange;
  handleDropdownChange: HandleDropdownChange;
  handleMultiDropdownChange?: HandleDropdownChange;
  handleOrgChange: HandleSelectChange;
  disabled?: boolean;
  dispatch?: React.Dispatch<IAction>;
}

interface IWebPostings {
  state: APIRequisition;
  errors: ReqFormErrors;
  handleChange: HandleChange;
  handleDropdownChange: HandleDropdownChange;
  handleMultiDropdownChange?: HandleDropdownChange;
  disabled?: boolean;
}
interface INCHCR {
  state: APIRequisition;
  errors: ReqFormErrors;
  handleDropdownChange: HandleDropdownChange;
  disabled?: boolean;
  handleChange: HandleChange;
}
interface IReqContact {
  state: APIRequisition;
  errors: ReqFormErrors;
  handleChange: HandleChange;
  disabled?: boolean;
}
interface IExternalFeeds {
  state: IRequisition;
  errors: ReqFormErrors;
  handleDropdownChange: HandleDropdownChange;
  disabled?: boolean;
  handleHCENChange: HandleSelectChange;
  dispatch?: React.Dispatch<IAction>;
  sponsoredAdOverLimitError?: boolean;
}

export function ZipCodeSelect({
  city,
  state,
  postalCode,
  customPostalCode,
  handleChange,
  errorMessage,
  hideLabel = false,
  classNames,
}: {
  city?: string;
  state?: string;
  postalCode?: string | null;
  customPostalCode?: string | null;
  handleChange: (val: string) => void;
  errorMessage?: string;
  hideLabel?: boolean;
  classNames?: {
    trigger?: string;
  };
}) {
  const isFetchingEnabled = !!city && !!state && !postalCode;
  const { data, isError, isFetching } = useCustomQuery({
    url: `https://api.zippopotam.us/us/${state}/${city}`,
    withCredentials: false,
    ignoreBaseUrl: true,
    enabled: isFetchingEnabled,
  });

  const zipOptions = useMemo(() => {
    return isFetchingEnabled && !isFetching
      ? data?.data?.places?.map((p: any) => ({
          value: p['post code'],
          label: p['post code'],
        })) ?? []
      : [];
  }, [data, isFetchingEnabled, isFetching]);

  useEffect(() => {
    const zip = zipOptions[0]?.value;
    if (zip) {
      handleChange(zip);
    }
    // handleChange causes an infinite loop since it renders new every time
    // eslint-disable-next-line
  }, [zipOptions]);

  const isDisabled = !city || !!postalCode;
  const hasNoResults =
    !!city &&
    !!state &&
    !postalCode &&
    !isFetching &&
    (isError || zipOptions.length === 0);

  return (
    <>
      {hasNoResults ? (
        <TextInputNew
          required
          placeholder='Enter zip...'
          disabled={isDisabled}
          value={customPostalCode || ''}
          onChange={(e) => {
            const val = e.target.value;

            const numericValue = val.replace(/[^\d]/g, '').slice(0, 5);
            handleChange(numericValue);
          }}
          errorMessage={errorMessage}
          id='customPostalCode'
          className='max-w-28'
        />
      ) : (
        <Select
          options={zipOptions}
          value={postalCode || customPostalCode || ''}
          onChange={handleChange}
          classNames={{
            container: 'max-w-28',
            label: 'text-base font-semibold',
            trigger: classNames?.trigger,
          }}
          disabled={isDisabled}
          label='Zip code'
          required
          hideLabel={hideLabel}
          placeholder='Select a zip'
          errorMessage={errorMessage}
        />
      )}
    </>
  );
}

export function JobDetails({
  state,
  errors,
  handleChange,
  handleAddressChange,
  handleDropdownChange,
  handleMultiDropdownChange,
  handleOrgChange,
  disabled,
  dispatch,
}: IJobDetails) {
  const handleSpecialCharacters = (value: string) => {
    const forbiddenChars = /[()|$]|bonus|[0-9]/gi;
    if (forbiddenChars.test(value.toLowerCase())) {
      return;
    }

    // Replace double spaces around - and / with a single space
    const formattedValue = value
      .replace(/\s*-\s*/g, ' - ')
      .replace(/\s*\/\s*/g, ' / ');
    handleChange('jobTitle')(formattedValue);
  };

  return (
    <>
      <TextInput
        required
        disabled={disabled}
        label='Job Title'
        handleChange={(value) => {
          handleSpecialCharacters(value);
        }}
        onBlur={(e) => {
          handleSpecialCharacters(e.target.value);
        }}
        onKeyDown={(e: any) => {
          if (e.key === 'Backspace') {
            const { selectionStart } = e.target;
            const jobTitle = state?.jobTitle;
            const prevChar = jobTitle && jobTitle[selectionStart - 1];
            const nextChar = jobTitle && jobTitle[selectionStart];

            if (
              (prevChar === '-' || prevChar === '/') &&
              nextChar === ' ' &&
              jobTitle
            ) {
              e.preventDefault();
              const newValue =
                jobTitle.slice(0, selectionStart) +
                jobTitle.slice(selectionStart + 1);

              handleChange('jobTitle')(newValue);
              return;
            }

            if (
              prevChar === ' ' &&
              jobTitle &&
              (jobTitle[selectionStart - 2] === '-' ||
                jobTitle[selectionStart - 2] === '/') &&
              nextChar !== ' '
            ) {
              e.preventDefault();
              const newValue =
                jobTitle.slice(0, selectionStart - 1) +
                jobTitle.slice(selectionStart);
              handleChange('jobTitle')(newValue);
              return;
            }
          }
        }}
        errorMessage={errors.jobTitle}
        value={state.jobTitle ?? ''}
      />
      <Label className='mt-2 text-base font-semibold'>
        Job location
        <span className='ml-1 text-error'>*</span>
      </Label>
      <div className='mt-2 flex gap-2'>
        <AddressInput
          required
          checkRegion
          handleChange={handleAddressChange}
          handleClear={() => {
            handleAddressChange({
              address1: '',
              city: '',
              state: '',
              postalCode: '',
              customPostalCode: '',
            });
          }}
          selectedAddress={{
            address1: state.address1,
            address2: state.address2,
            city: state.city,
            state: state.state,
            postalCode: state.postalCode,
          }}
          errorMessage={errors.city}
          disabled={disabled}
          className='flex-1'
          hideLabel
        />
        <ZipCodeSelect
          city={state.city}
          state={state.state}
          postalCode={state.postalCode}
          customPostalCode={state.customPostalCode}
          handleChange={handleChange('customPostalCode')}
          errorMessage={errors.customPostalCode}
          hideLabel
        />
      </div>
      <AdditionalLocationVariants
        state={state}
        errors={errors}
        dispatch={dispatch}
        disabled={disabled}
      />
      <div className='mt-1'>
        <Dropdown
          multiSelect
          required
          disabled={disabled}
          label='Profession'
          color='black'
          errorMessage={errors.profession}
          selectedKeys={state?.profession ? state?.profession?.split(',') : []}
          onChange={
            handleMultiDropdownChange && handleMultiDropdownChange('profession')
          }
          options={profession.map((p) => ({
            key: p,
            text: p,
          }))}
        />
      </div>
      <div className='flex flex-col gap-1'>
        <span className='label'>
          Salary
          <Required />
        </span>
        <div className='flex items-center gap-2'>
          <TextInput
            srOnly
            disabled={disabled}
            label='Salary bottom'
            type='string'
            value={state.salaryBottom ?? ''}
            handleChange={(value) => {
              if (value === '' || /^[+-]?\d+$/.test(value)) {
                handleChange('salaryBottom')(value);
              } else {
                handleChange('salaryBottom')(value.replace(/[^0-9]/g, ''));
              }
            }}
          />
          <span>to</span>
          <TextInput
            srOnly
            disabled={disabled}
            label='Salary Top'
            type='string'
            value={state.salaryTop ?? ''}
            handleChange={(value) => {
              if (value === '' || /^[+-]?\d+$/.test(value)) {
                handleChange('salaryTop')(value);
              } else {
                handleChange('salaryTop')(value.replace(/[^0-9]/g, ''));
              }
            }}
          />
        </div>
        {errors.salaryTop && <ErrorMessage message={errors.salaryTop} />}
      </div>
      <OrganizationSelect
        value={
          state.organizationId
            ? {
                label: state.organizationName,
                id: state.organizationId,
                contract: !!state.orgHasContract,
              }
            : undefined
        }
        onChange={(value) => {
          handleOrgChange({
            update: {
              organizationName: value.label,
              organizationId: value.id,
              orgHasContract: value.contract,
            },
          });
        }}
        onClear={() => {
          handleOrgChange({
            update: {
              organizationName: '',
              organizationId: undefined,
            },
          });
        }}
        disabled={disabled}
        errorMessage={errors.organizationId}
        required
      />
      <UsersDropdown
        required
        disabled={disabled}
        selectedKey={state.userId}
        onChange={handleDropdownChange('userId')}
        errorMessage={errors.userId}
        activeUserOnly={!state.organizationId}
        disabledOption
      />
      <Dropdown
        required
        disabled={disabled}
        color='black'
        label='Status'
        selectedKey={state.status}
        options={status}
        onChange={handleDropdownChange('status')}
      />
    </>
  );
}

export function WebPostings({
  state,
  handleChange,
  handleDropdownChange,
  handleMultiDropdownChange,
  disabled,
  errors,
}: IWebPostings) {
  return (
    <>
      <Dropdown
        required
        disabled={disabled}
        color='black'
        label='Show on web'
        onChange={handleDropdownChange('showOnWeb')}
        selectedKey={String(state.showOnWeb)}
        errorMessage={errors.showOnWeb}
        options={[
          { key: 'true', text: 'Show' },
          { key: 'false', text: "Don't show" },
        ]}
      />
      <TextInput
        required
        type='number'
        disabled={disabled}
        label='Number of openings'
        min={1}
        value={state.numberOfJobs ?? ''}
        errorMessage={errors.numberOfJobs}
        handleChange={handleChange('numberOfJobs')}
      />
      <Dropdown
        required
        color='black'
        label='Job Type'
        disabled={disabled}
        selectedKey={state.jobType}
        errorMessage={errors.jobType}
        options={jobTypes}
        onChange={handleDropdownChange('jobType')}
      />

      <div className='my-2 flex items-center gap-2'>
        <Checkbox
          checked={state.autoRefresh === 'true'}
          onClick={(event) => {
            const isCurrentlyChecked = state.autoRefresh === 'true';

            handleDropdownChange('autoRefresh')(
              event as any,
              isCurrentlyChecked
                ? { text: '', key: 'false' }
                : { text: '', key: 'true' },
            );
          }}
          id='auto-refresh'
          disabled={disabled || state?.indeedSponsored !== 0}
        />
        <Label
          htmlFor='auto-refresh'
          className='cursor-pointer text-base font-semibold'
        >
          Auto refresh <span className='text-error'>*</span>
        </Label>
      </div>
      {/*  */}
      <Dropdown
        color='black'
        multiSelect={!!handleMultiDropdownChange}
        disabled={disabled}
        label='Licenses/Cert'
        selectedKeys={
          handleMultiDropdownChange
            ? state?.credentials
              ? state?.credentials?.split(',')
              : []
            : undefined
        }
        onChange={
          handleMultiDropdownChange
            ? handleMultiDropdownChange('credentials')
            : handleDropdownChange('credentials')
        }
        selectedKey={handleMultiDropdownChange ? undefined : state?.credentials}
        errorMessage={errors.credentials}
        options={[
          { key: 'CPR Certification', text: 'CPR Certification' },
          { key: 'RN License', text: 'RN License' },
          { key: 'BLS Certification', text: 'BLS Certification' },
        ]}
      />
    </>
  );
}

export function NCHCR({
  state,
  errors,
  handleChange,
  handleDropdownChange,
  disabled,
}: INCHCR) {
  return (
    <>
      <StructuredTitleDropdown
        label='NCHCR Specialty'
        required
        disabled={disabled}
        errorMessage={errors?.title}
        value={state?.titleId || ''}
        handleChange={(title: string, titleId: string) => {
          handleChange('titleId')(titleId);
          handleChange('title')(titleId === 'Other' ? null : title);
        }}
      />
      <Dropdown
        color='black'
        label='Visa Type'
        disabled={disabled}
        onChange={handleDropdownChange('visaType')}
        selectedKey={state.visaType}
        options={visaTypes.map((v) => ({ key: v, text: v }))}
      />
      <Dropdown
        color='black'
        label='Splits'
        disabled={disabled}
        onChange={handleDropdownChange('split')}
        selectedKey={String(state.split)}
        options={[
          { key: 'true', text: 'Yes' },
          { key: 'false', text: 'No' },
        ]}
      />
      <Dropdown
        color='black'
        label='New Grads'
        disabled={disabled}
        onChange={handleDropdownChange('newGraduates')}
        selectedKey={String(state.newGraduates)}
        options={[
          { key: 'true', text: 'Yes' },
          { key: 'false', text: 'No' },
        ]}
      />
    </>
  );
}

export function ReqContact({
  state,
  errors,
  handleChange,
  disabled,
}: IReqContact) {
  const isDisabled = disabled || !state.organizationId;

  const value = useMemo(() => {
    if (disabled) {
      return '';
    }

    if (!state.organizationId) {
      return 'Organization selection required';
    }

    return state?.contactName;
  }, [disabled, state?.contactName, state.organizationId]);

  return (
    <>
      <ContactSelectDropdown
        required
        label=''
        disabled={isDisabled}
        errorMessage={
          errors.contactId || errors.contactPhone || errors.contactEmail || ''
        }
        handleChange={(contact) => {
          handleChange('contactName')(
            `${contact?.firstName} ${contact?.lastName}`,
          );
          handleChange('contactPhone')(contact?.phone);
          handleChange('contactEmail')(contact?.emailAddress);
          handleChange('contactId')(contact?.contactId);
        }}
        onClear={() => {
          handleChange('contactName')('');
          handleChange('contactPhone')('');
          handleChange('contactEmail')('');
          handleChange('contactId')('');
        }}
        selectedContactId={state?.contactId}
        organization={{
          organizationId: state.organizationId,
          organizationName: state.organizationName,
          organizationHasContract: !!state.orgHasContract,
        }}
        value={value}
      />
      <div className='ml-2 mt-1 flex w-full min-w-0 items-center gap-2 text-sm'>
        {state?.contactEmail && (
          <div className='flex min-w-0 flex-shrink items-center gap-1 text-neutral-50'>
            <MailIcon size={14} className='mt-0.5 flex-shrink-0' />
            <span className='truncate'>{state.contactEmail}</span>
          </div>
        )}
        {state?.contactPhone && (
          <div className='flex min-w-0 flex-shrink items-center gap-1 text-neutral-50'>
            <PhoneIcon size={14} className='mt-0.5 flex-shrink-0' />
            <PhoneNumber phoneNumber={state?.contactPhone} />
          </div>
        )}
      </div>
    </>
  );
}

export function ExternalFeeds({
  state,
  errors,
  handleDropdownChange,
  handleHCENChange,
  dispatch,
  disabled,
  sponsoredAdOverLimitError,
}: IExternalFeeds) {
  const { findUserById } = useGlobalState();
  const user = findUserById && findUserById(state?.userId);

  return (
    <>
      {sponsoredAdOverLimitError && (
        <div className='my-2'>
          <span className='text-sm tracking-[-0.56px] text-error'>
            Sponsored ad count is over the limit
          </span>
        </div>
      )}
      <Dropdown
        required
        color='black'
        disabled={disabled}
        label='ZipRecruiter Categories'
        onChange={handleDropdownChange('industry')}
        selectedKey={state.industry}
        options={zipRecruiterTypes.map((t) => ({ key: t, text: t }))}
      />
      <TextInput
        disabled
        label='Recruiter Email'
        value={state?.distributionList ?? ''}
      />
      <Dropdown
        required
        color='black'
        label='Featured'
        disabled={disabled}
        onChange={handleDropdownChange('featured')}
        selectedKey={String(state.featured)}
        options={[
          { key: 'true', text: 'Yes' },
          { key: 'false', text: 'No' },
        ]}
      />
      <div className='my-2 flex flex-col gap-1'>
        <div className='flex items-center gap-2'>
          <Checkbox
            checked={!!state.indeedSponsored}
            onClick={(event) => {
              const isCurrentlyChecked = !!state.indeedSponsored;

              dispatch &&
                dispatch({
                  path: 'autoRefresh',
                  value: isCurrentlyChecked ? 'true' : 'false',
                });

              event.stopPropagation();
              handleDropdownChange('indeedSponsored')(
                event as any,
                isCurrentlyChecked
                  ? { text: '', key: 0 }
                  : { text: '', key: 2 },
              );
            }}
            id='indeed-sponsored'
            disabled={disabled}
          />
          <Label
            htmlFor='indeed-sponsored'
            className='cursor-pointer text-base font-semibold'
          >
            Indeed sponsored <span className='text-error'>*</span>
          </Label>
        </div>
        {!!user && (
          <span className='text-sm tracking-[-0.56px] text-success'>
            <span>{user?.sponsoredAdsCount ?? '0'}</span>
            {' of '}
            <span>{user?.sponsoredAdLimit ?? '0'}</span>
            {' sponsored ads used'}
          </span>
        )}
      </div>

      <SelectDeprecated
        label='HCEN Specialty'
        isDisabled={disabled}
        placeholder=''
        isClearable
        value={
          state.hcenSpecialty
            ? { value: state.hcenSpecialty, label: state.hcenSpecialty }
            : undefined
        }
        options={HCENSpecialties.map((title) => ({
          value: title,
          label: title,
          update: { hcenSpecialty: title },
        }))}
        onChange={handleHCENChange}
        errorMessage={errors.hcenSpecialty}
      />
    </>
  );
}
