import { FormEvent, useEffect, useReducer } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { useToast } from '../../hooks/use-toast';
import { useCustomMutation } from '../../hooks/useCustomMutation';
// import { useHideRingCentralComponent } from '../../hooks/useHideRingCentralComponent';
import { usePageTitle } from '../../hooks/usePageTitle';
import { useQueryParams } from '../../hooks/useQueryParams';
import { APIUser } from '../../react-app-env';
import {
  generateAuthParams,
  constructAuthUrl,
  Providers,
} from '../../utils/login';
import { theme } from '../../utils/theme';
import { TextInput, commonReducer } from '../common';
import { Button } from '../common/Button';
import { UserNoOutlineIcon, MicrosoftIcon, GoogleIcon } from '../common/Icons';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

const initialOptions = {
  email: '',
  password: '',
  error: false,
  loading: false,
};

export function Login() {
  usePageTitle('Sign In • mlee Pulse');
  // const { showRingCentralComponent } = useHideRingCentralComponent();
  const { toast } = useToast();
  const [params] = useSearchParams();
  const { queryParams } = useQueryParams();
  const navigate = useNavigate();
  const [{ email, password, error }, reducer] = useReducer(
    commonReducer,
    initialOptions,
  );

  useEffect(() => {
    if (queryParams.error === 'auth') {
      toast({
        title: 'Error!',
        description: 'Error logging in! Please try again.',
        variant: 'destructive',
        duration: 15000,
      });
    }

    if (queryParams.error === 'noAccount') {
      toast({
        title: 'Error',
        description: 'No account found! Please contact administrator.',
        variant: 'destructive',
        duration: 15000,
      });
    }
  }, [queryParams, toast]);

  async function handleSSOLogin(provider: Providers) {
    try {
      const authParams = await generateAuthParams();

      sessionStorage.setItem('auth_state', authParams.state);
      sessionStorage.setItem('auth_nonce_verifier', authParams.nonceVerifier);
      sessionStorage.setItem('code_verifier', authParams.codeVerifier);

      const authUrl = constructAuthUrl(authParams, provider);

      window.location.assign(authUrl);
    } catch (error) {
      console.error('Error generating authentication parameters', error);
      toast({
        title: 'Error',
        description: 'Error generating authentication parameters.',
        variant: 'destructive',
        duration: 15000,
      });
    }
  }

  const handleChange = (path: string) => (value: any) => {
    reducer({ path, value });
  };

  const { isPending, mutate: submitLogin } = useCustomMutation({
    onSuccess: (response) => {
      const { user } = response.data as { user: APIUser };
      const redirect = params.get('redirectUrl');

      localStorage.setItem('user', JSON.stringify(user));
      navigate(redirect ?? '/');
    },
    onSuccessMessage: 'Login successful',
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    reducer({ path: 'loading', value: true });

    await submitLogin({
      url: `/login?emailAddress=${email}&password=${encodeURIComponent(password)}`,
      body: {},
    });
  };

  return (
    <div className='h-screen p-0'>
      <div className='flex h-full flex-col items-center justify-center gap-12 p-8 sm:p-16'>
        <div className='flex flex-col gap-8'>
          <div>
            <div className='flex justify-center'>
              <div className=' rounded-full border-[3px] border-purple p-5'>
                <UserNoOutlineIcon size={64} color={theme.purple} />
              </div>
            </div>
            <h1 className='purple text-center font-semibold text-blue-900'>
              Sign in to your account
            </h1>
          </div>
          <div className='m-auto flex flex-col items-center gap-4'>
            <div className='flex gap-4'>
              <button
                onClick={() => handleSSOLogin('Entra')}
                className='flex w-[224px] items-center gap-2 border border-neutral-60 bg-white px-4 py-2 font-medium'
              >
                <MicrosoftIcon />
                <span>Sign in with Microsoft</span>
              </button>
              <button
                onClick={() => handleSSOLogin('Google')}
                className='flex w-[224px] items-center gap-2 border border-neutral-60 bg-white px-4 py-2 font-medium'
              >
                <GoogleIcon />
                <span>Sign in with Google</span>
              </button>
            </div>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          className='relative flex w-full flex-col justify-center sm:w-1/2'
        >
          {!isProduction && (
            <>
              <TextInput
                required
                label='Email Address'
                type='email'
                autoComplete='email'
                handleChange={handleChange('email')}
              />
              <TextInput
                required
                label='Password'
                type='password'
                handleChange={handleChange('password')}
              />
              <>
                {error && (
                  <p
                    className='slide-in m-0 text-sm text-red-900'
                    style={{ position: 'absolute', bottom: 50 }}
                  >
                    Invalid email and/or password
                  </p>
                )}
              </>
              <div className='mt-4 flex items-center justify-between'>
                <Button
                  type='submit'
                  disabled={isPending}
                  isLoading={isPending}
                >
                  Sign In
                </Button>
                <Link to='/password-reset' className='text-end text-blue-900'>
                  Forgot Password?
                </Link>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
}
