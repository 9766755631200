import { useEffect } from 'react';

export function usePageTitle(title?: string) {
  useEffect(() => {
    const prevTitle = document.title;

    document.title = title ? `${title} • mlee Pulse` : 'mlee Pulse';

    return () => {
      document.title = prevTitle;
    };
  }, [title]);
}
